import Bluebird from 'bluebird';
import equal from 'fast-deep-equal';
import { stringify as toQueryStringParams } from 'query-string';
import { excludeKeysOf } from './Utils';
import { WebServiceHelper } from './WebServiceHelper';
import * as Models from './models';

Bluebird.config({
	cancellation: true,
});

export interface IPageCollectionControllerFetchResult<T> {
	/** The result of the fetch */
	values: T;

	/**
	 * True if the current page token was not used (context changed, etc.) or the controller fetched the resource for the
	 * first time.
	 */
	fetchedFirstPage: boolean;
}

export interface IPageCollectionController<T extends Models.IBaseApiModel = Models.IBaseApiModel> {
	/**
	 * Fetches the next batch in the page collection. If a new sortDescriptor or pageSize is provided, as compared to the
	 * previous fetch, then this will handle replacing the internal list with the result of the current fetch.
	 *
	 * @param sortDescriptor Optional sort params
	 * @param pageSize Optional page size
	 * @param key/value Pairs to add to the api query (encoding handled by getNext implementation)
	 * @returns The fetched batch. The result of getAllResults will have the aggregate list.
	 */
	getNext(
		sortDescriptor?: Models.ISortDescriptor,
		pageSize?: number,
		params?: any
	): Bluebird<IPageCollectionControllerFetchResult<T[]>>;
	getPageSize(): number;
	getSortDescriptor(): Models.ISortDescriptor;
	getTotalCount(): number;
	hasAllPages(sortDescriptor?: Models.ISortDescriptor, pageSize?: number, params?: any): boolean;
	isFetchingResults(): boolean;
	reset(): void;
}

export interface IPageCollectionControllerInsert<T extends Models.IBaseApiModel = Models.IBaseApiModel> {
	item: T;
	position?: 'before' | 'after';
	relativeTo?: string | T;
}

/** Encapsulates logic needed to fetch paged results. Maintains a list of fetched results (@see getAllResults) */
export class PageCollectionController<T extends Models.IBaseApiModel = Models.IBaseApiModel>
	implements IPageCollectionController<T>
{
	public static defaultPageSize = 25;

	private apiParams: Models.IDictionary;
	private apiPath: string;
	private client: WebServiceHelper;
	private fetchComplete: boolean;
	private fetchContext: Models.IPagedResultFetchContext;
	private fetchingResults: boolean;
	private fetchParams: any;
	private fetchPromise: Bluebird<IPageCollectionControllerFetchResult<T[]>>;
	private mFetchResults: T[];
	private totalCount: number;

	constructor(client: WebServiceHelper, apiPath: string, apiParams?: Models.IDictionary) {
		this.client = client;
		this.apiPath = apiPath;
		this.apiParams = apiParams;
		this.reset();
	}

	public reset = () => {
		if (this.fetchPromise) {
			this.fetchPromise.cancel();
			this.fetchPromise = null;
		}

		this.totalCount = -1;
		this.fetchComplete = false;
		this.fetchParams = null;
		this.fetchContext = {
			pageSize: PageCollectionController.defaultPageSize,
			sort: 'asc',
		};
	};

	public get fetchResults() {
		return this.mFetchResults;
	}

	public getNext = (
		sortDescriptor: Models.IPagedResultFetchContext = this.fetchContext,
		pageSize = this.fetchContext.pageSize,
		params?: any
	) => {
		const nextFetchContext: Models.IPagedResultFetchContext = {
			...this.fetchContext,
			...sortDescriptor,
			pageSize,
		};
		// remove null or undefined values
		Object.keys(nextFetchContext).forEach(key => {
			if (
				// FOLLOWUP: Resolve
				// @ts-ignore
				nextFetchContext[key] === null ||
				// FOLLOWUP: Resolve
				// @ts-ignore
				nextFetchContext[key] === undefined
			) {
				// FOLLOWUP: Resolve
				// @ts-ignore
				delete nextFetchContext[key];
			}
		});

		const hasFetchChanged = !this.contextIsEqual(nextFetchContext, pageSize, params);

		if (!hasFetchChanged && this.fetchComplete) {
			// return early
			if (this.fetchPromise) {
				return this.fetchPromise;
			}

			return Bluebird.resolve({
				fetchedFirstPage: false,
				values: [] as T[],
			});
		}

		if (hasFetchChanged) {
			// clear the token
			nextFetchContext.pageToken = '';
		}

		if (!this.fetchPromise || (!!this.fetchPromise && hasFetchChanged)) {
			// cancel the current fetch
			if (this.fetchPromise) {
				this.fetchPromise.cancel();
			}

			this.fetchingResults = true;
			this.fetchContext = nextFetchContext;
			this.fetchParams = params;
			const fetchingFirstPage = this.mFetchResults === null || this.fetchingResults === undefined || hasFetchChanged;

			this.fetchPromise = new Bluebird<IPageCollectionControllerFetchResult<T[]>>((resolve, reject, onCancel) => {
				let canceled = false;
				if (onCancel) {
					onCancel(() => {
						canceled = true;
					});
				}

				const nextFetchContextWithoutTypes = excludeKeysOf(nextFetchContext, ['typeOf']);
				// create a mapping of "typeof" to "," delimited list of types
				const types =
					!!nextFetchContext && !!nextFetchContext.typeOf ? { typeOf: nextFetchContext.typeOf.join(',') } : {};

				this.client.callWebServiceWithOperationResults<Models.IPagedCollection<T>>(
					`${this.apiPath}?${toQueryStringParams({
						...(this.apiParams || {}),
						...nextFetchContextWithoutTypes,
						...types,
						...(params || {}),
					})}`,
					'GET',
					null,
					opResult => {
						if (!canceled) {
							this.fetchContext = {
								...nextFetchContext,
								pageToken: opResult.value.pageToken,
							};
							this.totalCount = opResult.value.totalCount;
							this.fetchComplete = !opResult.value.pageToken;
							this.fetchingResults = false;
							this.fetchPromise = null;
							const currentBatch = opResult.value.values;
							this.mFetchResults = hasFetchChanged
								? opResult.value.values
								: [...(this.mFetchResults || []), ...currentBatch];
							resolve({
								fetchedFirstPage: fetchingFirstPage,
								values: currentBatch,
							});
						}
					},
					error => {
						if (!canceled) {
							this.fetchingResults = false;
							reject(error);
						}
					}
				);
			});
		}

		return this.fetchPromise;
	};

	public removeItems = (itemIndexes: number[]) => {
		if (this.fetchingResults) {
			const itemsToRemove = itemIndexes.map(x => this.mFetchResults[x]);
			const filteredResults = this.mFetchResults.filter(x => itemsToRemove.findIndex(s => x === s) >= 0);
			this.mFetchResults = filteredResults;
		}
	};

	public insertItems = (items: IPageCollectionControllerInsert<T>[]) => {
		if (!!items && items.length > 0) {
			const currentFetchResults = this.mFetchResults || [];
			const fetchResults: T[] = [...currentFetchResults];
			const fetchResultsDictionary: Models.IDictionary<T> = {};
			currentFetchResults.forEach(x => {
				fetchResults.push(x);
				if (x.id) {
					fetchResultsDictionary[x.id] = x;
				}
			});

			items.forEach(x => {
				let index = 0;
				if (x.relativeTo) {
					const relativeToItem = typeof x.relativeTo === 'string' ? fetchResultsDictionary[x.relativeTo] : x.relativeTo;
					if (relativeToItem) {
						index = fetchResults.indexOf(relativeToItem);
						if (index >= 0) {
							const position = x.position || 'before';
							index = position === 'before' ? index : index + 1;
						} else {
							index = 0;
						}
					}
				}
				fetchResults.splice(index, 0, x.item);
			});

			this.mFetchResults = fetchResults;
		}
	};

	public setItemAtIndex = (item: T, index: number) => {
		if (item) {
			const fetchResults = this.mFetchResults || [];
			fetchResults[index] = item;
			this.mFetchResults = fetchResults;
		}
	};

	public isFetchingResults = () => {
		return this.fetchingResults;
	};

	public hasAllPages = (sortDescriptor?: Models.IPagedResultFetchContext, pageSize?: number, params?: any) => {
		const contextIsEqual = this.contextIsEqual(sortDescriptor, pageSize, params);
		return this.fetchComplete && contextIsEqual;
	};

	public getTotalCount = () => {
		return this.totalCount;
	};

	public getPageSize = () => {
		return this.fetchContext.pageSize;
	};

	public getSortDescriptor = () => {
		const { sort, sortBy } = this.fetchContext;
		const sortDescriptor: Models.ISortDescriptor = { sort, sortBy };
		return sortDescriptor;
	};

	private contextIsEqual = (
		sortDescriptor = this.fetchContext,
		pageSize = this.fetchContext.pageSize,
		params?: any
	) => {
		const nextFetchContext: Models.IPagedResultFetchContext = {
			...this.fetchContext,
			...sortDescriptor,
			pageSize,
		};
		return (
			((!this.fetchContext && !nextFetchContext) || equal(this.fetchContext, nextFetchContext)) &&
			((!params && !this.fetchContext) || equal(params, this.fetchParams))
		);
	};
}
