import {
	createRichContentEditorStateBlock,
	getDefaultBulkMessagingBodyEditorState,
	getKeyDateKindFromResourceSelectorId,
	getPolicyFilterCriteriaInCriteria,
	openUrlInNewTab,
	replaceTokenWithActualFirstName,
} from '@AppModels/UiUtils';
import * as Api from '@ViewModels';
import * as H from 'history';
import { immerable } from 'immer';
import { action, computed, observable, runInAction } from 'mobx';
import { parse as getQueryStringParams } from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import { v4 as uuidgen } from 'uuid';
import {
	ContactSortKey,
	EmailMailerType,
	IComposerContent,
	IFabContext,
	SingleEmailGuidedComposerStep,
} from '../models';
import { BrowserLocationStateHistory } from '../models/Browser';
import { EventLogger } from '../models/Logging';
import { OAuthSignIn } from '../models/OAuthSignIn';
import { IActionItemsTheme, IAppTheme } from '../models/Themes';
import { createRichContentEditorStateWithText, isValidEmail } from '../models/UiUtils';
import * as Colors from '../web/styles/colors';
export * from '@ViewModels';

export interface IAppToastMessage extends Api.IToastMessage {
	customContent?: React.ReactNode;
	onLinkClicked?(routerProps?: RouteComponentProps<any>): void;
}

export type SignInField = 'email' | 'password' | 'username';
export type SignInErrorArea = SignInField & 'global';

export type LoginErrorArea = 'global' | 'password' | 'email' | 'username';
export interface ISignInRequest {
	email?: string;
	password?: string;
	username?: string;
}

export interface IApiParams extends Api.ISortDescriptor {
	excludeExpired?: boolean;
}

class SignInViewModel extends Api.ViewModel {
	@observable protected mEmail: string;
	@observable protected mPassword: string;
	@observable protected mUsername: string;
	@observable public validationErrorField?: LoginErrorArea;
	@observable public validationErrorMessage?: string;
	protected apiBasePath: string;
	protected httpMethod: Api.HTTPMethod = 'POST';
	protected required: SignInField[] = [];
	protected successCallback?: (session: Api.IUserSession) => void;

	constructor(userSession: Api.UserSessionContext) {
		super(userSession);
	}

	public set email(email: string) {
		runInAction(() => {
			this.mEmail = email;
			this.validationErrorField = null;
			this.validationErrorMessage = null;
		});
	}

	@computed
	public get email() {
		return this.mEmail;
	}

	public set password(password: string) {
		runInAction(() => {
			this.mPassword = password;
			this.validationErrorField = null;
			this.validationErrorMessage = null;
		});
	}

	@computed
	public get password() {
		return this.mPassword;
	}

	public set username(username: string) {
		runInAction(() => {
			this.mUsername = username;
			this.validationErrorField = null;
			this.validationErrorMessage = null;
		});
	}

	@computed
	public get username() {
		return this.mUsername;
	}

	@computed
	public get request() {
		const request: ISignInRequest = {};

		if (this.required.includes('email')) {
			request.email = this.mEmail;
		}

		if (this.required.includes('password')) {
			request.password = this.mPassword;
		}

		if (this.required.includes('username')) {
			request.username = this.mUsername;
		}

		return request;
	}

	@action
	public hasValidForm() {
		if (this.required.includes('email') && !this.mEmail) {
			this.validationErrorField = 'email';
			this.validationErrorMessage = 'Email is required';
			return false;
		}

		if (this.required.includes('password') && !this.mPassword) {
			this.validationErrorField = 'password';
			this.validationErrorMessage = 'Password is required';
			return false;
		}

		if (this.required.includes('username') && !this.mUsername) {
			this.validationErrorField = 'username';
			this.validationErrorMessage = 'Username is required';
			return false;
		}

		this.validationErrorField = null;
		this.validationErrorMessage = null;

		return true;
	}

	@action
	public async submit() {
		if (!this.hasValidForm()) {
			return;
		}

		try {
			this.busy = true;
			const value: Api.IPagedCollection<Api.ContactViewModel> = await this.mUserSession.webServiceHelper.callAsync(
				this.apiBasePath,
				this.httpMethod,
				this.request
			);

			this.busy = false;
			if (this.successCallback) {
				this.successCallback(this.userSession);
			}
			return value;
		} catch (error) {
			this.busy = false;
			this.submissionError(error);
			return error;
		}
	}

	public getErrorMessage(area: LoginErrorArea) {
		return this.hasErrorMessage(area) ? this.validationErrorMessage || '' : '';
	}

	public hasErrorMessage(area: LoginErrorArea) {
		return this.validationErrorField === area;
	}

	protected submissionError = (error: Api.IOperationResultNoValue) => {
		runInAction(() => {
			this.validationErrorField = 'global';
			this.validationErrorMessage = error.systemMessage;
		});
	};
}

export class LoginViewModel extends SignInViewModel {
	@observable public needsPhone: boolean;
	@observable public challenging: boolean;
	@observable public phoneNumber: string;
	@observable public challengeCode?: string;
	@observable public challengeCodeSystemMessage: string;
	protected submitPromise: Promise<any>;

	constructor(userSession: Api.UserSessionContext, successCallback?: (session: Api.IUserSession) => void) {
		super(userSession);
		this.successCallback = successCallback;
		this.required = ['email', 'password'];
		this.needsPhone = false;
		this.challenging = false;
	}

	@action
	public async submit() {
		if (!this.hasValidForm() || this.busy) {
			return;
		}

		this.busy = true;

		try {
			const credential: Api.IAuthenticationRequest = {
				email: this.mEmail,
				password: this.mPassword,
			};
			if (this.phoneNumber) {
				credential.phoneNumber = this.phoneNumber;
			}
			if (this.challengeCode) {
				credential.twoFactorCode = this.challengeCode;
			}
			const userSession = await this.userSession.updateWithCredential(credential);
			this.busy = false;
			if (this.successCallback) {
				this.successCallback(userSession);
			}
		} catch (error: any) {
			if (error.value) {
				const response: Api.IAuthenticationResponse = error.value;
				switch (response.result) {
					case Api.AuthenticationResult.Failure:
					case Api.AuthenticationResult.CantIssueToken:
					case Api.AuthenticationResult.InvalidCredentials:
						this.validationErrorField = 'global';
						this.validationErrorMessage = response.message ?? 'Invalid credentials';
						break;

					// show phone number entry field
					case Api.AuthenticationResult.MissingAuthConfig:
						this.needsPhone = true;
						break;

					// show code entry field
					case Api.AuthenticationResult.Challenge:
						this.needsPhone = false;
						this.challenging = true;
						this.challengeCodeSystemMessage = response.message;
						break;
					default:
						break;
				}
			} else {
				this.submissionError(error);
			}
			this.busy = false;
		}
	}
}

export class RedtailSignInViewModel extends SignInViewModel {
	constructor(userSession: Api.UserSessionContext) {
		super(userSession);
		this.apiBasePath = 'user/integrations/redtail/configure';
		this.required = ['username', 'password'];
	}

	getIntegrationStatus = async (): Promise<Api.IIntegrationStatus> => {
		try {
			this.busy = true;
			const value: Api.IIntegrationStatus = await this.mUserSession.webServiceHelper.callAsync(
				'user/integrations/redtail/status',
				'GET'
			);

			this.busy = false;
			return value;
		} catch (error) {
			this.busy = false;
			return error;
		}
	};

	successCallback = () => {
		this.mUserSession.resolvePendingAction(Api.PendingActions.ConnectRedtail);
	};

	submissionError = (error: Api.IOperationResultNoValue) => {
		throw Api.asApiError(error);
	};
}

export enum IntegrationOAuthStep {
	SignIn,
	InProgress,
	Connected,
}

/**
 * Test login string
 * https://localhost:5001/oauth/public/v1/authorize?client_id=0oafxtwzsU2D4UWic356&response_type=code&redirect_uri=https://localhost:3000/#/dashboard&state=asdfasdfadsf&scope=openid
 */
export class OAuthLoginViewModel extends LoginViewModel {
	private mOAuthSignIn: OAuthSignIn;
	private queryString: any;
	private queryStringRaw: string;
	private redirectToUrlCallback: (sessionToken: string) => void;
	constructor(userSession: Api.UserSessionContext, sessionTokenCallback: (sessionToken: string) => void) {
		super(userSession);
		this.mOAuthSignIn = new OAuthSignIn(userSession.webServiceHelper.baseUrl);
		this.redirectToUrlCallback = sessionTokenCallback;
	}

	public get oauthSignIn() {
		return this.mOAuthSignIn;
	}

	@action
	public setQueryString(queryString: string) {
		this.queryString = getQueryStringParams(queryString);
		this.queryStringRaw = queryString;

		if (!this.queryString.client_id || this.queryString.client_id.length === 0) {
			this.validationErrorField = 'global';
			this.validationErrorMessage = 'client_id is required';
			return;
		}

		if (!this.queryString.state || this.queryString.state.length === 0) {
			this.validationErrorField = 'global';
			this.validationErrorMessage = 'state is required';
			return;
		}

		if (!this.queryString.scope || this.queryString.scope.length === 0) {
			this.validationErrorField = 'global';
			this.validationErrorMessage = 'scope is required';
			return;
		}

		if (!this.queryString.redirect_uri || this.queryString.redirect_uri.length === 0) {
			this.validationErrorField = 'global';
			this.validationErrorMessage = 'redirect_uri is required';
			return;
		}

		if (!this.queryString.response_type || this.queryString.response_type.length === 0) {
			this.validationErrorField = 'global';
			this.validationErrorMessage = 'response_type is required';
			return;
		}
	}

	@action
	public submit() {
		if (!this.hasValidForm()) {
			return;
		}
		if (this.busy) {
			return this.submitPromise;
		}

		this.busy = true;
		this.submitPromise = this.mOAuthSignIn
			.validateUsernameAndPassword(this.email, this.password)
			.then(resp => {
				runInAction(() => {
					// Explicitly not clearing the busy flag to not re-render the login field while the redirect is occurring.
					const getAuthorizeUrl = this.oauthSignIn.getAuthorizeUrl(this.queryStringRaw, resp);
					this.redirectToUrlCallback(getAuthorizeUrl);
				});
			})
			.catch((error: Error) => {
				runInAction(() => {
					this.busy = false;
					this.validationErrorField = 'global';
					this.validationErrorMessage = error.message;
				});
			});

		return this.submitPromise;
	}
}

export enum BulkEmailComposerStep {
	SelectTemplate = 0,
	Compose,
}

export enum ComposeEmailError {
	NoError = 0,
	NoSubject,
	NoContent,
	NoRecipientEmail,
}

export enum SingleEmailGuideStep {
	SelectRecipient = 0,
	GuidedComposer,
	Compose,
	Complete,
}

/**
 * OPEN: Can this consume ComposeEmailToViewModel?
 *
 * Guide the user into creating an email with a guide initiated from the dashboard ONLY
 */
export class ComposeEmailToWithGuideViewModel extends Api.ViewModel {
	@observable private mGuidedComposerStep: SingleEmailGuidedComposerStep;
	@observable private mShowingModal: boolean;
	@observable private mStep: SingleEmailGuideStep;
	@observable.ref private mEmailMessage: Api.EmailMessageViewModel<File>;
	@observable.ref private mRecipient: Api.ContactViewModel;
	@observable.ref private mKeyFact: Api.IKeyFact;
	@observable.ref private mKeyDate: Api.IUpcomingKeyDateDashboardCard;
	@observable public qualifier: string;
	@observable.ref private mAlternateKeyDateTemplates: Api.ITemplate[];
	@observable.ref private mType: Api.ResourceSelectorId;

	protected mOnFinish?(didSend?: boolean): void;

	constructor(userSession: Api.UserSessionContext, step = SingleEmailGuideStep.SelectRecipient) {
		super(userSession);
		this.reset();
		this.mStep = step;
	}

	@computed
	public get keyFact() {
		return this.mKeyFact;
	}

	@computed
	public get alternateKeyDateTemplates() {
		return this.mAlternateKeyDateTemplates;
	}

	@computed
	public get type() {
		return this.mType;
	}

	@computed
	public get keyDate() {
		return this.mKeyDate;
	}

	@computed
	public get emailMessage() {
		return this.mEmailMessage;
	}

	@computed
	public get recipient() {
		return this.mRecipient;
	}

	@action
	public setRecipient = (recipient: Api.ContactViewModel) => {
		this.mRecipient = recipient;
	};

	@computed
	public get isShowingModal() {
		return this.mShowingModal;
	}

	@computed
	public get step() {
		return this.mStep;
	}

	@computed
	public get guidedComposerStep() {
		return this.mGuidedComposerStep;
	}

	@action
	public openForKeyDateSend = async (
		recipient: Api.ContactViewModel,
		keyDate: Api.IUpcomingKeyDateDashboardCard,
		onFinish?: (didSend: boolean) => void
	) => {
		try {
			this.qualifier = keyDate.qualifier;
			const keyFact = keyDate.contacts[0].keyFact;
			const isWedding = keyFact.keyDate?.kind === Api.KeyDateKind.Wedding;
			this.mKeyFact = keyFact;
			this.mKeyDate = keyDate;
			this.mRecipient = recipient;
			this.mEmailMessage = new ResourceEmailMessageViewModel(this.mUserSession);
			this.mEmailMessage.sendEmailRoute = `email/resourceSelector/${keyDate.type}`;
			this.mEmailMessage.contactsToAdd.add(this.mRecipient);

			this.mEmailMessage.options.sendSingleEmail = true;
			this.mEmailMessage.options.noteVisibility = 'all';
			this.mEmailMessage.options.saveAsNote = true;
			this.mEmailMessage.options.sendEmailFrom = Api.SendEmailFrom.CurrentUser;
			this.mType = keyDate.type;

			switch (keyDate.type) {
				case Api.ResourceSelectorId.HappyBirthday:
				case Api.ResourceSelectorId.HouseAnniversaries:
					this.mEmailMessage.options.scheduledSend = {
						criteria: Api.ScheduleCriteria.OnDayOf,
					};
					break;
				default:
					this.mEmailMessage.options.scheduledSend = null;
					break;
			}

			try {
				const templateToUse = isWedding
					? await Api.TemplatesViewModel.getKeyDateTemplate(this.mUserSession, Api.KeyDateKind.Wedding)
					: await Api.TemplatesViewModel.getResourceSelectorTemplate(this.mUserSession, keyDate.type);
				this.mEmailMessage.setContentWithTemplate(templateToUse.value);
			} catch {
				// don't let an error here break the whole flow
			}

			this.mEmailMessage = replaceTokenWithActualFirstName(this.mEmailMessage, this.recipient);

			this.mOnFinish = onFinish;
			this.mStep = SingleEmailGuideStep.Compose;
			this.mShowingModal = true;
		} catch (error) {
			throw Api.asApiError(error);
		}
	};

	@action
	public sendWithResource = () => {
		const request: IResourceSelectorEmailRequest = {
			email: this.emailMessage.toJs(),
			selectorRequest: {
				includeIds: [this.mRecipient.id],
				qualifier: this.qualifier,
			},
		};
		const message = this.emailMessage as ResourceEmailMessageViewModel;
		return message.sendWithResource(request);
	};

	@action
	public loadAlternateKeyDateTemplates() {
		const promise = new Promise<Api.ITemplate[]>((resolve, reject) => {
			const onFinish = action(async (opResult: Api.IOperationResult<Api.ITemplate[]>) => {
				if (opResult.success) {
					const templates = opResult.value;
					const uniqueTemplates = Array.from(new Set(templates.map(x => x.id))).map(id => {
						return templates.find(x => x.id === id);
					});
					this.mAlternateKeyDateTemplates = uniqueTemplates;
					resolve(this.mAlternateKeyDateTemplates);
				} else {
					reject(opResult);
				}
			});

			const kind = this.mKeyFact.keyDate.kind;

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ITemplate[]>(
				this.composeApiUrl({ queryParams: { kind }, urlPath: 'template/byKeyDateKind' }),
				'GET',
				null,
				onFinish,
				onFinish
			);
		});
		return promise;
	}

	@action
	public onGuidedComposerComplete = (recipient: Api.ContactViewModel, content?: IComposerContent) => {
		// create email vm
		this.mRecipient = recipient;
		this.mEmailMessage = new Api.EmailMessageViewModel<File>(this.mUserSession);
		this.mEmailMessage.contactsToAdd.add(this.mRecipient);
		this.mEmailMessage.options.sendSingleEmail = true;

		let recipientFirstName = recipient.firstName || null;
		if (recipientFirstName && isValidEmail(recipientFirstName)) {
			recipientFirstName = null;
		}

		// compose content
		let contentStringValue: string;
		const defaultGreeting = `Hi${recipientFirstName ? ` ${recipientFirstName}` : ''}`;
		if (content) {
			contentStringValue = [
				content.greeting || defaultGreeting,
				content.keyFacts,
				content.personalBusinessUpdate ? content.personalBusinessUpdate.getPlainTextPreview(false) || '' : '',
				content.conclusion,
			].reduce((result, sectionStringValue) => {
				if (sectionStringValue) {
					return `${result}${result && sectionStringValue ? createRichContentEditorStateBlock('</br>') : ''}${
						sectionStringValue || ''
					}`;
				}
				return result;
			}, '');
			this.mEmailMessage.subject = content.subject;
		} else {
			contentStringValue = defaultGreeting;
		}

		const emailEditorContentState = createRichContentEditorStateWithText(contentStringValue);
		this.mEmailMessage.content = emailEditorContentState.getRawRichTextContent();
		this.mStep = SingleEmailGuideStep.Compose;
	};

	@action
	public setGuidedComposerStep = (step: SingleEmailGuidedComposerStep) => {
		this.mGuidedComposerStep = step;
	};

	@action
	public setStep = (step: SingleEmailGuideStep) => {
		this.mStep = step;
	};

	@action
	public reset = () => {
		this.mReset();
	};

	@action
	public restart = () => {
		this.mEmailMessage = null;
		this.mGuidedComposerStep = SingleEmailGuidedComposerStep.Subject;
		this.mRecipient = null;
		this.mStep = SingleEmailGuideStep.SelectRecipient;
	};

	@action
	public finish = (didSend = false) => {
		if (this.mOnFinish) {
			this.mOnFinish(didSend);
		}
		this.mReset();
	};

	@action
	public show = (callbacks?: { onFinish?: (didSend: boolean) => void; onDismiss?: () => void }) => {
		if (!this.isShowingModal) {
			this.mOnFinish = callbacks ? callbacks.onFinish : null;
			this.mReset();
			this.mShowingModal = true;
		}
	};

	@action
	public suppressKeyFact = async (keyFactId: string) => {
		try {
			await this.recipient.suppressKeyFact(keyFactId);
			this.finish(true);
		} catch (err) {
			throw Api.asApiError(err);
		}
	};

	public get canShowGuidedComposer() {
		return !this.mUserSession.user.userPreferences.skipSendEmailGuide;
	}

	private mReset = () => {
		this.mEmailMessage = null;
		this.mGuidedComposerStep = SingleEmailGuidedComposerStep.Subject;
		this.mOnFinish = null;
		this.mRecipient = null;
		this.mShowingModal = false;
		this.mStep = SingleEmailGuideStep.SelectRecipient;
	};
}

/**
 * For sending a _list of contacts_ email (linked from action item/contact) Does not allow for customization Uses a To
 * field instead of customizing per individual
 */
export class ComposeEmailToViewModel {
	@observable protected showingModal: boolean;
	@observable protected shouldStartWithAISuggestion?: boolean;
	@observable protected mEmailType?: Api.EmailType;
	@observable.ref protected mActionItem: Api.ActionItemViewModel;
	@observable.ref protected mRecipients: Api.ContactViewModel[];
	@observable.ref protected mUserSession: Api.UserSessionContext;
	protected mDismissActionItem?(actionItem: Api.ActionItemViewModel): void;
	protected mOnFinish?(didSend?: boolean): void;

	constructor(userSession?: Api.UserSessionContext) {
		this.mUserSession = userSession;
		this.mReset = this.mReset.bind(this);
		if (this.mUserSession) {
			this.reset();
		}
	}

	@computed
	public get recipients() {
		return this.mRecipients;
	}

	@computed
	public get actionItem() {
		return this.mActionItem;
	}

	@computed
	public get isShowingModal() {
		return this.showingModal;
	}

	@computed
	public get shouldShowAISuggestion() {
		return this.shouldStartWithAISuggestion;
	}
	@computed
	public get emailType() {
		return this.mEmailType;
	}

	@action
	public reset = () => {
		this.mReset();
	};

	@action
	public showForActionItem = (
		actionItem: Api.ActionItemViewModel,
		callbacks?: {
			onFinish?: (didSend: boolean) => void;
			onDismiss?: () => void;
		}
	) => {
		if (!this.isShowingModal) {
			this.mActionItem = actionItem;
			this.mDismissActionItem = callbacks ? callbacks.onDismiss : null;
			this.mOnFinish = callbacks ? callbacks.onFinish : null;
			this.mRecipients =
				(actionItem.isKeepInTouchActionItem || actionItem.isSuggestedKeepInTouchActionItem) &&
				actionItem.keepInTouchReference &&
				actionItem.keepInTouchReference.contact
					? [actionItem.keepInTouchReference.contact]
					: actionItem.referencedContactsForSendMessage || [];
			this.showingModal = true;
		}
	};

	@action
	public showForRecipients = (recipients: Api.ContactViewModel[], onFinish?: (didSend: boolean) => void) => {
		if (!this.isShowingModal) {
			this.mRecipients = recipients;
			this.mOnFinish = onFinish;
			this.showingModal = true;
		}
	};

	@action
	public showForRecipientWithAI = (
		recipients: Api.ContactViewModel[],
		emailType: Api.EmailType,
		onFinish?: (didSend: boolean) => void
	) => {
		if (!this.isShowingModal) {
			this.mRecipients = recipients;
			this.mOnFinish = onFinish;
			this.showingModal = true;
			this.shouldStartWithAISuggestion = true;
			this.mEmailType = emailType;
		}
	};

	@action
	public finish = (didSend = false) => {
		if (this.mOnFinish) {
			this.mOnFinish(didSend);
		}
		this.mReset();
	};

	public dismissActionItem = () => {
		if (this.mDismissActionItem && this.actionItem) {
			this.mDismissActionItem(this.actionItem);
		}
	};

	public setUserSession = (userSession: Api.UserSessionContext) => {
		this.mUserSession = userSession;
		this.reset();
	};

	protected mReset() {
		this.mActionItem = null;
		this.mDismissActionItem = null;
		this.mOnFinish = null;
		this.mRecipients = null;
		this.showingModal = false;
	}
}

export class EmailComposerViewModel extends Api.ImpersonationBroker {
	@observable protected showingModal: boolean;
	@observable.ref protected mUserSession: Api.UserSessionContext;
	protected mEmailWorkload: Api.EmailWorkloadViewModel;

	constructor(userSession?: Api.UserSessionContext) {
		super();
		this.mUserSession = userSession;
		this.mReset = this.mReset.bind(this);
		this.impersonate = this.impersonate.bind(this);
		if (this.mUserSession) {
			this.reset();
		}
	}

	@computed
	public get emailWorkload() {
		return this.mEmailWorkload;
	}

	@computed
	public get isShowingModal() {
		return this.showingModal;
	}

	@action
	public reset = () => {
		this.mReset();
	};

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate(impersonationContext);
		this.mEmailWorkload?.impersonate(impersonationContext);
		return this;
	}

	public setUserSession = (userSession: Api.UserSessionContext) => {
		this.mUserSession = userSession;
		this.reset();
	};

	protected mReset() {
		this.mEmailWorkload = new Api.EmailWorkloadViewModel(this.mUserSession);
	}
}

export interface IEmailMessageComposeProjectedContact {
	/** ContactId must be provided. */
	contact?: Api.IProjectedContact;
	/** Customized message specifically for the contact. */
	content?: Api.IRawRichTextContentState;
	/** Any context that is specific to the contact. i.e an action item that is being resolved. */
	context?: Api.IEmailMessageComposeContext;
	/** Specifies the email address of the contact to use. If not provided, the primary email address will be used. */
	preferredEmailAddress?: string;
	signatureTemplateId?: string;
	/** Customized subject specifically for the contact. */
	subject?: string;
}

export interface IBulkEmailMessageCompose<
	TFollowUpOptions extends Api.IFollowUpOptions = Api.IEmailMessageFollowUpOptions,
> extends Api.IBaseApiModel,
		Api.IEmailMessageDraft<TFollowUpOptions> {
	actor?: Api.IActor;
	approvalRequests?: Api.ICampaignApprovalRequest[];
	automationId?: string;
	bccEmailAddresses?: string[];
	cancelledDate?: Date;
	ccEmailAddresses?: string[];
	completedDate?: Date;
	contactIdsToOmit?: string[];
	contacts?: IEmailMessageComposeProjectedContact[];
	contactsFilterRequest?: Api.IEmailMessageContactsFilterRequest;
	creationDate?: Date;
	creator?: Api.IUserReference;
	groupId?: string;
	lastCommErrorDate?: Date;
	reminderEmailSent?: Date;
	schedule?: Api.IScheduledSend;
	status?: Api.EmailSendStatus;
	totalContacts?: number;
	totalEmails?: number;

	/** These properties exist but won't be useful on a queued/ready email */
	bouncedCount?: number;
	failedCount?: number;
	hasResolvedTransactions?: boolean;
	noEmailAddressCount?: number;
	openCount?: number;
	replyCount?: number;
	sentSuccessfullyCount?: number;
	systemJobId?: string;
}

/** For emails to any number of contacts */
export class ComposeEmailViewModel<
	TFollowUpOptions extends Api.IFollowUpOptions = Api.IFollowUpOptions,
	TFollowUpSource = any,
> extends EmailComposerViewModel {
	/* kill */
	@observable public allowSenderSelection: boolean;
	@observable public canCustomizeIndividualEmails: boolean;
	@observable public showingSendScheduler: boolean;
	@observable public sendOnBehalfFromSelection: boolean;
	@observable public step: BulkEmailComposerStep;
	@observable public isUserEditingMessage = false;
	@observable public editModeIsActive = false;
	@observable.ref public followUpSource: TFollowUpSource;
	@observable.ref public followUpCount: number;
	@observable public sendAs?: string;
	@observable public sendOnBehalf: boolean;
	@observable public sendAsConnectionType?: string;

	/** For editing an existing one */
	@observable public id: string;
	/** Used to preserve the user's choice in case the contact list is unmounted (when customizing per contact) */
	@observable
	public userSelectedFilterOption: Api.IOwnershipFilter = null;
	@observable.ref public contactOwners: Api.IContactOwnerReference[];
	@observable
	protected mSelectedCustomEmail: Api.IEmailMessageComposeContact;
	@observable.ref public selectedCustomEmailContact: Api.ContactViewModel;
	@observable.ref public emailMessage: Api.EmailMessageViewModel<File, Api.ISendEmailResponse, TFollowUpOptions>;
	@observable.ref public reachOutInfo: Api.IDashboardReachOutInfo;
	@observable.ref
	public selectedAutomationTemplate: Api.AutomationTemplateViewModel;
	@observable.ref public selectedReachOutTags: string[] = [];
	@observable.ref public sendEstimate: Partial<Api.IEmailSendEstimate>;
	@observable.ref public sendSystemJob: Api.SystemJobViewModel;
	@observable.ref public alternateKeyDateTemplates: Api.ITemplate[];
	@observable.ref protected mAllowContactFilterTagSearchChanges = true;
	@observable.ref protected mCampaign: Api.CampaignViewModel;
	@observable.ref public suggestion: Api.ContentCalenderSuggestionViewModel;
	@observable.ref public resourceSelector: Api.ResourceSelectorId;
	protected mContactsPageCollectionController: Api.FilteredPageCollectionController<
		Api.IContact,
		Api.ContactViewModel,
		Api.IBulkContactsRequest
	>;
	public static ANNIVERSARY_TAG = '_upcoming_anniversaries_';
	public static BIRTHDAY_TAG = '_upcoming_birthdays_';
	public static KIT_TAG = '_contacts_with_kits_due_';
	[immerable] = true;

	public static instanceForTemplate = (userSession: Api.UserSessionContext, template?: Api.ITemplate) => {
		const emailComposer = new ComposeEmailViewModel(userSession);
		emailComposer.emailMessage = new Api.EmailMessageViewModel(userSession);
		emailComposer.emailMessage.setSavedAttachments(template?.attachments);
		emailComposer.emailMessage.templateReference = template
			? {
					isCustomized: false,
					isSystemTemplate: template.scope === Api.TemplateScope.Industry,
					templateId: template.id,
					name: template.name,
				}
			: null;
		emailComposer.emailMessage.subject = template?.subject;
		emailComposer.emailMessage.content = template
			? { ...template.content }
			: getDefaultBulkMessagingBodyEditorState().getRawRichTextContent();
		if (template && template.templateType === Api.TemplateType.HtmlNewsletter) {
			emailComposer.canCustomizeIndividualEmails = false;
		}
		return emailComposer;
	};

	public static loadInstanceForCampaign = async (
		userSession: Api.UserSessionContext,
		campaign: Api.CampaignViewModel,
		onSignatureTemplateLoadError?: (opResult: Api.IOperationResultNoValue) => boolean,
		onEmailBodyTemplateLoadError?: (opResult: Api.IOperationResultNoValue) => boolean,
		groupId?: string
	) => {
		let bulkMessageComposerModel: IBulkEmailMessageCompose<Api.IFollowUpOptions> | undefined;
		if (groupId) {
			const opResult = await userSession.webServiceHelper.callWebServiceAsync<Api.IGroupCampaignResult>(
				`email/group/${encodeURIComponent(groupId as string)}`,
				'GET'
			);
			if (opResult.success) {
				bulkMessageComposerModel = opResult.value?.draftEmailMessage;
			} else {
				throw Api.asApiError(opResult);
			}
		}

		if (!bulkMessageComposerModel) {
			const opResult = await userSession.webServiceHelper.callWebServiceAsync<
				IBulkEmailMessageCompose<Api.IFollowUpOptions>
			>(
				this.composeApiUrl({
					impersonationContext: campaign.impersonationContext,
					urlPath: `email/${encodeURIComponent(campaign.id as string)}/edit`,
				}),
				'GET'
			);
			if (opResult.success) {
				bulkMessageComposerModel = opResult.value;
			} else {
				throw Api.asApiError(opResult);
			}
		}

		if (bulkMessageComposerModel) {
			const composer: IBulkEmailMessageCompose<Api.IFollowUpOptions> = bulkMessageComposerModel;
			let bulkEmailComposer: ComposeEmailViewModel | null = null;
			if ((composer.options?.followUp as Api.IEmailMessageFollowUpOptions)?.bulkEmailMessageId) {
				const followUpSourceCampaign = new Api.CampaignViewModel(userSession, {
					id: (composer.options.followUp as Api.IEmailMessageFollowUpOptions).bulkEmailMessageId,
				}).impersonate(campaign?.impersonationContext);
				// not sure if we absolutely need to load this
				await followUpSourceCampaign.load();
				bulkEmailComposer = new ComposeFollowUpEmailViewModel(userSession, followUpSourceCampaign).impersonate(
					campaign?.impersonationContext
				);

				bulkEmailComposer.followUpCount = composer.totalEmails;
			} else if ((composer.options?.followUp as Api.ISurveyFollowUpOptions)?.surveyResponseFilter?.surveyId) {
				const survey = await Api.SurveyViewModel.load(
					userSession,
					{
						_type: 'Survey',
						id: (composer.options.followUp as Api.ISurveyFollowUpOptions).surveyResponseFilter.surveyId,
					},
					campaign?.impersonationContext
				);
				if (survey instanceof Api.SatisfactionSurveyViewModel) {
					bulkEmailComposer = new ComposeSatisfactionSurveyFollowUpEmailViewModel(
						userSession,
						new Api.SatisfactionSurveyReportViewModel(userSession, survey)
					);
				} else if (survey instanceof Api.EventSurveyViewModel) {
					bulkEmailComposer = new ComposeEventRegistrationSurveyFollowUpEmailViewModel(
						userSession,
						new Api.EventSurveyReportViewModel(userSession, survey)
					);
				}

				bulkEmailComposer.followUpCount = composer.totalEmails;
			} else {
				bulkEmailComposer = new ComposeEmailViewModel(userSession).impersonate(campaign?.impersonationContext);
			}
			bulkEmailComposer.id = composer.id;

			// message
			const draft: Api.IEmailMessageDraft = { ...(composer as any) };
			bulkEmailComposer.emailMessage = new Api.EmailMessageViewModel<File>(userSession, false, draft).impersonate(
				campaign?.impersonationContext
			);
			// contacts
			bulkEmailComposer.emailMessage.contactsFilterRequest = composer.contactsFilterRequest;
			bulkEmailComposer.emailMessage.groupByHousehold = composer?.contactsFilterRequest?.groupByHousehold;
			composer.contacts.forEach(x => {
				const contact = new Api.ContactViewModel(userSession, {
					...x.contact,
				}).impersonate(campaign?.impersonationContext);
				bulkEmailComposer.emailMessage.setCustomMessageForContact(contact, x);
			});
			const contactsToOmit = composer.contactIdsToOmit.map(x =>
				new Api.ContactViewModel(userSession, { id: x }).impersonate(campaign?.impersonationContext)
			);
			bulkEmailComposer.emailMessage.setContactsToOmit(new Api.ObservableCollection(contactsToOmit, 'id'));
			bulkEmailComposer.emailMessage.templateReference = composer.templateReference;
			bulkEmailComposer.emailMessage.options.scheduledSend = composer.schedule;
			bulkEmailComposer.emailMessage.options.followUp = composer.options?.followUp;
			if (bulkEmailComposer.emailMessage.options.followUp) {
				bulkEmailComposer.emailMessage.options.followUp.excludeContactIds = contactsToOmit.map(x => x.id);
			}

			if (groupId) {
				bulkEmailComposer.emailMessage.groupId = groupId;
			}
			bulkEmailComposer.emailMessage.cc = composer.ccEmailAddresses.map(x => ({
				email: x,
			}));
			bulkEmailComposer.emailMessage.bcc = composer.bccEmailAddresses.map(x => ({ email: x }));
			bulkEmailComposer.emailMessage.setSavedAttachments(composer.attachments);
			if (composer.signatureTemplateId) {
				try {
					const templates = new Api.TemplatesViewModel(userSession).impersonate(campaign?.impersonationContext);
					const signatureTemplate = await templates.getById(composer.signatureTemplateId);
					bulkEmailComposer.emailMessage.signatureTemplate = signatureTemplate;
				} catch (err) {
					const apiError = Api.asApiError(err);
					if (!onSignatureTemplateLoadError?.(apiError)) {
						// We don't want to stop the intended action if the signature is not loading correctly, so we just want to move on here.
					}
				}
			}

			if (campaign?.templateReference && !campaign?.emailBodyTemplate) {
				try {
					await campaign.loadEmailBodyTemplate();
				} catch (err) {
					const apiError = Api.asApiError(err);
					onEmailBodyTemplateLoadError?.(apiError);
				}
			}

			// individually selected recipients
			if (!bulkMessageComposerModel?.contactsFilterRequest && !campaign?.filterRequest && !composer.options?.followUp) {
				bulkEmailComposer.mContactsPageCollectionController = null;
			}
			bulkEmailComposer.mCampaign = campaign;
			bulkEmailComposer.canCustomizeIndividualEmails = true;
			return bulkEmailComposer;
		}
	};

	public static instanceForSuggestion = (suggestion: Api.ContentCalenderSuggestionViewModel) => {
		const emailComposer = ComposeEmailViewModel.instanceForTemplate(
			suggestion.userSession,
			suggestion.template
		).impersonate(suggestion.impersonationContext);
		emailComposer.emailMessage.contactsFilterRequest = {};
		emailComposer.emailMessage.contactsFilterRequest.contactFilterRequest = suggestion.filter;

		// Add default criteria if we have any. We'll rely on the send flow to otherwise add when criteria needs to be chosen explicitly by the customer.
		if (suggestion.filter?.criteria && suggestion.filter.criteria.length > 0) {
			for (const defaultCriteria of Api.DefaultBulkSendExcludedFilterCriteria) {
				if (!emailComposer.emailMessage.contactsFilterRequest.contactFilterRequest.criteria.includes(defaultCriteria)) {
					emailComposer.emailMessage.contactsFilterRequest.contactFilterRequest.criteria.push(defaultCriteria);
				}
			}
		}

		emailComposer.emailMessage.setMinimumDurationInDays(suggestion.minimumDurationInDays);
		emailComposer.emailMessage.options.scheduledSend = suggestion.schedule;
		emailComposer.emailMessage.options.scheduledSend.criteria = Api.ScheduleCriteria.StartAfter;
		emailComposer.emailMessage.options.sendEmailFrom = Api.SendEmailFrom.ContactOwner;
		emailComposer.emailMessage.options.sendWithCompliance = false;
		emailComposer.canCustomizeIndividualEmails = true;
		emailComposer.suggestion = suggestion;
		if (suggestion.template) {
			emailComposer.emailMessage.setContentWithTemplate(suggestion.template);
			if (!emailComposer.emailMessage.subject) {
				emailComposer.emailMessage.subject = suggestion.template.name || 'No subject';
			}
		}

		return emailComposer;
	};

	constructor(userSession?: Api.UserSessionContext) {
		super(userSession);
		this.mReset = this.mReset.bind(this);
		this.mCreateContactViewModel = this.mCreateContactViewModel.bind(this);
		this.setSendEmailFrom = this.setSendEmailFrom.bind(this);
		this.canCustomizeIndividualEmails = true;
		if (userSession) {
			this.mContactsPageCollectionController = new Api.FilteredPageCollectionController<
				Api.IContact,
				Api.ContactViewModel,
				Api.IBulkContactsRequest
			>({
				apiPath: 'contact/filter/v2',
				client: this.mUserSession.webServiceHelper,
				transformer: this.mCreateContactViewModel,
			});
		}
	}

	@computed
	public get householdOffForResourceSelector() {
		return [
			Api.ResourceSelectorId.TurningXX,
			Api.ResourceSelectorId.HappyBirthday,
			Api.ResourceSelectorId.Turning65,
			Api.ResourceSelectorId.Turning72,
			Api.ResourceSelectorId.Turning73,
		].includes(this.resourceSelectorId);
	}

	@computed
	public get sendAsEmployee() {
		return this.sendAs;
	}
	public set sendAsEmployee(value: string | undefined) {
		this.sendAs = value;
	}

	@computed
	public get allowContactFilterTagSearchChanges() {
		return this.mAllowContactFilterTagSearchChanges != null
			? this.mAllowContactFilterTagSearchChanges
			: !this.campaign?.id && !this.reachOutInfo;
	}

	public setAllowContactFilterTagSearchChangesOverride(value?: boolean) {
		this.mAllowContactFilterTagSearchChanges = value;
	}

	/**
	 * User must:
	 *
	 * 1. Have started the email flow from a valid entry point
	 * 2. Be assigned to an account where sending on behalf others has been enabled
	 * 3. Be an admin or superadmin for that account
	 * 4. This.campaign.id must be null/undefined
	 */
	@computed
	public get canSendOnBehalf() {
		if (this.mCampaign?.id || this.isFollowUp) {
			return false;
		}
		return !!this.mUserSession.canSendOnBehalf;
	}

	public setSendEmailFrom(sendEmailFromOption: Api.ISendEmailFrom, user?: Api.IUser) {
		this.emailMessage.options.sendEmailFrom = sendEmailFromOption;
		this.emailMessage.setSendEmailFromUser(user);
	}

	public getMailerType = async (forHtmlNewsletters = false) => {
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<EmailMailerType>(
			this.composeApiUrl({ queryParams: { forHtmlNewsletters }, urlPath: `email/mailerType` }),
			'GET'
		);
		if (opResult.success) {
			return opResult;
		} else {
			throw Api.asApiError(opResult);
		}
	};

	@computed
	public get resourceSelectorId() {
		return this.resourceSelector;
	}

	@computed
	public get isFollowUp() {
		return !!(this.emailMessage?.options?.followUp as Api.IEmailMessageFollowUpOptions)?.bulkEmailMessageId;
	}

	@computed
	public get isSuggestion() {
		return !!this.suggestion;
	}

	@computed
	public get selectedPolicies() {
		return getPolicyFilterCriteriaInCriteria(this.emailMessage?.contactsFilterRequest.contactFilterRequest)
			?.map(x => x.value)
			.filter(Boolean) as string[];
	}

	@action
	public isCustomAutomation(_action: 'edit' | 'automation') {
		return _action === 'automation' && this?.resourceSelectorId?.toLocaleLowerCase()?.includes('custom');
	}

	/**
	 * @param filter an explicit filter that could differ from one composed by default
	 * @param force reset/disregard an in-flight approximation promise and force a new approximation
	 * @returns Promise<Api.IContactFilterApproximation>
	 */
	@action
	public setSelectedPolicies = (policies: string[]) => {
		const { contactFilterRequest } = this.emailMessage.contactsFilterRequest;

		const nextFilterRequest: Api.IContactsFilterRequest = {
			...(contactFilterRequest || {}),
		};

		// remove all filters (retain searches) and add new filter criteria
		const sortedCriteria = Api.VmUtils.sortContactFilterCriteria(nextFilterRequest.criteria);
		nextFilterRequest.criteria = [
			...sortedCriteria.searches,
			...sortedCriteria.compound,
			...sortedCriteria.filters.filter(
				x =>
					!(
						x.op === Api.FilterOperator.Or &&
						x.criteria.find(y => y.property === Api.ContactFilterCriteriaProperty.Policy)
					)
			),
		];

		if (policies.length) {
			const policyCriteria: Api.IContactFilterCriteria[] = policies.map(x => {
				return {
					property: Api.ContactFilterCriteriaProperty.Policy,
					value: x,
				};
			});

			nextFilterRequest.criteria.push({
				criteria: policyCriteria,
				op: Api.FilterOperator.Or,
			});
		}

		this.emailMessage.contactsFilterRequest = {
			...this.emailMessage.contactsFilterRequest,
			contactFilterRequest: nextFilterRequest,
		};
		return this.reloadRecipientsList();
	};

	@action
	public updateApproximationForFilter = (filter?: Api.IBulkContactsRequest, forceReload = false) => {
		return this.emailMessage.getEmailApproximation(filter, forceReload);
	};

	@action
	public reloadRecipientsList = () => {
		this.resetRecipientsResultsList();
		const recipientsPromise = this.getNextBatchOfRecipients();
		const approximationPromise = this.updateApproximationForFilter();
		return [recipientsPromise, approximationPromise] as const;
	};

	/** OnSignatureTemplateLoadError: return true if handled */
	@action
	public async loadForEditing(
		campaign = this.mCampaign,
		onSignatureTemplateLoadError?: (opResult: Api.IOperationResultNoValue) => boolean,
		onEmailBodyTemplateLoadError?: (opResult: Api.IOperationResultNoValue) => boolean
	) {
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<IBulkEmailMessageCompose>(
			this.composeApiUrl({ urlPath: `email/${encodeURIComponent(campaign.id)}/edit` }),
			'GET'
		);
		if (opResult.success) {
			const composer = opResult.value;
			this.id = composer.id;
			// message
			const draft: Api.IEmailMessageDraft = { ...composer };
			this.emailMessage = new Api.EmailMessageViewModel<File, Api.ISendEmailResponse, TFollowUpOptions>(
				this.mUserSession,
				false,
				draft
			).impersonate(this.mImpersonationContext);

			// contacts
			this.emailMessage.contactsFilterRequest = composer.contactsFilterRequest;
			this.emailMessage.groupByHousehold = composer.contactsFilterRequest?.groupByHousehold;
			composer.contacts.forEach(x => {
				const contact = new Api.ContactViewModel(this.mUserSession, {
					...x.contact,
				}).impersonate(this.mImpersonationContext);
				this.emailMessage.setCustomMessageForContact(contact, x);
			});
			const contactsToOmit = composer.contactIdsToOmit.map(x =>
				new Api.ContactViewModel(this.mUserSession, { id: x }).impersonate(this.mImpersonationContext)
			);
			this.emailMessage.setContactsToOmit(new Api.ObservableCollection(contactsToOmit, 'id'));
			this.emailMessage.templateReference = composer.templateReference;
			this.emailMessage.options.scheduledSend = composer.schedule;
			this.emailMessage.options.followUp = composer.options?.followUp as any;
			if (composer.options?.followUp?.bulkEmailMessageId) {
				const followUpSourceCampaign = new Api.CampaignViewModel(this.mUserSession, {
					id: composer.options.followUp.bulkEmailMessageId,
				}).impersonate(campaign.impersonationContext);
				// not sure if we absolutely need to load this
				await followUpSourceCampaign.load();
				this.followUpSource = followUpSourceCampaign as any as TFollowUpSource;
				this.followUpCount = composer.totalEmails - (contactsToOmit?.length ?? 0);
				this.emailMessage.options.followUp.excludeContactIds = contactsToOmit.map(x => x.id);
			}
			this.emailMessage.cc = composer.ccEmailAddresses.map(x => ({ email: x }));
			this.emailMessage.bcc = composer.bccEmailAddresses.map(x => ({
				email: x,
			}));
			this.emailMessage.setSavedAttachments(composer.attachments);
			if (composer.signatureTemplateId) {
				try {
					const forUserId = this.mImpersonationContext?.user?.id ?? composer.creator?.id;
					const templates = new Api.TemplatesViewModel(this.mUserSession).impersonate(this.mImpersonationContext);
					const signatureTemplate = await templates.getById(composer.signatureTemplateId, forUserId);
					this.emailMessage.signatureTemplate = signatureTemplate;
				} catch (err) {
					const apiError = Api.asApiError(err);
					if (!onSignatureTemplateLoadError?.(apiError)) {
						// We don't want to stop the intended action if the signature is not loading correctly, so we just want to move on here.
					}
				}
			}

			if (campaign.templateReference && !campaign.emailBodyTemplate) {
				try {
					await campaign.loadEmailBodyTemplate();
				} catch (err) {
					const apiError = Api.asApiError(err);
					onEmailBodyTemplateLoadError?.(apiError);
				}
			}

			// individually selected recipients
			if (!campaign.filterRequest) {
				this.mContactsPageCollectionController = null;
			}

			this.mCampaign = campaign;
			this.canCustomizeIndividualEmails = true;
		} else {
			throw Api.asApiError(opResult);
		}
	}

	@action
	public loadAlternateKeyDateTemplates() {
		const promise = new Promise<Api.ITemplate[]>((resolve, reject) => {
			const onFinish = action(async (opResult: Api.IOperationResult<Api.ITemplate[]>) => {
				if (opResult.success) {
					const templates = opResult.value;
					const uniqueTemplates = Array.from(new Set(templates.map(x => x.id))).map(id => {
						return templates.find(x => x.id === id);
					});
					this.alternateKeyDateTemplates = uniqueTemplates;
					resolve(this.alternateKeyDateTemplates);
				} else {
					reject(opResult);
				}
			});

			const kind = getKeyDateKindFromResourceSelectorId(
				(this as unknown as ComposeResourceEmailViewModel).resourceSelector
			);

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ITemplate[]>(
				this.composeApiUrl({ queryParams: { kind }, urlPath: 'template/byKeyDateKind' }),
				'GET',
				null,
				onFinish,
				onFinish
			);
		});
		return promise;
	}

	@action
	public loadAlternateResourceSelectorTemplates() {
		const promise = new Promise<Api.ITemplate[]>((resolve, reject) => {
			const onFinish = action(async (opResult: Api.IOperationResult<Api.ITemplate[]>) => {
				if (opResult.success) {
					const templates = opResult.value;
					const uniqueTemplates = Array.from(new Set(templates.map(x => x.id))).map(id => {
						return templates.find(x => x.id === id);
					});
					this.alternateKeyDateTemplates = uniqueTemplates;
					resolve(this.alternateKeyDateTemplates);
				} else {
					reject(opResult);
				}
			});

			const resourceSelector = (this as unknown as ComposeResourceEmailViewModel).resourceSelector;

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ITemplate[]>(
				this.composeApiUrl({
					queryParams: {
						excludeExpired: true,
						resourceSelector,
					},
					urlPath: 'template/byResourceSelector',
				}),
				'GET',
				null,
				onFinish,
				onFinish
			);
		});
		return promise;
	}

	@computed
	public get ownersWithFirstNames() {
		return this.contactOwners?.filter(owner => owner?.user?.firstName && owner.contactsCount > 0);
	}

	@computed
	public get campaign() {
		return this.mCampaign;
	}

	@computed
	public get selectedCustomEmail() {
		return this.mSelectedCustomEmail;
	}

	@computed
	public get canSend() {
		return (
			!this.isBusy &&
			this.recipients.length > 0 &&
			this.recipients.some(x => !!x.emailAddresses && x.emailAddresses.length > 0)
		);
	}

	@computed
	public get isBusy() {
		return (
			this.isSending ||
			(this.mContactsPageCollectionController ? !!this.mContactsPageCollectionController.isFetching : false)
		);
	}

	@computed
	public get isFetchingContacts() {
		return this.mContactsPageCollectionController?.isFetching;
	}

	@computed
	public get isSending() {
		return (
			(this.emailMessage ? this.emailMessage.isSending : false) ||
			(!!this.sendSystemJob && this.sendSystemJob.percentComplete < 100)
		);
	}

	@computed
	public get recipients() {
		if (!this.emailMessage) {
			return [];
		}
		let recipients =
			this.emailMessage.contactsToOmit.length > 0
				? this.emailMessage.contactsToAdd.filter(x => !this.emailMessage.contactsToOmit.has(x))
				: this.emailMessage.contactsToAdd.toArray();
		if (this.mContactsPageCollectionController) {
			const fetchedContactsExcludingExplicitlyAdded = this.mContactsPageCollectionController.fetchResults
				.toArray()
				.filter(x => !this.emailMessage.contactsToAdd.has(x));
			recipients = [
				...recipients,
				...(this.emailMessage.contactsToOmit.length > 0
					? this.emailMessage.contactsFilterRequest?.groupByDuplicate
						? fetchedContactsExcludingExplicitlyAdded
								.map(x => {
									if (this.emailMessage.contactsToOmit.has(x)) {
										return x.duplicateContacts?.length
											? x.duplicateContacts.find(y => !this.emailMessage.contactsToOmit.has(y))
											: null;
									}
									return x;
								})
								.filter(Boolean)
						: fetchedContactsExcludingExplicitlyAdded.filter(x => !this.emailMessage.contactsToOmit.has(x))
					: fetchedContactsExcludingExplicitlyAdded),
			];
		}
		return recipients;
	}

	@computed
	public get estimatedRecipientsTotal() {
		if (this.emailMessage?.contactEmailApproximation?.hasEmail) {
			return this.emailMessage.contactEmailApproximation.hasEmail;
		}
		let count = 0;

		if (this.emailMessage) {
			const recipients =
				this.emailMessage.contactsToOmit.length > 0
					? this.emailMessage.contactsToAdd.filter(x => !this.emailMessage.contactsToOmit.has(x))
					: this.emailMessage.contactsToAdd;
			count += recipients.length;
		}

		if (this.mContactsPageCollectionController) {
			count +=
				this.mContactsPageCollectionController.totalCount -
				this.mContactsPageCollectionController.fetchResults.filter(x => this.emailMessage.contactsToOmit.has(x)).length;
		}

		if (this.followUpCount) {
			count = this.followUpCount;
		}

		return count;
	}

	/**
	 * @param createIfNeeded If true, does not automatically add to collection backing
	 *   this.emailMessage.getCustomMessageForContact. You must do this manually.
	 */
	@action
	public selectCustomEmailForContact = (contact: Api.ContactViewModel, createIfNeeded = false) => {
		if (!contact) {
			this.mSelectedCustomEmail = null;
			this.selectedCustomEmailContact = null;
			return;
		}

		let selectedCustomEmail = this.emailMessage.getCustomMessageForContact(contact);
		if (!selectedCustomEmail && createIfNeeded) {
			selectedCustomEmail = {
				content: this.emailMessage.content
					? { ...this.emailMessage.content }
					: Api.createRawRichTextContentStateWithText(''),
				subject: this.emailMessage.subject || '',
			};
		}
		if (selectedCustomEmail) {
			this.mSelectedCustomEmail = selectedCustomEmail;
			this.selectedCustomEmailContact = contact;
		}
		return selectedCustomEmail;
	};

	@computed
	public get keyDateFilter() {
		// for now skip only if sending to upcoming birthdays
		if (this.emailMessage.contactsFilterRequest?.contactFilterRequest?.criteria) {
			// We copy the array because it's observable
			const sorted = Api.VmUtils.sortContactFilterCriteria([
				...(this.emailMessage.contactsFilterRequest?.contactFilterRequest?.criteria ?? []),
			]);
			return sorted.filters.find(x => x.property === Api.ContactFilterCriteriaProperty.UpcomingKeyDatesNotScheduled);
		}

		return null;
	}

	@action
	public showUpcomingKeyDates = (keyDateKind: Api.KeyDateKind) => {
		const contactFilterRequest: Api.IContactsFilterRequest = {
			criteria: [
				{
					property: Api.ContactFilterCriteriaProperty.UpcomingKeyDatesNotScheduled,
					value: keyDateKind,
				},
			],
		};

		this.showForBulkContactsFilterRequest({
			bulkFilterRequest: {
				contactFilterRequest,
			},
			contactsToOmit: [],
		});

		if (this.emailMessage && this.emailMessage.options) {
			// set schedule
			this.emailMessage.options.scheduledSend = {
				criteria: Api.ScheduleCriteria.OnKeyDate,
			};
		}
	};

	@action
	public showForBulkContactsFilterRequest = (options: {
		bulkFilterRequest: Api.IEmailMessageContactsFilterRequest;
		contactsToOmit?: Api.ContactViewModel[];
	}) => {
		if (options && !this.isShowingModal) {
			this.emailMessage = new Api.EmailMessageViewModel<File, Api.ISendEmailResponse, TFollowUpOptions>(
				this.mUserSession
			);
			const filterRequestWithoutContactsWithoutEmails: Api.IEmailMessageContactsFilterRequest = {
				...options.bulkFilterRequest,
				contactFilterRequest: {
					...(options?.bulkFilterRequest?.contactFilterRequest || {}),
					criteria: [
						...((options?.bulkFilterRequest?.contactFilterRequest || {}).criteria || []),
						...Api.DefaultBulkSendExcludedFilterCriteria,
					],
				},
			};
			this.emailMessage.contactsFilterRequest = filterRequestWithoutContactsWithoutEmails;
			if (options.contactsToOmit) {
				this.emailMessage.contactsToOmit.addAll(options.contactsToOmit);
			}
			this.mContactsPageCollectionController = new Api.FilteredPageCollectionController<
				Api.IContact,
				Api.ContactViewModel,
				Api.IBulkContactsRequest
			>({
				apiPath: 'contact/filter/v2',
				client: this.mUserSession.webServiceHelper,
				transformer: this.mCreateContactViewModel,
			});
			this.showingModal = true;
		}
	};

	public resetRecipientsResultsList = () => {
		this.mContactsPageCollectionController?.reset();
	};

	public getNextBatchOfRecipients = (pageSize?: number, params?: any) => {
		if (this.mContactsPageCollectionController) {
			const filter = this.emailMessage.contactsFilterRequest;
			let sortDescriptor: Api.ISortDescriptor = null;
			if (filter?.sortProperty) {
				sortDescriptor = {
					sort: filter.sortAscending ? 'asc' : 'desc',
					sortBy: filter.sortProperty,
				};
			}
			// merge params with sortDescriptor
			const expandOptions = [
				this.emailMessage.groupByHousehold ? 'HouseholdMembers' : undefined,
				filter.groupByDuplicate ? 'DuplicateContacts' : null,
			].filter(Boolean);
			const computedParams = {
				...(sortDescriptor || {}),
				...(params || {}),
				expand: expandOptions.length ? expandOptions.join(',') : undefined,
			};
			const filterRequest = {
				filter: filter.contactFilterRequest,
				groupByHousehold: this.emailMessage.groupByHousehold,
				groupByDuplicate: filter.groupByDuplicate,
				groupByHouseholdFilter: filter.groupByHouseholdFilter,
				ownershipFilter: filter.ownershipFilter,
			} as Api.IBulkContactsRequest;
			return this.mContactsPageCollectionController.getNext(filterRequest, pageSize, computedParams);
		}
		return null;
	};

	@action
	public getContactOwners(): Promise<Api.IContactOwnerReference[]> {
		if (this.isBusy) {
			return null;
		}
		return new Promise((resolve, reject) => {
			this.mUserSession.webServiceHelper
				.callWebServiceAsync<Api.IContactOwnerReference[]>(
					this.composeApiUrl({ urlPath: 'email/sendonbehalf/owners' }),
					'POST',
					{
						filter: {
							criteria: [...(this.emailMessage.contactsFilterRequest?.contactFilterRequest?.criteria || [])],
						},
						contactIdsToOmit: this.emailMessage.contactsToOmit?.map(x => x.id) || [],
					}
				)
				.then((opResult: Api.IOperationResult<Api.IContactOwnerReference[]>) => {
					if (opResult.success) {
						this.contactOwners = opResult.value;
						resolve(opResult.value);
					} else {
						reject(opResult);
					}
				});
		});
	}

	@action
	public updateCampaign = (
		scheduleCriteria = (this.mCampaign?.status === Api.EmailSendStatus.WaitingForApproval
			? this.mCampaign?.schedule?.criteria
			: undefined) || Api.ScheduleCriteria.StartAfter
	) => {
		if (!this.isBusy) {
			const promise = new Promise<Api.IOperationResult<Api.ICampaign>>((resolve, reject) => {
				// pull out email and attachments into form data if attachments are present
				const formData: FormData = new FormData();
				const emailMessageModel: Api.IEmailMessageCompose = {
					...this.emailMessage.toJs(),
					options: {
						...(this.emailMessage.options as Api.IEmailMessageComposeOptions),
						scheduledSend: {
							...this.emailMessage.options.scheduledSend,
							criteria: scheduleCriteria,
						},
					},
				};
				formData.append('value', JSON.stringify(emailMessageModel));

				if (this.emailMessage.attachments?.count > 0) {
					this.emailMessage.attachments.attachments.forEach(x => formData.append('files', x));
				}

				const onFinish = action(async (opResult: Api.IOperationResult<Api.ICampaign>) => {
					if (opResult.success) {
						if (this.mCampaign) {
							this.mCampaign.setCampaign(opResult.value);
						} else {
							this.mCampaign = new Api.CampaignViewModel(this.mUserSession, opResult.value);
						}
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ICampaign>(
					this.composeApiUrl({ urlPath: `email/${this.id}` }),
					'PUT',
					formData,
					onFinish,
					onFinish
				);
			});
			return promise;
		}
	};

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate(impersonationContext);
		this.emailMessage?.impersonate(impersonationContext);
		this.campaign?.impersonate(impersonationContext);
		this.mContactsPageCollectionController?.impersonate(impersonationContext);
		return this;
	}

	@action
	public addEmailAttachmentToSelectedEmail = async (file: File) => {
		if (!this.selectedCustomEmail) {
			return;
		}

		const email = { ...this.selectedCustomEmail };
		const contactId = this.selectedCustomEmail.contactId;
		const attachment: Api.IInProgressFileAttachment = {
			fileName: file.name,
			fileSize: file.size,
			uploadTaskId: uuidgen(),
		};

		const data = new FormData();
		data.append('file', file);
		const promise = this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IFileAttachment>(
			Api.ImpersonationBroker.composeApiUrl({ urlPath: `email/attachment` }),
			'POST',
			data
		);
		attachment.uploadPromise = promise;
		email.attachments = [...(email.attachments || []), attachment];
		this.mSelectedCustomEmail = email;
		promise.then(({ value }) => {
			// make sure we're dealing with the same recipient's email
			if (this.mSelectedCustomEmail?.contactId === contactId) {
				const currentEmail = { ...this.selectedCustomEmail };
				const index = currentEmail.attachments.findIndex(
					x => (x as Api.IInProgressFileAttachment).uploadTaskId === attachment.uploadTaskId
				);
				const attachments = currentEmail.attachments.slice();
				const replacement = { ...attachment, ...value };
				delete replacement.uploadPromise;
				attachments.splice(index, 1, replacement);
				currentEmail.attachments = attachments;

				this.mSelectedCustomEmail = currentEmail;
			}
		});

		const result = await promise;
		if (!result.success) {
			email.attachments = email.attachments?.filter(
				x => (x as Api.IInProgressFileAttachment).uploadTaskId !== attachment.uploadTaskId
			);
			throw result;
		}

		return result;
	};

	protected mReset() {
		if (EventLogger) {
			EventLogger.logEvent(
				{
					action: 'LogInheritance',
					category: 'BulkEmailComposerViewModel',
				},
				{
					superResetFunctionType: super.mReset ? typeof super.mReset : null,
					thisConstructorName: this && this.constructor ? this.constructor.name : null,
					thisPrototype: this && this.constructor ? typeof this.constructor.prototype : null,
					thisType: this ? typeof this : null,
				}
			);
		}
		super.mReset();
		this.emailMessage = null;
		if (this.mContactsPageCollectionController) {
			this.mContactsPageCollectionController.reset();
			this.mContactsPageCollectionController = null;
		}
		this.mSelectedCustomEmail = null;
		this.selectedCustomEmailContact = null;
		this.sendSystemJob = null;
		this.showingModal = false;
		this.step = BulkEmailComposerStep.SelectTemplate;
		this.showingSendScheduler = false;
		this.canCustomizeIndividualEmails = true;
		this.userSelectedFilterOption = null;
		this.mAllowContactFilterTagSearchChanges = undefined;
	}

	protected mCreateContactViewModel(contact: Api.IContact) {
		return new Api.ContactViewModel(this.mUserSession, contact);
	}
}

export class ComposeFollowUpEmailViewModel<
	TFollowUpOptions extends Api.IFollowUpOptions = Api.IEmailMessageFollowUpOptions,
	TFollowUpSource = any,
> extends ComposeEmailViewModel<TFollowUpOptions, TFollowUpSource> {
	constructor(userSession: Api.UserSessionContext, followUpSource?: TFollowUpSource) {
		super(userSession);
		this.getFollowUpOptions = this.getFollowUpOptions.bind(this);
		this.followUpSource = followUpSource;
		this.mContactsPageCollectionController = new Api.FilteredPageCollectionController<
			Api.IContact,
			Api.ContactViewModel,
			Api.IEmailMessageFollowUpOptions
		>({
			apiPath: 'email/followup/contacts',
			client: this.mUserSession.webServiceHelper,
			transformer: this.mCreateContactViewModel,
		});
		this.canCustomizeIndividualEmails = false;
	}

	protected getFollowUpOptions(): TFollowUpOptions {
		return {
			_type: 'EmailFollowUpOptions',
			...this.emailMessage.options.followUp,
			excludeContactIds: this.emailMessage.contactsToOmit ? this.emailMessage.contactsToOmit.map(x => x.id) : [],
		};
	}

	public getNextBatchOfRecipients = (pageSize?: number, params?: any) => {
		if (this.mContactsPageCollectionController) {
			const nextOptions = this.getFollowUpOptions();
			delete nextOptions.excludeContactIds;
			return this.mContactsPageCollectionController.getNext(nextOptions, pageSize, params);
		}
		return null;
	};

	public getEmailApproximation = async () => {
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ContactFilterApproximation>(
			this.composeApiUrl({ urlPath: `email/followup/estimate` }),
			'POST',
			this.getFollowUpOptions()
		);

		if (!opResult.success) {
			throw opResult;
		}

		return opResult;
	};

	protected mReset() {
		super.mReset();
		this.canCustomizeIndividualEmails = false;
	}
}

/** SUBCLASSES MUST IMPLEMENT: getNextBatchOfRecipients */
export class ComposeSurveyFollowUpEmailViewModel<
	TFollowUpOptions extends Api.ISurveyFollowUpOptions = Api.ISurveyFollowUpOptions,
	TSurveyReportViewModel extends Api.SurveyReportViewModel = Api.SurveyReportViewModel,
> extends ComposeFollowUpEmailViewModel<TFollowUpOptions, TSurveyReportViewModel> {
	@observable.ref public selectedFollowUpDateRange: {
		end?: Date;
		start?: Date;
	};

	constructor(userSession: Api.UserSessionContext, followUpSource?: TSurveyReportViewModel) {
		super(userSession, followUpSource);
		this.getFollowUpOptions = this.getFollowUpOptions.bind(this);
		this.selectedFollowUpDateRange = followUpSource.dateRange;
	}

	protected getFollowUpOptions(): TFollowUpOptions {
		return {
			...this.emailMessage.options.followUp,
			_type: 'SurveyFollowUpOptions',
			excludeContactIds: this.emailMessage.contactsToOmit ? this.emailMessage.contactsToOmit.map(x => x.id) : [],
			surveyResponseFilter: {
				surveyId: this.followUpSource?.survey?.id,
				endDate: this.selectedFollowUpDateRange?.end?.toISOString(),
				startDate: this.selectedFollowUpDateRange?.start?.toISOString(),
			},
		};
	}

	@computed
	public get isFollowUp() {
		return !!this.emailMessage?.options?.followUp?.surveyResponseFilter?.surveyId;
	}
}

export class ComposeSatisfactionSurveyFollowUpEmailViewModel extends ComposeSurveyFollowUpEmailViewModel<
	Api.ISurveyFollowUpOptions<Api.ISatisfactionSurveyResponseFilterRequest>,
	// @ts-ignore
	Api.SatisfactionSurveyReportViewModel
> {
	@observable.ref
	public selectedFollowUpSubTotals: Api.ISatisfactionSurveyStatsSubtotal[];

	protected getFollowUpOptions(): Api.ISurveyFollowUpOptions<Api.ISatisfactionSurveyResponseFilterRequest> {
		return {
			...this.emailMessage.options.followUp,
			_type: 'SurveyFollowUpOptions',
			excludeContactIds: this.emailMessage.contactsToOmit ? this.emailMessage.contactsToOmit.map(x => x.id) : [],
			surveyResponseFilter: {
				...(this.emailMessage.options.followUp?.surveyResponseFilter || {}),
				surveyId: this.followUpSource?.survey?.id,
				endDate: this.selectedFollowUpDateRange?.end?.toISOString(),
				startDate: this.selectedFollowUpDateRange?.start?.toISOString(),
				_type: 'SatisfactionSurveyResponseFilterRequest',
			},
		};
	}
}

export class ComposeEventRegistrationSurveyFollowUpEmailViewModel extends ComposeSurveyFollowUpEmailViewModel<
	Api.ISurveyFollowUpOptions<Api.IEventRegistrationSurveyResponseFilterRequest>,
	// @ts-ignore
	Api.EventSurveyReportViewModel
> {
	protected getFollowUpOptions(): Api.ISurveyFollowUpOptions<Api.IEventRegistrationSurveyResponseFilterRequest> {
		return {
			...this.emailMessage.options.followUp,
			_type: 'SurveyFollowUpOptions',
			excludeContactIds: this.emailMessage.contactsToOmit ? this.emailMessage.contactsToOmit.map(x => x.id) : [],
			surveyResponseFilter: {
				status: [],
				...(this.emailMessage.options.followUp?.surveyResponseFilter || {}),
				surveyId: this.followUpSource?.survey?.id,
				_type: 'EventRegistrationSurveyResponseFilterRequest',
			},
		};
	}
}

export class ComposeResourceEmailViewModel extends ComposeEmailViewModel {
	@observable public approximation?: Api.ContactFilterApproximation | null = null;
	@observable public emailMessage: ResourceEmailMessageViewModel;
	@observable public qualifier: string;
	@observable public resourceSelector: Api.ResourceSelectorId;
	@observable public resourceSelectorOwners: Api.IUserReference[];

	@observable.ref private mApproximationPromise?: Promise<Api.ContactFilterApproximation> | null = null;

	protected mContactsPageCollectionController: Api.FilteredResourcePageCollectionController<
		Api.IContact,
		Api.ContactViewModel,
		Api.IResourceSelectorContactsByOwnerRequest
	>;

	public static instanceForResourceSelector = (
		userSession: Api.UserSessionContext,
		selector: Api.ResourceSelectorId,
		qualifier?: string,
		template?: Api.ITemplate
	) => {
		const bulkEmailComposer = new ComposeResourceEmailViewModel(userSession);
		bulkEmailComposer.resourceSelector = selector;
		bulkEmailComposer.qualifier = qualifier;
		// TODO: update to be based on selector?
		const emailMessage = new ResourceEmailMessageViewModel(userSession);
		emailMessage.sendEmailRoute = `email/resourceSelector/${bulkEmailComposer.resourceSelector}`;
		if (template) {
			emailMessage.setContentWithTemplate(template);
		}
		bulkEmailComposer.emailMessage = emailMessage;
		bulkEmailComposer.emailMessage.contactsFilterRequest = {
			contactFilterRequest: {},
			groupByDuplicate: true,
		};
		if (bulkEmailComposer.householdOffForResourceSelector) {
			bulkEmailComposer.emailMessage.groupByHousehold = false;
		}

		bulkEmailComposer.mContactsPageCollectionController = new Api.FilteredResourcePageCollectionController<
			Api.IContact,
			Api.ContactViewModel,
			Api.IResourceSelectorContactsByOwnerRequest
		>({
			apiPath: `contact/resourceSelector/${encodeURIComponent(selector)}/filter`,
			client: userSession.webServiceHelper,
			transformer: bulkEmailComposer.mCreateContactViewModel,
		});

		return bulkEmailComposer;
	};

	/**
	 * User must:
	 *
	 * 1. Have started the email flow from a valid entry point
	 * 2. Be assigned to an account where sending on behalf others has been enabled
	 * 3. Be an admin or superadmin for that account
	 * 4. This.resourceSelector === PolicyRenew or HappyBirthday or Turning65 or FinancialReview or Turning 72
	 * 5. This.campaign.id must be null/undefined
	 */
	@computed
	public get canSendOnBehalf() {
		if (this.mCampaign?.id) {
			return false;
		}

		const isAllowedResourceSelector =
			this.resourceSelector === Api.ResourceSelectorId.PolicyRenew ||
			this.resourceSelector === Api.ResourceSelectorId.HappyBirthday ||
			this.resourceSelector === Api.ResourceSelectorId.FinancialReview ||
			this.resourceSelector === Api.ResourceSelectorId.Turning65 ||
			this.resourceSelector === Api.ResourceSelectorId.Turning72 ||
			this.resourceSelector === Api.ResourceSelectorId.Turning73 ||
			this.resourceSelector === Api.ResourceSelectorId.TurningXX ||
			this.resourceSelector.startsWith('Custom');
		return (
			this.mUserSession.canSendOnBehalf &&
			isAllowedResourceSelector &&
			this.mUserSession.account.preferences.resourceSelectorSettings?.[this.resourceSelector]?.contactOwnerCriteria ===
				Api.ContactOwnerCriteria.OnBehalfOfContactOwners
		);
	}

	@computed
	get resourceSelectorContactPoliciesRequest() {
		const filter: Api.IContactsFilterRequest = {
			...(this.emailMessage.contactsFilterRequest.contactFilterRequest || {}),
		};
		// remove all policy filters (retain searches) and add new filter criteria... if we don't, the current list will be echoed back
		const sortedCriteria = Api.VmUtils.sortContactFilterCriteria(filter.criteria);
		filter.criteria = [
			...sortedCriteria.searches,
			...sortedCriteria.compound,
			...sortedCriteria.filters.filter(
				x =>
					!(
						x.op === Api.FilterOperator.Or &&
						x.criteria.find(y => y.property === Api.ContactFilterCriteriaProperty.Policy)
					)
			),
		];

		const request: Api.IResourceSelectorContactsByOwnerRequest = {
			filter,
			qualifier: this.qualifier,
		};
		return request;
	}

	@computed
	public get isApproximating() {
		return Boolean(this.mApproximationPromise);
	}

	/**
	 * @returns Promise<void>
	 * @Override updateApproximationForFilter
	 */
	@action
	public updateApproximationForFilter = () => {
		return this.getEmailApproximationWithResource(true);
	};

	@action
	public setSelectedPolicies = (policies: string[]) => {
		const { contactFilterRequest } = this.emailMessage.contactsFilterRequest;

		const nextFilterRequest: Api.IContactsFilterRequest = {
			...(contactFilterRequest || {}),
		};

		// remove all filters (retain searches) and add new filter criteria
		const sortedCriteria = Api.VmUtils.sortContactFilterCriteria(nextFilterRequest.criteria);
		nextFilterRequest.criteria = [
			...sortedCriteria.searches,
			...sortedCriteria.compound,
			...sortedCriteria.filters.filter(
				x =>
					!(
						x.op === Api.FilterOperator.Or &&
						x.criteria.find(y => y.property === Api.ContactFilterCriteriaProperty.Policy)
					)
			),
		];

		if (policies.length) {
			const policyCriteria: Api.IContactsFilterRequest[] = policies.map(x => {
				return {
					property: Api.ContactFilterCriteriaProperty.Policy,
					value: x,
				};
			});

			nextFilterRequest.criteria.push({
				criteria: policyCriteria,
				op: Api.FilterOperator.Or,
			});
		}

		this.emailMessage.contactsFilterRequest = {
			...this.emailMessage.contactsFilterRequest,
			contactFilterRequest: nextFilterRequest,
		};
		return this.reloadRecipientsList();
	};

	public suppressContactsFromSend = async (contactsToSuppress: string[]) => {
		const request: Api.IResourceSelectorContactsByOwnerRequest = {
			excludeIds: [],
			includeIds: contactsToSuppress,
			qualifier: this.qualifier,
		};
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<string[]>(
			this.composeApiUrl({ urlPath: `contact/resourceSelector/${this.resourceSelector}/suppress` }),
			'POST',
			request
		);

		if (!opResult?.success) {
			throw opResult;
		}
		return opResult;
	};

	@action
	public async getContactOwners(): Promise<Api.IContactOwnerReference[]> {
		if (this.isBusy) {
			return null;
		}

		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<
			{ owner: Api.IUserReference; total: number }[]
		>(
			this.composeApiUrl({ urlPath: `contact/resourceselector/${this.resourceSelector}/owners` }),
			'POST',
			this.selectorRequest
		);

		if (opResult.success) {
			this.contactOwners = opResult.value.map<Api.IContactOwnerReference>(x => ({
				contactsCount: x.total,
				user: x.owner,
			}));
			return this.contactOwners;
		} else {
			throw Api.asApiError(opResult);
		}
	}

	public getNextBatchOfRecipients = (pageSize?: number, params?: any) => {
		if (this.mContactsPageCollectionController) {
			const filter = this.emailMessage.contactsFilterRequest;
			let sortDescriptor: Api.ISortDescriptor = null;
			sortDescriptor = filter?.sortProperty
				? {
						sort: filter.sortAscending ? 'asc' : 'desc',
						sortBy: filter.sortProperty,
					}
				: {
						sort: 'asc',
						sortBy: ContactSortKey.Handle,
					};
			// merge params with sortDescriptor
			const computedParams = {
				...(sortDescriptor || {}),
				...(params || {}),
				expand: this.emailMessage.groupByHousehold ? 'HouseholdMembers' : undefined,
			};

			/**
			 * Don't add the excluded contactIds when fetching the list from the server. We will filter these from the results
			 * in this.recipients. We only need them when sending at the end of the flow and when requesting an approximate
			 * count for the send. If you include them here, you will throw off the skip count in the page token... resulting
			 * in sending to contacts that won't appear in the recipients list.
			 */
			const selectorRequestWithoutExclusion = { ...this.selectorRequest };
			delete selectorRequestWithoutExclusion['excludeIds'];

			return this.mContactsPageCollectionController.getNext(selectorRequestWithoutExclusion, pageSize, computedParams);
		}
		return null;
	};

	/**
	 * @param force reset/disregard an in-flight approximation promise and force a new approximation
	 * @returns Promise<Api.IContactFilterApproximation>
	 */
	@action
	public getEmailApproximationWithResource = (forceReload = false) => {
		if ((this.isApproximating && !forceReload) || !this.resourceSelector) {
			return;
		}

		const filterCopy: Api.IEmailMessageContactsFilterRequest = JSON.parse(
			JSON.stringify(this.emailMessage.contactsFilterRequest)
		);
		filterCopy.contactFilterRequest = {
			...filterCopy.contactFilterRequest,
			criteria: [
				...(filterCopy?.contactFilterRequest?.criteria ?? []),
				{ property: Api.ContactFilterCriteriaProperty.WithEmailAddress },
			],
		};

		const requestWithEmail: Api.IResourceSelectorContactsByOwnerRequest = {
			excludeIds: this.emailMessage.contactsToOmit.map(x => x.id).filter(Boolean) as string[],
			filter: filterCopy.contactFilterRequest,
			groupByHousehold: this.emailMessage.groupByHousehold,
			qualifier: this.qualifier,
			ownershipFilter: filterCopy.ownershipFilter,
		};

		const totalPromise = this.mUserSession.webServiceHelper.callWebServiceAsync<number>(
			this.composeApiUrl({ urlPath: `contact/resourceSelector/${this.resourceSelector}/count` }),
			'POST',
			this.selectorRequest
		);

		const withEmailPromise = this.mUserSession.webServiceHelper.callWebServiceAsync<number>(
			this.composeApiUrl({ urlPath: `contact/resourceSelector/${this.resourceSelector}/count` }),
			'POST',
			requestWithEmail
		);
		const promise = new Promise<Api.ContactFilterApproximation>((resolve, reject) => {
			Promise.all([totalPromise, withEmailPromise])
				.then(([total, withEmail]) => {
					if (promise === this.mApproximationPromise) {
						if (total.success && withEmail.success) {
							this.approximation = {
								hasEmail: withEmail.value,
								total: total.value,
							};
							this.emailMessage.setEmailApproximation(this.approximation);
							resolve(this.approximation);
						} else {
							reject(
								Api.asApiError({
									message: 'Could not fetch contacts with email addresses',
								})
							);
						}
					}
				})
				.finally(() => {
					if (promise === this.mApproximationPromise) {
						this.mApproximationPromise = undefined;
					}
				});
		});
		(promise as any).test = uuidgen();
		this.mApproximationPromise = promise;
		return promise;
	};

	@computed
	public get sendingOnBehalf() {
		return (
			this.canSendOnBehalf &&
			(this.resourceSelector === Api.ResourceSelectorId.HappyBirthday ||
				this.resourceSelector === Api.ResourceSelectorId.PolicyRenew) &&
			(this.emailMessage.options?.sendEmailFrom === Api.SendEmailFrom.SelectedUser ||
				this.emailMessage.options?.sendEmailFrom === Api.SendEmailFrom.ContactOwner)
		);
	}

	@computed
	private get selectorRequest() {
		const request: Api.IResourceSelectorContactsByOwnerRequest = {
			excludeIds: this.emailMessage.contactsToOmit.map(x => x.id),
			filter: this.emailMessage.contactsFilterRequest.contactFilterRequest,
			groupByHousehold: this.emailMessage.groupByHousehold,
			qualifier: this.qualifier,
			ownershipFilter: this.emailMessage.contactsFilterRequest.ownershipFilter,
		};
		return request;
	}

	@action
	public sendWithResource = () => {
		const request: IResourceSelectorEmailRequest = {
			email: this.emailMessage.toJs(),
			selectorRequest: this.selectorRequest,
		};
		return this.emailMessage.sendWithResource(
			request,
			request.ownerIds?.length > 0 ? `${this.emailMessage.sendEmailRoute}/OnBehalf` : undefined
		);
	};
}

interface IResourceSelectorEmailRequest {
	email: Api.IEmailMessageCompose;
	ownerIds?: string[];
	selectorRequest: Api.IResourceSelectorContactsByOwnerRequest;
}

export class ResourceEmailMessageViewModel extends Api.EmailMessageViewModel<File> {
	@action
	public sendWithResource = (request: IResourceSelectorEmailRequest, sendRoute?: string) => {
		if (!this.mSending) {
			this.mSending = true;
			const promise = new Promise<Api.IOperationResult<Api.ISendEmailResponse>>((resolve, reject) => {
				const onFinish = action((result: Api.IOperationResult<Api.ISendEmailResponse>) => {
					this.mSending = false;
					if (result.success) {
						this.didScheduleOrSendSuccessFully = true;
						resolve(result);
					} else {
						reject(result);
					}
				});

				// pull out email and attachments into form data if attachments are present
				const formData: FormData = new FormData();
				formData.append('value', JSON.stringify(request));
				if (this.mNewAttachments && this.mNewAttachments.count > 0) {
					this.mNewAttachments.attachments.forEach(x => formData.append('files', x));
				}

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ISendEmailResponse>(
					this.composeApiUrl({ urlPath: sendRoute || this.sendEmailRoute }),
					'POST',
					formData,
					onFinish,
					onFinish
				);
			});

			return promise;
		}
		return null;
	};
}

export class AppTheme implements IAppTheme {
	@observable public actionItems?: IActionItemsTheme;

	constructor() {
		this.actionItems = {
			kitTintColor: Colors.kitTintColor,
			tintColor: Colors.actionItemTintColor,
		};
	}
}

export class FullScreenModalViewModel {
	@observable public hideBackButton: boolean;
	public readonly history: BrowserLocationStateHistory;
	private mLockedPaths: Set<string>;

	constructor() {
		this.mLockedPaths = new Set<string>();
		this.history = new BrowserLocationStateHistory();
	}

	@computed
	public get hasValidLocation() {
		return this.history.location && this.history.location.pathname && !!this.history.location.key;
	}

	@action
	public dismissModal = () => {
		this.hideBackButton = false;
		this.history.goBackToLocationBeforeTrackedHistory(true);
	};

	public addExclusivePaths = (...args: string[]) => {
		args.forEach(x => this.mLockedPaths.add(x.toLocaleLowerCase()));
	};

	/**
	 * Pushing here will only push to the fullscreen if the current location doesn't have exclusive access to the
	 * fullscreen modal. In that case, the
	 *
	 * @param {H.LocationDescriptorObject<any>} to Location will open in a new tab instead
	 */
	public contextAwarePushLocation = (to: H.LocationDescriptor<any>) => {
		const toPathname = (typeof to === 'string' ? to : (to as H.LocationDescriptorObject<any>).pathname) || '';
		const pathname = ((this.history.location ? this.history.location.pathname : '') || '').toLocaleLowerCase();
		if (Array.from(this.mLockedPaths).some(x => pathname.startsWith(x))) {
			openUrlInNewTab(`${window.location.origin}/#${toPathname}`);
			return;
		}

		this.history.push(to);
	};

	/**
	 * Replacing here will only replace to the fullscreen if the current location doesn't have exclusive access to the
	 * fullscreen modal. In that case, the
	 *
	 * @param {H.LocationDescriptorObject<any>} to Location will open in a new tab instead
	 */
	public contextAwareReplaceLocation = (to: H.LocationDescriptor<any>) => {
		const toPathname = (typeof to === 'string' ? to : (to as H.LocationDescriptorObject<any>).pathname) || '';
		const pathname = ((this.history.location ? this.history.location.pathname : '') || '').toLocaleLowerCase();
		if (Array.from(this.mLockedPaths).some(x => pathname.startsWith(x))) {
			openUrlInNewTab(`${window.location.origin}/#${toPathname}`);
			return;
		}

		this.history.replace(to);
	};
}

export class FabViewModel {
	@observable.ref private mContextCollection: IFabContext[];
	constructor() {
		this.mContextCollection = [];
	}

	@computed
	public get contextCollection() {
		return this.mContextCollection;
	}

	public registerContext = (context: IFabContext) => {
		if (context) {
			if (this.mContextCollection.indexOf(context) < 0) {
				this.mContextCollection = [...this.mContextCollection, context];
			}
			let disposed = false;
			return () => {
				if (!disposed) {
					const index = this.mContextCollection.indexOf(context);
					if (index >= 0) {
						const nextContextCollection = [...this.mContextCollection];
						nextContextCollection.splice(index, 1);
						this.mContextCollection = nextContextCollection;
					}
				}
				disposed = true;
			};
		} else {
			return Api.VmUtils.Noop;
		}
	};
}

export class BulkEmailEventsViewModel<
	TUserSession extends Api.UserSessionContext = Api.UserSessionContext,
> extends Api.RemoteResourceEventsViewModel<TUserSession, Api.ICampaign, Api.IBulkEmailUpdate | Api.IEmailUpdate> {
	@observable.ref private mLastBulkEmailUpdate: Api.IBulkEmailUpdate;
	private mOnEmailsUpdated: (events: Api.IRemoteEvent<Api.IEmailUpdate>[]) => void;
	private mOnBulkEmailUpdated: (events: Api.IRemoteEvent<Api.IBulkEmailUpdate>) => void;

	// {
	// 	delay: 1000,
	// 	type: 'allSinceLastDelivery',
	// }

	constructor(
		userSession: TUserSession,
		resource: Api.ICampaign,
		eventLogger?: Api.IEventLoggingService,
		deliveryOptions?: Api.IRemoteEventsDeliveryOptions
	) {
		super(userSession, resource, '#', eventLogger, deliveryOptions);
	}

	protected composeRoute() {
		const id = this.mResource?.id || '';
		return `BulkEmail${id ? `.${id}` : ''}.${this.mEventType}`;
	}

	public set onEmailsUpdated(callback: (events: Api.IRemoteEvent<Api.IEmailUpdate>[]) => void) {
		this.mOnEmailsUpdated = callback;
	}

	public set onBulkEmailUpdated(callback: (event: Api.IRemoteEvent<Api.IBulkEmailUpdate>) => void) {
		this.mOnBulkEmailUpdated = callback;
	}

	public permananentlyDisconnect() {
		const promise = super.permananentlyDisconnect();
		this.mOnEmailsUpdated = null;
		this.mOnBulkEmailUpdated = null;
		return promise;
	}

	@computed
	public get lastBulkEmailUpdate() {
		return this.mLastBulkEmailUpdate;
	}

	@action
	protected onMessage(events: Api.IRemoteEvent<Api.IBulkEmailUpdate | Api.IEmailUpdate>[]) {
		if (this.mOnMessageCallback) {
			this.mOnMessageCallback(events);
		}

		if (this.mOnEmailsUpdated) {
			const emailEvents = events.filter(x => x.valueType === 'EmailUpdate') as Api.IRemoteEvent<Api.IEmailUpdate>[];
			if (emailEvents.length > 0) {
				this.mOnEmailsUpdated(emailEvents);
			}
		}

		const bulkEmailEvent = events
			.reverse()
			.find(x => x.valueType === 'BulkEmailUpdate') as Api.IRemoteEvent<Api.IBulkEmailUpdate>;
		if (bulkEmailEvent) {
			this.mLastBulkEmailUpdate = bulkEmailEvent.value;
			if (this.mOnBulkEmailUpdated) {
				this.mOnBulkEmailUpdated(bulkEmailEvent);
			}
		}
	}
}

/** A single approvable/rejectable campaign representation */
export class CampaignApprovalViewModel extends ComposeEmailViewModel<Api.IEmailMessageFollowUpOptions> {
	@observable public busy: boolean;
	@observable private mLoadingDefaultMessageContent: boolean;

	@computed
	public get isBusy() {
		return this.busy;
	}

	constructor(userSession: Api.UserSessionContext, campaign: Api.CampaignViewModel) {
		super(userSession);
		const emailMessage = Api.EmailMessageViewModel.instanceForCampaign<File>(userSession, campaign);
		this.emailMessage = emailMessage;
		this.mCampaign = campaign;

		this.mContactsPageCollectionController = new Api.FilteredPageCollectionController<
			Api.IContact,
			Api.ContactViewModel,
			Api.IBulkContactsRequest
		>({
			apiPath: this.composeApiUrl({ urlPath: 'contact/filter/v2' }),
			client: userSession.webServiceHelper,
			transformer: this.mCreateContactViewModel,
		});
		this.canCustomizeIndividualEmails = false;
	}

	@computed
	public get isLoadingDefaultMessageContent() {
		return this.mLoadingDefaultMessageContent;
	}

	@action
	public approveCampaign = async () => {
		if (!this.isBusy) {
			this.busy = true;
			try {
				// pull out email and attachments into form data if attachments are present
				const formData: FormData = new FormData();
				const emailMessageModel: Api.IEmailMessageCompose = {
					...this.emailMessage.toJs(),
					options: {
						...(this.emailMessage.options as Api.IEmailMessageComposeOptions),
						scheduledSend: {
							...this.emailMessage.options.scheduledSend,
							// Apparently, you cannot change this to "Immediately" or the api won't send at all and these campaigns won't be listed in reporting (they disappear??)
							// Will have to disable the ability to "Send Now" during R&A flow
							// criteria: this.emailMessage.options.scheduledSend?.criteria || ViewModels.ScheduleCriteria.StartAfter,
							criteria: Api.ScheduleCriteria.StartAfter,
						},
					},
				};
				formData.append('value', JSON.stringify(emailMessageModel));
				if (this.emailMessage.attachments?.count > 0) {
					this.emailMessage.attachments.attachments.forEach(x => formData.append('files', x));
				}

				// 1. Save the changes
				let opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ICampaign>(
					this.composeApiUrl({ urlPath: `email/${this.mCampaign.id}` }),
					'PUT',
					formData
				);
				if (!opResult?.success) {
					throw opResult;
				}

				// 2. Call explicit approve
				opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ICampaign>(
					this.composeApiUrl({ urlPath: `email/${this.mCampaign.id}/approve` }),
					'POST'
				);
				if (!opResult?.success) {
					throw opResult;
				}
				this.mCampaign.setCampaign(opResult.value);
				return opResult;
			} catch (error) {
				throw Api.asApiError(error);
			}
		}
	};
	@action
	public sendCompliance = async (sendWithComplianceEmail?: string) => {
		if (!this.isBusy) {
			this.busy = true;
			// 1. Call explicit to send
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ICampaign>(
				this.composeApiUrl({
					queryParams: { sendWithComplianceEmail },
					urlPath: `email/${this.mCampaign.id}/sendCompliance`,
				}),
				'POST'
			);
			this.busy = false;
			if (!opResult?.success) {
				throw opResult;
			}
			return opResult;
		}
	};

	@action
	public rejectCampaign = () => {
		if (!this.isBusy) {
			this.busy = true;
			const promise = new Promise<Api.IOperationResult<Api.ICampaign>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.ICampaign>) => {
					this.busy = false;
					if (opResult.success) {
						this.mCampaign.setCampaign(opResult.value);
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ICampaign>(
					this.composeApiUrl({ urlPath: `email/${this.mCampaign.id}/reject` }),
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
			return promise;
		}
	};

	@action
	public loadDefaultMessageContent = (forceReload?: boolean) => {
		const promise = this.mCampaign.loadDefaultMessageContent(forceReload);
		if (promise) {
			this.busy = true;
			this.mLoadingDefaultMessageContent = true;
			promise
				.then(
					action(() => {
						this.emailMessage.content = this.mCampaign.defaultMessageContent;
						this.mLoadingDefaultMessageContent = false;
						this.busy = false;
					})
				)
				.catch(() => {
					this.mLoadingDefaultMessageContent = false;
				});
		}
		return promise;
	};
}

/** For managing the approval/rejection of multiple campaigns */
export class CampaignsApprovalViewModel extends Api.ViewModel {
	@observable public name: string;
	@observable.ref
	private mCompletedCampaignComposers: CampaignApprovalViewModel[];
	@observable.ref
	private mPendingCampaignComposers: CampaignApprovalViewModel[];
	@observable.ref private mApprovePromise: Promise<Api.IOperationResult<Api.ICampaign>>;
	@observable.ref private mRejectPromise: Promise<Api.IOperationResultNoValue>;

	constructor(userSession: Api.UserSessionContext, campaigns: Api.CampaignViewModel[]) {
		super(userSession);
		this.mPendingCampaignComposers = campaigns.map(x => new CampaignApprovalViewModel(userSession, x));
		this.mCompletedCampaignComposers = [];
	}

	@computed
	public get isBusy() {
		return this.busy || this.loading || !!this.mApprovePromise || !!this.mRejectPromise;
	}

	@computed
	public get activeCampaign() {
		return this.mPendingCampaignComposers?.length > 0 ? this.mPendingCampaignComposers[0].campaign : null;
	}

	@computed
	public get pendingCampaigns() {
		return this.mPendingCampaignComposers.map(x => x.campaign);
	}

	@computed
	public get completedCampaigns() {
		return this.mCompletedCampaignComposers.map(x => x.campaign);
	}

	@computed
	public get campaignComposer() {
		return this.mPendingCampaignComposers.length > 0 ? this.mPendingCampaignComposers[0] : null;
	}

	@computed
	public get sendDate() {
		return this.campaignComposer.emailMessage.options.scheduledSend.startDate;
	}

	@action
	public approveCampaign = () => {
		if (!this.mApprovePromise) {
			const pending = [...this.mPendingCampaignComposers];
			const campaignComposer = pending[0];
			pending.splice(0, 1);
			const promise = campaignComposer.approveCampaign();
			if (promise) {
				promise.then(() => {
					runInAction(() => {
						this.mPendingCampaignComposers = pending;
						this.mCompletedCampaignComposers = [...this.mCompletedCampaignComposers, campaignComposer];
						this.mApprovePromise = null;
					});
				});
			}
			this.mApprovePromise = promise;
		}

		return this.mApprovePromise;
	};
	@action
	public sendCompliance = (sendWithComplianceEmail?: string) => {
		if (!this.mApprovePromise) {
			const pending = [...this.mPendingCampaignComposers];
			const campaignComposer = pending[0];
			pending.splice(0, 1);
			const promise = campaignComposer.sendCompliance(sendWithComplianceEmail);
			if (promise) {
				this.mApprovePromise = null;
			}
			return promise;
		}
		return this.mApprovePromise;
	};
	@action
	public approveWithoutCompliance = () => {
		if (!this.mApprovePromise) {
			const pending = [...this.mPendingCampaignComposers];
			const campaignComposer = pending[0];
			pending.splice(0, 1);
			const promise = campaignComposer.approveCampaign();
			if (promise) {
				promise.then(() => {
					this.mPendingCampaignComposers = pending;
					this.mCompletedCampaignComposers = [...this.mCompletedCampaignComposers, campaignComposer];
					this.mApprovePromise = null;
				});
			}
			this.mApprovePromise = promise;
		}

		return this.mApprovePromise;
	};

	@action
	public rejectCampaign = () => {
		if (!this.mApprovePromise) {
			const pending = [...this.mPendingCampaignComposers];
			const campaignComposer = pending[0];
			pending.splice(0, 1);
			const promise = campaignComposer.rejectCampaign();
			if (promise) {
				promise.then(() => {
					runInAction(() => {
						this.mPendingCampaignComposers = pending;
						this.mCompletedCampaignComposers = [...this.mCompletedCampaignComposers, campaignComposer];
						this.mRejectPromise = null;
					});
				});
			}
			this.mRejectPromise = promise;
		}

		return this.mRejectPromise;
	};

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate();
		this.mPendingCampaignComposers?.forEach(x => x.impersonate(impersonationContext));
		this.mCompletedCampaignComposers?.forEach(x => x.impersonate(impersonationContext));
		return this;
	}
}

export interface ITemplateCard {
	_type?: Api.ApiModelType;
	associatedTemplates?: IAssociatedTemplate[];
	attachments?: Api.IFileAttachment[];
	content?: Api.IRawRichTextContentState;
	creator?: Api.IUserReference;
	id: string;
	keywords?: string[];
	name: string;
	schedule?: Api.ISuggestedSendSchedule;
	scope?: Api.TemplateScope;
	summary?: string;
	creationDate?: string;
}

export interface IBlogTemplateCard extends ITemplateCard {
	attachments: Api.IFileAttachmentWithURL[];
	contentCalendarSuggestionId?: string;
}

export interface IAssociatedTemplate {
	id: string;
	templateType: Api.TemplateType;
}

export enum KnownCategories {
	All = 'All',
	Featured = 'Featured',
	Me = 'Me',
	MyTemplates = 'My Templates',
	Others = 'Others',
	SocialMediaDrafts = 'Social Media Drafts',
	BlogPostDrafts = 'Blog Post Drafts',
	/** Doesn't exist on the server */
	FinraReviewed = 'FINRA Reviewed',
	HtmlNewsletters = 'Html Newsletters',
}

export abstract class CampaignBrowserViewModel<TCardType extends object = any> extends Api.ViewModel {
	@observable.ref protected BrowseSection: Api.IDictionary<TCardType[]>;
	@observable.ref protected TemplateCards: Api.ObservableCollection<TCardType>;
	@observable.ref protected AutomationTemplateCards: ITemplateCard[];
	@observable.ref protected Categories: string[];
	@observable.ref protected searching: boolean;

	@computed
	public get isBusy() {
		return this.searching || this.busy || this.loading;
	}

	@computed
	public get isSearching() {
		return this.searching;
	}

	@computed
	public get categories() {
		return this.Categories;
	}

	@computed
	public get browseSection() {
		return this.BrowseSection;
	}

	@computed
	public get categorySection() {
		return this.TemplateCards;
	}

	@computed
	public get automationTemplates() {
		return this.AutomationTemplateCards;
	}

	public abstract loadCategories(industry: Api.Industry): Promise<void>;

	public abstract loadTemplates(
		industry: Api.Industry,
		categoryName: string,
		excludeExpired?: boolean,
		sortBy?: string
	): Promise<void>;

	public abstract search(
		query: string,
		industry: Api.Industry,
		excludeExpired?: boolean,
		sortBy?: string
	): Promise<void>;

	public abstract clearSearch(): void;

	public abstract loadAll(industry: Api.Industry, excludeExpired?: boolean, sortBy?: string): Promise<void>;

	@action
	public async loadAutomationTemplateById(templateId: string) {
		if (!this.busy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<ITemplateCard[]>(
				this.composeApiUrl({ urlPath: `automationTemplate/${templateId}` }),
				'GET'
			);
			this.busy = false;
			if (opResult.success) {
				return opResult;
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	@action
	public async startAutomationForContact(contactId: string, automationId: string) {
		if (!this.isBusy) {
			this.busy = true;
			try {
				const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAutomation>(
					this.composeApiUrl({
						queryParams: { start: 'true' },
						urlPath: `Automation/${automationId}/Contact/${contactId}`,
					}),
					'POST'
				);
				this.busy = false;
				if (opResult.success) {
					return opResult;
				} else {
					throw Api.asApiError(opResult);
				}
			} catch (error) {
				this.busy = false;
				throw Api.asApiError(error);
			}
		}
	}

	@action
	public async loadAutomationTemplate() {
		if (!this.busy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<ITemplateCard[]>(
				this.composeApiUrl({
					queryParams: {
						status: 'Published',
					},
					urlPath: `automationTemplate`,
				}),
				'GET'
			);
			this.busy = false;
			if (opResult.success) {
				this.AutomationTemplateCards = opResult.value;
				return opResult;
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}
}

export class EmailCampaignBrowserViewModel extends CampaignBrowserViewModel<ITemplateCard> {
	constructor(userSession: Api.UserSessionContext) {
		super(userSession);
		this.loadCategories = this.loadCategories.bind(this);
		this.loadTemplates = this.loadTemplates.bind(this);
		this.search = this.search.bind(this);
		this.clearSearch = this.clearSearch.bind(this);
		this.loadAll = this.loadAll.bind(this);
	}

	@action
	public async deleteTemplate(template: ITemplateCard): Promise<void> {
		if (this.busy) {
			return null;
		}

		this.busy = true;
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync(
			this.composeApiUrl({ urlPath: `template/${encodeURIComponent(template.id)}` }),
			'DELETE'
		);

		this.busy = false;
		if (!opResult.success) {
			throw Api.asApiError(opResult);
		}
	}

	@action
	public async shareTemplate(template: ITemplateCard, scope: Api.TemplateScope): Promise<void> {
		if (this.busy) {
			return null;
		}

		this.busy = true;
		/**
		 * Returning `0` or `1` here because the existing enum on the front end uses strings (`ViewModels.TemplateScope`)
		 * but the API uses integers. didnt make sense to update `ViewModels.TemplateScope` for this one use case.
		 */
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync(
			this.composeApiUrl({
				queryParams: {
					scope: scope === Api.TemplateScope.Account ? 1 : 0,
				},
				urlPath: `template/${encodeURIComponent(template.id)}/scope`,
			}),
			'PATCH'
		);

		this.busy = false;
		if (!opResult.success) {
			throw Api.asApiError(opResult);
		}
	}

	@action
	public async loadCategories(industry: Api.Industry) {
		if (!this.busy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<string[]>(
				this.composeApiUrl({ urlPath: `template/${encodeURIComponent(industry)}/categories` }),
				'GET'
			);
			this.busy = false;
			if (opResult.success) {
				const isFinancial = industry === Api.Industry.Financial;
				const categories = opResult.value.filter(
					x => x !== 'Archived' && x !== 'Uncategorized' && (isFinancial ? x !== KnownCategories.FinraReviewed : true)
				);

				categories.splice(0, 0, KnownCategories.All);
				categories.splice(2, 0, KnownCategories.MyTemplates);
				if (isFinancial) {
					const indexOfFeatured = categories.findIndex(x => x === KnownCategories.Featured) || 0;
					categories.splice(indexOfFeatured + 1, 0, KnownCategories.FinraReviewed);
				}

				this.Categories = categories;
			} else {
				throw opResult;
			}
		}
	}

	@action
	public async loadTemplates(
		industry: Api.Industry,
		categoryName: string,
		excludeExpired = true,
		sortBy: 'lastModifiedDate' | 'name' = 'lastModifiedDate'
	) {
		if (categoryName === KnownCategories.HtmlNewsletters) {
			// handled by the component because the category doesn't exist on the server
			return Promise.resolve();
		}

		if (categoryName === KnownCategories.All) {
			return this.loadAll(industry, excludeExpired);
		}

		if (categoryName === KnownCategories.MyTemplates) {
			return this.loadMe(industry, { excludeExpired, sortBy });
		}

		if (!this.busy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<ITemplateCard[]>(
				this.composeApiUrl({
					queryParams: {
						excludeExpired,
						sortBy,
					},
					urlPath: `template/${encodeURIComponent(industry)}/${encodeURIComponent(categoryName)}`,
				}),
				'GET'
			);

			this.busy = false;
			if (opResult.success) {
				this.TemplateCards = new Api.ObservableCollection(opResult.value, 'id');
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	@action
	public async loadAll(industry: Api.Industry, excludeExpired = true) {
		if (!this.busy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IDictionary<ITemplateCard[]>>(
				this.composeApiUrl({
					queryParams: {
						excludeExpired,
					},
					urlPath: `template/${encodeURIComponent(industry)}/browse`,
				}),
				'GET'
			);
			this.busy = false;
			if (opResult.success) {
				this.BrowseSection = opResult.value;
				this.loaded = true;
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	public clearSearch() {
		this.TemplateCards = null;
	}

	@action
	public async loadMe(industry: Api.Industry, params?: IApiParams) {
		const { excludeExpired = true, sort = 'desc', sortBy = 'lastModifiedDate' } = params || {};
		if (!this.busy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<ITemplateCard[]>(
				this.composeApiUrl({
					queryParams: {
						excludeExpired,
						sort,
						sortBy,
					},
					urlPath: `template/${encodeURIComponent(industry)}/me`,
				}),
				'GET'
			);

			this.busy = false;
			if (opResult.success) {
				this.TemplateCards = new Api.ObservableCollection(opResult.value, 'id');
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	@action
	public async search(query: string, industry: Api.Industry, excludeExpired = true, sortBy = 'lastModifiedDate') {
		const search = query.trim();
		if (!this.searching && search) {
			this.searching = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<ITemplateCard[]>(
				this.composeApiUrl({
					queryParams: {
						excludeExpired,
						search,
						sortBy,
					},
					urlPath: `template/${industry}/filter`,
				}),
				'GET'
			);

			this.searching = false;
			if (opResult.success) {
				this.TemplateCards = new Api.ObservableCollection(opResult.value, 'id');
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}
}

export class SocialMediaCampaignBrowserViewModel extends CampaignBrowserViewModel<ITemplateCard> {
	@observable.ref protected SocialMediaCards: Api.ObservableCollection<Api.ISocialMediaPost>;

	constructor(userSession: Api.UserSessionContext) {
		super(userSession);
		this.loadCategories = this.loadCategories.bind(this);
		this.loadTemplates = this.loadTemplates.bind(this);
		this.search = this.search.bind(this);
		this.clearSearch = this.clearSearch.bind(this);
		this.loadAll = this.loadAll.bind(this);
	}

	@computed
	public get draftSocialMediaPosts() {
		return this.SocialMediaCards;
	}

	@action
	public async loadCategories(industry: Api.Industry) {
		if (!this.busy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<string[]>(
				this.composeApiUrl({ urlPath: `template/${encodeURIComponent(industry)}/socialCategories` }),
				'GET'
			);
			this.busy = false;
			if (opResult.success) {
				const categories = opResult.value.filter(
					x => x !== 'Archived' && x !== 'Uncategorized' && x !== 'HTML Newsletters' && x !== 'My Templates'
				);
				categories.splice(0, 0, KnownCategories.All);
				const account = this.impersonationContext?.account || this.mUserSession.account;
				if (account?.features?.socialMedia?.enabled) {
					categories.splice(2, 0, KnownCategories.SocialMediaDrafts);
				}
				this.Categories = categories;
			} else {
				throw opResult;
			}
		}
	}

	@action
	public async loadTemplates(
		industry: Api.Industry,
		categoryName: string,
		excludeExpired = true,
		sortBy: 'lastModifiedDate' | 'name' = 'lastModifiedDate'
	) {
		if (categoryName === KnownCategories.All) {
			return this.loadAll(industry, excludeExpired);
		}

		if (categoryName === KnownCategories.SocialMediaDrafts) {
			return this.loadSocialDrafts();
		}

		if (!this.busy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<ITemplateCard[]>(
				this.composeApiUrl({
					queryParams: {
						excludeExpired,
						sortBy,
					},
					urlPath: `template/${encodeURIComponent(industry)}/${encodeURIComponent(categoryName)}/social`,
				}),
				'GET'
			);

			this.busy = false;
			if (opResult.success) {
				this.TemplateCards = new Api.ObservableCollection(opResult.value, 'id');
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	@action
	public async loadAll(industry: Api.Industry, excludeExpired = true) {
		if (!this.busy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IDictionary<ITemplateCard[]>>(
				this.composeApiUrl({
					queryParams: {
						excludeExpired,
					},
					urlPath: `template/${encodeURIComponent(industry)}/browse/social`,
				}),
				'GET'
			);
			this.busy = false;
			if (opResult.success) {
				await this.loadSocialDrafts(6);
				this.BrowseSection = opResult.value;
				this.loaded = true;
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	@action
	public async loadSocialDrafts(draftsToLoad = 100) {
		/**
		 * In admin -> account content calendar, the "All" category tries to load these, hence the
		 * "!this.impersonationContext?.account" check
		 */
		if (
			!this.busy &&
			this.mUserSession?.account?.features?.socialMedia?.enabled &&
			!this.impersonationContext?.account
		) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<
				Api.IPagedCollection<Api.ISocialMediaPost>
			>(
				this.composeApiUrl({
					queryParams: { pageSize: draftsToLoad, userId: this.mUserSession.user.id },
					urlPath: `social/post/drafts`,
				}),
				'GET'
			);

			this.busy = false;
			if (opResult.success) {
				this.SocialMediaCards = new Api.ObservableCollection(opResult.value.values, 'id');
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	@action
	public async search(query: string, industry: Api.Industry, excludeExpired = true, sortBy = 'lastModifiedDate') {
		const search = query.trim();
		if (!this.busy && search) {
			this.searching = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<ITemplateCard[]>(
				this.composeApiUrl({
					queryParams: {
						excludeExpired,
						search,
						sortBy,
					},
					urlPath: `template/${industry}/filter/social`,
				}),
				'GET'
			);

			this.searching = false;
			if (opResult.success) {
				this.TemplateCards = new Api.ObservableCollection(opResult.value);
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	public clearSearch() {
		this.TemplateCards = null;
	}
}

export class AppImportContactsViewModel extends Api.ImportContactsViewModel<File> {
	public importFromFile(file: File, contentType?: Api.ImportContactsContentType, deleteRenewalKeyFacts?: boolean) {
		let type = contentType;
		if (!type && file) {
			const fileExt = (file.name || '')
				.split('.')
				.filter(x => !!x)
				.reverse()[0]
				.toLocaleLowerCase();
			switch (fileExt) {
				case 'xlsx': {
					type = Api.ImportContactsContentType.xlsx;
					break;
				}
				case 'xls': {
					type = Api.ImportContactsContentType.xls;
					break;
				}
				default: {
					type = Api.ImportContactsContentType.csv;
					break;
				}
			}
		}
		return super.importFromFile(file, type, deleteRenewalKeyFacts);
	}
}

export class UnsubscribeViewModel extends Api.ViewModel {
	private mAccountName: string;
	private mToken: string;
	private mEmail: string;
	@observable private mWasError = false;
	@observable private mIsSubscribed = true;

	constructor(accountName: string, token: string, email: string) {
		super(
			new Api.UserSessionContext({
				apiConfig: { baseUrl: process.env.API_URL },
			})
		);

		this.mAccountName = accountName;
		this.mToken = token;
		this.mEmail = email;
	}

	public get accountName() {
		return this.mAccountName;
	}
	public get token() {
		return this.mToken;
	}
	public get email() {
		return this.mEmail;
	}

	@computed
	public get wasError() {
		return this.mWasError;
	}

	@computed
	public get isSubscribed() {
		return this.mIsSubscribed;
	}

	@action
	private subscribeAction = async (route: string, isSubscribed: boolean) => {
		this.busy = true;
		this.mWasError = false;
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<boolean>(route, 'POST');

		if (!opResult.success) {
			this.busy = false;
			this.mWasError = true;
			return false;
		}

		this.busy = false;
		this.mIsSubscribed = isSubscribed;
		return true;
	};

	public unsubscribe = () => this.subscribeAction(`unsubscribe/${this.token}`, false);
	public resubscribe = () => this.subscribeAction(`unsubscribe/${this.token}/resubscribe`, true);
}

interface IContactSearch extends Api.IBaseResourceModel {
	filter: Api.IContactFilterCriteria;
	name: string;
}

export class SavedSearchesViewModel extends Api.ViewModel {
	@observable.ref
	private mSavedSearches: Api.ObservableCollection<SavedSearchViewModel> =
		new Api.ObservableCollection<SavedSearchViewModel>();

	constructor(userSession: Api.UserSessionContext) {
		super(userSession);
	}

	@computed
	public get searches() {
		return this.mSavedSearches;
	}

	@computed
	public get hasSearches() {
		return !!this.mSavedSearches.length;
	}

	@action
	public async save(search: IContactSearch) {
		if (!this.busy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<IContactSearch>(
				this.composeApiUrl({ urlPath: 'contactSearch' }),
				'POST',
				search
			);

			this.busy = false;
			if (opResult.success) {
				const savedSearch = new SavedSearchViewModel(this.mUserSession, opResult.value);
				this.mSavedSearches.add(savedSearch);
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	@action
	public async delete(id: string) {
		if (!this.busy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<IContactSearch>(
				this.composeApiUrl({ urlPath: `contactSearch/${encodeURIComponent(id)}` }),
				'DELETE'
			);

			this.busy = false;
			if (opResult.success) {
				this.mSavedSearches = new Api.ObservableCollection(this.mSavedSearches.filter(x => x.id !== id));
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}

	@action
	public async loadAll() {
		if (!this.busy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<IContactSearch[]>(
				this.composeApiUrl({ urlPath: 'contactSearch' }),
				'GET'
			);

			this.busy = false;
			if (opResult.success) {
				this.mSavedSearches = new Api.ObservableCollection(
					opResult.value.map(x => new SavedSearchViewModel(this.mUserSession, x))
				);
				this.loaded = true;
			} else {
				throw Api.asApiError(opResult);
			}
		}
	}
}

export class SavedSearchViewModel extends Api.ViewModel {
	@observable.ref private mSavedSearch: IContactSearch;

	constructor(userSession: Api.UserSessionContext, savedSearch: IContactSearch) {
		super(userSession);
		this.mSavedSearch = savedSearch;
	}

	@computed
	public get filter() {
		return this.mSavedSearch.filter;
	}

	@computed
	public get name() {
		return this.mSavedSearch.name;
	}

	@computed
	public get id() {
		return this.mSavedSearch.id;
	}
}

export class CreateAccountViewModel extends Api.ViewModel {
	constructor(userSession: Api.UserSessionContext) {
		super(userSession);
	}

	@action
	public createInitialUser = async (account: Api.IAccount, email: string, phone: string) => {
		const result = await this.userSession.webServiceHelper.callWebServiceAsync<Api.IUser>(
			'user/initiate-create?sendWelcomeEmail=false',
			'POST',
			{
				accountId: account.id,
				email,
				mobilePhone: phone,
				token: account.initialUserToken,
			}
		);

		if (!result.success) {
			throw Api.asApiError(result.systemMessage);
		}

		return result.value;
	};

	@action
	public createAccount = async (account: Api.IAccount) => {
		const result = await this.userSession.webServiceHelper.callWebServiceAsync<Api.IAccount>(
			'account',
			'POST',
			account
		);

		if (!result.success) {
			throw Api.asApiError(result.systemMessage);
		}

		return result.value;
	};
}

export class MangeEmailTemplatesViewModel extends Api.TemplatesViewModel {
	@observable.ref private mLoadingTemplate: boolean;

	@computed
	public get isLoadingTemplate() {
		return this.mLoadingTemplate;
	}

	public async getById(id: string) {
		const promise = super.getById(id);
		if (promise) {
			try {
				this.mLoadingTemplate = true;
				await promise;
			} catch (_) {
				// do nothing
			}
			this.mLoadingTemplate = false;
		}
		return promise;
	}
}
