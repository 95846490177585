import Bluebird from 'bluebird';
import equal from 'fast-deep-equal';
import escapeRegExp from 'lodash.escaperegexp';
import { action, computed, observable, runInAction, toJS } from 'mobx';
import moment from 'moment';
import { stringify as toQueryStringParams } from 'query-string';
import { v4 as uuidgen } from 'uuid';
import { NotesSortOptionValue } from '../models';
import * as VmUtils from './Utils';
import * as Api from './sdk';
import { AutomationViewModel } from './viewModels/Automations';
import {
	BaseObservablePageCollectionController,
	FilteredPageCollectionController,
	ObservableCollection,
	ObservablePageCollectionController,
	PagedViewModel,
} from './viewModels/Collections';
import {
	AttachmentsToBeUploadedViewModel,
	AttachmentsViewModel,
	FileAttachmentViewModel,
	FileWithExtensionsType,
} from './viewModels/Files';
import { UpcomingAcknowledgeableKeyFactsViewModel, UpcomingKeyFactViewModel } from './viewModels/KeyFacts';
import { TemplatesViewModel } from './viewModels/Templates';
import {
	ObservablePageCollectionControllerOld,
	UserSessionContext,
	UserViewModel,
	ViewModel,
	getEmptyPageControllerResolvedPromise,
} from './viewModels/index';

export interface IRichContentEditorStateAttributes {
	document?: {
		characterCount?: number;
		wordCount?: number;
	};
	selection?: {
		characterCount?: number;
		wordCount?: number;
	};
}

export interface IRichContentEditorState {
	attributes?: IRichContentEditorStateAttributes;
	getPlainTextPreview(truncate?: boolean, terminator?: string): string;
	getRawRichTextContent(): Api.IRawRichTextContentState;
	hasContent(): boolean;
	reset(): void;
}

export interface IBoardSyncDelta<
	TBoard extends Api.IBoard = Api.IBoard,
	TItem extends Api.IBoardItem = Api.IBoardItem,
> {
	board?: TBoard;
	stageItems?: Api.IDictionary<TItem[]>;
}

export interface IAggregateActivity<TResource extends object = any> extends Api.IResourceAggregateActivity {
	resource?: TResource;
}

export class UserWithStatsViewModel extends UserViewModel<Api.IUserWithStats> {
	@computed
	public get stats() {
		return this.mUser.stats;
	}

	@action
	public loadUserStats = () => {
		if (!this.isBusy) {
			this.busy = true;
			const promise = new Promise<Api.IUserStats>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IUserStats>) => {
					runInAction(() => {
						if (opResult.success) {
							const userStats: Api.IUserWithStats = {
								...(this.mUser || {}),
								stats: opResult.value,
							};
							this.mSetUser(userStats);
							this.busy = false;
							resolve(opResult.value);
						} else {
							this.busy = false;
							reject(opResult);
						}
					});
				};
				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IUserStats>(
					this.composeApiUrl({ urlPath: 'user/stats' }),
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
			return promise;
		}
	};

	@action
	public updateTaggingGameActivities = (taggingGameActivities: Api.ITaggingGameActivities) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IUserStats>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<Api.IUserStats>) => {
					runInAction(() => {
						this.busy = false;
						if (result.success) {
							this.mUser.stats = result.value;
							resolve(result.value);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IUserStats>(
					'user/stats/taggingGame',
					'POST',
					taggingGameActivities,
					onFinish,
					onFinish
				);
			});
		}
		return null;
	};
}

export type AutoCompleteResultFilter<T> = (result: T, query: string) => boolean;

export interface IAutoCompleteOptions<T> {
	apiParams?: Api.IDictionary;
	apiPath: string;
	inputDelay?: number;
	localFilter?: AutoCompleteResultFilter<T>;
	pageSize?: number;
	searchParamName: string;
}

/** Encapsulates logic needed to do autocomplete searches. */
export class AutoCompleteViewModel<T = any> extends Api.ImpersonationBroker {
	@observable protected mSearchQuery: string;
	@observable.ref private mTimeoutHandle: any;
	@observable.ref protected mSearchPromise: Bluebird<Api.IOperationResult<Api.IPagedCollection<T>>>;
	@observable.ref protected mSearchResults: T[];
	@observable protected mTotalCount: number;
	@observable protected mHasMorePages: boolean;
	protected mOptions: IAutoCompleteOptions<T>;
	protected mUserSession: UserSessionContext;
	protected mDefaultApiParams: Api.IDictionary<any>;

	/**
	 * @param dataSource Provides the specific models
	 * @param filter Allows for a final filter before updating
	 */
	constructor(userSession: UserSessionContext, options: IAutoCompleteOptions<T>) {
		super();
		this.mGetResultsWithQuery = this.mGetResultsWithQuery.bind(this);
		this.mGetOpResult = this.mGetOpResult.bind(this);
		this.mReset = this.mReset.bind(this);
		this.mOptions = options;
		this.mUserSession = userSession;
		this.mDefaultApiParams = {
			enforcePageSize: true,
			...(options.apiParams || {}),
			pageSize: options.pageSize > 0 ? options.pageSize : 5,
		};
		this.mReset();
	}

	@computed
	public get totalCount() {
		return this.mTotalCount || 0;
	}

	@computed
	public get searchResults() {
		const results = this.mSearchResults || [];
		if (this.canFilterLocally) {
			return results.filter(x => {
				return this.mOptions.localFilter(x, this.mSearchQuery);
			});
		}
		return results;
	}

	@computed
	public get isFetchingResults() {
		return !!this.mSearchPromise;
	}

	@computed
	public get isBusy() {
		return this.isFetchingResults || !!this.mTimeoutHandle;
	}

	protected get canFilterLocally() {
		return !this.mHasMorePages && this.mOptions.localFilter && !!this.mSearchQuery;
	}

	@action
	public reset = () => {
		this.mReset();
	};

	@action
	public addParam = (param: Api.IDictionary) => {
		this.mDefaultApiParams = {
			...this.mDefaultApiParams,
			...param,
		};
	};

	@action
	public setSearchQuery = (
		query: string,
		delay = this.mOptions.inputDelay >= 0 ? this.mOptions.inputDelay : 0,
		searchWithNoQuery?: boolean,
		customParams?: Api.IDictionary<any>
	) => {
		if (searchWithNoQuery) {
			this.mGetResultsWithQuery('');
			return;
		}

		const trimmedQuery = (query || '').trim();
		if (trimmedQuery) {
			// check to see if we can filter locally
			if (!this.mTimeoutHandle && !this.mSearchPromise && this.canFilterLocally) {
				// Is this a continuation?
				const beginsWithRegExp = new RegExp(`^${escapeRegExp(this.mSearchQuery)}`, 'i');
				if (beginsWithRegExp.test(trimmedQuery)) {
					// just set mSearchQuery and let the computed getter filter locally
					this.mSearchQuery = trimmedQuery;
					return;
				}
			}

			this.mClearTimeoutHandle();
			this.mCancelCurrentSearch();

			const performSearch = () => {
				this.mGetResultsWithQuery(trimmedQuery, customParams);
			};

			if (delay > 0) {
				this.mTimeoutHandle = setTimeout(() => {
					runInAction(() => {
						this.mClearTimeoutHandle();
						performSearch();
					});
				}, delay);
			} else {
				performSearch();
			}
		} else {
			this.mReset();
		}
	};

	protected mCancelCurrentSearch = () => {
		if (this.mSearchPromise) {
			this.mSearchPromise.cancel();
			this.mSearchPromise = null;
		}
	};

	protected mClearTimeoutHandle = () => {
		if (this.mTimeoutHandle) {
			clearTimeout(this.mTimeoutHandle);
			this.mTimeoutHandle = null;
		}
	};

	protected mGetResultsWithQuery(query: string, customParams?: Api.IDictionary<any>) {
		const promise = new Bluebird<Api.IOperationResult<Api.IPagedCollection<T>>>(async (resolve, reject, onCancel) => {
			let canceled = false;
			if (onCancel) {
				onCancel(() => {
					canceled = true;
				});
			}

			const computedParams = {
				...this.mDefaultApiParams,
				...(customParams || {}),
				[this.mOptions.searchParamName]: query || '',
			};
			VmUtils.removeEmptyKvpFromObject(computedParams);

			try {
				const opResult = await this.mGetOpResult(computedParams);
				runInAction(() => {
					if (!canceled) {
						this.mSearchPromise = null;
						this.mClearTimeoutHandle();
						if (opResult.success) {
							this.mSearchQuery = query;
							this.mHasMorePages = !!opResult.value.pageToken;
							this.mTotalCount = opResult.value.totalCount;

							const isValue = typeof opResult?.value === 'object';
							const isValuesInValue = typeof opResult?.value?.values === 'object';

							let data = null;

							if (isValue && !isValuesInValue) {
								data = opResult?.value;
							}

							if (isValue && isValuesInValue) {
								data = opResult?.value?.values;
							}

							this.mSearchResults = data as [];

							resolve(opResult);
						} else {
							this.mReset();
							reject(opResult);
						}
					}
				});
			} catch (err) {
				if (!canceled) {
					runInAction(() => {
						this.mReset();
						reject(Api.asApiError(err));
					});
				}
			}
		});

		this.mSearchPromise = promise;
		return promise;
	}

	protected mGetOpResult(computedParams?: Api.IDictionary<any>) {
		return this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IPagedCollection<T>>(
			this.composeApiUrl({ queryParams: computedParams, urlPath: this.mOptions.apiPath }),
			'GET'
		);
	}

	protected mReset() {
		this.mCancelCurrentSearch();
		this.mClearTimeoutHandle();
		this.mSearchQuery = null;
		this.mTotalCount = 0;
	}
}

export type ResourceAutoCompleteModel =
	| Api.IUser
	| Api.ICompany
	| Api.IContact
	| Api.IHandleAutoCompleteResult
	| string
	| Api.IAccountTag;

export enum ResourceAutoCompleteViewModelType {
	AccountTag = 'accountTag',
	Company = 'company',
	Contact = 'contact',
	Entity = 'entity',
	EventRegistrationSurvey = 'eventRegistrationSurvey',
	LineOfBusiness = 'lineOfBusiness',
	Manager = 'manager',
	PhoneCallCategory = 'phoneCallCategory',
	Role = 'role',
	Tag = 'tag',
	Team = 'team',
	User = 'user',
	Vertical = 'Vertical',
	VerticalV2 = 'VerticalV2',
}

export interface IResourceAutoCompleteOptions<T extends ResourceAutoCompleteModel = ResourceAutoCompleteModel> {
	/**
	 * This filter is always applied to the entire collection of search results, not just after local filtering is
	 * triggered.
	 */
	filter?: AutoCompleteResultFilter<T>;
	inputDelay?: number;
	localFilter?: AutoCompleteResultFilter<T>;
	pageSize?: number;
	type: ResourceAutoCompleteViewModelType;
}

export class ResourceAutoCompleteViewModel<
	T extends ResourceAutoCompleteModel = ResourceAutoCompleteModel,
> extends AutoCompleteViewModel<T> {
	protected mExtendedOptions: IResourceAutoCompleteOptions<T>;
	protected mAllResults: T[];
	constructor(userSession: UserSessionContext, options: IResourceAutoCompleteOptions<T>) {
		let apiPath = `${options.type}/autocomplete`;

		if (options.type === ResourceAutoCompleteViewModelType.AccountTag) {
			apiPath = 'tag/autocomplete/v2';
		}
		let searchParamName = 'fragment';
		let apiParams: Api.IDictionary = null;

		if (
			options.type === ResourceAutoCompleteViewModelType.Contact ||
			options.type === ResourceAutoCompleteViewModelType.Entity
		) {
			apiParams = {
				searchFields: 'nameParts',
			};
		}

		if (
			options.type === ResourceAutoCompleteViewModelType.Tag ||
			options.type === ResourceAutoCompleteViewModelType.AccountTag
		) {
			searchParamName = 'query';
		}

		if (options.type === ResourceAutoCompleteViewModelType.Team) {
			apiPath = 'LeadServed/team/autocomplete';
		}

		if (options.type === ResourceAutoCompleteViewModelType.Entity) {
			apiPath = 'autocomplete/byHandle';
		}

		if (options.type === ResourceAutoCompleteViewModelType.LineOfBusiness) {
			apiPath = 'keyfact/LineOfBusiness';
		}

		if (options.type === ResourceAutoCompleteViewModelType.Manager) {
			apiPath = 'LeadServed/manager/autocomplete';
		}

		if (options.type === ResourceAutoCompleteViewModelType.PhoneCallCategory) {
			searchParamName = 'query';
			apiPath = 'phonecall/category';
		}

		if (options.type === ResourceAutoCompleteViewModelType.Role) {
			apiPath = 'LeadServed/role/autocomplete';
		}

		if (options.type === ResourceAutoCompleteViewModelType.Vertical) {
			apiPath = 'aida/vertical/autocomplete';
		}

		if (options.type === ResourceAutoCompleteViewModelType.VerticalV2) {
			apiPath = 'LeadServed/vertical/autocomplete';
		}

		if (options.type === ResourceAutoCompleteViewModelType.EventRegistrationSurvey) {
			searchParamName = 'query';
			apiPath = 'survey/eventRegistration/autocomplete';
		}

		const extendedOptions: IResourceAutoCompleteOptions<T> = { ...options };

		const autocompleteOptions: IAutoCompleteOptions<T> = {
			apiParams,
			apiPath,
			inputDelay: extendedOptions.inputDelay >= 0 ? extendedOptions.inputDelay : 150,
			localFilter: extendedOptions.localFilter,
			pageSize: options.pageSize || 5,
			searchParamName,
		};
		super(userSession, autocompleteOptions);
		this.mExtendedOptions = extendedOptions;
		this.mGetOpResult = this.mGetOpResult.bind(this);
		this.mReset = this.mReset.bind(this);
	}

	@computed
	public get searchResults() {
		const results = this.mSearchResults || [];
		if (this.mExtendedOptions.filter || this.canFilterLocally) {
			return results.filter(x => {
				let include = this.mExtendedOptions.filter ? this.mExtendedOptions.filter(x, this.mSearchQuery) : true;
				if (include && this.canFilterLocally) {
					include = this.mExtendedOptions.localFilter(x, this.mSearchQuery);
				}
				return include;
			});
		}
		return results;
	}

	protected mGetOpResult(computedParams?: Api.IDictionary<any>) {
		if (this.mExtendedOptions.type === ResourceAutoCompleteViewModelType.LineOfBusiness) {
			// coerce the flat list into a paged result
			return new Promise<Api.IOperationResult<Api.IPagedCollection<T>>>(resolve => {
				const onOpResultSuccess = (result: Api.IOperationResult<string[]>) => {
					this.mAllResults = result.value as T[];
					const query = computedParams?.[this.mOptions.searchParamName] || '';
					const filter = query ? new RegExp(`^${escapeRegExp(query)}`, 'igm') : null;
					const values = (
						filter
							? result.value.filter(x => {
									filter.lastIndex = 0;
									return filter.test(x);
								})
							: result.value
					) as T[];
					resolve({
						...result,
						value: {
							totalCount: values.length,
							values,
						},
					});
				};
				let opResult: Api.IOperationResult<string[]> = null;
				if (this.mAllResults) {
					opResult = {
						success: true,
						value: this.mAllResults as string[],
					};
					onOpResultSuccess(opResult);
				} else {
					this.mUserSession.webServiceHelper
						.callWebServiceAsync<string[]>(this.composeApiUrl({ urlPath: this.mOptions.apiPath }), 'GET')
						.then((result: Api.IOperationResult<string[]>) => {
							if (result.success) {
								onOpResultSuccess(result);
							} else {
								resolve(result as Api.IOperationResultNoValue);
							}
						});
				}
			});
		}
		return super.mGetOpResult(computedParams);
	}

	protected mReset() {
		super.mReset();
		this.mAllResults = null;
	}
}

export type ToastMessageDuraton = 'short' | 'long';
export type ToastMessageType = 'textMessage' | 'successMessage' | 'custom' | 'errorMessage';

export interface IToastMessage {
	duration?: ToastMessageDuraton | number;
	linkTitle?: string;
	message?: string;
	onHide?(): void;
	onShow?(): void;
	type?: ToastMessageType;
}

export class ToasterViewModel<T extends IToastMessage> {
	@observable.ref public currentToastMessage: T;
	@observable.ref private queue: T[];
	private toastTimerHandle: any;

	constructor() {
		this.queue = [];
	}

	@action
	public push = (toastMessage: T) => {
		const message: T = {
			...(toastMessage as any),
			duration: toastMessage.duration || 'long',
			type: toastMessage.type || 'textMessage',
		};

		const queue = this.queue.slice();
		queue.push(message);
		this.queue = queue;
		this.showNext();
	};

	private pop = (): T => {
		if (this.queue.length > 0) {
			// get the first message
			const first = this.queue[0];

			// remove it from the queue and update the queue ref
			const queue = this.queue.slice();
			queue.splice(0, 1);
			this.queue = queue;

			return first;
		}
		return null;
	};

	private showNext = () => {
		if (!this.toastTimerHandle) {
			const nextMessage = this.pop();
			if (nextMessage) {
				let delay = 5000;
				if (typeof nextMessage.duration === 'number') {
					delay = Math.max(nextMessage.duration as number, 3000);
				} else {
					switch (nextMessage.duration) {
						case 'short': {
							delay = 3000;
							break;
						}
						default: {
							delay = 5000;
							break;
						}
					}
				}

				this.currentToastMessage = nextMessage;
				this.toastTimerHandle = setTimeout(() => {
					runInAction(() => {
						this.toastTimerHandle = null;
						this.currentToastMessage = null;
						this.showNext();
					});
				}, delay);
			}
		}
	};
}

export interface IErrorMessage {
	messages?: string[];
	onClose?(): void;
	title?: string;
}

export class ErrorMessageViewModel {
	@observable.ref public currentErrorMessage?: IErrorMessage;
	@observable.ref private queue: IErrorMessage[];

	constructor() {
		this.queue = [];
	}

	@action
	public pushApiError = (error: any, title?: string, onClose?: () => void) => {
		if (!error) {
			return;
		}
		let errorMessage = 'Unexpected error occurred';
		if (typeof error === 'object' && 'systemMessage' in error) {
			errorMessage = error.systemMessage;
		}
		if (error) {
			this.mPush({
				messages: [errorMessage],
				onClose,
				title,
			});
		}
	};

	public catchPromiseError = <TPromiseResult = any, TPromise extends Promise<TPromiseResult> = Promise<TPromiseResult>>(
		promise: TPromise,
		onWillShow?: boolean | (() => boolean),
		title?: string,
		onClose?: () => void
	) => {
		if (promise) {
			promise.catch(e => {
				if (e && onWillShow !== false) {
					const error = Api.asApiError(e);
					if (onWillShow === null || onWillShow === undefined || (typeof onWillShow === 'function' && onWillShow())) {
						this.pushApiError(error, title, onClose);
					}
				}
			});
		}
		return promise;
	};

	@action
	public push = (errorMessage?: IErrorMessage) => {
		if (errorMessage) {
			this.mPush(errorMessage);
		}
	};

	@action
	public dismiss = () => {
		this.currentErrorMessage = undefined;
		this.showNext();
	};

	@action
	public reset = () => {
		this.currentErrorMessage = undefined;
		this.queue = [];
	};

	private mPush = (errorMessage: IErrorMessage) => {
		const queue = this.queue.slice();
		queue.push(errorMessage);
		this.queue = queue;
		this.tryShowNext();
	};

	private tryShowNext = () => {
		if (!this.currentErrorMessage) {
			this.dismiss();
		}
	};

	private showNext = () => {
		this.currentErrorMessage = this.pop();
	};

	private pop = (): IErrorMessage => {
		if (this.queue.length > 0) {
			// get the first message
			const first = this.queue[0];

			// remove it from the queue and update the queue ref
			const queue = this.queue.slice();
			queue.splice(0, 1);
			this.queue = queue;

			return first;
		}
		return null;
	};
}

export enum ESelectionState {
	All = 'all',
	Some = 'some',
	None = 'none',
}

export class CompaniesViewModel extends ViewModel {
	@observable private deleting: boolean;
	@observable private merging: boolean;
	@observable private exporting: boolean;
	@observable private mSelectAll: boolean;
	@observable.ref private lastSuccessfulRequest: Api.ICompaniesSearchRequest;
	@observable.ref private nextRequest: Api.ICompaniesSearchRequest;
	@observable.ref
	private mSelectedCompanies: ObservableCollection<CompanyViewModel>;
	@observable.ref private mExcludedCompanies: ObservableCollection<CompanyViewModel>;
	private companiesPageCollectionController: FilteredPageCollectionController<
		Api.ICompany,
		CompanyViewModel,
		Api.ICompaniesSearchRequest
	>;

	public static getAllByIds = (userSession: UserSessionContext, companyIds: string[]) => {
		const promise = new Bluebird<CompanyViewModel[]>((resolve, _, onCancel) => {
			let canceled = false;
			if (onCancel) {
				onCancel(() => {
					canceled = true;
				});
			}

			const companies: CompanyViewModel[] = [];
			if (companyIds && companyIds.length > 0) {
				const promises = companyIds.map(x => {
					const company = new CompanyViewModel(userSession, { id: x });
					return { company, promise: company.load() };
				});
				promises.forEach(x => {
					const onFinish = (success: boolean) => {
						if (success) {
							companies.push(x.company);
						}
						const index = promises.findIndex(y => y.company === x.company);
						promises.splice(index, 1);

						if (!canceled && promises.length === 0) {
							resolve(companies);
						}
					};

					x.promise
						.then(() => {
							onFinish(true);
						})
						.catch(() => {
							onFinish(false);
						});
				});
			} else {
				resolve(companies);
			}
		});
		return promise;
	};

	constructor(userSession: UserSessionContext) {
		super(userSession);
		this.mSelectedCompanies = new ObservableCollection<CompanyViewModel>(null, 'id');
		this.mExcludedCompanies = new ObservableCollection<CompanyViewModel>(null, 'id');
		this.companiesPageCollectionController = new FilteredPageCollectionController<
			Api.ICompany,
			CompanyViewModel,
			Api.ICompaniesSearchRequest
		>({
			apiPath: 'company/filter',
			client: userSession.webServiceHelper,
			transformer: this.mCreateCompanyViewModel,
		});
	}

	@computed
	public get totalNumberOfResults() {
		return this.companiesPageCollectionController.totalCount;
	}

	@computed
	public get isSearching() {
		const companiesSearchRequest = this.nextRequest || this.lastSuccessfulRequest;
		if (companiesSearchRequest) {
			return companiesSearchRequest.searches && companiesSearchRequest.searches.some(x => x.value);
		}
		return false;
	}

	@computed
	public get isMerging() {
		return this.merging;
	}

	@computed
	public get selectedCompanies() {
		return this.mSelectedCompanies;
	}
	@computed
	public get excludedCompanies() {
		return this.mExcludedCompanies;
	}

	@action
	public deseclectAll = () => {
		this.mDeselectAll();
	};

	@action
	public selectAll = () => {
		this.mSelectedCompanies.clear();
		this.mExcludedCompanies.clear();
		this.mSelectAll = true;
	};

	private mDeselectAll = () => {
		this.mSelectAll = false;
		this.mExcludedCompanies.clear();
		this.mSelectedCompanies.clear();
	};

	@computed
	public get isFetchingResults() {
		return this.companiesPageCollectionController.isFetching;
	}

	@computed
	public get companies() {
		return this.companiesPageCollectionController.fetchResults;
	}

	@computed
	public get isDeleting() {
		return this.deleting;
	}

	@computed
	public get isBusy() {
		return (
			this.busy ||
			this.loading ||
			this.companiesPageCollectionController.isFetching ||
			this.deleting ||
			this.merging ||
			this.exporting
		);
	}

	public get selectionState(): ESelectionState {
		if (this.mSelectAll) {
			return this.mExcludedCompanies.length > 0 ? ESelectionState.Some : ESelectionState.All;
		}

		return this.mSelectedCompanies.length > 0 ? ESelectionState.Some : ESelectionState.None;
	}

	@action
	public reset = () => {
		this.busy = false;
		this.companiesPageCollectionController.reset();
		this.deleting = false;
		this.loading = false;
		this.merging = false;
		this.exporting = false;
		this.mSelectedCompanies.clear();
		this.mExcludedCompanies.clear();
	};

	@action
	public getCompanies = (
		searchRequest?: Api.ICompaniesSearchRequest,
		sortDescriptor?: Api.ISortDescriptor,
		pageSize?: number,
		params?: any
	) => {
		// merge params with sortDescriptor
		const computedParams = {
			...(sortDescriptor || {}),
			...(params || {}),
		};

		const promise = this.companiesPageCollectionController.getNext(searchRequest, pageSize, computedParams);
		if (promise) {
			this.nextRequest = searchRequest;
			promise.then(() => {
				if (searchRequest === this.nextRequest) {
					this.lastSuccessfulRequest = searchRequest;
					this.nextRequest = null;
				}
			});
		}
		return promise;
	};

	private mCreateCompanyViewModel = (company: Api.ICompany) => {
		return new CompanyViewModel(this.mUserSession, company);
	};

	@action
	public delete = (companies: CompanyViewModel[]) => {
		const companiesToDelete = companies || [];
		if (!this.isBusy && companiesToDelete.length > 0 && companiesToDelete.every(x => !x.isBusy)) {
			const idToCompanyToDelete = companiesToDelete.reduce(
				(res, curr) => {
					res[curr.id] = curr;
					return res;
				},
				{} as Record<string, CompanyViewModel>
			);
			const setDeletingState = (deleting: boolean) => {
				this.deleting = deleting;
				companiesToDelete.forEach(x => {
					// set deleting state... a bit of a hack
					if (Object.prototype.hasOwnProperty.call(x, 'setDeleting')) {
						(x as any).setDeleting(deleting);
					}
				});
			};

			setDeletingState(true);
			const promise = new Promise<Api.IBulkOperationResult<string>>((resolve, reject) => {
				const onFinish = (opResult: Api.IBulkOperationResult<string>) => {
					runInAction(() => {
						setDeletingState(false);

						// delete the succeeded items from collections
						const deletedCompanies = opResult.success
							? companiesToDelete
							: (opResult.succeeded || []).map(x => idToCompanyToDelete[x]).filter(x => x);
						this.mRemoveCompanies(deletedCompanies);

						if (opResult.success) {
							resolve(opResult);
						} else {
							reject(opResult);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithBulkOperationResult<string>(
					'company',
					'DELETE',
					companiesToDelete.map(x => x.id),
					onFinish,
					onFinish
				);
			});

			return promise;
		}

		return null;
	};

	/**
	 * @param target Target company to merge into.
	 * @param companies Companies to merge with. The receiver will update with the single resulting company
	 */
	@action
	public merge = (target: CompanyViewModel, companies: CompanyViewModel[]) => {
		if (!this.merging && target) {
			const promise = target.merge(companies);
			if (promise) {
				const setMergingState = (merging: boolean) => {
					this.merging = merging;
					companies.forEach(x => {
						// set mergine state... a bit of a hack
						if (Object.prototype.hasOwnProperty.call(x, 'setMerging')) {
							(x as any).setMerging(merging);
						}
					});
				};

				const onFinish = (opResult: Api.IOperationResult<Api.ICompany>) => {
					runInAction(() => {
						setMergingState(false);
						if (opResult.success) {
							// remove the merged companies from selction and paged controller collections
							this.mRemoveCompanies(companies);
						}
					});
				};
				promise
					.then(updatedCompany => {
						onFinish({ success: true, value: updatedCompany });
					})
					.catch(onFinish);

				return promise;
			}
		}
		return null;
	};

	@action
	public export = async (includeContacts: boolean) => {
		const exportRequest: Api.ICompanyExportRequest = {
			excludeCompanyIds: this.mExcludedCompanies.map(x => x.id),
			includeCompanyIds: this.selectionState === ESelectionState.All ? null : this.mSelectedCompanies.map(x => x.id),
			includeContacts,
		};

		// add filter if selecting all or selecting all with exclusions (no explicit selection of contacts)
		if (
			this.selectionState === ESelectionState.All ||
			(this.selectionState === ESelectionState.Some && this.selectedCompanies.length === 0)
		) {
			exportRequest.deprecatedFilter = {
				...(this.nextRequest || this.lastSuccessfulRequest || {}),
			};
			// exportRequest.filter = { criteria: SearchFilterOptions };
		}

		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ISystemJob>(
			this.composeApiUrl({ urlPath: 'company/export' }),
			'POST',
			exportRequest
		);

		if (!opResult.success) {
			throw opResult;
		}

		return opResult.value;
	};

	private mRemoveCompanies = (companies: CompanyViewModel[]) => {
		this.companiesPageCollectionController.fetchResults.removeItems(companies);
		this.mSelectedCompanies.removeItems(companies);
	};
}

export class TimelineEventViewModel<T extends Api.ITimelineEvent = Api.ITimelineEvent> extends ViewModel {
	@observable protected mEvent: T;

	constructor(userSession: UserSessionContext, event: T) {
		super(userSession);
		this.loading = false;
		this.mEvent = event;
	}

	@computed
	public get resourceId() {
		return this.mEvent.resourceId;
	}

	@computed
	public get scheduledMeeting() {
		return this.mEvent.scheduledMeeting;
	}

	@computed
	public get id() {
		return this.mEvent.id;
	}

	@computed
	public get timestamp() {
		return this.mEvent.timestamp;
	}

	@computed
	public get type(): Api.TimelineEventTypes {
		return this.mEvent._type as Api.TimelineEventTypes;
	}

	@computed
	public get title() {
		return this.mEvent.title;
	}

	toJs = () => {
		return this.mEvent;
	};
}

export class ActionItemEventViewModel<
	T extends Api.IActionItemEvent = Api.IActionItemEvent,
> extends TimelineEventViewModel<T> {
	@observable actionItem: ActionItemViewModel;
	constructor(userSession: UserSessionContext, event: T) {
		super(userSession, event);
		this.actionItem = new ActionItemViewModel(userSession, event.actionItem);
	}

	@computed
	public get viewmodel() {
		return this.actionItem;
	}
}

export class NoteEventViewModel<T extends Api.INoteEvent = Api.INoteEvent> extends TimelineEventViewModel<T> {
	@observable note: NoteViewModel;
	constructor(userSession: UserSessionContext, event: T) {
		super(userSession, event);
		this.note = new NoteViewModel(userSession, event.note);
	}

	@computed
	public get viewmodel() {
		return this.note;
	}
}

export class PhoneCallCompletedEventViewModel<
	T extends Api.IPhoneCallCompletedEvent = Api.IPhoneCallCompletedEvent,
> extends NoteEventViewModel<T> {
	@computed
	public get phoneCall() {
		return this.mEvent.phoneCall;
	}
}

export class HandwrittenCardOrderEventViewModel<
	T extends Api.IHandwrittenCardOrderEvent = Api.IHandwrittenCardOrderEvent,
> extends TimelineEventViewModel<T> {
	@computed
	public get cardContent() {
		return this.mEvent.cardContent;
	}

	@computed
	public get cardSignature() {
		return this.mEvent.cardSignature;
	}
}

export class UntrackedPhoneCallEventViewModel<
	T extends Api.INoteEvent = Api.INoteEvent,
> extends NoteEventViewModel<T> {}
export class DealCreatedEventViewModel<T extends Api.INoteEvent = Api.INoteEvent> extends NoteEventViewModel<T> {}
export class DealUpdatedEventViewModel<T extends Api.INoteEvent = Api.INoteEvent> extends NoteEventViewModel<T> {}
export class SkipLeadEventViewModel<T extends Api.INoteEvent = Api.INoteEvent> extends NoteEventViewModel<T> {}

export class FollowUpEventViewModel<
	T extends Api.IAbstractFollowUpEvent = Api.IAbstractFollowUpEvent,
> extends TimelineEventViewModel<T> {
	@computed
	public get followUpId() {
		return this.mEvent.followUpId;
	}
}

export class CancelledFollowUpEventViewModel<
	T extends Api.IAbstractFollowUpEvent = Api.IAbstractFollowUpEvent,
> extends TimelineEventViewModel<T> {}
export class RescheduledFollowUpEventViewModel<
	T extends Api.IAbstractFollowUpEvent = Api.IAbstractFollowUpEvent,
> extends TimelineEventViewModel<T> {}

export class ConversationThreadEventViewModel<
	T extends Api.IConversationThreadEvent = Api.IConversationThreadEvent,
> extends TimelineEventViewModel<T> {
	@computed
	public get lastMessages() {
		return this.mEvent.lastMessages;
	}
}

export class SentEmailEventViewModel<
	T extends Api.ISentEmailEvent = Api.ISentEmailEvent,
> extends TimelineEventViewModel<T> {
	@observable note: NoteViewModel;
	constructor(userSession: UserSessionContext, event: T) {
		super(userSession, event);
		this.note = new NoteViewModel(userSession, event.note);
	}

	@computed
	public get viewmodel() {
		return this.note;
	}
	@computed
	public get openDate() {
		return this.mEvent.openDate;
	}
}

export class HtmlNewsletterEventViewModel<
	T extends Api.IHtmlNewsletterEvent = Api.IHtmlNewsletterEvent,
> extends TimelineEventViewModel<T> {
	@observable note: NoteViewModel;
	constructor(userSession: UserSessionContext, event: T) {
		super(userSession, event);
		this.note = new NoteViewModel(userSession, event.note);
	}

	@computed
	public get viewmodel() {
		return this.note;
	}
	@computed
	public get openDate() {
		return this.mEvent.openDate;
	}
}

export class PhoneCallEventViewModel<T extends Api.INoteEvent = Api.INoteEvent> extends TimelineEventViewModel<T> {
	@observable phoneCall: NoteViewModel;
	constructor(userSession: UserSessionContext, event: T) {
		super(userSession, event);
		this.phoneCall = new NoteViewModel(userSession, event.note);
	}

	@computed
	public get viewmodel() {
		return this.phoneCall;
	}
}

export class MeetingEventViewModel<
	T extends Api.ITimelineEvent = Api.ITimelineEvent,
> extends TimelineEventViewModel<T> {}

export class SurveyResponseEventViewModel<
	TSurveyResponse extends Api.ISurveyResponse = Api.ISurveyResponse,
	T extends Api.ISurveyResponseEvent<TSurveyResponse> = Api.ISurveyResponseEvent<TSurveyResponse>,
> extends TimelineEventViewModel<T> {
	@computed
	public get surveyName() {
		return this.mEvent.surveyName;
	}

	@computed
	public get response() {
		return this.mEvent.response;
	}
}

export class SatisfactionSurveyResponseEventViewModel extends SurveyResponseEventViewModel<
	Api.ISatisfactionSurveyResponse,
	Api.ISatisfactionSurveyResponseEvent
> {}

export class RichContentViewModel<T extends Api.IRichContent = Api.IRichContent> extends ViewModel {
	@observable protected creatorDisplayName: string;
	@observable protected creatorDisplayNameShort: string;
	@observable protected deleting: boolean;
	@observable protected mLastModifiedDate: Date;
	@observable protected mCreationDate: Date;
	@observable protected saving: boolean;
	@observable public dateFormat: string;
	@observable.ref
	protected mCompanyRefs: Api.IRichContentEntityReference<CompanyViewModel>[];
	@observable.ref
	protected mContactRefs: Api.IRichContentEntityReference<ContactViewModel>[];
	@observable.ref protected mMentionedEntities: Api.IRichContentEntityReference<EntityViewModel<Api.IEntity>>[];
	@observable.ref protected mRichContent: T;
	@observable.ref
	protected mUserRefs: Api.IRichContentEntityReference<Api.IUser>[];
	@observable.ref private mAttachments: FileAttachmentViewModel[];
	protected mUuid: string;

	constructor(userSession: UserSessionContext, richContent: T = {} as T) {
		super(userSession);
		this.mUuid = uuidgen();
		this.dateFormat = 'MM/DD/YY';
		this.deleting = false;
		this.loading = false;
		this.mGetApiPath = this.mGetApiPath.bind(this);
		this.mSetRichContent = this.mSetRichContent.bind(this);
		this.mSetRichContent(richContent);
		this.saving = false;
	}

	/** Unique instance id (e.g. for use with react component keys) */
	@computed
	public get uuid() {
		return this.mUuid;
	}

	@computed
	public get id() {
		return this.mRichContent.id;
	}

	@computed
	public get plainTextContent() {
		return this.mRichContent.plainTextContent;
	}

	@computed
	public get context() {
		return this.mRichContent.context;
	}

	@action
	public setContext(context: Api.IRichContentContext) {
		this.mRichContent = { ...(this.mRichContent || {}), context } as T;
	}

	@action
	public setContent(content: Api.IRichContent) {
		this.mRichContent = { ...(content || {}) } as T;
	}

	@computed
	public get isPublic() {
		return this.mRichContent.visibility === 'all';
	}

	@computed
	public get visibility() {
		return this.mRichContent.visibility;
	}

	@computed
	public get creationDate() {
		return this.mCreationDate;
	}

	@computed
	public get lastModifiedDateAsDate() {
		return this.mLastModifiedDate;
	}

	@computed
	public get lastModifiedDateText() {
		if (this.mRichContent && this.mRichContent.lastModifiedDate) {
			const lastModifiedMoment = moment(this.mLastModifiedDate);
			const now = moment();
			const startOfToday = now.startOf('day');
			if (startOfToday < lastModifiedMoment && lastModifiedMoment < now) {
				return 'EARLIER TODAY';
			}

			return lastModifiedMoment.format(this.dateFormat);
		}

		return null;
	}

	@computed
	public get creator() {
		return this.mRichContent.creator;
	}

	@computed
	public get creatorId() {
		return this.mRichContent.creatorId;
	}

	@computed
	public get creatorName() {
		return this.creatorDisplayName;
	}

	@computed
	public get creatorNameShort() {
		return this.creatorDisplayNameShort;
	}

	@computed
	public get isBusy() {
		return this.deleting || this.loading || this.saving;
	}

	@computed
	public get isSaving() {
		return this.saving;
	}

	@computed
	public get isDeleting() {
		return this.deleting;
	}

	@computed
	// eslint-disable-next-line @typescript-eslint/class-literal-property-style
	public get canDelete() {
		return true;
	}

	@computed
	// eslint-disable-next-line @typescript-eslint/class-literal-property-style
	public get canEdit() {
		return true;
	}

	@computed
	public get rawContentState() {
		return this.mRichContent.content;
	}

	@computed
	public get preview() {
		return this.mRichContent.preview;
	}

	@computed
	public get mentionedEntities() {
		return this.mMentionedEntities;
	}

	@computed
	public get contactReferences() {
		return this.mContactRefs;
	}

	@computed
	public get companyReferences() {
		return this.mCompanyRefs;
	}

	@computed
	public get userReferences() {
		return this.mUserRefs;
	}

	@computed
	public get attachments(): FileAttachmentViewModel[] {
		return this.mAttachments;
	}

	public toJs = () => {
		return this.mRichContent;
	};

	@action
	public load() {
		if (!this.isBusy) {
			this.loading = true;
			return new Promise<T>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<T>) => {
					runInAction(() => {
						this.loading = false;
						if (opResult.success) {
							this.mSetRichContent(opResult.value);
							resolve(opResult.value);
						} else {
							reject(opResult);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<T>(
					`${this.mGetApiPath()}/${this.mRichContent.id}`,
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
		}
		return null;
	}

	@action
	public delete = () => {
		if (this.deleting || !this.canDelete) {
			return;
		}

		this.deleting = true;
		const promise = new Promise<T>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<T>) => {
				runInAction(() => {
					this.deleting = false;
					if (opResult.success) {
						resolve(opResult.value);
					} else {
						reject(opResult);
					}
				});
			};
			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<T>(
				`${this.mGetApiPath()}/${this.mRichContent.id}`,
				'DELETE',
				null,
				onFinish,
				onFinish
			);
		});
		return promise;
	};

	/** Subclasses should implement this */
	protected mGetApiPath(): string {
		throw new Error('Not Implemented');
	}

	protected mSetRichContent(richContent?: T) {
		this.mRichContent = richContent;
		this.creatorDisplayName = null;
		this.creatorDisplayNameShort = null;
		this.mCompanyRefs = [];
		this.mContactRefs = [];
		this.mLastModifiedDate = null;
		this.mCreationDate = null;
		this.mMentionedEntities = [];
		this.mUserRefs = [];

		this.mAttachments = FileAttachmentViewModel.create(
			this.mUserSession,
			`${this.mGetApiPath()}/${this.id}`,
			richContent.attachments
		);

		if (this.mRichContent) {
			this.mLastModifiedDate = this.mRichContent.lastModifiedDate ? new Date(this.mRichContent.lastModifiedDate) : null;
			this.mCreationDate = this.mRichContent.creationDate ? new Date(this.mRichContent.creationDate) : null;
			this.creatorDisplayName = VmUtils.getDisplayName(this.mRichContent.creator);
			this.creatorDisplayNameShort = VmUtils.getDisplayName(this.mRichContent.creator, true);

			// convert referencedEntities to view models
			if (this.mRichContent.referencedEntities) {
				const refs = VmUtils.getReferenceEntityViewModels(this.mUserSession, this.mRichContent);
				this.mCompanyRefs = refs.companyRefs;
				this.mContactRefs = refs.contactRefs;
				this.mUserRefs = refs.userRefs;
				this.mMentionedEntities = [...refs.contactRefs, ...refs.companyRefs];
			}
		}
	}
}

export class NoteViewModel extends RichContentViewModel<Api.INote> {
	@observable.ref protected actionItemVms: ActionItemViewModel[];
	@observable.ref protected note: Api.INote;
	protected savePromise: Promise<Api.INote>;

	constructor(userSession: UserSessionContext, note?: Api.INote) {
		super(userSession, note);
		this.mSetRichContent(note);
	}

	@computed
	public get canDelete() {
		if (!this.note || !this.note.id || !this.note.creatorId || !this.note.permissions) {
			// this is the create note case
			return true;
		}

		return this.note.permissions.canDelete;
	}

	@computed
	public get canEdit() {
		if (!this.note || !this.note.id || !this.note.creatorId || !this.note.permissions) {
			return true;
		}

		return this.note.permissions.canUpdate;
	}

	@computed
	public get actionItems() {
		return this.actionItemVms;
	}

	@action
	public saveFromEmail = (note: Api.INote, newAttachments?: AttachmentsToBeUploadedViewModel) => {
		return this.save(note, newAttachments, 'note/fromEmail');
	};

	@action
	public save = (note: Api.INote, newAttachments?: AttachmentsToBeUploadedViewModel, url?: string) => {
		if (this.saving || this.savePromise) {
			return;
		}

		this.saving = true;
		const promise = new Promise<Api.INote>((resolve, reject) => {
			const updateExistingNote = this.note && !!this.note.id;
			const noteToSave = { ...note };
			if (updateExistingNote) {
				noteToSave.id = this.note.id;
			}

			// pull out email and attachments into form data if attachments are present
			let formData: FormData = null;
			if (newAttachments && newAttachments.count > 0) {
				formData = new FormData();
				formData.append('value', JSON.stringify(noteToSave));
				newAttachments.attachments.forEach(x => {
					if (!(x instanceof File) && (x as File).name) {
						// is a Blob that has been given a name
						// need to send name as additional arg
						formData.append('files', x, (x as File).name);
					} else {
						formData.append('files', x);
					}
				});
			}

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.INote>(
				url || `note${updateExistingNote ? `/${this.note.id}` : ''}`,
				updateExistingNote ? 'PUT' : 'POST',
				formData ? formData : noteToSave,
				opResult => {
					runInAction(() => {
						this.saving = false;
						this.savePromise = null;
						this.mSetRichContent(opResult.value);
						resolve(opResult.value);
					});
				},
				error => {
					runInAction(() => {
						this.saving = false;
						this.savePromise = null;
						reject(error);
					});
				}
			);
		});

		this.savePromise = promise;
		return promise;
	};

	public toNote = () => {
		return this.note;
	};

	public setContext(context: Api.IRichContentContext) {
		super.setContext(context);
		if (this.note) {
			this.note = { ...this.note, context };
		}
	}

	protected mGetApiPath() {
		return 'note';
	}

	protected mSetRichContent(note?: Api.INote) {
		this.note =
			!note || !note.id
				? {
						actionItems: [],
						referencedEntities: { companies: [], contacts: [], users: [] },
						visibility: VmUtils.getDefaultVisibility(this.mUserSession.user),
						...(note || {}), // could be partial note... e.g. recent meeting
					}
				: note;
		this.actionItemVms = (this.note.actionItems || []).map(x => new ActionItemViewModel(this.mUserSession, x));

		super.mSetRichContent(this.note);
	}
}

export class ActionItemViewModel extends RichContentViewModel<Api.IActionItem> {
	@observable private completed: boolean;
	@observable private mDueDate?: Date;
	@observable.ref private mKeepInTouchReference: KeepInTouchReferenceViewModel;
	@observable.ref private actionItem: Api.IActionItem;
	@observable.ref private associatedNoteRefs: Api.IRichContentEntityReference<EntityViewModel<Api.IEntity>>[];
	private savePromise: Promise<Api.IActionItem>;

	constructor(userSession: UserSessionContext, actionItem?: Api.IActionItem) {
		super(userSession, actionItem);
		this.mSetRichContent(actionItem);
	}

	@computed
	public get associatedNoteModel() {
		return this.actionItem.associatedNote;
	}

	@computed
	public get associatedNotesReferencedEntities() {
		return this.associatedNoteRefs;
	}

	@computed
	public get referencedContactsForSendMessage() {
		if (!this.actionItem) {
			return [];
		}

		const referencedContactsMapForSendMessage: Api.IDictionary<ContactViewModel> = {};

		// add contacts from non-content referenced contacts, content referenced contacts and associated note
		[...(this.mContactRefs || []), ...(this.mMentionedEntities || []), ...(this.associatedNoteRefs || [])]
			.filter(x => Api.getTypeForEntity(x.entity.toJs()) !== 'company')
			.map(x => x.entity as ContactViewModel)
			.forEach(x => {
				if (!referencedContactsMapForSendMessage[x.id]) {
					referencedContactsMapForSendMessage[x.id] = x;
				}
			});

		return Object.keys(referencedContactsMapForSendMessage).map(x => referencedContactsMapForSendMessage[x]);
	}

	@computed
	public get assignee() {
		return this.actionItem ? this.actionItem.assignee : null;
	}

	@computed
	public get automationId() {
		return this.actionItem?.automationId;
	}

	@computed
	public get dueDate() {
		return this.mDueDate;
	}

	@computed
	public get isCompleted() {
		return this.completed;
	}

	@computed
	public get keepInTouchReference() {
		return this.mKeepInTouchReference;
	}

	@computed
	public get isSuggestedKeepInTouchActionItem() {
		return this.mKeepInTouchReference && !this.mKeepInTouchReference.id;
	}

	@computed
	public get isKeepInTouchActionItem() {
		return !!this.mKeepInTouchReference;
	}

	@action
	public setActionItem(actionItem: Api.IActionItem) {
		this.mSetRichContent(actionItem);
	}

	public toActionItem = (shallow = false) => {
		const actionItem = this.actionItem || {};
		const actionItemClone: Api.IActionItem = { ...actionItem };

		if (actionItem.assignee) {
			// create a copy because we might need to modify it
			actionItemClone.assignee = { ...actionItem.assignee };
		}

		if (actionItem.keepInTouchReference) {
			// create a copy because we might need to modify it
			actionItemClone.keepInTouchReference = {
				...actionItem.keepInTouchReference,
				contact:
					actionItem.keepInTouchReference && actionItem.keepInTouchReference.contact
						? {
								...actionItem.keepInTouchReference.contact,
							}
						: null,
			};
		}

		if (shallow) {
			if (actionItemClone.assignee) {
				actionItemClone.assignee = { id: actionItemClone.assignee.id };
			}

			if (actionItemClone.keepInTouchReference && actionItemClone.keepInTouchReference.contact) {
				actionItemClone.keepInTouchReference.contact = {
					id: actionItemClone.keepInTouchReference.contact.id,
				};
			}
		}

		return actionItemClone;
	};

	@action
	public save = (actionItemToSave: Api.IActionItem) => {
		if (!this.saving) {
			const promise = new Promise<Api.IActionItem>((resolve, reject) => {
				this.saving = true;
				const updatingActionItem = this.actionItem && this.actionItem.id;
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IActionItem>(
					`actionitem${updatingActionItem ? `/${this.actionItem.id}` : ''}`,
					updatingActionItem ? 'PUT' : 'POST',
					actionItemToSave,
					opResult => {
						runInAction(() => {
							this.mSetRichContent(opResult.value);
							this.saving = false;
							resolve(opResult.value);
						});
					},
					(error: Api.IOperationResultNoValue) => {
						runInAction(() => {
							this.saving = false;
							reject(error);
						});
					}
				);
			});
			this.savePromise = promise;
			return promise;
		} else {
			return this.savePromise;
		}
	};

	@action
	public toggleComplete = (complete?: boolean) => {
		if (!this.saving) {
			const promise = new Promise<Api.IActionItem>((resolve, reject) => {
				this.saving = true;
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IActionItem>(
					`actionitem/${this.actionItem.id}/${complete ? 'complete' : 'incomplete'}`,
					'POST',
					null,
					opResult => {
						runInAction(() => {
							// no need to call actionItem setter for one prop change
							this.completed = complete;
							this.actionItem.isCompleted = complete;
							this.saving = false;
							resolve(opResult.value);
						});
					},
					(error: Api.IOperationResultNoValue) => {
						runInAction(() => {
							this.saving = false;
							reject(error);
						});
					}
				);
			});
			return promise;
		} else {
			return null;
		}
	};

	/** Set the due date to today + numberOfDays */
	@action
	public snooze = (numberOfDays: number) => {
		if (!this.isBusy && numberOfDays > 0) {
			const nextDueDate = new Date();
			nextDueDate.setDate(nextDueDate.getDate() + numberOfDays);
			const actionItemModel = {
				...this.toActionItem(true),
				dueDate: moment(nextDueDate).toISOString(),
			};
			return this.save(actionItemModel);
		}

		return null;
	};

	protected mGetApiPath() {
		return 'actionitem';
	}

	// internal method w/o action
	protected mSetRichContent(actionItem?: Api.IActionItem) {
		this.actionItem =
			!actionItem || !actionItem.id
				? {
						referencedEntities: { companies: [], contacts: [], users: [] },
						visibility: VmUtils.getDefaultVisibility(this.mUserSession.user),
						...(actionItem || {}), // could be partial actionItem
					}
				: actionItem;
		this.associatedNoteRefs = [];
		this.completed = false;
		this.mDueDate = null;
		this.mKeepInTouchReference = null;

		if (actionItem) {
			this.mDueDate = actionItem.dueDate ? new Date(actionItem.dueDate) : null;
			this.completed = actionItem.isCompleted;

			// convert associated notes referencedEntities to view models
			if (actionItem.associatedNote && actionItem.associatedNote.referencedEntities) {
				const refs = VmUtils.getReferenceEntityViewModels(this.mUserSession, actionItem.associatedNote);
				this.associatedNoteRefs = [...refs.companyRefs, ...refs.contactRefs];
			}

			if (actionItem.keepInTouchReference) {
				this.mKeepInTouchReference = new KeepInTouchReferenceViewModel(
					this.mUserSession,
					actionItem.keepInTouchReference
				);
			}
		}

		super.mSetRichContent(this.actionItem);
	}
}

export class ActionItemAttachmentViewModel<TContentState extends IRichContentEditorState = IRichContentEditorState> {
	@observable.ref protected mActionItem: ActionItemViewModel;
	@observable.ref public assignee: Api.IUser;
	@observable.ref public contentState: TContentState;
	@observable.ref public dueDate: Date;

	constructor(actionItem?: ActionItemViewModel, contentState?: TContentState) {
		this.mSetActionItem(actionItem);
		if (contentState) {
			this.contentState = contentState;
		}
	}

	@computed
	public get actionItem() {
		return this.mActionItem;
	}

	@computed
	public get hasContent() {
		return this.contentState && this.contentState.hasContent();
	}

	@action
	public setActionItem = (actionItem: ActionItemViewModel) => {
		this.mSetActionItem(actionItem);
	};

	public toJs = (shallow = false) => {
		const originalActionItemModel = this.mActionItem ? this.mActionItem.toActionItem(shallow) : {};
		const actionItemModel: Api.IActionItem = {
			...originalActionItemModel,
		};

		// important: remove completion date and let isCompleted control the completion state. setting both is undefined
		delete actionItemModel.completionDate;

		// ensure referenced entities exists
		if (!originalActionItemModel || !actionItemModel.referencedEntities) {
			actionItemModel.referencedEntities = {
				companies: [],
				contacts: [],
				users: [],
			};
		}

		if (this.contentState) {
			actionItemModel.content = this.contentState.getRawRichTextContent();
		}

		if (this.assignee && this.assignee.id) {
			actionItemModel.assignee = shallow ? { id: this.assignee.id } : this.assignee;
		}

		if (this.dueDate) {
			actionItemModel.dueDate = moment(this.dueDate).toISOString();
		}

		return actionItemModel;
	};

	protected mSetActionItem = (actionItem?: ActionItemViewModel) => {
		this.mActionItem = actionItem;

		if (this.mActionItem) {
			this.assignee = this.mActionItem.assignee;
			this.dueDate = this.mActionItem.dueDate;
		} else {
			this.assignee = null;
			this.dueDate = null;
		}
	};
}

export class EntityViewModel<T extends Api.IEntity = Api.IEntity> extends ViewModel {
	@observable protected deleting: boolean;
	@observable protected displayName: string;
	@observable protected saving: boolean;
	@observable protected validEntity: boolean;
	@observable.ref protected entity: T;
	@observable.ref protected mLastModifiedDate: Date;
	@observable.ref
	protected mRichContentViewModel: EntityRichContentViewModel<T>;

	constructor(userSession: UserSessionContext, entity: T) {
		super(userSession);
		this.mUpdateTags = this.mUpdateTags.bind(this);
		this.setEntity(entity);
	}

	public get richContentViewModel() {
		if (!this.mRichContentViewModel) {
			this.mRichContentViewModel = new EntityRichContentViewModel(this);
		}

		return this.mRichContentViewModel;
	}

	@computed
	public get phoneNumbers() {
		return this.entity.phoneNumbers;
	}

	@computed
	public get lastModifiedDateAsDate() {
		return this.mLastModifiedDate;
	}

	@computed
	public get lastModifiedDate() {
		return this.entity.lastModifiedDate;
	}

	@computed
	public get isDeleting() {
		return this.deleting;
	}

	@computed
	public get keyFactsCollection() {
		return this.entity.keyFactsCollection;
	}

	@computed
	public get tags() {
		return this.entity.tags;
	}

	@computed
	public get tagsCollection() {
		return this.entity.tagsCollection;
	}

	@computed
	public get socialProfiles() {
		return this.entity.socialProfiles;
	}

	@computed
	public get webSite() {
		return this.entity.webSite;
	}

	@computed
	public get bio() {
		return this.entity.bio;
	}

	@computed
	public get companyName() {
		return this.entity.companyName;
	}

	@computed
	public get isValid() {
		return this.validEntity;
	}

	@computed
	public get id() {
		return this.entity.id;
	}

	@computed
	public get name() {
		return this.displayName;
	}

	@computed
	public get isFetchingRichContent() {
		return this.richContentViewModel ? this.richContentViewModel.controller.isFetching : false;
	}

	@action
	public setEntity(entity: T) {
		this.entity = entity;
		this.displayName = VmUtils.getEntityDisplayName(entity);
		this.validEntity = entity.id && !VmUtils.ResolveByEmailRegExp.test(entity.id);
		this.mLastModifiedDate = entity && entity.lastModifiedDate ? new Date(entity.lastModifiedDate) : null;
	}

	@computed
	public get richContent() {
		// Note: we use richContentViewModel prop because this.mRichContentViewModel is created lazily
		return this.richContentViewModel.controller.fetchResults;
	}

	@action
	public setRichContent = (richContentCollection: RichContentViewModel[]) => {
		// Note: we use richContentViewModel prop because this.mRichContentViewModel is created lazily
		this.richContentViewModel.controller.fetchResults.setItems(richContentCollection);
	};

	@computed
	public get isBusy() {
		return this.busy || this.saving || this.loading;
	}

	/** Subclasses should override this... default returns empty string */
	protected getNotesApiQueryPath = () => {
		return '';
	};

	/** Subclasses should override this... default returns empty string */
	protected getEntityApiPath = () => {
		return '';
	};

	public reset() {
		this.richContentViewModel.controller.reset();
	}

	public toJs = () => {
		return this.entity;
	};

	public toMention = () => {
		return this.entity;
	};

	@action
	public load(allowArchived = false) {
		if (!this.isLoading) {
			this.loading = true;

			const promise = new Promise<T>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<T>) => {
					runInAction(() => {
						this.loading = false;
						if (opResult.success) {
							this.loaded = true;
							this.setEntity(opResult.value);
							resolve(opResult.value);
						} else {
							reject(opResult);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ICompany>(
					Api.ImpersonationBroker.composeApiUrl({
						queryParams: {
							expand: 'HouseholdMembers',
							includeArchived: allowArchived,
						},
						urlPath: `${this.getEntityApiPath()}/${encodeURIComponent(this.entity.id)}`,
					}),
					'GET',
					null,
					// FOLLOWUP: Resolve
					// @ts-ignore
					onFinish,
					onFinish
				);
			});

			return promise;
		}

		return null;
	}

	@action
	public getRichContent = (sortDescriptor?: Api.ISortDescriptor, pageSize?: number) => {
		// Note: we use notesViewModel prop because this.mRichContentViewModel is created lazily
		return this.richContentViewModel.controller.getNext(sortDescriptor, pageSize);
	};

	@action
	public deleteRichContent = (richContent: RichContentViewModel) => {
		const promise = richContent.delete();
		if (richContent && this.richContentViewModel.controller.fetchResults.has(richContent)) {
			if (promise) {
				promise.then(() => {
					this.richContentViewModel.controller.fetchResults.removeItems([richContent]);
				});
			}
		}

		return promise;
	};

	@action
	public update = (entity: T) => {
		return this.mUpdate(entity);
	};

	@action
	public delete = () => {
		if (!this.isBusy) {
			this.deleting = true;
			const promise = new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResultNoValue) => {
					runInAction(() => {
						this.deleting = false;
						if (result.success) {
							resolve(result);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
					`${this.getEntityApiPath()}/${this.entity.id}`,
					'DELETE',
					null,
					onFinish,
					onFinish
				);
			});

			return promise;
		}

		return null;
	};

	@action
	public updateKeyFactsCollection = (keyFactsCollection?: Api.IKeyFact[]) => {
		if (!this.isBusy) {
			const entity: T = {
				...(this.entity || ({} as any)),
				keyFactsCollection: keyFactsCollection || [],
			};
			return this.mUpdate(entity);
		}
		return null;
	};

	@action
	public updateTags = (tags?: string[]) => {
		return this.mUpdateTags('PUT', tags);
	};

	@action
	public removeTags = (tags?: string[]) => {
		return this.mUpdateTags('DELETE', tags);
	};

	@action
	public addTags = (tags?: string[]) => {
		return this.mUpdateTags('POST', tags);
	};

	protected mUpdateTags(method: Api.HTTPMethod, tags?: string[]) {
		if (!this.isBusy) {
			this.busy = true;
			const promise = new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResultNoValue) => {
					runInAction(() => {
						this.busy = false;
						if (result.success) {
							switch (method) {
								case 'PUT': {
									this.entity = {
										...this.entity,
										tags: tags || [],
									};
									break;
								}
								case 'DELETE': {
									const tagsToSet = [...(this.entity.tags || [])];
									const lowerTags = tagsToSet.map(x => x.toLocaleLowerCase());
									(tags || []).forEach(x => {
										const index = lowerTags.indexOf(x.toLocaleLowerCase());
										if (index >= 0) {
											lowerTags.splice(index, 1);
											tagsToSet.splice(index, 1);
										}
									});
									this.entity = {
										...this.entity,
										tags: tagsToSet,
									};
									break;
								}
								case 'POST': {
									const tagsToSet = [...(this.entity.tags || [])];
									const lowerTags = tagsToSet.map(x => x.toLocaleLowerCase());
									(tags || []).forEach(x => {
										const index = lowerTags.indexOf(x.toLocaleLowerCase());
										if (index < 0) {
											tagsToSet.push(x);
										}
									});
									this.entity = {
										...this.entity,
										tags: tagsToSet,
									};
									break;
								}
								default: {
									break;
								}
							}
							resolve(result);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
					`${this.getEntityApiPath()}/${this.entity.id}/tags${method === 'POST' ? '/add' : ''}`,
					method,
					tags || [],
					onFinish,
					onFinish
				);
			});
			return promise;
		}
		return null;
	}

	protected mUpdate = (entity: T) => {
		if (!entity || (this.entity && this.entity.id && this.entity.id !== entity.id)) {
			const error: Api.IOperationResultNoValue = {
				systemMessage: 'Missing or mismatched entity for this operation.',
			};
			return Promise.reject(error);
		}

		if (!this.isBusy) {
			this.saving = true;
			const promise = new Promise<T>((resolve, reject) => {
				// remove legacy key facts property
				const entityToSave = Api.excludeKeysOf(entity, ['keyFacts']) as T;
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<T>(
					this.composeApiUrl({
						urlPath: `${this.getEntityApiPath()}${entityToSave.id ? `/${entityToSave.id}` : ''}`,
						queryParams: {
							expand: entityToSave.id ? 'HouseholdMembers' : null,
						},
					}),
					entityToSave.id ? 'PUT' : 'POST',
					entityToSave,
					opResult => {
						runInAction(() => {
							this.saving = false;
							this.setEntity(opResult.value);
							resolve(opResult.value);
						});
					},
					error => {
						runInAction(() => {
							this.saving = false;
							reject(error);
						});
					}
				);
			});

			return promise;
		}
		return null;
	};
}

export class KeepInTouchViewModel extends ViewModel {
	@observable protected deleting: boolean;
	@observable protected saving: boolean;
	@observable.ref protected kit: Api.IKeepInTouch;
	@observable.ref protected promise: Promise<any>;
	private mSuggestionsPageCollectionController: ObservablePageCollectionController<Api.IContact, ContactViewModel>;

	constructor(userSession: UserSessionContext, keepInTouch?: Api.IKeepInTouch) {
		super(userSession);
		this.setKeepInTouch(keepInTouch);
		this.mSuggestionsPageCollectionController = new ObservablePageCollectionController({
			apiPath: 'keepintouch/suggestions',
			client: userSession.webServiceHelper,
			transformer: this.getContactViewModelRepresentation,
		});
	}

	public toJs() {
		return this.kit;
	}

	@computed
	public get totalNumberOfSuggestions() {
		return this.mSuggestionsPageCollectionController.totalCount;
	}

	@computed
	public get fetchingSuggestions() {
		return this.mSuggestionsPageCollectionController.isFetching;
	}

	@computed
	public get suggestions() {
		return this.mSuggestionsPageCollectionController.fetchResults;
	}

	@computed
	public get isLoaded() {
		return this.kit && !!this.kit.id;
	}

	@computed
	public get Id() {
		if (!this.kit) {
			return null;
		}
		return this.kit.id;
	}

	@computed
	public get frequency() {
		if (!this.kit) {
			return -1;
		}
		return this.kit.frequency;
	}

	@computed
	public get isSaving() {
		return this.saving;
	}

	@computed
	public get isBusy() {
		return this.busy || this.loading || this.deleting || this.mSuggestionsPageCollectionController.isFetching;
	}

	@action
	public reset = () => {
		this.mSuggestionsPageCollectionController.reset();
		this.busy = false;
		this.deleting = false;
		this.saving = false;
		this.loading = false;
	};

	/** Only removes contacts from the local collection of suggestions, not from Levitate */
	@action
	public removeSuggestions = (contacts: ContactViewModel[]) => {
		this.removeSuggestionsPrivate(contacts);
	};

	@action
	public getSuggestions = (sortDescriptor?: Api.ISortDescriptor, pageSize?: number, params?: any) => {
		return this.mSuggestionsPageCollectionController.getNext(sortDescriptor, pageSize, params);
	};

	@action
	public save = (keepintouch: Api.IKeepInTouch) => {
		if (!this.busy) {
			this.saving = true;
			const promise = new Promise<Api.IKeepInTouch>((resolve, reject) => {
				const onFinish = (kit?: Api.IKeepInTouch) => {
					runInAction(() => {
						this.saving = false;
						if (this.promise === promise) {
							this.promise = null;
						}

						if (kit) {
							this.setKeepInTouch(kit);
						}
					});
				};

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IKeepInTouch>(
					'keepintouch',
					'POST',
					keepintouch,
					opResult => {
						onFinish(opResult.value);
						resolve(opResult.value);
					},
					(error: Api.IOperationResultNoValue) => {
						onFinish();
						reject(error);
					}
				);
			});
			this.promise = promise;
			return promise;
		}
	};

	@action
	public delete = () => {
		if (!this.busy) {
			this.deleting = true;
			const promise = new Promise<void>((resolve, reject) => {
				const onFinish = (error: Api.IOperationResultNoValue) => {
					runInAction(() => {
						this.deleting = false;
						if (this.promise === promise) {
							this.promise = null;
						}

						if (error) {
							reject(error);
						} else {
							this.setKeepInTouch(null);
							resolve();
						}
					});
				};

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<void>(
					`keepintouch/${this.kit.id}`,
					'DELETE',
					null,
					() => {
						onFinish(null);
					},
					onFinish
				);
			});
			this.promise = promise;
			return promise;
		}
	};

	public load() {
		return this.privateLoad();
	}

	@action
	public loadByContact(contactId?: string) {
		return this.privateLoad(contactId);
	}

	@action
	public setKeepInTouch(keepInTouch: Api.IKeepInTouch) {
		this.kit = keepInTouch;
	}

	@action
	protected privateLoad = (contactId?: string) => {
		if (!this.busy) {
			this.loading = true;
			const promise = new Promise<Api.IKeepInTouch>((resolve, reject) => {
				const onFinish = () => {
					runInAction(() => {
						this.loading = false;
						if (this.promise === promise) {
							this.promise = null;
						}
					});
				};

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IKeepInTouch>(
					contactId ? `keepintouch/byContact/${contactId}/me` : `keepintouch/${this.kit.id}`,
					'GET',
					null,
					opResult => {
						onFinish();
						this.setKeepInTouch(opResult.value);
						resolve(opResult.value);
					},
					(error: Api.IOperationResultNoValue) => {
						onFinish();
						reject(error);
					}
				);
			});
			this.promise = promise;
			return promise;
		}
	};

	private getContactViewModelRepresentation = (contact: Api.IContact) => {
		return new ContactViewModel(this.mUserSession, contact);
	};

	/** Intentionally not an action */
	private removeSuggestionsPrivate = (contacts: ContactViewModel[]) => {
		this.mSuggestionsPageCollectionController.fetchResults.removeItems(contacts);
	};
}

export class ContactViewModel extends EntityViewModel<Api.IContact> {
	@observable.ref protected kitVm: KeepInTouchViewModel;
	@observable.ref protected mConnections: ContactConnectionsViewModel;
	@observable.ref protected mRelationshipHealth: Api.IRelationshipHealth;
	@observable.ref protected mTagAlerts: TagAlertViewModel[];
	@observable.ref protected mTagValueToTagAlert: Api.IDictionary<TagAlertViewModel>;
	@observable.ref protected mIntegrationData: Api.IContactIntegrationData[];
	@observable private mDataOriginEnabled: boolean;
	@observable private mIsFetchingIntegrationData = false;
	@observable.ref private mUploadingProfilePic = false;
	@observable private mIntegrationSource: Api.IntegrationSources;

	public static Create = (userSession: UserSessionContext, contactModel: Api.IContact) => {
		const promise = new Promise<ContactViewModel>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.IContact>) => {
				if (opResult.success) {
					const contact = new ContactViewModel(userSession, opResult.value);
					resolve(contact);
				} else {
					reject(opResult);
				}
			};
			userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IContact>(
				'contact',
				'POST',
				contactModel,
				onFinish,
				onFinish
			);
		});
		return promise;
	};

	constructor(userSession: UserSessionContext, contact: Api.IContact) {
		super(userSession, contact);
	}

	@computed
	public get duplicateContacts() {
		return this.contact?.duplicateContacts?.map(x =>
			new ContactViewModel(this.mUserSession, x).impersonate(this.impersonationContext)
		);
	}

	@computed
	public get uploadingProfilePic() {
		return this.mUploadingProfilePic;
	}

	@computed
	public get bounceMetadata() {
		return this.entity.metadata?.bounceMetadata;
	}

	@computed
	public get unsubscribeMetadata() {
		return this.entity.metadata?.unsubscribeMetadata;
	}

	@computed
	public get automatedSmsOptOutMetadata() {
		return this.entity.metadata?.automatedSmsOptOutMetadata;
	}

	@computed
	public get inProgressAutomations() {
		return this.entity?.inProgressAutomations;
	}

	@computed
	public get companyId() {
		return this.entity.companyId;
	}

	@computed
	public get ownerId() {
		return this.entity.ownerId;
	}

	@computed
	public get relationshipHealth() {
		return this.mRelationshipHealth;
	}

	@computed
	public get isRecognizedFirstName() {
		return this.entity.isRecognizedFirstName;
	}

	@computed
	public get customProperties() {
		return this.entity.customProperties;
	}

	/**
	 * Method returns the names from the KeyFactsCollection
	 *
	 * @returns {string[]} List of names
	 * @if they fall between now and 30 days from now
	 */
	@computed
	public get namesFromKeyFactsCollection() {
		const names: string[] = [];

		this.entity?.keyFactsCollection?.forEach((x: Api.IKeyFact) => {
			if (x?.keyDate?.month && x?.keyDate?.day) {
				// set up
				const now = new Date();
				now.setHours(0, 0, 0, 0); // set now to beginning of day
				const yesterdayAtEndOfDay: Date = new Date();
				yesterdayAtEndOfDay.setDate(yesterdayAtEndOfDay.getDate() - 1); // set day to yesterday's date so we can check for same day birthdays
				yesterdayAtEndOfDay.setHours(23, 59, 59, 999); // yesterdays date set to end of day
				const upComingBirthDate: Date = new Date(`${x.keyDate.month}/${x.keyDate.day}/${now.getFullYear()}`);
				upComingBirthDate.setHours(0, 0, 0, 0);
				const thirtyDaysFromNow = moment(new Date()).add(30, 'days').toDate();
				const birthdayKeyFactMetaData: Api.IBirthInfoKeyFactMetadata = x?.source?.additionalMetadata;
				const isBirthdayDateBetweenSpecifiedDates: boolean = moment(upComingBirthDate).isBetween(
					yesterdayAtEndOfDay,
					thirtyDaysFromNow
				);
				if (isBirthdayDateBetweenSpecifiedDates && birthdayKeyFactMetaData?.birthInfo?.name) {
					names.push(birthdayKeyFactMetaData?.birthInfo?.name);
				}
			}
		});
		return names;
	}

	@computed
	public get tagsWithAlerts() {
		return this.entity.tagsWithAlerts;
	}

	@computed
	public get tagAlerts() {
		return this.mTagAlerts;
	}

	@computed
	public get keepInTouch() {
		return this.kitVm;
	}

	@computed
	public get connections() {
		return this.mConnections;
	}

	@computed
	public get isLoaded() {
		return this.validEntity
			? this.entity.visibility &&
					this.entity.creatorId &&
					(!!this.entity.primaryEmail || (this.entity.firstName && !!this.entity.lastName))
			: true;
	}

	@computed
	public get hasLoaded() {
		return this.loaded;
	}

	@computed
	public get primaryEmail() {
		return this.contact.primaryEmail;
	}

	@computed
	public get profilePic() {
		return this.contact.profilePic;
	}

	@computed
	public get jobTitle() {
		return this.contact.jobTitle;
	}

	@computed
	public get bio() {
		return this.contact.bio;
	}

	@computed
	public get address() {
		return this.contact.address;
	}

	@action
	public setAddress = async (address: Api.IAddress) => {
		if (!!address?.address1 || !!address?.address2) {
			this.contact.address = address;
			await this.update(this.contact);
		}
		return this.contact.address;
	};

	@computed
	public get emailAddresses() {
		return this.contact.emailAddresses;
	}

	@computed
	public get handle() {
		return this.contact.handle;
	}

	@computed
	public get handleReverse() {
		return this.contact.handleReverse;
	}

	@computed
	public get userHasKeepInTouch() {
		return this.contact.userHasKeepInTouch || (this.keepInTouch && this.keepInTouch.isLoaded);
	}

	@computed
	public get visibility() {
		return this.contact.visibility;
	}

	@computed
	public get visibilityFriendlyName() {
		return this.contact.visibilityFriendlyName;
	}

	@computed
	public get householdMembers() {
		return this.contact.householdMembers;
	}

	@computed
	public get household() {
		return this.contact.household;
	}

	@computed
	public get creatorId() {
		return this.contact.creatorId;
	}

	@computed
	public get firstName() {
		return this.contact.firstName;
	}

	@computed
	public get firstNameForSalutation() {
		return this.entity?.firstNameForSalutation;
	}

	@computed
	public get lastName() {
		return this.contact.lastName;
	}

	@computed
	public get source() {
		return this.mIntegrationSource;
	}

	@computed
	get integrationData() {
		return this.mIntegrationData;
	}

	@computed
	get isFetchingIntegrationData() {
		return this.mIsFetchingIntegrationData;
	}

	@computed
	protected get contact() {
		return this.entity as Api.IContact;
	}

	@computed
	get dataOriginEnabled() {
		return this.mDataOriginEnabled;
	}

	set dataOriginEnabled(value: boolean) {
		runInAction(() => {
			const valueBefore = this.mDataOriginEnabled;
			this.mDataOriginEnabled = !!value;
			if (this.mDataOriginEnabled && valueBefore !== this.mDataOriginEnabled && !this.integrationData) {
				this.fetchIntegrationData();
			}
		});
	}

	@computed
	get isArchived() {
		return this.contact.isArchived;
	}

	@action
	public uploadProfilePic = async <TFile extends Blob = Blob>(file: TFile) => {
		this.mUploadingProfilePic = true;
		const formData = new FormData();
		formData.append(`file`, file);

		const opResult: Api.IOperationResult<Api.IContact> = await this.mUserSession.webServiceHelper.callWebServiceAsync(
			this.composeApiUrl({ urlPath: `contact/${this.entity.id}/profilePic` }),
			'PUT',
			formData
		);

		this.mUploadingProfilePic = false;
		if (!opResult.success) {
			throw opResult;
		}

		const contactModel = { ...this.entity };
		contactModel.profilePic = opResult.value.profilePic;
		this.setEntity(contactModel);
		return contactModel;
	};

	// @action
	// public deleteProfilePic

	@action
	public unarchive = (forAll = true) => {
		const promise = new Promise<Api.IOperationResult<Api.IContact>>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.IContact>) => {
				if (opResult.success) {
					resolve(opResult);
				} else {
					reject(opResult);
				}
			};

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IContact>(
				`contact/${encodeURIComponent(this.id)}/unarchive?=${forAll ? 'true' : 'false'}`,
				'POST',
				null,
				onFinish,
				onFinish
			);
		});
		return promise;
	};

	@action
	public suppressKeyFact = (keyFactId: string) => {
		const promise = new Promise<Api.IOperationResult<null>>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<null>) => {
				if (opResult.success) {
					resolve(opResult);
				} else {
					reject(opResult);
				}
			};

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<null>(
				`contact/${encodeURIComponent(this.id)}/suppress/${encodeURIComponent(keyFactId)}`,
				'PUT',
				null,
				onFinish,
				onFinish
			);
		});
		return promise;
	};

	@action
	public setConnection = (connection: Api.IContactConnection, asOwner = false) => {
		if (!this.isBusy) {
			const mergedConnection: Api.IContactConnection = {
				...(this.entity.connections || []).find(x => x.user.id === connection.user.id),
				...connection,
			};
			const setConnectionPromise = this.mConnections.setConnection(mergedConnection);

			if (setConnectionPromise) {
				this.busy = true;
				return new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
					const onFinish = (opResult: Api.IOperationResultNoValue) => {
						runInAction(() => {
							if (opResult.success) {
								// updated local contact
								const contactModel = { ...this.entity };
								const connections = [...(contactModel.connections || [])];

								if (mergedConnection.user && mergedConnection.user.id) {
									const index = connections.findIndex(x => x.user.id === mergedConnection.user.id);
									if (index >= 0) {
										connections.splice(index, 1, mergedConnection);
									} else {
										connections.push(mergedConnection);
									}
								}

								// update connections
								contactModel.connections = connections;
								this.setEntity(contactModel);

								if (asOwner && contactModel.ownerId !== mergedConnection.user.id) {
									// update owner
									this.mSetOwner(mergedConnection.user).then(resolve).catch(reject);
								} else {
									this.busy = false;
									resolve(opResult);
								}
							} else {
								this.busy = false;
								reject(opResult);
							}
						});
					};
					setConnectionPromise.then(onFinish).catch(onFinish);
				});
			}
		}

		return null;
	};

	@action
	public setEntity(entity: Api.IContact) {
		if (!entity.inProgressAutomations) {
			entity.inProgressAutomations = [];
		}
		super.setEntity(entity);
		this.mRelationshipHealth = entity.relationshipHealth;
		this.mConnections = new ContactConnectionsViewModel(this.mUserSession, entity);
		this.kitVm = new KeepInTouchViewModel(this.mUserSession);

		// create tag alerts
		const tagAlerts: TagAlertViewModel[] = [];
		const tagValueToTagAlert: Api.IDictionary<TagAlertViewModel> = {};
		(entity && entity.tagsWithAlerts ? entity.tagsWithAlerts : []).forEach(tag => {
			const tagAlert = new TagAlertViewModel(this.mUserSession, { tag });
			tagAlerts.push(tagAlert);
			tagValueToTagAlert[tag.toLocaleLowerCase()] = tagAlert;
		});
		this.mTagAlerts = tagAlerts;
		this.mTagValueToTagAlert = tagValueToTagAlert;

		if (!entity?.source) {
			return;
		}

		const source = VmUtils.apiIntegrationSources.includes(entity.source) ? entity.source : null;

		this.mIntegrationSource = source;
	}

	@action
	public addInProgressAutomations = (inProgressAutomations: Api.IInProgressAutomation[]) => {
		const toAdd =
			inProgressAutomations?.filter(
				x => !(this.entity?.inProgressAutomations || []).find(y => y.automationId === x.automationId)
			) || [];
		if (toAdd?.length > 0) {
			this.entity = {
				...this.entity,
				inProgressAutomations: [...(this.entity.inProgressAutomations || []), ...toAdd],
			};
		}
	};

	@action
	public cancelInProgressAutomation = (inProgressAutomation: Api.IInProgressAutomation) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<Api.IAutomation>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomation>) => {
					this.busy = false;
					if (opResult.success) {
						const inProgressAutomations = (this.entity.inProgressAutomations || []).filter(
							x => x.automationId !== inProgressAutomation.automationId
						);
						this.entity = {
							...this.entity,
							inProgressAutomations,
						};
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({ urlPath: `Automation/${inProgressAutomation.automationId}` }),
					'DELETE',
					null,
					onFinish,
					onFinish
				);
			});
		}
	};

	public getTagAlertForTagValue = (tagValue?: string) => {
		if (tagValue && this.mTagValueToTagAlert) {
			return this.mTagValueToTagAlert[tagValue.toLocaleLowerCase()];
		}
		return null;
	};

	public reset() {
		super.reset();
		this.mConnections.reset();
		this.mUploadingProfilePic = false;
	}

	public toMention = () => {
		const contact: Api.IContact = {
			firstName: this.entity.firstName,
			id: this.entity.id,
			lastName: this.entity.lastName,
			primaryEmail: this.entity.primaryEmail,
			profilePic: this.entity.profilePic,
		};
		Object.keys(contact).forEach(key => {
			if (!(contact as any)[key]) {
				delete (contact as any)[key];
			}
		});
		return contact;
	};

	@action
	public setOwner = (user: Api.IUser) => {
		if (!this.isBusy && user && user.id) {
			this.busy = true;
			return this.mSetOwner(user);
		}

		return null;
	};

	/** Prevent this contact from appearing as a kit suggestion */
	@action
	public suppressKeepInTouchSuggestion = () => {
		if (this.entity && this.entity.id && !this.busy) {
			this.busy = true;
			const promise = new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
				const onFinish = action((result: Api.IOperationResultNoValue) => {
					this.busy = false;
					if (result.success) {
						resolve(result);
					} else {
						reject(result);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
					`contact/${this.entity.id}/suppressKeepInTouchSuggestion`,
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
			return promise;
		}
		return null;
	};

	@action
	public updateRelationshipHealth = (showBusy = true) => {
		if (!this.isBusy) {
			if (showBusy) {
				this.busy = true;
			}
			const contact = this.entity;
			const promise = new Promise<Api.IRelationshipHealth>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IRelationshipHealth>) => {
					if (showBusy) {
						this.busy = false;
					}
					if (opResult.success) {
						if (contact === this.entity) {
							this.mRelationshipHealth = opResult.value;
							this.entity.relationshipHealth = opResult.value;
						}
						resolve(opResult.value);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IRelationshipHealth>(
					`contact/${this.entity.id}/relationshipHealth`,
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
			return promise;
		}
		return null;
	};

	@action
	public updateVisibility = (visibility: Api.ResourceVisibility) => {
		if (visibility && !this.isBusy) {
			this.busy = true;
			const promise = new Promise<Api.IBulkOperationResult<Api.IContact>>((resolve, reject) => {
				const onFinish = action((result: Api.IBulkOperationResult<Api.IContact>) => {
					this.busy = false;
					if (result.success && result.succeeded.find(x => x.id === this.entity.id)) {
						this.entity = {
							...this.entity,
							visibility,
						};
						resolve(result);
					} else {
						reject(result);
					}
				});

				const request: Api.ISetContactVisibilityRequest = {
					ids: [this.entity.id],
					visibility,
				};
				this.mUserSession.webServiceHelper.callWebServiceWithBulkOperationResult<Api.IContact>(
					'contact/visibility',
					'PUT',
					request,
					onFinish,
					onFinish
				);
			});
			return promise;
		}
		return null;
	};

	public toggleTextSubscriptionForContact = async (unsubscribe: boolean) => {
		if (!this.isBusy) {
			this.busy = true;
			const result = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IContact>(
				`contact/${this.entity.id}/metadata/automatedSMSOptOut`,
				unsubscribe ? 'PUT' : 'DELETE',
				null
			);
			if (result.success) {
				this.busy = false;
				this.setEntity(result.value);
			}
			return result.value;
		}
		return null;
	};

	@action
	public removeKeyDate = (keyFact: Api.IKeyFact) => {
		if (!this.isBusy) {
			this.busy = true;
			const promise = new Promise<Api.IOperationResult<Api.IContact>>((resolve, reject) => {
				const onFinish = action((result: Api.IOperationResult<Api.IContact>) => {
					this.busy = false;
					if (result.success) {
						this.setEntity(result.value);
						resolve(result);
					} else {
						reject(result);
					}
				});

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IContact>(
					`contact/${this.entity.id}/keyfacts/${keyFact.id}/keyDate`,
					'DELETE',
					null,
					onFinish,
					onFinish
				);
			});
			return promise;
		}
		return null;
	};

	@action
	public fetchIntegrationData = async () => {
		if (this.mIsFetchingIntegrationData) {
			return;
		}

		this.mIsFetchingIntegrationData = true;
		const value: Api.IContactIntegrationData[] = await this.mUserSession.webServiceHelper.callAsync(
			`contact/${this.contact.id}/integrationData`,
			'GET'
		);
		this.mIsFetchingIntegrationData = false;
		this.mIntegrationData = value;
	};

	protected mSetOwner = (user: Api.IUser) => {
		// intentionally not checking for isBusy
		return new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
			const onFinish = action((result: Api.IOperationResultNoValue) => {
				this.busy = false;
				if (result.success) {
					// update the contact
					const contactModel = { ...(this.entity || {}) };
					contactModel.ownerId = user.id;
					this.setEntity(contactModel);
					resolve(result);
				} else {
					reject(result);
				}
			});
			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
				`contact/${this.entity.id}/owner?userId=${user.id}`,
				'PUT',
				null,
				onFinish,
				onFinish
			);
		});
	};

	protected mUpdateTags(method: Api.HTTPMethod, tags?: string[]) {
		const tagsWithAlerts = [...(this.entity.tagsWithAlerts || [])];
		const lowerTagsWithAlerts = tagsWithAlerts.map(x => x.toLocaleLowerCase());

		const promise = super.mUpdateTags(method, tags);
		if (promise && lowerTagsWithAlerts.length > 0) {
			const updatedTagAlerts = [...(this.mTagAlerts || [])];
			const updatedTagsWithAlerts: string[] = [];
			promise.then(() => {
				runInAction(() => {
					// update tag alerts
					const lowerUpdatedTagsCollection = (this.entity.tags || []).map(x => x.toLocaleLowerCase());
					if (lowerUpdatedTagsCollection.length === 0) {
						// remove them all
						this.entity = {
							...this.entity,
							tagsWithAlerts: [],
						};
						this.mTagAlerts = [];
					} else {
						let tagAlertWasRemoved = false;
						lowerTagsWithAlerts.forEach((x, i) => {
							let index = lowerUpdatedTagsCollection.findIndex(y => x === y);
							if (index < 0) {
								// tag was removed... remove tagAlert
								index = updatedTagAlerts.findIndex(y => y.tagValue.toLocaleLowerCase() === x);
								if (index >= 0) {
									updatedTagAlerts.splice(index, 1);
									tagAlertWasRemoved = true;
								}
							} else {
								updatedTagsWithAlerts.push(tagsWithAlerts[i]);
							}
						});

						if (tagAlertWasRemoved) {
							this.entity = {
								...this.entity,
								tagsWithAlerts: updatedTagsWithAlerts,
							};
							this.mTagAlerts = updatedTagAlerts;
						}
					}
				});
			});
		}

		return promise;
	}

	public loadKeepInTouch = () => {
		return this.kitVm.loadByContact(this.contact.id);
	};

	protected getNotesApiQueryPath = () => {
		return `note/byContact/${this.entity.id}`;
	};

	protected getEntityApiPath = () => {
		return 'contact';
	};

	/** Internal setter... intentionally not @action */
	protected setDeleting = (value: boolean) => {
		this.deleting = value;
	};
}

export class SuggestedContactsViewModel extends ViewModel {
	private mSuggestionsPageCollectionController: ObservablePageCollectionController<Api.IContact, ContactViewModel>;
	constructor(userSession: UserSessionContext) {
		super(userSession);
		this.mSuggestionsPageCollectionController = new ObservablePageCollectionController({
			apiPath: 'contact/suggestions',
			client: userSession.webServiceHelper,
			transformer: this.getContactViewModelRepresentation,
		});
	}

	@computed
	public get totalNumberOfSuggestions() {
		return this.mSuggestionsPageCollectionController.totalCount;
	}

	@computed
	public get isFetching() {
		return this.mSuggestionsPageCollectionController.isFetching;
	}

	@computed
	public get fetchResults() {
		return this.mSuggestionsPageCollectionController.fetchResults;
	}

	@computed
	public get isBusy() {
		return this.busy || this.loading || this.mSuggestionsPageCollectionController.isFetching;
	}

	@action
	public getSuggestions = (sortDescriptor?: Api.ISortDescriptor, pageSize?: number, params?: any) => {
		return this.mSuggestionsPageCollectionController.getNext(sortDescriptor, pageSize, params);
	};

	@action
	public reset = () => {
		this.mSuggestionsPageCollectionController.reset();
		this.busy = false;
	};

	private getContactViewModelRepresentation = (contact: Api.IContact) => {
		return new ContactViewModel(this.mUserSession, contact);
	};
}

export class ContactsViewModel extends ViewModel {
	@observable private deleting: boolean;
	@observable private merging: boolean;
	@observable private mSelectAll: boolean;
	@observable.ref private mLastSuccessfulRequest: Api.IBulkContactsRequest;
	@observable.ref private mLastSuccessfulSortDescriptor: Api.ISortDescriptor;
	@observable.ref
	private mExcludedContacts: ObservableCollection<ContactViewModel>;
	@observable.ref
	private mSelectedContacts: ObservableCollection<ContactViewModel>;
	@observable.ref private nextRequest: Api.IBulkContactsRequest;
	private contactsPageCollectionController: FilteredPageCollectionController<
		Api.IContact,
		ContactViewModel,
		Api.IBulkContactsRequest
	>;
	public static SearchCriteriaProperties: Api.ContactFilterCriteriaProperty[] = [
		Api.ContactFilterCriteriaProperty.All,
		Api.ContactFilterCriteriaProperty.Company,
		Api.ContactFilterCriteriaProperty.Email,
		Api.ContactFilterCriteriaProperty.Name,
		Api.ContactFilterCriteriaProperty.Tag,
	];
	public static SearchCriteriaFilterProperties: Api.ContactFilterCriteriaProperty[] = [
		Api.ContactFilterCriteriaProperty.Connections,
		Api.ContactFilterCriteriaProperty.CreatedBy,
		Api.ContactFilterCriteriaProperty.InProgressAutomations,
		Api.ContactFilterCriteriaProperty.KeepInTouch,
		Api.ContactFilterCriteriaProperty.OwnedBy,
		Api.ContactFilterCriteriaProperty.Policy,
		Api.ContactFilterCriteriaProperty.PreviousAutomations,
		Api.ContactFilterCriteriaProperty.PrivateContacts,
		Api.ContactFilterCriteriaProperty.TagAlert,
		Api.ContactFilterCriteriaProperty.UpcomingKeyDates,
		Api.ContactFilterCriteriaProperty.UpcomingKeyDatesNotScheduled,
		Api.ContactFilterCriteriaProperty.WithEmailAddress,
		Api.ContactFilterCriteriaProperty.WithoutEmailAddresses,
		Api.ContactFilterCriteriaProperty.WithoutTags,
		Api.ContactFilterCriteriaProperty.CapableOf,
	];

	public static getAllByIds = (userSession: UserSessionContext, contactIds: string[]) => {
		const promise = new Promise<ContactViewModel[]>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.IContact[]>) => {
				if (opResult.success) {
					const contacts = opResult.value.map(x => new ContactViewModel(userSession, x));
					resolve(contacts);
				} else {
					reject(opResult);
				}
			};
			userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IContact[]>(
				'contact/byIds',
				'POST',
				contactIds,
				onFinish,
				onFinish
			);
		});
		return promise;
	};

	public static addConnection = (
		userSession: UserSessionContext,
		addConnectionRequest: Api.IContactsAddConnectionRequest
	) => {
		const promise = new Promise<Api.ISystemJob>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.ISystemJob>) => {
				if (opResult.success) {
					resolve(opResult.value);
				} else {
					reject(opResult);
				}
			};
			userSession.webServiceHelper.callWebServiceWithOperationResults<Api.ISystemJob>(
				'contact/connection',
				'POST',
				addConnectionRequest,
				onFinish,
				onFinish
			);
		});
		return promise;
	};

	public static exportContacts = (userSession: UserSessionContext, exportRequest: Api.IContactsExportRequest) => {
		const promise = new Promise<Api.ISystemJob>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.ISystemJob>) => {
				if (opResult.success) {
					resolve(opResult.value);
				} else {
					reject(opResult);
				}
			};
			userSession.webServiceHelper.callWebServiceWithOperationResults<Api.ISystemJob>(
				'contact/export',
				'POST',
				exportRequest,
				onFinish,
				onFinish
			);
		});
		return promise;
	};

	constructor(userSession: UserSessionContext) {
		super(userSession);
		this.mSelectedContacts = new ObservableCollection<ContactViewModel>(null, 'id');
		this.mExcludedContacts = new ObservableCollection<ContactViewModel>(null, 'id');
		this.contactsPageCollectionController = new FilteredPageCollectionController<
			Api.IContact,
			ContactViewModel,
			Api.IBulkContactsRequest
		>({
			apiPath: () => this.composeApiUrl({ urlPath: 'contact/filter/v2' }),
			client: userSession.webServiceHelper,
			transformer: this.mCreateContactViewModel,
		});
	}

	@computed
	public get filterRequest() {
		return this.mLastSuccessfulRequest;
	}

	@computed
	public get sortDescriptor() {
		return this.mLastSuccessfulSortDescriptor;
	}

	@computed
	public get isMerging() {
		return this.merging;
	}

	@computed
	public get isDeleting() {
		return this.deleting;
	}

	@computed
	public get isFetchingResults() {
		return this.contactsPageCollectionController.isFetching;
	}

	@computed
	public get isSearching() {
		const contactsFilterRequest = this.nextRequest || this.mLastSuccessfulRequest;
		if (contactsFilterRequest) {
			return (
				contactsFilterRequest.filter.criteria &&
				!!contactsFilterRequest.filter.criteria.find(
					x => x.property && x.value && ContactsViewModel.SearchCriteriaProperties.indexOf(x.property) >= 0
				)
			);
		}
		return false;
	}

	@computed
	public get isBusy() {
		return (
			this.contactsPageCollectionController.isFetching || this.busy || this.loading || this.deleting || this.merging
		);
	}

	@computed
	public get totalNumberOfResults() {
		return this.contactsPageCollectionController.totalCount;
	}

	@computed
	public get selectedContacts() {
		return this.mSelectedContacts;
	}

	@computed
	public get excludedContacts() {
		return this.mExcludedContacts;
	}

	@computed
	public get fetchResults() {
		return this.contactsPageCollectionController.fetchResults;
	}

	@computed
	public get isAllSelected() {
		return this.mSelectAll;
	}

	public isContactSelected(id: string) {
		const contactVm = new ContactViewModel(this.mUserSession, { id });
		if (this.isAllSelected) {
			return !this.excludedContacts.has(contactVm);
		}
		return this.selectedContacts.has(contactVm);
	}

	@computed
	public get selectionState(): 'all' | 'some' | 'none' {
		if (this.mSelectAll) {
			return this.mExcludedContacts.length > 0 ? 'some' : 'all';
		}

		return this.mSelectedContacts.length > 0 ? 'some' : 'none';
	}

	@action
	public deselectAll = () => {
		this.mDeselectAll();
	};

	@action
	public selectAll = () => {
		this.mSelectedContacts.clear();
		this.mExcludedContacts.clear();
		this.mSelectAll = true;
	};

	@action
	public reset = () => {
		this.busy = false;
		this.contactsPageCollectionController.reset();
		this.deleting = false;
		this.loading = false;
		this.mDeselectAll();
		this.merging = false;
		this.mLastSuccessfulRequest = null;
		this.mLastSuccessfulSortDescriptor = null;
		this.nextRequest = null;
	};

	@action
	public getContacts = (
		filter?: Api.IBulkContactsRequest,
		sortDescriptor?: Api.ISortDescriptor,
		pageSize?: number,
		params?: any
	) => {
		// merge params with sortDescriptor
		const computedParams = {
			...(sortDescriptor || {}),
			...(params || {}),
		};

		const promise = this.contactsPageCollectionController.getNext(filter, pageSize, computedParams);
		if (promise) {
			this.nextRequest = filter;
			promise.then(() => {
				if (filter === this.nextRequest) {
					this.mLastSuccessfulRequest = filter;
					this.mLastSuccessfulSortDescriptor = sortDescriptor;
					this.nextRequest = null;
				}
			});
		}
		return promise;
	};

	/** Adds tags to selected contacts. Uses contact filter in addition if selecting all */
	@action
	public addTags = (tags: string[]) => {
		if (tags && tags.length > 0 && !this.isBusy) {
			const promise = new Promise<Api.ISystemJob>((resolve, reject) => {
				this.busy = true;
				const onFinish = (opResult: Api.IOperationResult<Api.ISystemJob>) => {
					runInAction(() => {
						this.busy = false;
						if (opResult.success) {
							resolve(opResult.value);
						} else {
							reject(opResult);
						}
					});
				};

				const request: Api.IContactsAddTagsRequest = {
					excludeContactIds: this.excludedContacts.map(x => x.id),
					includeContactIds: this.selectedContacts.map(x => x.id),
					tags,
				};

				if (this.selectionState === 'all' || (this.selectionState === 'some' && this.selectedContacts.length === 0)) {
					// use filter
					request.filter = this.nextRequest?.filter || this.mLastSuccessfulRequest?.filter;
				}

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ISystemJob>(
					'contact/tags/add',
					'POST',
					request,
					onFinish,
					onFinish
				);
			});

			return promise;
		}
		return null;
	};

	@action
	public merge = (target: ContactViewModel, contacts: ContactViewModel[]) => {
		if (target && target.id && !this.busy) {
			const promise = new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
				const contactsToDelete = contacts || [];
				this.merging = true;
				this.setDeletingState(contactsToDelete, true);

				const onFinish = (opResult: Api.IOperationResultNoValue) => {
					runInAction(() => {
						this.merging = false;
						// delete the succeeded items from collections
						this.mRemoveContacts(contacts);
						this.setDeletingState(contactsToDelete, false);

						if (opResult.success) {
							resolve(opResult);
						} else {
							reject(opResult);
						}
					});
				};

				const listOfContactIds = contactsToDelete.map(x => x.id).filter(x => x);
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<void>(
					`contact/${target.id}/merge`,
					'POST',
					listOfContactIds,
					onFinish,
					onFinish
				);
			});

			return promise;
		}

		return null;
	};

	@action
	public setVisibility = (setVisibilityRequest: Api.IContactsSetVisibilityRequest) => {
		const promise = new Promise<Api.ISystemJob>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.ISystemJob>) => {
				if (opResult.success) {
					resolve(opResult.value);
				} else {
					reject(opResult);
				}
			};
			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ISystemJob>(
				'contact/visibility',
				'POST',
				setVisibilityRequest,
				onFinish,
				onFinish
			);
		});

		return promise;
	};

	@action
	public delete = (contacts: ContactViewModel[]) => {
		const contactsToDelete = contacts || [];
		if (!this.isBusy && contactsToDelete.length > 0 && contactsToDelete.every(x => !x.isBusy)) {
			const idToContactToDelete = contactsToDelete.reduce<Api.IDictionary<ContactViewModel>>((res, curr) => {
				res[curr.id] = curr;
				return res;
			}, {});

			this.deleting = true;
			this.setDeletingState(contactsToDelete, true);
			const promise = new Promise<Api.IBulkOperationResult<string>>((resolve, reject) => {
				const onFinish = (opResult: Api.IBulkOperationResult<string>) => {
					runInAction(() => {
						this.deleting = false;

						// delete the succeeded items from collections
						const deletedContacts = opResult.success
							? contactsToDelete
							: (opResult.succeeded || []).map(x => idToContactToDelete[x]).filter(x => x);
						this.mRemoveContacts(deletedContacts);
						this.setDeletingState(contactsToDelete, false);

						if (opResult.success) {
							resolve(opResult);
						} else {
							reject(opResult);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithBulkOperationResult<string>(
					'contact',
					'DELETE',
					contactsToDelete.map(x => x.id),
					onFinish,
					onFinish
				);
			});

			return promise;
		}

		return null;
	};

	/** Delete all selected with current filter */
	@action
	public deleteMany = async () => {
		if (!this.isBusy) {
			this.deleting = true;
			const request: Api.IBulkContactsRequest = {
				excludeContactIds: this.excludedContacts.map(x => x.id),
				filter: this.filterRequest.filter,
				includeContactIds: this.selectedContacts.map(x => x.id),
			};
			const result = await this.mUserSession.webServiceHelper.callWebServiceAsync<number>(
				this.composeApiUrl({ urlPath: 'contact/delete' }),
				'POST',
				request
			);

			this.deleting = false;
			if (result.success) {
				return result;
			} else {
				throw Api.asApiError(result);
			}
		}
	};

	@action
	public setKeepInTouch = (keepInTouch: Partial<Api.IKeepInTouch>, contacts: ContactViewModel[]) => {
		if (!this.busy && contacts && contacts.length > 0 && contacts.every(x => x.id)) {
			const idToContact = contacts.reduce<Api.IDictionary<ContactViewModel>>((res, curr) => {
				res[curr.id] = curr;
				VmUtils.setViewModelBusy(curr, true);
				return res;
			}, {});
			this.busy = true;

			const promise = new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
				const onFinish = (opResult: Api.IBulkOperationResult<Api.IKeepInTouch>) => {
					runInAction(() => {
						this.busy = false;
						contacts.forEach(x => VmUtils.setViewModelBusy(x, false));
						if (opResult.success) {
							// set the kits back on the contacts
							(opResult.succeeded || []).forEach(x => {
								const contact = idToContact[x.contactId];
								if (contact && contact.keepInTouch) {
									contact.keepInTouch.setKeepInTouch(x);
								}
							});

							resolve(opResult);
						} else {
							reject(opResult);
						}
					});
				};

				// create the kits using keepInTouch as the base
				const keepInTouches = contacts.map(x => {
					const kit: Api.IKeepInTouch = {
						...keepInTouch,
						contactId: x.id,
					};
					kit.accountId = kit.accountId || this.mUserSession.account.id;
					kit.userId = kit.userId || this.mUserSession.user.id;
					return kit;
				});

				// TODO: don't like this... should be patch and should return a list of contact models... we shouldn't trust the input
				this.mUserSession.webServiceHelper.callWebServiceWithBulkOperationResult<Api.IKeepInTouch>(
					'keepintouch/multiple',
					'POST',
					keepInTouches,
					onFinish,
					onFinish
				);
			});

			return promise;
		}

		return null;
	};

	/** Removes the given contacts from all collections but does not delete them from Levitate */
	@action
	public removeContacts = (contacts: ContactViewModel[]) => {
		this.mRemoveContacts(contacts);
	};

	@action
	public assignOwner = (userSession: UserSessionContext, assignOwnerRequest: Api.IContactsAssignOwnerRequest) => {
		const promise = new Promise<Api.ISystemJob>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.ISystemJob>) => {
				if (opResult.success) {
					resolve(opResult.value);
				} else {
					reject(opResult);
				}
			};
			userSession.webServiceHelper.callWebServiceWithOperationResults<Api.ISystemJob>(
				this.composeApiUrl({ urlPath: 'contact/owner/assign' }),
				'POST',
				assignOwnerRequest,
				onFinish,
				onFinish
			);
		});
		return promise;
	};

	/** Uses the current filter to a bulk contact request */
	public getBulkContactRequest = () => {
		const exportRequest: Api.IBulkContactsRequest = {
			excludeContactIds: this.mExcludedContacts.map(this.selectById),
			includeContactIds: this.mSelectedContacts.map(this.selectById),
		};

		// add filter if selecting all or selecting all with exclusions (no explicit selection of contacts)
		if (this.selectionState === 'all' || (this.selectionState === 'some' && this.selectedContacts.length === 0)) {
			exportRequest.filter = {
				...(this.nextRequest?.filter || this.mLastSuccessfulRequest?.filter || {}),
			};
		}
		return exportRequest;
	};

	public exportContacts = () => {
		return ContactsViewModel.exportContacts(this.mUserSession, this.getBulkContactRequest());
	};

	public unarchiveContacts = () => {
		return this.userSession.webServiceHelper.callAsync<number>(
			this.composeApiUrl({ urlPath: 'contact/unarchive' }),
			'POST',
			this.getBulkContactRequest()
		);
	};

	private selectById = (contactViewModel: ContactViewModel) => contactViewModel.id;

	private mDeselectAll = () => {
		this.mSelectAll = false;
		this.mSelectedContacts.clear();
		this.mExcludedContacts.clear();
	};

	/** Helper method for setting delete state of managed contacts */
	private setDeletingState = (contacts: ContactViewModel[], deleting: boolean) => {
		this.deleting = deleting;
		contacts.forEach(x => {
			// set deleting state... a bit of a hack
			if (Object.prototype.hasOwnProperty.call(x, 'setDeleting')) {
				(x as any).setDeleting(deleting);
			}
		});
	};

	/** Intentionally not an action */
	private mRemoveContacts = (contacts: ContactViewModel[]) => {
		this.contactsPageCollectionController.fetchResults.removeItems(contacts);
		this.selectedContacts.removeItems(contacts);
		this.excludedContacts.removeItems(contacts);
	};

	private mCreateContactViewModel = (contact: Api.IContact) => {
		return new ContactViewModel(this.mUserSession, contact);
	};
}

export class CompanyViewModel extends EntityViewModel<Api.ICompany> {
	@observable private merging: boolean;
	@observable public fetchingContacts: boolean;
	@observable.ref public contacts: ContactViewModel[];
	private contactsPageCollectionController: Api.IPageCollectionController<Api.IContact>;

	constructor(userSession: UserSessionContext, company: Api.ICompany) {
		super(userSession, company);
		this.contactsPageCollectionController = new Api.PageCollectionController(
			userSession.webServiceHelper,
			`contact/byCompany/${company.id}`
		);
	}

	@computed
	public get isBusy() {
		return this.busy || this.saving || this.loading || this.merging || this.deleting;
	}

	@computed
	public get isMerging() {
		return this.merging;
	}

	@computed
	public get isLoaded() {
		return this.validEntity ? !!this.entity.companyName : true;
	}

	@computed
	public get emailDomain() {
		return this.company.emailDomain;
	}

	@computed
	public get emailDomains() {
		return this.company.emailDomains;
	}

	@computed
	public get address() {
		return this.company.address;
	}

	@computed
	public get logoUrl() {
		return this.company.logoUrl;
	}

	@computed
	public get timeZone() {
		return this.company.timeZone;
	}

	@computed
	public get timeZoneAbbr() {
		// TODO: these cases are wrong
		switch (this.timeZone) {
			case 'Eastern Standard Time':
			case 'US/Eastern':
				return 'EST';
			case 'Central Standard Time':
			case 'US/Central':
				return 'CST';
			case 'Mountain Standard Time':
			case 'US/Mountain':
				return 'MST';
			case 'Hawaii Standard Time':
			case 'US/Hawaii':
				return 'HST';
			case 'Alaska Standard Time':
			case 'US/Alaska':
				return 'AKST';
			case 'Pacific Standard Time':
			case 'US/Pacific':
				return 'PST';
			default:
				return this.timeZone ?? 'Unknown';
		}
	}

	@action
	public setTimezone = (timeZone: string) => {
		this.entity = { ...this.entity, timeZone };
	};

	@action
	public reset() {
		super.reset();
		this.contacts = null;
		this.contactsPageCollectionController.reset();
		this.deleting = false;
		this.fetchingContacts = false;
		this.merging = false;
	}

	@action
	public getContacts = (sortDescriptor?: Api.ISortDescriptor, pageSize?: number) => {
		if (this.contactsPageCollectionController.hasAllPages(sortDescriptor, pageSize)) {
			return getEmptyPageControllerResolvedPromise<Api.IContact>();
		}

		this.fetchingContacts = true;
		const promise = this.contactsPageCollectionController.getNext(sortDescriptor, pageSize);
		promise
			.then(result => {
				runInAction(() => {
					const fetchResults = result.values.map(x => new ContactViewModel(this.mUserSession, x));
					this.contacts = result.fetchedFirstPage
						? fetchResults
						: fetchResults.length > 0
							? [...this.contacts, ...fetchResults]
							: this.contacts;
					this.fetchingContacts = false;
				});
			})
			.catch(() => {
				runInAction(() => {
					this.contacts = [];
					this.fetchingContacts = false;
				});
			});
		return promise;
	};

	/**
	 * Merge companies with the receiver.
	 *
	 * @param companies Companies to merge with. The receiver will update with the single resulting company
	 */
	@action
	public merge = (companies: CompanyViewModel[]) => {
		if (!this.merging && this.company && this.company.id) {
			const companiesToMerge = (companies || []).filter(x => x && x.id && x.id !== this.company.id && !x.merging);
			if (companiesToMerge.length > 0) {
				this.merging = true;
				companiesToMerge.forEach(x => (x.merging = true));
				const promise = new Promise<Api.ICompany>((resolve, reject) => {
					const onFinish = (result: Api.IOperationResult<Api.ICompany>) => {
						runInAction(() => {
							this.merging = false;
							companiesToMerge.forEach(x => (x.merging = false));
							if (result.success) {
								this.setEntity(result.value);
								resolve(result.value);
							} else {
								reject(result);
							}
						});
					};
					this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ICompany>(
						`company/${this.company.id}/merge`,
						'POST',
						// first company id is what the others merge into
						companiesToMerge.map(x => x.id),
						onFinish,
						onFinish
					);
				});
				return promise;
			}
		}
		return null;
	};

	public toMention = () => {
		const company: Api.ICompany = {
			companyName: this.entity.companyName,
			emailDomain: this.entity.emailDomain,
			id: this.entity.id,
			logoUrl: this.entity.logoUrl,
		};
		Object.keys(company).forEach(key => {
			if (!(company as any)[key]) {
				delete (company as any)[key];
			}
		});
		return company;
	};

	@computed
	protected get company() {
		return this.entity as Api.ICompany;
	}

	protected getNotesApiQueryPath = () => {
		return `note/byCompany/${this.entity.id}`;
	};

	protected getEntityApiPath = () => {
		return 'company';
	};

	/** Internal setter... intentionally not @action */
	protected setDeleting = (value: boolean) => {
		this.deleting = value;
	};

	/** Internal setter... intentionally not @action */
	protected setMerging = (value: boolean) => {
		this.merging = value;
	};
}

export type ActionItemLoadFilter = 'all' | 'assignedOnly' | 'upcomingOnly';

export class ActionItemsViewModel extends ViewModel {
	@observable public searchQuery: string;
	private assignedOnly: boolean;
	private completedItemsPageCollectionController: FilteredPageCollectionController<
		Api.IActionItem,
		ActionItemViewModel
	>;
	private inProgressItemsPageCollectionController: FilteredPageCollectionController<
		Api.IActionItem,
		ActionItemViewModel,
		Api.IRichContentRequest
	>;
	private searchPageCollectionController: FilteredPageCollectionController<
		Api.IActionItem,
		ActionItemViewModel,
		Api.IRichContentRequest
	>;

	constructor(userSession: UserSessionContext, filter?: ActionItemLoadFilter) {
		super(userSession);
		this.inProgressItemsPageCollectionController = new FilteredPageCollectionController<
			Api.IActionItem,
			ActionItemViewModel
		>({
			apiPath: 'actionitem/filter',
			client: userSession.webServiceHelper,
			transformer: this.createActionItemVm,
			httpMethod: 'POST',
		});

		if (filter && filter !== 'all') {
			this.assignedOnly = filter === 'assignedOnly';
			if (filter === 'upcomingOnly') {
				this.inProgressItemsPageCollectionController = new FilteredPageCollectionController<
					Api.IActionItem,
					ActionItemViewModel
				>({
					apiPath: 'actionitem/upcoming',
					client: userSession.webServiceHelper,
					transformer: this.createActionItemVm,
				});
			}
		}

		this.completedItemsPageCollectionController = new FilteredPageCollectionController<
			Api.IActionItem,
			ActionItemViewModel
		>({
			apiPath: 'actionitem/filter',
			client: userSession.webServiceHelper,
			transformer: this.createActionItemVm,
			httpMethod: 'POST',
		});

		this.searchPageCollectionController = new FilteredPageCollectionController<Api.IActionItem, ActionItemViewModel>({
			apiPath: 'actionitem/filter',
			client: userSession.webServiceHelper,
			transformer: this.createActionItemVm,
			httpMethod: 'POST',
		});
	}

	@computed
	public get isSearching() {
		return (
			this.searchPageCollectionController.isFetching ||
			this.searchPageCollectionController.hasContext ||
			this.searchPageCollectionController.hasFetchedFirstPage
		);
	}

	@computed
	public get fetchingCompletedActionItems() {
		return this.completedItemsPageCollectionController.isFetching;
	}

	@computed
	public get fetchingInProgressActionItems() {
		return this.inProgressItemsPageCollectionController.isFetching;
	}

	@computed
	public get fetchingSearchResults() {
		return this.searchPageCollectionController.isFetching;
	}

	@computed
	public get completedActionItems() {
		return this.completedItemsPageCollectionController.fetchResults;
	}

	@computed
	public get inProgressActionItems() {
		return this.inProgressItemsPageCollectionController.fetchResults;
	}

	@computed
	public get searchResults() {
		return this.searchPageCollectionController.fetchResults;
	}

	@computed
	public get isBusy() {
		return (
			this.inProgressItemsPageCollectionController.isFetching ||
			this.completedItemsPageCollectionController.isFetching ||
			this.searchPageCollectionController.isFetching
		);
	}

	@action
	public reset = () => {
		this.completedItemsPageCollectionController.reset();
		this.inProgressItemsPageCollectionController.reset();
		this.searchPageCollectionController.reset();
		this.searchQuery = null;
	};

	@action
	public resetSearch = () => {
		this.searchPageCollectionController.reset();
		this.searchQuery = null;
	};

	public removeActionItems = (actionItems: ActionItemViewModel[], completed: boolean) => {
		if (completed) {
			this.completedItemsPageCollectionController.fetchResults.removeItems(actionItems);
		} else {
			this.inProgressItemsPageCollectionController.fetchResults.removeItems(actionItems);
		}
	};

	public getInProgressItems = (request?: Api.IRichContentRequest, sort?: Api.ISortDescriptor, pageSize?: number) => {
		const params = { assignedOnly: this.assignedOnly, ...(sort || {}) };
		return this.inProgressItemsPageCollectionController.getNext(request, pageSize, params);
	};

	public getCompletedItems = (request?: Api.IRichContentRequest, sort?: Api.ISortDescriptor, pageSize?: number) => {
		const params = { assignedOnly: this.assignedOnly, ...(sort || {}) };
		return this.completedItemsPageCollectionController.getNext(request, pageSize, params);
	};

	public toggleComplete = (actionItem: ActionItemViewModel, complete?: boolean) => {
		const promise = actionItem.toggleComplete(complete);
		promise.then(() => {
			// remove the item from the list it came from
			if (complete) {
				this.inProgressItemsPageCollectionController.fetchResults.removeItems([actionItem]);
			} else {
				this.completedItemsPageCollectionController.fetchResults.removeItems([actionItem]);
			}
		});
		return promise;
	};

	public filterItems = (
		filterRequest: Api.IRichContentRequest,
		pageSize?: number,
		sortOption?: NotesSortOptionValue
	) => {
		const query = (this.searchQuery || '').trim();
		if (query) {
			filterRequest.criteria?.push({
				property: Api.RichContentProperty.Content,
				value: query,
			});
		}
		// if filterRequest doesnt have criteria add ALL
		if (!filterRequest.criteria || filterRequest.criteria.length === 0) {
			filterRequest.criteria?.push({
				property: Api.RichContentProperty.All,
				value: 'All',
			});
		}
		const options = this.getOptions(sortOption);
		if (filterRequest.criteria?.includes({ property: Api.RichContentProperty.Content, value: query })) {
			return this.searchPageCollectionController.getNext(filterRequest, pageSize, {
				sort: options.sort,
				sortBy: options.sortBy,
			});
		}

		return filterRequest.criteria?.find(x => x.property === Api.RichContentProperty.IsCompleted && x.value === 'true')
			? this.completedItemsPageCollectionController.getNext(filterRequest, pageSize, {
					sort: options.sort,
					sortBy: options.sortBy,
				})
			: this.inProgressItemsPageCollectionController.getNext(filterRequest, pageSize, {
					sort: options.sort,
					sortBy: options.sortBy,
				});
	};

	private createActionItemVm = (actionItemModel: Api.IActionItem) => {
		return new ActionItemViewModel(this.userSession, actionItemModel);
	};

	private getOptions = (sortOption?: NotesSortOptionValue) => {
		let newSort = '';
		let sortBy = '';
		switch (sortOption) {
			case 'LastModifiedDate-asc':
				newSort = 'asc';
				sortBy = 'lastModifiedDate';
				break;
			case 'LastModifiedDate-dsc':
				newSort = 'desc';
				sortBy = 'lastModifiedDate';
				break;
			case 'CreationDate-asc':
				newSort = 'asc';
				sortBy = 'creationDate';
				break;
			case 'CreationDate-dsc':
				newSort = 'desc';
				sortBy = 'creationDate';
				break;
			case 'DueDate-asc':
				newSort = 'asc';
				sortBy = 'dueDate';
				break;
			case 'DueDate-dsc':
				newSort = 'desc';
				sortBy = 'dueDate';
				break;
			default:
				break;
		}
		return { sort: newSort, sortBy };
	};
}

export class ContactConnectionsViewModel extends ViewModel {
	@observable public fetchingConnections: boolean;
	@observable.ref mContact: Api.IContact;
	private connectionsPageCollectionController: ObservablePageCollectionControllerOld<
		Api.IContactConnection,
		Api.IContactConnection
	>;

	constructor(userSession: UserSessionContext, contact: Api.IContact) {
		super(userSession);
		this.mContact = contact;
		this.connectionsPageCollectionController = new ObservablePageCollectionControllerOld<
			Api.IContactConnection,
			Api.IContactConnection
		>(userSession.webServiceHelper, `contact/${contact.id}/connections`, null, null);
	}

	@computed
	public get connections() {
		return this.connectionsPageCollectionController.fetchResults;
	}

	@action
	public reset() {
		this.connectionsPageCollectionController.reset();
		this.fetchingConnections = false;
	}

	public getConnections = (sortDescriptor?: Api.ISortDescriptor, pageSize?: number) => {
		return this.connectionsPageCollectionController.getNext(sortDescriptor, pageSize);
	};

	/**
	 * Sets (adds/updates) a connection with the receiver. If this is an update, the vm will handle replacing the
	 * connection in the list
	 */
	@action
	public setConnection = (connection: Api.IContactConnection) => {
		return this.editContactConnections('PUT', connection, () => {
			const matchingIndex = (this.connectionsPageCollectionController.fetchResults || []).findIndex(
				x => x.user.id === connection.user.id
			);
			if (matchingIndex >= 0) {
				// replace
				const fetchResults = [...this.connectionsPageCollectionController.fetchResults];
				fetchResults.splice(matchingIndex, 1, connection);
				this.connectionsPageCollectionController.fetchResults = fetchResults;
			}
		});
	};

	/** The vm will handle removing the connection from the list */
	@action
	public removeConnectionToUser = (user: Api.IUser) => {
		const connection: Api.IContactConnection = { user };
		return this.editContactConnections('DELETE', connection, () => {
			const matchingIndex = (this.connectionsPageCollectionController.fetchResults || []).findIndex(
				x => x.user.id === connection.user.id
			);
			if (matchingIndex >= 0) {
				// remove
				const fetchResults = [...this.connectionsPageCollectionController.fetchResults];
				fetchResults.splice(matchingIndex, 1);
				this.connectionsPageCollectionController.fetchResults = fetchResults;
			}
		});
	};

	/** Intentionally not an action */
	private editContactConnections = (
		method: 'DELETE' | 'PUT',
		connection: Api.IContactConnection,
		onSuccess?: () => void
	) => {
		if (connection && connection.user && connection.user.id && !this.busy) {
			this.busy = true;
			const promise = new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
				const onFinish = action((result: Api.IOperationResultNoValue) => {
					this.busy = false;
					if (result.success) {
						if (onSuccess) {
							onSuccess();
						}
						resolve(result);
					} else {
						reject(result);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
					`contact/${this.mContact.id}/connections/${connection.user.id}`,
					method,
					method !== 'DELETE' ? connection : null,
					onFinish,
					onFinish
				);
			});
			return promise;
		}

		return null;
	};
}

export type QuickAddEntityCompletion = (error: Api.IOperationResultNoValue, entity?: Api.IEntity) => void;

/** @returns `false` to indicate the default action (onComplete) should be prevented. */
export type QuickAddEntityErrorHandler = (error: Api.IOperationResultNoValue) => boolean;

export interface IQuickAddEntityContactCreateOptions {
	requireEmail?: boolean;
	requireFirstName?: boolean;
	requireJobTitle?: boolean;
	requireLastName?: boolean;
}

export interface IQuickAddEntityShowOptions {
	contactCreateOptions?: IQuickAddEntityContactCreateOptions;
	entity?: Api.IEntity;
	onComplete?: QuickAddEntityCompletion;
	onError?: QuickAddEntityErrorHandler;
	type: 'contact' | 'company';
}

export class QuickAddEntityViewModel {
	@observable public companyName: string;
	@observable public emailAddress: string;
	@observable public emailAddressLabel: string;
	@observable public emailDomain: string;
	@observable public entityType: 'contact' | 'company';
	@observable public firstName: string;
	@observable public newPhoneNumber: string;
	@observable.ref public phoneNumbers: Api.IPhoneNumber[] = [];
	@observable public isBusy: boolean;
	@observable public isOpen: boolean;
	@observable public jobTitle: string;
	@observable public lastName: string;
	@observable public publicVisibility: boolean;
	@observable.ref private userSession: UserSessionContext;
	@observable.ref
	public contactCreateOptions: IQuickAddEntityContactCreateOptions;

	private createContactPromise: Promise<Api.IOperationResult<Api.IContact>>;
	private createCompanyPromise: Promise<Api.IOperationResult<Api.ICompany>>;

	private onComplete: QuickAddEntityCompletion;
	private onError: QuickAddEntityErrorHandler;
	public defaultOnErrorHandler: QuickAddEntityErrorHandler;

	constructor(userSession?: UserSessionContext) {
		this.userSession = userSession;
		this.publicVisibility = true;
		this.reset();
	}

	@action
	public show = (options: IQuickAddEntityShowOptions) => {
		this.reset();
		this.entityType = options.type;
		const defaultVisibilityIsPublic = VmUtils.getDefaultVisibility(this.userSession.user) === 'all';

		if (options.entity) {
			this.companyName = options.entity.companyName;

			if (options.type === 'contact') {
				const contact: Api.IContact = options.entity;
				this.emailAddress = contact.primaryEmail ? contact.primaryEmail.value : null;
				this.firstName = contact.firstName;
				this.jobTitle = contact.jobTitle;
				this.lastName = contact.lastName;
				this.publicVisibility = Object.prototype.hasOwnProperty.call(contact, 'visibility')
					? contact.visibility === 'all'
					: defaultVisibilityIsPublic;
			} else {
				const company: Api.ICompany = options.entity;
				this.emailDomain = company.emailDomain;
			}
		} else {
			if (options.type === 'contact') {
				this.publicVisibility = defaultVisibilityIsPublic;
			}
		}

		this.isOpen = true;
		this.onComplete = options.onComplete;
		this.onError = options.onError;
		this.contactCreateOptions = options.contactCreateOptions;
	};

	@action
	public reset() {
		this.companyName = null;
		this.contactCreateOptions = null;
		this.emailAddress = null;
		this.emailAddressLabel = null;
		this.emailDomain = null;
		this.entityType = 'contact';
		this.firstName = null;
		this.isOpen = false;
		this.jobTitle = null;
		this.lastName = null;
		this.onComplete = null;
		this.onError = null;
		this.publicVisibility = true;
		this.phoneNumbers = [];
		this.newPhoneNumber = null;
	}

	@action
	public close = () => {
		this.reset();
	};

	@action
	public setUserSession = (userSession: UserSessionContext) => {
		this.userSession = userSession;
	};

	@computed
	public get canCreate() {
		if (!this.userSession) {
			return false;
		}

		if (this.entityType === 'contact') {
			let canCreate = (this.firstName && !!this.lastName) || !!this.emailAddress;
			if (canCreate && this.contactCreateOptions) {
				if (
					Object.prototype.hasOwnProperty.call(this.contactCreateOptions, 'requireEmail') &&
					this.contactCreateOptions.requireEmail &&
					!this.emailAddress
				) {
					canCreate = false;
				}
			}
			return canCreate;
		}

		return !!this.companyName;
	}

	@action
	public save = () => {
		return this.entityType === 'company' ? this.createCompany() : this.createContact();
	};

	@action
	private createContact = () => {
		if (this.isBusy) {
			return;
		}

		const visibility = this.publicVisibility ? 'all' : 'admin';
		this.isBusy = true;
		const emailAddresses: Api.EmailAddress[] = this.emailAddress
			? [{ label: this.emailAddressLabel, value: this.emailAddress }]
			: null;
		let phoneNumbers: Api.IPhoneNumber[] = this.phoneNumbers;
		if (this.newPhoneNumber) {
			phoneNumbers = [...phoneNumbers, { value: this.newPhoneNumber }];
		}
		this.createContactPromise = this.create<Api.IContact>('contact', {
			emailAddresses,
			firstName: this.firstName,
			jobTitle: this.jobTitle,
			lastName: this.lastName,
			phoneNumbers,
			visibility,
		});

		return this.createContactPromise;
	};

	@action
	private createCompany = () => {
		if (this.isBusy) {
			return;
		}
		this.isBusy = true;
		this.createCompanyPromise = this.create<Api.ICompany>('company', {
			companyName: this.companyName,
			emailDomain: this.emailDomain,
		});

		return this.createCompanyPromise;
	};

	private create = <T extends Api.IEntity>(type: 'company' | 'contact', entity: T) => {
		return new Promise((resolve, reject) =>
			this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IEntity>(
				type,
				'POST',
				entity,
				result => {
					runInAction(() => {
						this.isBusy = false;
						resolve(result);
						if (this.onComplete) {
							this.onComplete(null, result.value);
						}
						this.reset();
					});
				},
				error => {
					runInAction(() => {
						this.isBusy = false;
						let finish = true;
						const onErrorHandler = this.onError || this.defaultOnErrorHandler;
						if (onErrorHandler) {
							finish = onErrorHandler(error);
						}

						if (finish) {
							if (this.onComplete) {
								this.onComplete(error);
							}
							this.reset();
						}
						reject(error);
					});
				}
			)
		);
	};
}

export class MeetingAttendee {
	@observable.ref mContact: ContactViewModel;
	@observable.ref mMeetingAttendee: Api.IMeetingAttendee;

	constructor(userSession: UserSessionContext, meetingAttendee: Api.IMeetingAttendee) {
		this.mMeetingAttendee = meetingAttendee;
		if (meetingAttendee.contact) {
			this.mContact = new ContactViewModel(userSession, meetingAttendee.contact);
		}
	}

	@computed
	public get isOrganizer() {
		return this.mMeetingAttendee.organizer;
	}

	@computed
	public get isOptional() {
		return this.mMeetingAttendee.optional;
	}

	@computed
	public get isResource() {
		return this.mMeetingAttendee.resource;
	}

	@computed
	public get contact() {
		return this.mContact;
	}

	public toJs = () => {
		return this.mMeetingAttendee;
	};
}

export class MeetingViewModel {
	@observable public dateFormat = 'MM/DD/YY';
	@observable public dayFormat = 'dddd';
	@observable public timeFormat = 'h:mm A';
	@observable.ref private mAttendees: MeetingAttendee[];
	@observable.ref private mEndTime: Date;
	@observable.ref private mMeeting: Api.IMeeting;
	@observable.ref private mStartTime: Date;
	protected userSession: UserSessionContext;

	constructor(userSession: UserSessionContext, meeting: Api.IMeeting) {
		this.userSession = userSession;
		this.setMeeting(meeting);
	}

	@computed
	public get id() {
		return this.mMeeting.id;
	}

	@computed
	public get recurringId() {
		return this.mMeeting.recurringId;
	}

	@computed
	public get iCalUid() {
		return this.mMeeting.iCalUid;
	}

	@computed
	public get summary() {
		return this.mMeeting.summary;
	}

	@computed
	public get startTime() {
		return this.mStartTime;
	}

	@computed
	public get endTime() {
		return this.mEndTime;
	}

	@computed
	public get isBusyDuringMeeting() {
		return this.mMeeting.isBusy;
	}

	@computed
	public get isAllDay() {
		return this.mMeeting.isAllDay;
	}

	@computed
	public get attendees() {
		return this.mAttendees;
	}

	@computed
	public get durationStringValue() {
		if (this.mMeeting) {
			if (this.mMeeting.isAllDay) {
				return moment(this.startTime).format(this.dateFormat);
			} else {
				const today = moment();
				const endOfToday = today.endOf('day');
				const endOfTomorrow = today.add(1, 'day').endOf('day');

				const startMoment = moment(this.startTime);
				const endMoment = moment(this.endTime);

				const timeRangeStringValue = `${startMoment.format(this.timeFormat)} - ${endMoment.format(this.timeFormat)}`;
				let dayStringValue: string = null;
				if (startMoment < endOfToday) {
					dayStringValue = 'TODAY';
				} else if (startMoment < endOfTomorrow) {
					dayStringValue = 'TOMORROW';
				} else {
					dayStringValue = startMoment.format(this.dayFormat).toUpperCase();
				}

				return `${dayStringValue} ${timeRangeStringValue}`;
			}
		}

		return null;
	}

	public toJs = () => {
		return this.mMeeting;
	};

	@action
	protected setMeeting(meeting: Api.IMeeting) {
		this.mMeeting = meeting;
		this.mStartTime = meeting.startTime ? new Date(meeting.startTime) : null;
		this.mEndTime = meeting.endTime ? new Date(meeting.endTime) : null;
		this.mAttendees = (meeting.attendees || []).map(x => new MeetingAttendee(this.userSession, x));
	}
}

export class RecentMeetingViewModel extends MeetingViewModel {
	@observable.ref private mNote: NoteViewModel;

	constructor(userSession: UserSessionContext, recentMeeting: Api.IMeeting) {
		super(userSession, recentMeeting);
	}

	@computed
	public get note() {
		return this.mNote;
	}

	public suppress = () => {
		const promise = new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
			const suppress: Api.IDashboardSuppress = {
				context: Api.DashboardSuppressContext.RecentMeeting,
				id: this.id,
			};

			const onFinish = (result: Api.IOperationResultNoValue) => {
				if (result.success) {
					resolve(result);
				} else {
					reject(result);
				}
			};

			this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
				'dashboard/suppress',
				'POST',
				suppress,
				onFinish,
				onFinish
			);
		});

		return promise;
	};

	@action
	protected setMeeting(recentMeeting: Api.IMeeting) {
		super.setMeeting(recentMeeting);

		// create a note using meeting meta
		const noteModel: Api.INote = {
			context: {
				name: recentMeeting.summary,
				source: Api.RichContentContextSource.Meeting,
			},
			referencedEntities: {
				companies: [],
				contacts: (recentMeeting.attendees || [])
					.filter(x => x.contact)
					.map(attendee => {
						const ref: Api.IRichContentEntityReference<Api.IContact> = {
							entity: attendee.contact,
							method: Api.RichContentReferenceMethod.Implicit,
						};
						return ref;
					}),
				users: [],
			},
		};

		this.mNote = new NoteViewModel(this.userSession, noteModel);
	}
}

export class ClassifyContactViewModel {
	@observable.ref private mContact: ContactViewModel;
	@observable.ref private mMostRecentInteraction: {
		interactionDate?: Date;
		interactionType: Api.InteractionType;
	};
	@observable.ref private mSuggestedTags: string[];
	@observable.ref private mRecentEmailSubjects: string[];
	private mUserSession: UserSessionContext;

	constructor(userSession: UserSessionContext, model?: Api.IClassifyContact) {
		this.mUserSession = userSession;
		this.mSetModel(model);
	}

	@computed
	public get id() {
		return this.mContact ? this.mContact.id : null;
	}

	@computed
	public get contact() {
		return this.mContact;
	}

	@computed
	public get suggestedTags() {
		return this.mSuggestedTags;
	}

	@computed
	public get recentEmailSubjects() {
		return this.mRecentEmailSubjects;
	}

	@computed
	public get mostRecentInteraction() {
		return this.mMostRecentInteraction;
	}

	private mSetModel = (model: Api.IClassifyContact) => {
		if (model && model.contact) {
			this.mContact = new ContactViewModel(this.mUserSession, model.contact);
			this.mSuggestedTags = [...(model.suggestedTags || [])];
			this.mMostRecentInteraction = model.mostRecentInteraction
				? {
						interactionDate: model.mostRecentInteraction.interactionDate
							? new Date(model.mostRecentInteraction.interactionDate)
							: null,
						interactionType: model.mostRecentInteraction.interactionType,
					}
				: null;
			this.mRecentEmailSubjects = model.recentEmailSubjects ?? [];
		} else {
			this.mSuggestedTags = [];
			this.mContact = null;
			this.mMostRecentInteraction = null;
			this.mRecentEmailSubjects = [];
		}
	};
}

export class EmailActivityViewModel {
	@observable.ref private mContact: ContactViewModel;
	@observable.ref private mOpenDate: Date;
	@observable.ref private mRepliedDate: Date;
	@observable.ref private mSentContent: NoteViewModel;
	@observable.ref private mSentDate: Date;
	@observable.ref private mEmailActivity: Api.IEmailActivity;
	private mUserSession: UserSessionContext;

	constructor(userSession: UserSessionContext, emailActivity?: Api.IEmailActivity) {
		this.mUserSession = userSession;
		this.mSetModel(emailActivity);
	}

	@computed
	public get id() {
		return this.mEmailActivity ? this.mEmailActivity.id : null;
	}

	@computed
	public get contact() {
		return this.mContact;
	}

	@computed
	public get openDate() {
		return this.mOpenDate;
	}

	@computed
	public get repliedDate() {
		return this.mRepliedDate;
	}

	@computed
	public get sentContent() {
		return this.mSentContent;
	}

	@computed
	public get sentDate() {
		return this.mSentDate;
	}

	@computed
	public get status() {
		return this.mEmailActivity ? this.mEmailActivity.status : null;
	}

	private mSetModel = (emailActivity?: Api.IEmailActivity) => {
		this.mEmailActivity = emailActivity;
		if (this.mEmailActivity) {
			this.mContact = emailActivity.contact ? new ContactViewModel(this.mUserSession, emailActivity.contact) : null;
			this.mSentContent = emailActivity.sentContent
				? new NoteViewModel(this.mUserSession, emailActivity.sentContent)
				: null;
			this.mOpenDate = emailActivity.openDate ? new Date(emailActivity.openDate) : null;
			this.mRepliedDate = emailActivity.repliedDate ? new Date(emailActivity.repliedDate) : null;
			this.mSentDate = emailActivity.sentDate ? new Date(emailActivity.sentDate) : null;
		} else {
			this.mContact = null;
			this.mSentContent = null;
			this.mOpenDate = null;
			this.mRepliedDate = null;
			this.mSentDate = null;
		}
	};
}

export type DashboardFeedItemViewModel =
	| ActionItemViewModel
	| RecentMeetingViewModel
	| NoteViewModel
	| ClassifyContactViewModel
	| EmailActivityViewModel
	| UpcomingKeyFactViewModel
	| UpcomingAcknowledgeableKeyFactsViewModel
	| CampaignViewModel;

const composeDashboardFeedCampaignReportRequest = (userId: string): Api.ICampaignReportRequest => {
	return {
		filter: {
			criteria: [
				{
					property: Api.BulkEmailFilterProperty.Status,
					value: Api.EmailSendStatus.WaitingForApproval,
				},
				{
					property: Api.BulkEmailFilterProperty.User,
					value: userId,
				},
			],
			op: Api.FilterOperator.And,
		},
	};
};

export class DashboardFeedViewModel extends ViewModel {
	@observable private mSuppressing: boolean;
	private mFeedPageCollectionController: ObservablePageCollectionController<
		Api.IDashboardFeedItem,
		DashboardFeedItemViewModel
	>;
	private mAcknowledgeableKeyDatesPageCollectionController: ObservablePageCollectionController<
		Api.IDashboardFeedItem,
		DashboardFeedItemViewModel
	>;
	private mCampaignApprovalRequestsPagedCollectionController: FilteredPageCollectionController<
		Api.ICampaign,
		CampaignViewModel,
		Api.ICampaignReportRequest
	>;

	constructor(userSession: UserSessionContext) {
		super(userSession);
		this.mCampaignApprovalRequestsPagedCollectionController = new FilteredPageCollectionController<
			Api.ICampaign,
			CampaignViewModel,
			Api.ICampaignReportRequest
		>({
			apiPath: 'reports/campaign',
			client: this.mUserSession.webServiceHelper,
			transformer: this.mCampaignTransformer,
		});
		this.mFeedPageCollectionController = new ObservablePageCollectionController<
			Api.IDashboardFeedItem,
			DashboardFeedItemViewModel
		>({
			apiPath: 'dashboard/feed',
			client: userSession.webServiceHelper,
			transformer: this.mCreateFeedItemViewModel,
		});
		this.mAcknowledgeableKeyDatesPageCollectionController = new ObservablePageCollectionController<
			Api.IDashboardFeedItem,
			DashboardFeedItemViewModel
		>({
			apiPath: 'dashboard/feed/acknowledgeableKeyDates',
			client: userSession.webServiceHelper,
			transformer: this.mCreateAcknowledgeableKeyDateViewModel,
		});
	}

	@computed
	public get isBusy() {
		return (
			this.busy ||
			this.loading ||
			this.mFeedPageCollectionController.isFetching ||
			this.mSuppressing ||
			this.mCampaignApprovalRequestsPagedCollectionController.isFetching ||
			this.mAcknowledgeableKeyDatesPageCollectionController.isFetching
		);
	}

	@computed
	public get isSuppressing() {
		return this.mSuppressing;
	}

	@computed
	public get items() {
		return this.mFeedPageCollectionController.fetchResults;
	}

	@action
	public reset = () => {
		this.mFeedPageCollectionController.reset();
	};

	@action
	public suppress = (feedItem: DashboardFeedItemViewModel, suppressType: 'snooze' | 'dismiss' = 'dismiss') => {
		let context: Api.DashboardSuppressContext = null;
		if (feedItem instanceof ActionItemViewModel) {
			context = feedItem.isSuggestedKeepInTouchActionItem
				? Api.DashboardSuppressContext.SuggestedContact
				: Api.DashboardSuppressContext.ActionItem;
		} else if (feedItem instanceof RecentMeetingViewModel) {
			context = Api.DashboardSuppressContext.RecentMeeting;
		} else if (feedItem instanceof NoteViewModel) {
			context = Api.DashboardSuppressContext.Note;
		} else if (feedItem instanceof ClassifyContactViewModel) {
			context = Api.DashboardSuppressContext.ClassifyContact;
		} else if (feedItem instanceof EmailActivityViewModel) {
			context = Api.DashboardSuppressContext.EmailActivity;
		} else if (feedItem instanceof UpcomingKeyFactViewModel) {
			context = Api.DashboardSuppressContext.UpcomingKeyFact;
		} else if (feedItem instanceof UpcomingAcknowledgeableKeyFactsViewModel) {
			context =
				suppressType === 'snooze'
					? Api.DashboardSuppressContext.UpcomingRenewalSnooze
					: Api.DashboardSuppressContext.UpcomingAcknowledgeableKeyDates;
		} else if (feedItem instanceof CampaignViewModel) {
			context = Api.DashboardSuppressContext.RecentBulkEmails;
		}

		if (context && feedItem && !this.isBusy) {
			this.mSuppressing = true;
			const promise = new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
				const suppress: Api.IDashboardSuppress = {
					context,
					id: feedItem.id,
				};

				const onFinish = (result: Api.IOperationResultNoValue) => {
					runInAction(() => {
						this.mSuppressing = false;
						if (result.success) {
							// remove it from the fetch results
							this.mFeedPageCollectionController.fetchResults.removeItems([feedItem]);
							this.mAcknowledgeableKeyDatesPageCollectionController.fetchResults.removeItems([feedItem]);
							resolve(result);
						} else {
							reject(result);
						}
					});
				};

				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
					'dashboard/suppress',
					'POST',
					suppress,
					onFinish,
					onFinish
				);
			});
			return promise;
		}

		return null;
	};

	public getItems = (sortDescriptor?: Api.IPagedResultFetchContext, pageSize?: number, params?: Api.IDictionary) => {
		return this.mFeedPageCollectionController.getNext(sortDescriptor, pageSize, params);
	};

	public getCampaignsWithApprovalRequests = () => {
		return this.mCampaignApprovalRequestsPagedCollectionController.getNext(
			composeDashboardFeedCampaignReportRequest(this.mUserSession.user.id)
		);
	};

	@action
	public resetCampaignsWithApprovalRequests = () => {
		this.mCampaignApprovalRequestsPagedCollectionController.reset();
	};

	public get campaignsWithApprovalRequests() {
		return this.mCampaignApprovalRequestsPagedCollectionController.fetchResults;
	}

	@computed
	public get isFetchingCampaignsWithApprovalRequests() {
		return this.mCampaignApprovalRequestsPagedCollectionController.isFetching;
	}

	public getAcknowledgeableKeyDateItems = (
		sortDescriptor?: Api.IPagedResultFetchContext,
		pageSize?: number,
		params?: Api.IDictionary
	) => {
		return this.mAcknowledgeableKeyDatesPageCollectionController.getNext(sortDescriptor, pageSize, params);
	};

	@action
	public resetAcknowledgeableKeyDateItems = () => {
		this.mAcknowledgeableKeyDatesPageCollectionController.reset();
	};

	@computed
	public get acknowledgeableKeyDateitems() {
		return this.mAcknowledgeableKeyDatesPageCollectionController.fetchResults;
	}

	private mCreateFeedItemViewModel = (feedItemModel: Api.IDashboardFeedItem) => {
		switch (feedItemModel.model._type) {
			case 'Meeting': {
				return new RecentMeetingViewModel(this.mUserSession, feedItemModel.model as Api.IMeeting);
			}
			case 'ActionItem': {
				return new ActionItemViewModel(this.mUserSession, feedItemModel.model as Api.IActionItem);
			}
			case 'Note': {
				return new NoteViewModel(this.mUserSession, feedItemModel.model as Api.INote);
			}
			case 'ClassifyContact': {
				return new ClassifyContactViewModel(this.mUserSession, feedItemModel.model as Api.IClassifyContact);
			}
			case 'EmailActivity': {
				return new EmailActivityViewModel(this.mUserSession, feedItemModel.model as Api.IEmailActivity);
			}
			case 'UpcomingKeyFact': {
				return new UpcomingKeyFactViewModel(this.mUserSession, feedItemModel.model as Api.IUpcomingKeyFact);
			}
			case 'Campaign': {
				return new CampaignViewModel(this.mUserSession, feedItemModel.model as Api.ICampaign);
			}
			default: {
				return null;
			}
		}
	};

	private mCreateAcknowledgeableKeyDateViewModel = (feedItemModel: Api.IDashboardFeedItem) => {
		switch (feedItemModel.model._type) {
			case 'UpcomingBirthdays': {
				return new UpcomingAcknowledgeableKeyFactsViewModel(
					this.mUserSession,
					feedItemModel.model as Api.IUpcomingBirthdays
				);
			}
			case 'UpcomingAnniversaries': {
				return new UpcomingAcknowledgeableKeyFactsViewModel(
					this.mUserSession,
					feedItemModel.model as Api.IUpcomingAnniversaries
				);
			}
			case 'UpcomingRenewals': {
				return new UpcomingAcknowledgeableKeyFactsViewModel(
					this.mUserSession,
					feedItemModel.model as Api.IUpcomingRenewals
				);
			}
			default: {
				return null;
			}
		}
	};

	protected mCampaignTransformer = (campaign: Api.ICampaign) => {
		return new CampaignViewModel(this.userSession, campaign);
	};
}

export interface IDashboardReachOutInfo {
	contactsWithKeepInTouchesDue: ContactViewModel[];
	tagAlerts: TagAlertViewModel[];
	totalCount: number;
}

export class KeepInTouchReferenceViewModel extends ViewModel {
	@observable private mFrequency: number;
	@observable private mId: string;
	@observable.ref private mContact: ContactViewModel;
	@observable.ref private keepInTouchReference: Api.IKeepInTouchReference;

	constructor(userSession: UserSessionContext, keepInTouchReference: Api.IKeepInTouchReference) {
		super(userSession);
		this.setKeepInTouchReference(keepInTouchReference);
	}

	@computed
	public get contact() {
		return this.mContact;
	}

	@computed
	public get id() {
		return this.mId;
	}

	@computed
	public get frequency() {
		return this.mFrequency;
	}

	public toJs = () => {
		return this.keepInTouchReference;
	};

	@action
	private setKeepInTouchReference = (keepInTouchReference: Api.IKeepInTouchReference) => {
		if (keepInTouchReference) {
			this.mContact = keepInTouchReference.contact
				? new ContactViewModel(this.mUserSession, keepInTouchReference.contact)
				: null;
			this.mId = keepInTouchReference.id;
			this.mFrequency = keepInTouchReference.frequency || -1;
		} else {
			this.mId = null;
			this.mContact = null;
			this.mFrequency = -1;
		}
		this.keepInTouchReference = keepInTouchReference;
	};
}

/** Base email view model for all things email Can be used for an email that needs to be customized per recipient */
export class EmailMessageViewModel<
		TAttachmentType extends FileWithExtensionsType = FileWithExtensionsType,
		TSendResult = Api.ISendEmailResponse,
		TFollowUpOptions extends Api.IFollowUpOptions = Api.IEmailMessageFollowUpOptions,
	>
	extends ViewModel
	implements Api.IEmailMessage<TFollowUpOptions>
{
	@observable
	public aiReference: Api.IAIReference;
	@observable
	public contactsFilterRequest: Api.IEmailMessageContactsFilterRequest;
	@observable.ref public templateReference: Api.ITemplateReference;
	@observable.ref
	protected mContactIdToCustomComposeContentMap: Api.IDictionary<Api.IEmailMessageComposeContact>;
	@observable.ref
	protected mPreferredEmailAddressesMap: Api.IDictionary<Api.EmailAddress>;
	@observable.ref public bcc: Api.IRecipient[];
	@observable.ref public cc: Api.IRecipient[];
	protected mContactsToAdd: ObservableCollection<ContactViewModel>;
	protected mContactsToOmit: ObservableCollection<ContactViewModel>;
	@observable protected mSending: boolean;
	@observable public content: Api.IRawRichTextContentState;
	@observable public options: Api.IEmailMessageComposeOptions<TFollowUpOptions>;
	@observable public signatureTemplate: Api.ITemplate;
	@observable public subject: string;
	@observable public citation: string;
	@observable.ref protected mContext: Api.IEmailMessageComposeContext;
	@observable.ref
	protected mNewAttachments: AttachmentsToBeUploadedViewModel<TAttachmentType>;
	@observable.ref protected mSavedAttachments: Api.IFileAttachment[];
	@observable.ref
	private mContactEmailApproximation?: Api.ContactFilterApproximation | null = null;
	@observable.ref private mApproximationPromise?: Promise<Api.ContactFilterApproximation> | null = null;
	@observable public sendEmailRoute = 'email';
	/** Set to true if user sends or schedules the email and the request was successfully accepted by the backend. */
	@observable public didScheduleOrSendSuccessFully: boolean;
	protected mSetDefaultKeepInTouchFrequency: boolean;
	public static MaxByteCount = 6 * 1024 * 1024; // max allowed by the platform
	@observable protected mGroupByHousehold: boolean;

	@observable public groupId: string | undefined = '';

	public static instanceForCampaign = <
		STAttachmentType extends FileWithExtensionsType = FileWithExtensionsType,
		STSendResult = any,
	>(
		userSession: UserSessionContext,
		campaign: CampaignViewModel,
		setDefaultKeepInTouchFrequency?: boolean
	) => {
		const email = new EmailMessageViewModel<STAttachmentType, STSendResult>(
			userSession,
			setDefaultKeepInTouchFrequency
		);
		email.content = campaign.defaultMessageContent;
		email.subject = campaign.subject;
		email.contactsFilterRequest = campaign.filterRequest
			? {
					contactFilterRequest: campaign.filterRequest,
				}
			: null;
		email.templateReference = campaign.templateReference;

		if (campaign.schedule) {
			email.options.scheduledSend = {
				...email.options.scheduledSend,
				...campaign.schedule,
			};
		}
		return email;
	};

	constructor(
		userSession: UserSessionContext,
		setDefaultKeepInTouchFrequency?: boolean,
		emailDraft?: Api.IEmailMessageDraft
	) {
		super(userSession);
		this.mReset();
		this.mSetDefaultKeepInTouchFrequency = setDefaultKeepInTouchFrequency;
		if (emailDraft) {
			this.content = emailDraft.content;
			this.subject = emailDraft.subject;
			this.templateReference = emailDraft.templateReference;
			this.options = {
				...this.options,
				...(emailDraft.options || {}),
			} as any;
			this.signatureTemplate = emailDraft.signatureTemplateId ? { id: emailDraft.signatureTemplateId } : null;
			this.mSavedAttachments = emailDraft.attachments || [];
		}
	}

	@computed
	public get isSendingImmediately() {
		return !this.options.scheduledSend || this.options.scheduledSend.criteria === Api.ScheduleCriteria.Immediately;
	}

	@computed
	public get contactsToAdd() {
		return this.mContactsToAdd;
	}

	@computed
	public get contactsToOmit() {
		return this.mContactsToOmit;
	}

	@computed
	public get contactEmailApproximation() {
		return this.mContactEmailApproximation;
	}

	@computed
	public get isApproximating() {
		return Boolean(this.mApproximationPromise);
	}

	@computed
	public get sendLimits() {
		return this.options.emailSendLimits;
	}

	@computed
	public get hasUserSelectedContactFilterSearchCriteria() {
		const sortedCriteria = VmUtils.sortContactFilterCriteria(
			this.contactsFilterRequest?.contactFilterRequest?.criteria
		);
		const findHandler = (x: Api.IContactFilterCriteria) => (y: Api.IContactFilterCriteria) =>
			y.op === x.op && y.property === x.property && y.value === x.value;
		const searches = sortedCriteria.searches.filter(
			x => !Api.DefaultBulkSendExcludedFilterCriteria.find(findHandler(x))
		);
		if (searches.length > 0) {
			return true;
		}

		return false;
	}

	/** @param minimumDays Pass null here to clear out minimumDurationInDays */
	@action
	public setMinimumDurationInDays = (minimumDurationInDays?: number) => {
		this.options = {
			...this.options,
			minimumDurationInDays,
		};
	};

	@action
	public setScheduleExpirationDate = (newValue: Date | null) => {
		this.options.scheduledSend = {
			...this.options.scheduledSend,
			expirationDate: newValue?.toISOString() ?? null,
		};
	};

	@action
	public setContactsToOmit = (contacts: ObservableCollection<ContactViewModel>) => {
		this.mContactsToOmit = contacts;
	};

	@action
	public setContentWithTemplate = (template: Api.ITemplate, includeTemplateAttachments = true) => {
		this.templateReference = template
			? {
					isCustomized: false,
					isSystemTemplate:
						template.scope === Api.TemplateScope.Industry || template.scope === Api.TemplateScope.System,
					templateId: template.id,
					name: template.name,
				}
			: null;
		this.subject = template?.subject;
		this.content = template?.content ? { ...template.content } : null;
		this.citation = template?.citation;
		if (includeTemplateAttachments) {
			this.setSavedAttachments(template?.attachments);
		}
	};

	/** @param emailAddress Pass null here to remove previously set override for preferred email */
	@action
	public setPreferredEmailAddressForContact = (
		contact: Api.IContact | ContactViewModel,
		emailAddress?: Api.EmailAddress
	) => {
		if (contact && contact.id && emailAddress) {
			const map: Api.IDictionary<Api.EmailAddress> = {
				...this.mPreferredEmailAddressesMap,
			};
			if (!emailAddress) {
				delete map[contact.id];
			} else {
				map[contact.id] = emailAddress;
			}
			this.mPreferredEmailAddressesMap = map;
		}
	};

	public setEmailApproximation = (approximation?: Api.ContactFilterApproximation) => {
		this.mContactEmailApproximation = approximation;
		this.mApproximationPromise = undefined;
	};

	public get bulkContactsRequest() {
		return {
			excludeContactIds: (this.contactsToOmit?.map(x => x.id).filter(Boolean) ?? []) as string[],
			filter: this.contactsFilterRequest?.contactFilterRequest ?? undefined,
			groupByHousehold: this.groupByHousehold,
			includeContactIds: (this.contactsToAdd?.map(x => x.id).filter(Boolean) ?? []) as string[],
			groupByDuplicate: this.contactsFilterRequest?.groupByDuplicate,
			groupByHouseholdFilter: this.contactsFilterRequest?.groupByHouseholdFilter,
			ownershipFilter: this.contactsFilterRequest?.ownershipFilter,
		} as Api.IBulkContactsRequest;
	}

	/**
	 * @param alternateFilter an explicit filter that could differ from one composed by default
	 * @param forceReload reset/disregard an in-flight approximation promise and force a new approximation
	 * @returns Promise<Api.IContactFilterApproximation>
	 */
	@action
	public getEmailApproximation = (alternateFilter?: Api.IBulkContactsRequest, forceReload = false) => {
		if (this.isApproximating && !forceReload) {
			return;
		}

		if (!this.hasUserSelectedContactFilterSearchCriteria && !alternateFilter) {
			const count = this.contactsToAdd?.length || 0;
			this.mContactEmailApproximation = {
				hasEmail: count,
				total: count,
			};
			return;
		}

		const filterRequest = alternateFilter ?? this.bulkContactsRequest;

		const sendType =
			this.content?.sourceFormat === Api.ContentSourceFormat.UnlayerHtmlNewsletter
				? Api.ContactEmailApproximation.Html
				: Api.ContactEmailApproximation.GroupSend;

		const promise = this.mUserSession.webServiceHelper.callAsync<Api.ContactFilterApproximation>(
			this.composeApiUrl({ urlPath: `contact/filter/approximate/${sendType}` }),
			'POST',
			filterRequest
		);
		this.mApproximationPromise = promise as Promise<Api.ContactFilterApproximation>;

		promise
			.then(approximation => {
				if (promise === this.mApproximationPromise) {
					this.mContactEmailApproximation = approximation;
				}
			})
			.finally(() => {
				if (promise === this.mApproximationPromise) {
					this.mApproximationPromise = undefined;
				}
			});

		return promise;
	};

	public getPreferredEmailAddressForContact = (contact: Api.IContact | ContactViewModel) => {
		const emailAddress = contact && contact.id ? this.mPreferredEmailAddressesMap[contact.id] : null;
		if (emailAddress) {
			if (contact.emailAddresses && contact.emailAddresses.find(x => equal(x, emailAddress))) {
				return emailAddress;
			} else {
				delete this.mPreferredEmailAddressesMap[contact.id];
			}
		}

		if (contact) {
			return contact.primaryEmail || (contact.emailAddresses || [])[0] || null;
		}

		return null;
	};

	/** @param customMessage Pass null to remove a custom message from the collection */
	@action
	public setCustomMessageForContact = (
		contact: ContactViewModel,
		customMessage?: Pick<Api.IEmailMessageComposeContact, Exclude<keyof Api.IEmailMessageComposeContact, 'contactId'>>
	) => {
		if (contact && contact.id) {
			const contactIdToCustomComposeContentMap = {
				...this.mContactIdToCustomComposeContentMap,
			};
			if (customMessage) {
				contactIdToCustomComposeContentMap[contact.id] = {
					...customMessage,
					contactId: contact.id,
				};
				// always add them to this explicit list when creating
				this.contactsToAdd.add(contact);
			} else {
				delete contactIdToCustomComposeContentMap[contact.id];
			}
			this.mContactIdToCustomComposeContentMap = contactIdToCustomComposeContentMap;
		}
	};

	public getCustomMessageForContact = (contact: ContactViewModel) => {
		if (contact && contact.id) {
			return this.mContactIdToCustomComposeContentMap[contact.id];
		}
		return null;
	};

	public getAllRecipientSpecificAttachments = () => {
		return Object.values(this.mContactIdToCustomComposeContentMap).flatMap(contact => contact.attachments || []);
	};

	public deleteSavedEmailAttachmentAsync = async (savedAttachment: Api.IFileAttachment) => {
		// wait for inprogress to finish
		const asInProgressFileAttachment = savedAttachment as Api.IInProgressFileAttachment;
		if (asInProgressFileAttachment.uploadPromise) {
			await asInProgressFileAttachment.uploadPromise;
		}

		const recipientMessagesMap = this.mContactIdToCustomComposeContentMap || {};
		const recipientSpecificEmailMessageWithAttachment = Object.keys(recipientMessagesMap).reduce((res, curr) => {
			if (!res) {
				const message = recipientMessagesMap[curr];
				if (message.attachments?.find(x => x.id === savedAttachment.id)) {
					return message;
				}
			}
			return res;
		}, null as Api.IEmailMessageComposeContact);

		if (recipientSpecificEmailMessageWithAttachment) {
			const attachmentVM = new AttachmentsViewModel(this.mUserSession);
			await attachmentVM.delete(savedAttachment);
			recipientSpecificEmailMessageWithAttachment.attachments =
				recipientSpecificEmailMessageWithAttachment.attachments.filter(x => x.id !== savedAttachment.id);
			this.mContactIdToCustomComposeContentMap[recipientSpecificEmailMessageWithAttachment.contactId] = {
				...recipientSpecificEmailMessageWithAttachment,
			};
		} else {
			const currentAttachments = this.savedAttachments;
			const filteredAttachments = currentAttachments?.filter(x => x.id !== savedAttachment.id);
			this.setSavedAttachments(filteredAttachments);
		}
	};

	public toJs = () => {
		const options = toJS(this.options);
		options.sendEmailFromUser = options.sendEmailFromUser
			? Api.selectKeysOf(options.sendEmailFromUser, ['id', 'email', 'primaryEmail', 'firstName', 'lastName'])
			: null;

		if (
			!options.followUpIfNoResponseInDays ||
			isNaN(options.followUpIfNoResponseInDays) ||
			options.followUpIfNoResponseInDays < 1
		) {
			delete options.followUpIfNoResponseInDays;
		}

		const emailMessageModel: Api.IEmailMessageDraft<TFollowUpOptions> = {
			attachments: this.mSavedAttachments,
			content: this.content,
			options,
			subject: this.subject,
			groupId: this.groupId,
		};
		const result: Api.IEmailMessageCompose<TFollowUpOptions> = {
			...emailMessageModel,
			aiReference: this.aiReference,
			contactIdsToOmit: this.contactsToOmit ? this.contactsToOmit.map(x => x.id) : [],
			contacts: [],
			contactsFilterRequest: !this.hasUserSelectedContactFilterSearchCriteria
				? { contactFilterRequest: { criteria: null }, groupByHousehold: this.groupByHousehold }
				: this.contactsFilterRequest
					? { ...this.contactsFilterRequest, groupByHousehold: this.groupByHousehold }
					: null,
			signatureTemplateId: this.signatureTemplate ? this.signatureTemplate.id : null,
			templateReference: this.templateReference || null,
		};
		VmUtils.removeEmptyKvpFromObject(result);

		const idsOfContactsWithCustomMessages = Object.keys(this.mContactIdToCustomComposeContentMap) || [];
		// apply explicit contacts... these get the default message, context, and options
		// note: we do not add contacts who have custom messages here. They will be handled below
		if (this.contactsToAdd.length > 0) {
			this.contactsToAdd.forEach(x => {
				if (idsOfContactsWithCustomMessages.indexOf(x.id) < 0 && result.contactIdsToOmit.indexOf(x.id) < 0) {
					const preferredEmailAddress = this.getPreferredEmailAddressForContact(x) || null;
					result.contacts.push({
						contactId: x.id,
						context: this.mContext,
						preferredEmailAddress:
							preferredEmailAddress && preferredEmailAddress.value ? preferredEmailAddress.value : null,
					});
				}
			});
		}

		// apply custom messages
		if (idsOfContactsWithCustomMessages && idsOfContactsWithCustomMessages.length > 0) {
			idsOfContactsWithCustomMessages.forEach(x => {
				const customMessage = this.mContactIdToCustomComposeContentMap[x];
				if (customMessage && result.contactIdsToOmit.indexOf(x) < 0) {
					const preferredEmailAddress = this.getPreferredEmailAddressForContact({ id: x }) || null;
					result.contacts.push({
						...customMessage,
						context: this.mContext,
						preferredEmailAddress:
							preferredEmailAddress && preferredEmailAddress.value ? preferredEmailAddress.value : null,
					});
				}
			});
		}

		// bcc/cc
		result.carbonCopy = {
			bcc: (this.bcc || []).filter(x => x.email).map(x => x.email),
			cc: (this.cc || []).filter(x => x.email).map(x => x.email),
		};

		return result;
	};

	@action
	public setSendEmailFromUser(user?: Api.IUser) {
		this.options.sendEmailFromUser = user ?? undefined;
		this.options.sendEmailFromUserId = user?.id ?? undefined;
	}

	public isSendFromOption = (option: Api.ISendEmailFrom) => {
		return this.options.sendEmailFrom === option;
	};

	@computed
	public get groupByHousehold() {
		return this.mGroupByHousehold;
	}

	public set groupByHousehold(value: boolean) {
		this.mGroupByHousehold = value;
	}

	@computed
	public get isBusy() {
		return this.busy || this.loading || this.mSending;
	}

	@computed
	public get context() {
		return this.mContext;
	}

	@action
	public setContext(context: Api.IEmailMessageComposeContext) {
		this.mSetContext(context);
	}

	@computed
	public get isSending() {
		return this.mSending;
	}

	@computed
	public get savedAttachments() {
		return this.mSavedAttachments;
	}

	@action
	public setSavedAttachments(attachments: Api.IFileAttachment[]) {
		this.mSavedAttachments = attachments;
	}

	@action
	public removeSavedAttachment(attachment: Api.IFileAttachment): Promise<void> {
		return new Promise(resolve => {
			const filteredAttachments = this.mSavedAttachments?.filter(x => x.id !== attachment.id);
			this.mSavedAttachments = filteredAttachments;
			resolve();
		});
	}

	@computed
	public get attachments() {
		return this.mNewAttachments;
	}

	@action
	public setAttachments = (attachments: AttachmentsToBeUploadedViewModel<TAttachmentType>) => {
		this.mNewAttachments = attachments;
	};

	@action
	public send = (suggestionId?: string) => {
		if (!this.mSending) {
			this.mSending = true;
			const promise = new Promise<Api.IOperationResult<TSendResult>>((resolve, reject) => {
				const onFinish = action((result: Api.IOperationResult<TSendResult>) => {
					this.mSending = false;
					if (result.success) {
						this.didScheduleOrSendSuccessFully = true;
						resolve(result);
					} else {
						reject(result);
					}
				});

				// pull out email and attachments into form data if attachments are present
				const formData: FormData = new FormData();
				formData.append('value', JSON.stringify(this.toJs()));
				if (this.mNewAttachments && this.mNewAttachments.count > 0) {
					this.mNewAttachments.attachments.forEach(x => formData.append('files', x));
				}

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResult<TSendResult>>(
					this.composeApiUrl({
						queryParams: {
							contentCalendarSuggestionId: suggestionId,
						},
						urlPath: this.sendEmailRoute,
					}),
					'POST',
					formData,
					// FOLLOWUP: Resolve
					// @ts-ignore
					onFinish,
					onFinish
				);
			});

			return promise;
		}
		return null;
	};

	@action
	public reset() {
		this.mReset();
	}

	public mGetEstimatedTotalByteCount = () => {
		let byteCount = new Blob([JSON.stringify(this.toJs())]).size;
		if (this.attachments) {
			byteCount = byteCount + this.attachments.byteCount;
		}

		return byteCount;
	};

	public sendTestEmail = async (options?: Api.ISendTestEmailOptions<ContactViewModel>) => {
		if (!this.busy) {
			this.busy = true;
			const draft: Api.IEmailMessageDraft = {
				content: toJS(this.content),
				options: {
					saveAsNote: false,
					scheduledSend: {
						criteria: Api.ScheduleCriteria.Immediately,
					},
				},
				signatureTemplateId: this.signatureTemplate?.id ?? 'default',
				subject: this.subject || 'Test email from Levitate',
			};
			const computedOptions: Api.ISendTestEmailOptions<ContactViewModel> = {
				includeAttachments: true,
				...(options || {}),
			};
			const testEmail = new EmailMessageViewModel<TAttachmentType>(
				this.mUserSession,
				false,
				computedOptions?.onCreateDraft ? computedOptions.onCreateDraft(draft) : draft
			);

			if (this.templateReference) {
				testEmail.templateReference = {
					isCustomized: false,
					isSystemTemplate: false,
					templateId: this.templateReference.templateId,
					name: this.templateReference.name,
				};
			}

			testEmail.impersonate(this.impersonationContext);
			const contactId =
				computedOptions?.to?.id || (this.mImpersonationContext?.user?.contactId ?? this.mUserSession.user.contactId);
			const emailProviderConfigurations =
				this.mImpersonationContext?.account?.emailProviderConfigurations ||
				this.mUserSession?.account?.emailProviderConfiguration;

			if (computedOptions?.includeAttachments) {
				testEmail.mSavedAttachments = this.mSavedAttachments?.length ? [...this.mSavedAttachments] : undefined;
				if (this.attachments?.count > 0) {
					testEmail.setAttachments(
						new AttachmentsToBeUploadedViewModel<TAttachmentType>(
							null,
							VmUtils.getMaxAttachmentByteCountForEmailConfigurations(emailProviderConfigurations)
						)
					);
					testEmail.attachments.add(this.attachments.attachments);
				}
			}

			try {
				const contact = new ContactViewModel(this.mUserSession, {
					id: contactId,
				}).impersonate(this.mImpersonationContext);
				testEmail.contactsToAdd.add(contact);
				const res = await testEmail.send();
				this.busy = false;
				return res;
			} catch (err) {
				this.busy = false;
				throw Api.asApiError(err);
			}
		}
	};

	protected mReset = () => {
		this.aiReference = null;
		this.bcc = [];
		this.cc = [];
		this.contactsFilterRequest = null;
		this.mContactIdToCustomComposeContentMap = {};
		this.mContactsToAdd = new ObservableCollection<ContactViewModel>(null, 'id');
		this.mContactsToOmit = new ObservableCollection<ContactViewModel>(null, 'id');
		this.mPreferredEmailAddressesMap = {};
		this.templateReference = null;
		this.options = {
			followUp: null,
			followUpIfNoResponseInDays: 0,
			keepInTouchFrequency: this.mGetDefaultKeepInTouchFrequency(),
			noteVisibility: VmUtils.getDefaultVisibility(this.mUserSession.user),
			saveAsNote: this.userSession?.account?.preferences?.saveEmailAsNoteDefault,
			scheduledSend: {
				criteria: Api.ScheduleCriteria.Immediately,
				endDate: null,
				startDate: null,
			},
			sendEmailFrom: Api.SendEmailFrom.CurrentUser,
			sendEmailFromUser: undefined,
			sendFromConnectionType: null,
		};
		this.subject = null;
		this.content = null;
		this.signatureTemplate = null;
		this.mSetContext({
			ref: null,
		});
		this.mGroupByHousehold = this.userSession?.account?.features?.households?.sendToHouseholdByDefault ?? false;
	};

	protected mGetDefaultKeepInTouchFrequency = () => {
		return this.mSetDefaultKeepInTouchFrequency ? 3 : null;
	};

	protected mSetContext = (context: Api.IEmailMessageComposeContext) => {
		this.mContext = context;
	};
}

export class DeactivateAccountViewModel {
	@observable public cancelReason: string;
	@observable public cancelConfirmed: boolean;
	@observable private busy: boolean;
	private userSession: UserSessionContext;

	constructor(userSession: UserSessionContext) {
		this.userSession = userSession;
		this.reset();
	}

	@computed
	public get canDeactivate() {
		return this.cancelConfirmed;
	}

	@computed
	public get isBusy() {
		return this.busy;
	}

	public toJs = (): Api.ICancelAccount => {
		return {
			reason: this.cancelReason,
		};
	};

	@action
	public deactivate() {
		if (!this.canDeactivate) {
			return null;
		}

		this.busy = true;
		return new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
			const onFinish = action((result: Api.IOperationResultNoValue) => {
				this.busy = false;
				if (result.success) {
					this.reset();
					resolve(result);
				} else {
					reject(result);
				}
			});

			this.userSession.webServiceHelper.callWebServiceWithOperationResults(
				'account/cancel',
				'POST',
				this.toJs(),
				onFinish,
				onFinish
			);
		});
	}

	@action
	private reset() {
		this.busy = false;
		this.cancelReason = null;
		this.cancelConfirmed = false;
	}
}

export type EditActionItemCompletion = (actionItem?: ActionItemViewModel, deleted?: boolean) => void;

export class PasswordValidationViewModel {
	@observable private mPasswordConfirmation: string;
	@observable private mPassword: string;

	constructor(password?: string, confirmPassword?: string) {
		this.mPassword = password;
		this.mPasswordConfirmation = confirmPassword;
	}

	@computed
	public get password() {
		return this.mPassword;
	}

	@action
	public setPassword(password: string) {
		this.mPassword = password;
	}

	@computed
	public get passwordConfirmation() {
		return this.mPasswordConfirmation;
	}

	@action
	public setPasswordConfirmation(value: string) {
		this.mPasswordConfirmation = value;
	}

	@computed
	public get hasValidCharCount() {
		return this.mPassword && this.mPassword.length >= 8;
	}

	@computed
	public get hasUpperChar() {
		return this.mPassword && /^(?=.*[A-Z]).*$/.test(this.mPassword);
	}

	@computed
	public get hasLowerChar() {
		return this.mPassword && /^(?=.*[a-z]).*$/.test(this.mPassword);
	}

	@computed
	public get hasNumber() {
		return this.mPassword && /^(?=.*[0-9]).*$/.test(this.mPassword);
	}

	@computed
	public get matchesConfirmPassword() {
		return this.mPassword && this.mPasswordConfirmation && this.mPasswordConfirmation === this.mPassword;
	}
}

export class PasswordResetWithTokenViewModel extends PasswordValidationViewModel {
	@observable.ref busy = false;
	@observable.ref mUserSession: UserSessionContext;
	constructor(userSession: UserSessionContext, password?: string, confirmPassword?: string) {
		super(password, confirmPassword);
		this.mUserSession = userSession;
	}

	@action
	public resetPassword(newPassword: string, token: string) {
		this.busy = true;
		return new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
			const onFinish = (result: Api.IOperationResultNoValue) => {
				runInAction(() => {
					this.busy = false;
					if (result.success) {
						resolve(result);
					} else {
						reject(result);
					}
				});
			};
			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
				`user/resetPasswordWithToken?token=${token}`,
				'POST',
				{ newPassword },
				onFinish,
				onFinish
			);
		});
	}

	@computed
	public get isBusy() {
		return this.busy;
	}
}

export class SystemJobViewModel<TSystemJob extends Api.ISystemJob = Api.ISystemJob> extends ViewModel {
	@observable protected mSystemJob: TSystemJob;
	@observable.ref protected mPollingTimeoutHandle: any;
	protected mPollingPromise: Promise<Api.IOperationResult<TSystemJob>>;
	protected mUpdateCallback: (opResult: Api.IOperationResult<TSystemJob>) => void;

	constructor(userSession: UserSessionContext, systemJob?: TSystemJob) {
		super(userSession);
		this.setSystemJob = this.setSystemJob.bind(this);
		this.setSystemJob(systemJob);
	}

	@computed
	public get isWatching() {
		return !!this.mPollingTimeoutHandle;
	}

	@computed
	public get id() {
		return this.mSystemJob ? this.mSystemJob.id : null;
	}

	@computed
	public get percentComplete() {
		return this.mSystemJob.percentComplete;
	}

	@computed
	public get jobType() {
		return this.mSystemJob.jobType;
	}

	@computed
	public get status() {
		return this.mSystemJob.status;
	}

	@computed
	public get additionalFields() {
		return this.mSystemJob.additionalFields;
	}

	@computed
	public get additionalInfo() {
		return this.mSystemJob.additionalInfo;
	}

	@computed
	public get recordsFailed() {
		return this.mSystemJob.recordsFailed;
	}

	@computed
	public get recordsProcessed() {
		return this.mSystemJob.recordsProcessed;
	}

	@computed
	public get recordsSucceeded() {
		return this.mSystemJob.recordsSucceeded;
	}

	@computed
	public get totalRecords() {
		return this.mSystemJob.totalRecords;
	}

	@computed
	public get isBusy() {
		return this.busy || this.loading;
	}

	@computed
	public get isLoading() {
		return this.loading;
	}

	@action
	public load(): Promise<Api.IOperationResult<TSystemJob>> {
		if (this.isLoaded) {
			return Promise.resolve<Api.IOperationResult<TSystemJob>>({
				success: true,
				systemCode: 200,
				value: this.mSystemJob,
			});
		}

		return this.get();
	}

	@action
	public get = () => {
		if (!this.isBusy) {
			if (!this.isLoaded) {
				this.loading = true;
			}
			this.busy = true;
			return new Promise<Api.IOperationResult<TSystemJob>>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<TSystemJob>) => {
					runInAction(() => {
						this.loading = false;
						this.busy = false;
						if (result.success) {
							const systemJob = result.value;
							this.setSystemJob(systemJob);
							resolve(result);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<TSystemJob>(
					`systemjob/${this.mSystemJob.id}`,
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
		}

		return null;
	};

	/** @param continueWatching Called once for each successful/error update. Return true to continue polling for updates. */
	@action
	public startWatching = (
		continueWatching: (opResult: Api.IOperationResult<TSystemJob>) => boolean,
		interval = 2000
	) => {
		this.mStopWatching();
		if (continueWatching) {
			this.mUpdateCallback = continueWatching;
			const update = (firstRun = false) => {
				this.mPollingPromise = this.get();
				this.mPollingPromise
					.then(opResult => {
						if ((this.isWatching || firstRun) && continueWatching === this.mUpdateCallback && this.mUpdateCallback) {
							const reschedule = continueWatching(opResult);
							if (reschedule) {
								this.mPollingTimeoutHandle = setTimeout(() => {
									update();
								}, interval);
							} else {
								this.stopWatching();
							}
						}
					})
					.catch(continueWatching);
			};
			update(true);
		}
	};

	@action
	public stopWatching = () => {
		this.mStopWatching();
	};

	public toJs = () => {
		return this.mSystemJob;
	};

	private mStopWatching = () => {
		this.mPollingPromise = null;
		this.mUpdateCallback = null;
		if (this.mPollingTimeoutHandle) {
			clearTimeout(this.mPollingTimeoutHandle);
			this.mPollingTimeoutHandle = null;
		}
	};

	protected setSystemJob(systemJob: TSystemJob) {
		this.mSystemJob = systemJob;
		this.loaded = systemJob && systemJob.id && !!systemJob.dateCreatedUtc;
	}
}

export class AddUserViewModel {
	@observable private busy: boolean;
	@observable private mBasicCredential: Api.IBasicCredential;
	@observable private mEmailAddress: string;
	@observable private mPasswordConfirmation: string;
	@observable private mUser: Api.IUser;
	@observable private mIsTermsAcked: boolean;
	@observable public contactImportVisibility: Api.ResourceVisibility;
	@observable.ref private mContactImportSystemJob: SystemJobViewModel;
	@observable.ref private mOAuthLinkSystemJob: SystemJobViewModel;
	private mPasswordValidation: PasswordValidationViewModel;
	private mUserSession: UserSessionContext;

	constructor(
		userSession: UserSessionContext,
		userToCreate?: Api.IUser,
		contactImportVisibility?: Api.ResourceVisibility
	) {
		this.mUserSession = userSession;
		this.mUser = {
			accountId: null,
			firstName: null,
			id: null,
			lastName: null,
			mobilePhone: null,
			password: null,
			passwordRecoveryAnswer: null,
			passwordRecoveryQuestion: null,
			token: null,
			...(userToCreate || {}),
		};
		this.contactImportVisibility = contactImportVisibility || 'all';
		this.mPasswordValidation = new PasswordValidationViewModel(this.mUser.password, this.mPasswordConfirmation);
		this.mBasicCredential = {
			password: null,
			username: null,
		};
	}

	public get passwordValidation() {
		return this.mPasswordValidation;
	}

	public get userSession() {
		return this.mUserSession;
	}

	@computed
	public get isBusy() {
		return this.busy;
	}

	@computed
	public get isTermsAcked() {
		return this.mIsTermsAcked;
	}

	@computed
	public get emailProviderBasicCredential() {
		return this.mBasicCredential;
	}

	@computed
	public get oauthLinkJob() {
		return this.mOAuthLinkSystemJob;
	}

	@computed
	public get user() {
		return this.mUser;
	}

	@computed
	public get contactImportJob() {
		return this.mContactImportSystemJob;
	}

	@action
	public setContactImportJob(systemJob: Api.ISystemJob) {
		this.mContactImportSystemJob = new SystemJobViewModel(this.mUserSession, systemJob);
	}

	@computed
	public get firstName() {
		return this.mUser.firstName;
	}

	@action
	public setFirstName(value: string) {
		this.mUser.firstName = value;
	}

	@computed
	public get emailAddress() {
		return this.mEmailAddress;
	}

	@action
	public setEmailAddress(value: string) {
		this.mEmailAddress = value;
	}

	@computed
	public get lastName() {
		return this.mUser.lastName;
	}

	@action
	public setLastName(value: string) {
		this.mUser.lastName = value;
	}

	@computed
	public get mobilePhone() {
		return this.mUser.mobilePhone;
	}

	@action
	public setMobilePhone(value: string) {
		this.mUser.mobilePhone = value;
	}

	@computed
	public get password() {
		return this.mUser.password;
	}

	@action
	public setPassword(value: string) {
		this.mUser.password = value;
		this.mPasswordValidation.setPassword(value);
	}

	@computed
	public get passwordConfirmation() {
		return this.mPasswordConfirmation;
	}

	@action
	public setPasswordConfirmation(value: string) {
		this.mPasswordConfirmation = value;
		this.mPasswordValidation.setPasswordConfirmation(value);
	}

	@computed
	public get passwordRecoveryAnswer() {
		return this.mUser.passwordRecoveryAnswer;
	}

	@action
	public setPasswordRecoveryAnswer(value: string) {
		this.mUser.passwordRecoveryAnswer = value;
	}

	@computed
	public get passwordRecoveryQuestion() {
		return this.mUser.passwordRecoveryQuestion;
	}

	@action
	public setPasswordRecoveryQuestion(value: string) {
		this.mUser.passwordRecoveryQuestion = value;
	}

	@computed
	public get passwordRecoveryIsValid() {
		return (
			this.user &&
			this.mUser.passwordRecoveryAnswer &&
			this.mUser.passwordRecoveryQuestion &&
			this.mUser.passwordRecoveryQuestion.length >= 4 &&
			this.mUser.passwordRecoveryQuestion !== this.mUser.passwordRecoveryAnswer
		);
	}

	@action
	public isTermsAcknowledged = () => {
		return new Promise<Api.IOperationResult<boolean>>((resolve, reject) => {
			const onFinish = (result: Api.IOperationResult<boolean>) => {
				runInAction(() => {
					if (result.success) {
						this.mIsTermsAcked = result.value;
						resolve(result);
					} else {
						reject(result);
					}
				});
			};
			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<boolean>(
				`termsOfService/${this.mUser.accountId}/acknowledged`,
				'GET',
				null,
				onFinish,
				onFinish
			);
		});
	};

	@action
	public acknowledgeTerms = () => {
		return new Promise<Api.IOperationResult<Api.ITermsOfServiceAck>>((resolve, reject) => {
			const onFinish = (result: Api.IOperationResult<Api.ITermsOfServiceAck>) => {
				runInAction(() => {
					if (result.success) {
						this.mIsTermsAcked = result.value.accountId ? true : false;
						resolve(result);
					} else {
						reject(result);
					}
				});
			};
			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ITermsOfServiceAck>(
				`termsOfService`,
				'POST',
				null,
				onFinish,
				onFinish
			);
		});
	};

	@action
	public createOAuthLinkJob = () => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.ISystemJob>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<Api.ISystemJob>) => {
					runInAction(() => {
						this.busy = false;
						if (result.success) {
							const systemJob = result.value;
							this.mOAuthLinkSystemJob = new SystemJobViewModel(this.mUserSession, systemJob);
							resolve(systemJob);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ISystemJob>(
					'oauthlink/authUrl',
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
		}

		return null;
	};

	@action
	public setEmailProviderBasicCredential = (credential: Api.IBasicCredential) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<Api.IOperationResultNoValue>) => {
					runInAction(() => {
						this.busy = false;
						if (result.success) {
							this.mUserSession.user.emailCalendarTokenExists = true;
							resolve(undefined);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
					`user/${this.mUserSession.user.id}/configureBasicCredentials`,
					'POST',
					credential,
					onFinish,
					onFinish
				);
			});
		}

		return null;
	};

	@action
	public createContactImportJob = () => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.ISystemJob>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<Api.ISystemJob>) => {
					runInAction(() => {
						this.busy = false;
						if (result.success) {
							const systemJob = result.value;
							this.mContactImportSystemJob = new SystemJobViewModel(this.mUserSession, systemJob);
							resolve(systemJob);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ISystemJob>(
					`contact/import?visibility=${this.contactImportVisibility}`,
					'POST',
					{},
					onFinish,
					onFinish
				);
			});
		}

		return null;
	};

	@action
	public verifyWelcomeToken = (verifyToken: Api.IVerifyToken) => {
		return new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
			const onFinish = (result: Api.IOperationResult<Api.IOperationResultNoValue>) => {
				if (result.success) {
					resolve(result);
				} else {
					reject(result);
				}
			};
			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
				'user/verifyToken',
				'POST',
				verifyToken,
				onFinish,
				onFinish
			);
		});
	};

	public autoLoginAfterCreatingUser = (createdUser: Api.IUser, password: string, attempts = 1) =>
		new Promise((resolve, reject) => {
			this.mUserSession
				.updateWithCredential({
					email: createdUser.primaryEmail.value,
					password,
				})
				.then(() => resolve(null))
				.catch((err: Api.IOperationResultNoValue) => {
					if (attempts < 2) {
						setTimeout(() => resolve(this.autoLoginAfterCreatingUser(createdUser, password, attempts + 1)), 3000);
					} else {
						reject(err);
					}
				});
		});

	@action
	public createUser = (user = this.mUser, autoLogin = true) => {
		if (!this.isBusy) {
			this.busy = true;
			const password = user.password;
			const promise = new Promise<Api.IUser>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<Api.IUser>) => {
					runInAction(() => {
						if (result.success) {
							const createdUser = {
								...user,
								...result.value,
							};
							if (autoLogin) {
								this.autoLoginAfterCreatingUser(createdUser, password)
									.then(() => {
										this.busy = false;
										this.mUser = createdUser;
										resolve(this.mUser);
									})
									.catch((loginError: Api.IOperationResultNoValue) => {
										this.busy = false;
										reject(loginError);
									});
							} else {
								this.busy = false;
								this.mUser = createdUser;
								resolve(this.mUser);
							}

							this.contactImportVisibility =
								createdUser.userPreferences && createdUser.userPreferences.defaultGroup
									? createdUser.userPreferences.defaultGroup
									: this.contactImportVisibility;
						} else {
							this.busy = false;
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IUser>(
					'user',
					'POST',
					user,
					onFinish,
					onFinish,
					null,
					false
				);
			});
			return promise;
		}

		return null;
	};

	@action
	public registerUserByEmail = (accountId: string, emailAddress: string) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IUser>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<Api.IUser>) => {
					runInAction(() => {
						this.busy = false;
						if (result.success) {
							resolve(result.value);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IUser>(
					'user/register',
					'POST',
					{ accountId, email: emailAddress },
					onFinish,
					onFinish
				);
			});
		}
	};
}

export class BaseResourceViewModel<
	TResource extends Api.IBaseResourceModel = Api.IBaseResourceModel,
> extends ViewModel {
	@observable protected mResource?: TResource;
	protected apiPathDictionary: Api.IDictionary<string>;
	protected apiBasePath: string;

	constructor(userSession: UserSessionContext, resource?: TResource) {
		super(userSession);
		this.apiPathDictionary = {};
		this.mSetResource = this.mSetResource.bind(this);
		this.mSetResource(resource);
	}

	@computed
	public get isBusy() {
		return this.busy || this.loading;
	}

	@computed
	public get resource() {
		return this.mResource;
	}

	@action
	public load() {
		if (!this.isBusy && this.mResource) {
			// note: 'as any' due to ts typing issues
			const promise = this.executeCrudRequest('GET', {
				id: this.mResource.id,
			} as any);
			if (promise) {
				this.loading = true;
				const onFinish = () => {
					runInAction(() => {
						this.loading = false;
					});
				};
				promise.then(onFinish).catch(onFinish);
			}
			return promise;
		}
		return null;
	}

	@action
	public save = (resource: TResource) => {
		if (resource) {
			this.busy = true;
			return this.executeCrudRequest(resource.id ? 'PUT' : 'POST', resource, (resolve, reject, opResult) => {
				runInAction(() => {
					this.busy = false;
					if (opResult.success) {
						this.mSetResource(opResult.value);
						resolve(opResult.value);
					} else {
						reject(opResult);
					}
				});
			});
		}
		return null;
	};

	@action
	public delete = () => {
		if (this.mResource && !this.isBusy) {
			this.busy = true;
			return this.executeCrudRequest('DELETE', this.mResource, (resolve, reject, opResult) => {
				runInAction(() => {
					this.busy = false;
					// note: we eat the 404 error here
					if (opResult.success || opResult.systemCode === 404) {
						resolve(opResult.value || this.mResource);
					} else {
						reject(opResult);
					}
				});
			});
		}
		return null;
	};

	@action
	public setResource = (resource: TResource) => {
		this.mSetResource(resource);
	};

	public toJs = () => {
		return this.mResource;
	};

	protected setApiPathForMethod(method: Api.HTTPMethod, path: string) {
		this.apiPathDictionary[method] = path;
	}

	protected mSetResource(resource?: TResource) {
		this.mResource = resource;
	}

	protected executeCrudRequest = (
		method: Api.HTTPMethod,
		resource?: TResource,
		onFinish?: (
			resolve: (value?: TResource | PromiseLike<TResource>) => void,
			reject: (error: Api.IOperationResultNoValue) => void,
			opResult: Api.IOperationResult<TResource>
		) => void
	) => {
		return new Promise<TResource>((resolve, reject) => {
			const onComplete = (opResult: Api.IOperationResult<TResource>) => {
				if (onFinish) {
					onFinish(resolve, reject, opResult);
				} else {
					runInAction(() => {
						// note: we eat the 404 error w.r.t delete ops.
						if (opResult.success || (method === 'DELETE' && opResult.systemCode === 404)) {
							const value = opResult.value || resource;
							this.mSetResource(value);
							resolve(value);
						} else {
							reject(opResult);
						}
					});
				}
			};
			const path = this.apiPathDictionary[method] || `${this.apiBasePath}${method === 'POST' ? '' : `/${resource.id}`}`;
			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<TResource>(
				path,
				method,
				method !== 'GET' ? resource : null,
				onComplete,
				onComplete
			);
		});
	};
}

export class BoardStageDefinitionViewModel implements Api.IBoardStage {
	@observable public enableStageIndicator: boolean;
	@observable public errorMessage: string;
	@observable public id: string;
	@observable public name: string;
	@observable.ref private mStage: BoardStageViewModel;
	protected mUuid: string;

	constructor(stage?: BoardStageViewModel) {
		if (stage) {
			this.id = stage.id;
			this.enableStageIndicator = stage.enableStageIndicator;
			this.name = stage.name;
			this.mStage = stage;
		}

		this.mUuid = uuidgen();
	}

	public get uuid() {
		return this.mUuid;
	}

	/** Get the actual column in an existing board, if it exists. */
	@computed
	public get stage() {
		return this.mStage;
	}

	public toJs = () => {
		const stage: Api.IBoardStageReference = {
			config: {
				enableStageIndicator: this.enableStageIndicator,
			},
			id: this.id,
			name: this.name,
			templateType: this.mStage ? this.mStage.templateType : null,
		};
		return stage;
	};
}

export class EditBoardViewModel {
	@observable private saving: boolean;
	@observable.ref private mMoveItemSourceIds: string[];
	@observable.ref private mName: string;
	@observable.ref private mStageDefinitions: BoardStageDefinitionViewModel[];
	private mId: string;
	private mUserSession: UserSessionContext;

	constructor(userSession: UserSessionContext, name?: string, stageDefinitions?: BoardStageDefinitionViewModel[]) {
		this.mUserSession = userSession;
		this.mStageDefinitions = stageDefinitions || [];
		this.mMoveItemSourceIds = [];
		this.mName = name || 'Opportunities';
		this.mId = uuidgen();
	}

	@computed
	public get isBusy() {
		return this.saving || this.isMovingStageItems;
	}

	@computed
	public get isMovingStageItems() {
		return this.mMoveItemSourceIds && this.mMoveItemSourceIds.length > 0;
	}

	@computed
	public get isSaving() {
		return this.saving;
	}

	public get id() {
		return this.mId;
	}

	@action
	public setName(newName: string) {
		this.mName = newName;
	}

	public get name() {
		return this.mName;
	}

	public get userSession() {
		return this.mUserSession;
	}

	@computed
	public get columns() {
		return this.mStageDefinitions;
	}

	@action
	public removeColumnAtIndex = (index: number) => {
		const columns = [...this.mStageDefinitions];
		columns.splice(index, 1);
		this.mStageDefinitions = columns;
	};

	/** @param index Default = 0. */
	@action
	public insertColumn = (stageDefinition: BoardStageDefinitionViewModel, index?: number) => {
		const stageDefinitions = [...this.mStageDefinitions];
		stageDefinitions.splice(index || 0, 0, stageDefinition);
		this.mStageDefinitions = stageDefinitions;
	};

	@action
	public moveColumn = (fromIndex: number, toIndex: number) => {
		const stageDefinitions = [...this.mStageDefinitions];
		const removed = stageDefinitions.splice(fromIndex, 1);
		stageDefinitions.splice(toIndex, 0, removed[0]);
		this.mStageDefinitions = stageDefinitions;
	};

	@action
	public moveStageItems = (fromStage: BoardStageViewModel, toStage: BoardStageViewModel) => {
		if (fromStage && fromStage.id && toStage && toStage.id) {
			const promise = new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResultNoValue) => {
					runInAction(() => {
						// remove the source id from the tracked collection... affects this.isMovingStageItems
						const moveItemSourceIds = [...(this.mMoveItemSourceIds || [])];
						moveItemSourceIds.splice(moveItemSourceIds.indexOf(fromStage.id), 1);
						this.mMoveItemSourceIds = moveItemSourceIds;

						if (opResult.success) {
							resolve(undefined);
						} else {
							reject(opResult);
						}
					});
				};

				const moveItemsRequest: Api.IMoveBoardItem = {
					stageId: toStage.id,
				};

				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
					`board/stage/${fromStage.id}/moveItems`,
					'POST',
					moveItemsRequest,
					onFinish,
					onFinish
				);
			});

			// add the source id to the tracked collection... affects this.isMovingStageItems
			this.mMoveItemSourceIds.push(fromStage.id);
			return promise;
		}

		return null;
	};
}
export class BoardItemViewModel<
	TItem extends Api.IBoardItem = Api.IBoardItem,
	TStage extends Api.IBoardStage = Api.IBoardStage,
> extends BaseResourceViewModel<TItem> {
	@observable.ref
	private mActivityCollectionController: ObservablePageCollectionControllerOld<
		Api.IBoardItemActivity,
		Api.IBoardItemActivity
	>;
	@observable.ref protected mItem: TItem;
	@observable.ref protected mLastModifiedDate: Date;
	@observable.ref protected mStage: BoardStageViewModel;
	@observable.ref private mPendingAutomationId: string;

	constructor(userSession: UserSessionContext, stage?: BoardStageViewModel, item?: TItem) {
		super(userSession);
		this.apiBasePath = 'board/item';
		// set the stage before the resource
		this.mStage = stage;
		this.mSetResource(item);
	}

	@action
	public setPendingAutomationId(automationTemplateId: string) {
		this.mPendingAutomationId = automationTemplateId;
	}

	@computed
	public get pendingAutomationId() {
		return this.mPendingAutomationId;
	}

	@computed
	public get lastModifiedDate() {
		return this.mItem.lastModifiedDate;
	}

	@computed
	public get lastModifiedDateAsDate() {
		return this.mLastModifiedDate;
	}

	@computed
	public get id() {
		return this.mItem.id;
	}

	@computed
	public get activityItems() {
		return this.mActivityCollectionController.fetchResults;
	}

	@computed
	public get fetchingActivityItems() {
		return this.mActivityCollectionController.fetching;
	}

	@computed
	public get position() {
		return this.mItem.position;
	}

	@action
	public setPosition(value: number) {
		this.mItem.position = value;
	}

	@computed
	public get stage() {
		return this.mStage;
	}

	@action
	public setStage(stage: BoardStageViewModel) {
		this.mStage = stage;
	}

	@action
	public reset = () => {
		if (this.mActivityCollectionController) {
			this.mActivityCollectionController.reset();
		}
	};

	@action
	public archive = () => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<Api.IOperationResultNoValue>) => {
					runInAction(() => {
						this.busy = false;
						if (result.success) {
							resolve(undefined);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
					`board/item/${this.mItem.id}/archive`,
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
		}

		return null;
	};

	public getActivity = (sortDescriptor?: Api.ISortDescriptor, pageSize?: number, params?: any) => {
		return this.mActivityCollectionController.getNext(sortDescriptor, pageSize, params);
	};

	protected mSetResource(item: TItem) {
		const previousItem = this.mItem;
		super.mSetResource(item);
		this.mItem = item;
		this.mLastModifiedDate = item && item.lastModifiedDate ? new Date(item.lastModifiedDate) : null;

		if (item) {
			if (!this.mStage && item.boardStage) {
				this.mStage = this.createStageViewModel(item.boardStage as TStage);
			}

			// only replace the activity page controller if the item actually changes (not on update)
			if (!previousItem || previousItem.id !== item.id) {
				this.mActivityCollectionController = new ObservablePageCollectionControllerOld<
					Api.IBoardItemActivity,
					Api.IBoardItemActivity
				>(this.userSession.webServiceHelper, `board/item/${item.id}/activity`);
			}
		}
	}

	public toJs = () => {
		return this.mItem;
	};

	protected createStageViewModel(stage: TStage): BoardStageViewModel {
		// FOLLOWUP: Resolve
		// @ts-ignore
		return new BoardStageViewModel(this.mUserSession, stage, null);
	}
}

export class BoardStageViewModel<
	TStage extends Api.IBoardStage = Api.IBoardStage,
	TItem extends Api.IBoardItem = Api.IBoardItem,
	TRollup extends Api.IBoardStageRollup = Api.IBoardStageRollup,
	TItemViewModel extends BoardItemViewModel<TItem> = BoardItemViewModel<TItem>,
	TSearchContext = any,
	TSearchController extends BaseObservablePageCollectionController<
		TItem,
		TItemViewModel,
		TSearchContext
	> = BaseObservablePageCollectionController<TItem, TItemViewModel, TSearchContext>,
> extends BaseResourceViewModel<TStage> {
	@observable.ref protected mboard: BoardViewModel;
	@observable.ref
	protected mItemsCollectionController: BaseObservablePageCollectionController<
		TItem,
		TItemViewModel,
		Api.ISortDescriptor
	>;
	@observable.ref protected mSearchController: TSearchController;
	@observable.ref protected mStage: TStage;

	constructor(userSession: UserSessionContext, stage: TStage, board?: BoardViewModel) {
		super(userSession);
		this.createItem = this.createItem.bind(this);
		this.createSearchController = this.createSearchController.bind(this);
		this.deleteItem = this.deleteItem.bind(this);
		this.moveItemToStage = this.moveItemToStage.bind(this);
		this.apiBasePath = 'board/stage';
		this.mboard = board;
		this.mSetResource(stage);
	}

	@computed
	public get isSearching() {
		return this.mSearchController && (this.mSearchController.isFetching || this.mSearchController.hasContext);
	}

	@computed
	public get isFetchingSearchResults() {
		return this.mSearchController && this.mSearchController.isFetching;
	}

	/** @returns This is only applies to stage items and not search results */
	@computed
	public get isFetchingItems() {
		return this.mItemsCollectionController && this.mItemsCollectionController.isFetching;
	}

	/** @returns Note: If searching, this will return this.searchController.fetchResults instead of stage items */
	@computed
	public get items() {
		return this.isSearching
			? this.mSearchController
				? this.mSearchController.fetchResults
				: null
			: this.mItemsCollectionController
				? this.mItemsCollectionController.fetchResults
				: null;
	}

	@computed
	public get config() {
		return this.mStage.config;
	}

	@computed
	public get isBusy() {
		return (
			this.busy ||
			this.loading ||
			(this.mItemsCollectionController && this.mItemsCollectionController.isFetching) ||
			(this.mSearchController && this.mSearchController.isFetching)
		);
	}

	@computed
	public get id() {
		return this.mStage.id;
	}

	@computed
	public get rollup(): TRollup {
		return this.mStage ? (this.mStage.rollup as TRollup) : ({} as TRollup);
	}

	@computed
	public get indexInBoard() {
		return this.mStage.boardIndex;
	}

	@computed
	public get boardStageCount() {
		return this.mStage.boardTotalStageCount;
	}

	@computed
	public get enableStageIndicator() {
		return this.mStage.config && this.mStage.config.enableStageIndicator;
	}

	@computed
	public get name() {
		return this.mStage.name;
	}

	@computed
	public get board() {
		return this.mboard;
	}

	@computed
	public get templateType() {
		return this.mStage.templateType || (this.mboard ? this.mboard.templateType : null);
	}

	/** Reposition an item within this board */
	@action
	public repositionItem = (item: TItemViewModel, toIndex: number): Promise<void> => {
		if (this.items) {
			const currentIndex = this.items.indexOf(item);
			if (!this.isBusy && !item.isBusy && currentIndex >= 0 && currentIndex !== toIndex) {
				this.busy = true;
				VmUtils.setViewModelBusy(item, true);
				const originalItemCollection = this.items.toArray();

				// reorder in UI layer first... don't block the UI
				const itemCollection = [...originalItemCollection];
				itemCollection.splice(currentIndex, 1);
				itemCollection.splice(toIndex, 0, item);
				this.items.setItems(itemCollection);
				item.setPosition(toIndex);

				const itemAtDestination = originalItemCollection[toIndex];

				return new Promise<void>((resolve, reject) => {
					const onFinish = (opResult: Api.IOperationResultNoValue) => {
						runInAction(() => {
							VmUtils.setViewModelBusy(item, false);
							this.busy = false;
							if (opResult.success) {
								resolve();
							} else {
								// restore original collection
								this.items.setItems(originalItemCollection);
								item.setPosition(currentIndex);
								reject(opResult);
							}
						});
					};

					// create the move request
					const moveBoardItem: Api.IMoveBoardItem = {
						fallbackIndex: toIndex,
						position: toIndex > currentIndex ? Api.MoveBoardItemPosition.After : Api.MoveBoardItemPosition.Before,
						relativeToItemId: itemAtDestination ? itemAtDestination.id : null,
						stageId: this.mStage.id,
					};

					this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
						`board/item/${item.id}/move`,
						'POST',
						moveBoardItem,
						onFinish,
						onFinish
					);
				});
			}
		}

		return null;
	};

	/** Move an item to another stage */
	@action
	public moveItemToStage(
		item: TItemViewModel,
		toStage: BoardStageViewModel<TStage, TItem>,
		atIndex = 0
	): Promise<void> {
		if (!this.isBusy && item && !item.isBusy && toStage && !toStage.isBusy && this.items && toStage.items) {
			this.busy = true;
			toStage.busy = true;

			// make changes in UI first
			const sourceStageItemCollection = this.items.toArray();
			const originalSourceStageItemCollection = [...sourceStageItemCollection];
			const destinationStageItemCollection = toStage.items.toArray();
			const originalDestinationStageItemCollection = [...destinationStageItemCollection];
			const currentIndexInSourceStage = sourceStageItemCollection.indexOf(item);
			const itemAtDestination = originalDestinationStageItemCollection[atIndex];

			VmUtils.setViewModelBusy(item, true);
			sourceStageItemCollection.splice(currentIndexInSourceStage, 1);
			destinationStageItemCollection.splice(atIndex, 0, item);
			// FOLLOWUP: Resolve
			// @ts-ignore
			item.setStage(toStage);
			item.setPosition(atIndex);

			this.items.setItems(sourceStageItemCollection);
			toStage.items.setItems(destinationStageItemCollection);

			return new Promise<void>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResultNoValue) => {
					runInAction(() => {
						VmUtils.setViewModelBusy(item, false);
						this.busy = false;
						toStage.busy = false;
						if (opResult.success) {
							resolve();
						} else {
							// restore original collections
							this.items.setItems(originalSourceStageItemCollection);
							toStage.items.setItems(originalDestinationStageItemCollection);
							// FOLLOWUP: Resolve
							// @ts-ignore
							item.setStage(this);
							item.setPosition(currentIndexInSourceStage);
							reject(opResult);
						}
					});
				};

				// create the move request
				const moveBoardItem: Api.IMoveBoardItem = {
					fallbackIndex: atIndex,
					position: Api.MoveBoardItemPosition.Before,
					relativeToItemId: itemAtDestination ? itemAtDestination.id : null,
					stageId: toStage.id,
				};

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
					`board/item/${item.id}/move`,
					'POST',
					moveBoardItem,
					onFinish,
					onFinish
				);
			});
		}

		return null;
	}

	/**
	 * Delete an item from this stage
	 *
	 * @param item
	 */
	@action
	public deleteItem(item: TItemViewModel) {
		if (!this.isBusy && !item.isBusy && this.items) {
			this.busy = true;
			return new Promise<TItemViewModel>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<TItem>) => {
					runInAction(() => {
						this.busy = false;
						if (opResult.success) {
							// preform delete in UI
							this.items.removeItems([item]);
							resolve(item);
						} else {
							reject(opResult);
						}
					});
				};

				item
					.delete()
					.then(deletedItem => onFinish({ success: true, value: deletedItem }))
					.catch(onFinish);
			});
		}

		return null;
	}

	/**
	 * Create an item in this stage
	 *
	 * @param item
	 */
	@action
	public createItem(item: TItem) {
		if (!this.isBusy && this.items) {
			this.busy = true;
			const boardItem = this.createBoardItemViewModel();

			return new Promise<TItemViewModel>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<TItem>) => {
					runInAction(() => {
						this.busy = false;
						if (opResult.success) {
							this.items.splice(item.position, 0, boardItem);
							resolve(boardItem);
						} else {
							reject(opResult);
						}
					});
				};

				boardItem
					.save(item)
					.then(createdItem => onFinish({ success: true, value: createdItem }))
					.catch(onFinish);
			});
		}
	}

	/** Archive an item */
	@action
	public archiveItem(item: TItemViewModel) {
		if (!this.isBusy && !item.isBusy && this.items) {
			this.busy = true;
			return new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResultNoValue) => {
					runInAction(() => {
						this.busy = false;
						if (opResult.success) {
							// preform delete in UI
							this.items.removeItems([item]);
							resolve(undefined);
						} else {
							reject(opResult);
						}
					});
				};

				item
					.archive()
					.then(() => onFinish({ success: true }))
					.catch(onFinish);
			});
		}

		return null;
	}

	@action
	public reset = () => {
		if (this.mItemsCollectionController) {
			this.mItemsCollectionController.reset();
		}

		if (this.mSearchController) {
			this.mSearchController.reset();
		}
	};

	@action
	public resetSearch = () => {
		if (this.mSearchController) {
			this.mSearchController.reset();
		}
	};

	@action
	public setStage = (stage: TStage, resetItems = false) => {
		const prevStage = this.mStage;
		// super.mSetResource(stage);
		this.mStage = stage;

		if (this.mStage) {
			// only update/replace this if the prevStage.id has changed... if we need to clear these items, call reset()
			if (resetItems || !prevStage || prevStage.id !== this.mStage.id) {
				this.mItemsCollectionController = new BaseObservablePageCollectionController<TItem, TItemViewModel>({
					apiPath: `board/stage/${this.mStage.id}/items`,
					client: this.mUserSession.webServiceHelper,
					transformer: (itemModel: TItem) => this.createBoardItemViewModel(itemModel),
				});
			}
		} else {
			this.mItemsCollectionController = null;
		}
	};

	/**
	 * Updates items collection (vms) with the given item models. If an item (model) does not has a corresponding vm the
	 * current collection, then it will be added. If a vm the current collection does not exist in the give item (model)
	 * collection, then it will be removed. Item vms will be updated with new models only if "lastModifiedDate" doesn't
	 * match the current value.
	 */
	@action
	public updateWithItems = (items: TItem[]) => {
		if (items && this.mStage && this.mItemsCollectionController) {
			const itemModelsDictionary: Api.IDictionary<TItem> = items.reduce<Api.IDictionary<TItem>>((result, item) => {
				result[item.id] = item;
				return result;
			}, {});

			let itemsCollectionModified = false;
			const currentItemsDictionary: Api.IDictionary<TItemViewModel> = this.items.reduce<
				Api.IDictionary<TItemViewModel>
			>((result, itemVm) => {
				// only add current items that are present in the incoming collection
				if (itemModelsDictionary[itemVm.id]) {
					result[itemVm.id] = itemVm;
				} else {
					// item removed
					itemsCollectionModified = true;
				}
				return result;
			}, {});

			const fetchResults: TItemViewModel[] = [];
			items.forEach(x => {
				let itemVm = currentItemsDictionary[x.id];
				if (itemVm) {
					if (itemVm.lastModifiedDate !== x.lastModifiedDate || itemVm.position !== x.position) {
						// only update if the lastModifiedDate has changed
						itemVm.setResource(x);
						itemsCollectionModified = true;
					}
				} else {
					itemVm = this.createBoardItemViewModel(x);
					itemsCollectionModified = true;
				}
				fetchResults.push(itemVm as TItemViewModel);
			});

			if (itemsCollectionModified) {
				this.mItemsCollectionController.fetchResults.setItems(fetchResults);
			}
		}
	};

	@action
	public setItems = (items: TItem[]) => {
		if (items && this.mStage && this.mItemsCollectionController) {
			const currentItemsDictionary: Api.IDictionary<TItemViewModel> = this.items.reduce<
				Api.IDictionary<TItemViewModel>
			>((result, item) => {
				result[item.id] = item;
				return result;
			}, {});

			const fetchResults: TItemViewModel[] = [];
			items.forEach(x => {
				let itemVm = currentItemsDictionary[x.id];
				if (itemVm) {
					itemVm.setResource(x);
				} else {
					itemVm = this.createBoardItemViewModel(x);
				}
				fetchResults.push(itemVm as TItemViewModel);
			});

			this.mItemsCollectionController.fetchResults.setItems(fetchResults);
		}
	};

	public getSearchResults = (searchContext?: TSearchContext, pageSize?: number, params?: any) => {
		if (this.mSearchController) {
			return this.mSearchController.getNext(searchContext, pageSize, params);
		}
		return null;
	};

	public getItems = (
		sortDescriptor?: Api.ISortDescriptor,
		pageSize?: number,
		params?: any
	): Bluebird<Api.IPageCollectionControllerFetchResult<TItem[]>> => {
		return this.mItemsCollectionController.getNext(sortDescriptor, pageSize, params);
	};

	public toJs = () => {
		return this.mStage;
	};

	public toColumnDefinition = () => {
		// FOLLOWUP: Argument of type 'this' is not assignable to parameter of type ...
		// @ts-ignore
		return new BoardStageDefinitionViewModel(this);
	};

	protected mSetResource(stage: TStage) {
		super.mSetResource(stage);
		this.mStage = stage;

		if (this.mStage) {
			this.mItemsCollectionController = new BaseObservablePageCollectionController<
				TItem,
				TItemViewModel,
				Api.ISortDescriptor
			>({
				apiPath: `board/stage/${this.mStage.id}/items`,
				client: this.mUserSession.webServiceHelper,
				transformer: (itemModel: TItem) => this.createBoardItemViewModel(itemModel),
			});

			this.mSearchController = this.createSearchController(stage);
		} else {
			this.mItemsCollectionController = null;
			this.mSearchController = null;
		}
	}

	protected createBoardItemViewModel(item?: TItem) {
		return new BoardItemViewModel(this.mUserSession, this, item) as TItemViewModel;
	}

	protected createSearchController(stage: TStage): TSearchController {
		return new BaseObservablePageCollectionController<TItem, TItemViewModel>({
			apiPath: `board/stage/${stage.id}/items/search`,
			client: this.mUserSession.webServiceHelper,
			transformer: (item: TItem) => {
				return this.createBoardItemViewModel(item);
			},
		}) as TSearchController;
	}
}

export class BoardViewModel<
	TBoard extends Api.IBoard = Api.IBoard,
	TStage extends Api.IBoardStage = Api.IBoardStage,
	TItem extends Api.IBoardItem = Api.IBoardItem,
> extends BaseResourceViewModel<TBoard> {
	@observable.ref protected mBoard: TBoard;
	@observable.ref protected mStages: BoardStageViewModel<TStage>[];
	@observable.ref protected mShowArchivedColumn = false;
	@observable.ref protected mStageToReset: TStage;

	constructor(userSession: UserSessionContext, board?: TBoard) {
		super(userSession);
		this.apiBasePath = 'board';
		this.mSetResource(board);
	}

	/** @returns True if fetching search results in stages */
	@computed
	public get isFetchingSearchResultsInStages() {
		return this.mStages && this.mStages.length > 0 && !!this.mStages.some(x => x.isFetchingSearchResults);
	}

	@computed
	public get isBusy() {
		return this.busy || this.loading;
	}

	@computed
	public get stages() {
		return this.mStages;
	}

	@computed
	public get id() {
		return this.mBoard.id;
	}

	@computed
	public get name() {
		return this.mBoard.name;
	}

	@computed
	public get templateType() {
		return this.mBoard.template ? this.mBoard.template.type : null;
	}

	@computed
	public get stageToReset() {
		return this.mStageToReset;
	}

	public set stageToReset(value: TStage) {
		this.mStageToReset = value;
	}

	@action
	public resetStageSearches = () => {
		if (this.mStages && this.mStages.length > 0) {
			this.mStages.forEach(x => x.resetSearch());
		}
	};

	@action
	public reset = () => {
		if (this.mStages && this.mStages.length > 0) {
			this.mStages.forEach(x => {
				x.reset();
			});
		}
	};

	@action resetColumn = () => {
		const stageId = this.stageToReset.id;
		const stage = this.mStages.find(x => x.id === stageId);
		if (stage) {
			stage.reset();
		}
		this.stageToReset = null;
	};

	protected mSetResource(board: TBoard) {
		const prevStages: Api.IDictionary<BoardStageViewModel<TStage>> = (this.stages || []).reduce<
			Api.IDictionary<BoardStageViewModel<TStage>>
		>((result, stage) => {
			result[stage.id] = stage as BoardStageViewModel<TStage>;
			return result;
		}, {});

		super.mSetResource(board);
		this.mBoard = board;
		if (this.mBoard) {
			// FOLLOWUP: Resolve
			// @ts-ignore
			this.mStages = (this.mBoard.stages || []).map(x => {
				const prevBoardStage = prevStages[x.id];
				if (prevBoardStage) {
					// if the board already had a stage for this id, just update and return that so we preserve the items
					prevBoardStage.setStage(x as TStage, false);
					return prevBoardStage;
				}
				return this.createBoardStageViewModel(x as TStage);
			});
		}
	}

	protected createBoardStageViewModel(stage: TStage): BoardStageViewModel {
		// FOLLOWUP: Resolve
		// @ts-ignore
		return new BoardStageViewModel<TStage>(this.mUserSession, stage, this);
	}

	protected createBoardItemViewModel(item: TItem) {
		const stage =
			this.mStages && this.mStages.length > 0 && item.boardStage && item.boardStage.id
				? this.mStages.find(x => x.id === item.boardStage.id)
				: null;
		// FOLLOWUP: Resolve
		// @ts-ignore
		return new BoardItemViewModel(this.mUserSession, stage, item);
	}
}

export class BoardSyncViewModel<
	TBoard extends Api.IBoard = Api.IBoard,
	TStage extends Api.IBoardStage = Api.IBoardStage,
	TItem extends Api.IBoardItem = Api.IBoardItem,
> {
	@observable mFetching: boolean;
	@observable private mStarted: boolean;
	@observable.ref private mLastRunTimestamp: Date;
	@observable.ref private mStartTimestamp: Date;
	private mBoard: BoardViewModel<TBoard, TStage>;
	private mIntervalInSeconds: number;
	private mOnChangeHandler: (delta?: IBoardSyncDelta<TBoard, TItem>) => void;
	private mOnErrorHandler: (error?: Api.IOperationResultNoValue) => void;
	private mPageSize: number;
	private mUserSession: UserSessionContext;
	private timeoutRef: any;

	constructor(
		userSession: UserSessionContext,
		board: BoardViewModel<TBoard, TStage>,
		intervalInSeconds = 10,
		pageSize = 100
	) {
		this.mIntervalInSeconds = intervalInSeconds;
		this.mBoard = board;
		this.mStarted = false;
		this.mUserSession = userSession;
		this.mPageSize = pageSize;
	}

	@computed
	public get isRunning() {
		return this.mFetching;
	}

	@computed
	public get pageSize() {
		return this.mPageSize;
	}

	@computed
	public get lastRunTimestamp() {
		return this.mLastRunTimestamp;
	}

	@computed
	public get hasStarted() {
		return this.mStarted;
	}

	public onChange = (handler?: (delta?: IBoardSyncDelta<TBoard, TItem>) => void) => {
		this.mOnChangeHandler = handler;
		return this;
	};

	public onError = (handler?: (error?: Api.IOperationResultNoValue) => void) => {
		this.mOnErrorHandler = handler;
		return this;
	};

	public get board() {
		return this.mBoard;
	}

	/** Time in seconds */
	public get updateInterval() {
		return this.mIntervalInSeconds;
	}

	/** Time in seconds */
	public set updateInterval(value: number) {
		this.mIntervalInSeconds = value;
	}

	public getTimeSinceLastRun = (units: 'seconds' | 'minutes') => {
		if (this.mLastRunTimestamp) {
			return moment().diff(this.mLastRunTimestamp, units);
		}

		return -1;
	};

	@action
	public stop = () => {
		if (this.timeoutRef) {
			clearTimeout(this.timeoutRef);
			this.timeoutRef = null;
		}

		this.mStarted = false;
		this.mStartTimestamp = null;
	};

	@action
	public start = (runNow = false) => {
		if (!this.mBoard || this.mStarted) {
			return;
		}

		this.mStarted = true;
		this.mStartTimestamp = new Date();

		if (runNow) {
			this.run();
		} else {
			this.scheduleNextRun();
		}
	};

	@action
	private scheduleNextRun = () => {
		this.mFetching = false;
		if (!this.timeoutRef && this.hasStarted) {
			this.timeoutRef = setTimeout(() => {
				if (this.hasStarted) {
					this.timeoutRef = null;
					this.run();
				}
			}, this.mIntervalInSeconds * 1000);
		}
	};

	@action
	private run = () => {
		if (this.mOnChangeHandler) {
			this.mFetching = true;
			const onError = (error: Api.IOperationResultNoValue) => {
				this.mLastRunTimestamp = new Date();
				if (this.mOnErrorHandler) {
					this.mOnErrorHandler(error);
				}
				this.scheduleNextRun();
			};

			const syncStartDate = this.mStartTimestamp;

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IBoard>(
				`board/${this.mBoard.id}`,
				'GET',
				null,
				(opResult: Api.IOperationResult<Api.IBoard>) => {
					if (this.mOnChangeHandler) {
						if (opResult.success) {
							const boardModel = opResult.value as TBoard;
							const delta: IBoardSyncDelta<TBoard, TItem> = {
								board: boardModel,
								stageItems: {},
							};

							// get items for each stage
							this.getItems(delta, syncStartDate);
						} else {
							onError(opResult);
						}
					} else {
						this.mLastRunTimestamp = new Date();
						this.scheduleNextRun();
					}
				},
				onError
			);
		} else {
			this.mLastRunTimestamp = new Date();
			this.scheduleNextRun();
		}
	};

	private getItems = (delta: IBoardSyncDelta<TBoard, TItem>, syncStartDate: Date) => {
		if (delta.board.stages && delta.board.stages.length > 0) {
			const getPromise = (stage: TStage) => {
				return new Promise<Api.IPagedCollection<TItem>>(resolve => {
					const onFinish = (result: Api.IOperationResult<Api.IPagedCollection<TItem>>) => {
						if (result.success) {
							resolve(result.value);
						} else {
							// eat the error
							resolve(null);
						}
					};
					this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IPagedCollection<TItem>>(
						`board/stage/${stage.id}/items?pageSize=${this.pageSize}`,
						'GET',
						null,
						onFinish,
						onFinish
					);
				});
			};

			const promiseCollection = delta.board.stages.map(x => getPromise(x as TStage));
			Promise.all<Api.IPagedCollection<TItem>>(promiseCollection)
				.then(results => {
					if (this.mStarted && this.mStartTimestamp && syncStartDate === this.mStartTimestamp) {
						delta.board.stages.forEach((stage, i) => {
							const result = results[i];
							if (result) {
								delta.stageItems[stage.id] = result.values || [];
							}
						});

						this.mLastRunTimestamp = new Date();
						if (this.mOnChangeHandler && this.mStarted) {
							this.mOnChangeHandler(delta);
						}
						this.scheduleNextRun();
					}
				})
				.catch((error: Api.IOperationResultNoValue) => {
					this.mLastRunTimestamp = new Date();
					if (this.mOnErrorHandler) {
						this.mOnErrorHandler(error);
					}
					this.scheduleNextRun();
				});
		} else {
			this.mLastRunTimestamp = new Date();
			if (this.mOnChangeHandler && this.mStarted) {
				this.mOnChangeHandler(delta);
			}
			this.scheduleNextRun();
		}
	};
}

export class OpportunitiesSearchController extends BaseObservablePageCollectionController<
	Api.IOpportunity,
	OpportunityViewModel,
	Api.IOpportunitySearchRequest
> {
	protected mUserSession: UserSessionContext;
	protected createdStages: Api.IDictionary<OpportunitiesBoardStageViewModel> = {};

	public static createBoardStageSearchController = (
		userSession: UserSessionContext,
		board: OpportunitiesBoardViewModel,
		stage: OpportunitiesBoardStageViewModel,
		apiParams?: Api.IDictionary
	) => {
		return new OpportunitiesSearchController(userSession, board, stage, apiParams);
	};

	public static createBoardSearchController = (
		userSession: UserSessionContext,
		board: OpportunitiesBoardViewModel,
		apiParams?: Api.IDictionary
	) => {
		return new OpportunitiesSearchController(userSession, board, null, apiParams);
	};

	constructor(
		userSession: UserSessionContext,
		board: OpportunitiesBoardViewModel,
		stage?: OpportunitiesBoardStageViewModel,
		apiParams?: Api.IDictionary
	) {
		super({
			apiParams,
			apiPath: stage ? `board/stage/${stage.id}/items/search` : `board/${board.id}/items/search`,
			client: userSession.webServiceHelper,
			transformer: (opportunityModel: Api.IOpportunity): OpportunityViewModel => {
				let stageVm: OpportunitiesBoardStageViewModel = null;
				if (opportunityModel.boardStage && opportunityModel.boardStage.id) {
					// FOLLOWUP: Resolve
					// @ts-ignore
					stageVm = board.stages.find(x => x.id === opportunityModel.boardStage.id) as OpportunitiesBoardStageViewModel;
					if (!stageVm) {
						// create one
						stageVm = new OpportunitiesBoardStageViewModel(userSession, opportunityModel.boardStage, board);
						this.createdStages[stageVm.id] = stageVm;
					}
				}
				// FOLLOWUP: Resolve
				// @ts-ignore
				return new OpportunityViewModel(userSession, stageVm, opportunityModel);
			},
		});
		this.mUserSession = userSession;
	}

	protected executeFetch(
		searchRequest?: Api.IOpportunitySearchRequest,
		pageSize?: number,
		params?: Api.IDictionary,
		isFetchEqualToPreviousFetch = true
	) {
		return new Promise<Api.IOperationResult<Api.IPagedCollection<Api.IOpportunity>>>((resolve, reject) => {
			const queryParams = {
				...(this.mApiParams || {}),
				...(params || {}),
				pageSize: pageSize || this.mPageSize,
			};
			VmUtils.removeEmptyKvpFromObject(queryParams);
			const query = toQueryStringParams(queryParams);

			const apiUrl = searchRequest?.stageId ? `board/stage/${searchRequest?.stageId}/items/search` : this.mApiPath;

			this.mClient.callWebServiceWithOperationResults<Api.IPagedCollection<Api.IOpportunity>>(
				`${apiUrl}?${query}`,
				'POST',
				searchRequest,
				opResult => {
					if (!isFetchEqualToPreviousFetch) {
						// clear created stages
						this.createdStages = {};
					}
					resolve(opResult);
				},
				error => {
					reject(error);
				}
			);
		});
	}
}

export class OpportunityViewModel extends BoardItemViewModel<Api.IOpportunity> {
	@observable.ref private mCompany: CompanyViewModel;
	@observable.ref private mCreator: UserViewModel;
	@observable.ref private mOpportunity: Api.IOpportunity;
	@observable.ref private mOwner: UserViewModel;
	@observable.ref private mPrimaryContact: ContactViewModel;

	constructor(userSession: UserSessionContext, stage: BoardStageViewModel, opportunity?: Api.IOpportunity) {
		super(userSession, stage, opportunity);
	}

	@computed
	public get isLoaded() {
		return (
			this.loaded ||
			(this.mOpportunity && this.mOpportunity.id && this.mOpportunity.boardStage && !!this.mOpportunity.creator)
		);
	}

	@computed
	public get company() {
		return this.mCompany;
	}

	@computed
	public get name() {
		return this.mOpportunity.name;
	}

	@computed
	public get owner() {
		return this.mOwner;
	}

	@computed
	public get creator() {
		return this.mCreator;
	}

	@computed
	public get primaryContact() {
		return this.mPrimaryContact;
	}

	@computed
	public get closeDate() {
		return this.mOpportunity.closeDate ? new Date(this.mOpportunity.closeDate) : null;
	}

	@computed
	public get dealSize() {
		return this.mOpportunity.dealSize;
	}

	@computed
	public get isArchived() {
		return this.mOpportunity.isArchived;
	}

	@computed
	public get details() {
		return this.mOpportunity.details;
	}

	@action
	public mSetResource(opportunity: Api.IOpportunity) {
		super.mSetResource(opportunity);
		this.mOpportunity = opportunity;

		if (opportunity) {
			if (opportunity.company) {
				this.mCompany = new CompanyViewModel(this.mUserSession, opportunity.company);
			}

			if (opportunity.assignees && opportunity.assignees.length > 0) {
				this.mOwner = new UserViewModel(this.mUserSession, opportunity.assignees[0]);
			}

			if (opportunity.creator) {
				this.mCreator = new UserViewModel(this.mUserSession, opportunity.creator);
			}

			if (opportunity.primaryContact) {
				this.mPrimaryContact = new ContactViewModel(this.mUserSession, opportunity.primaryContact);
			}

			if (opportunity.id) {
				this.apiPathDictionary.PUT = `board/item/${opportunity.id}/opportunity`;
			}
		}

		const stageId =
			this.mStage && this.mStage.id
				? this.mStage.id
				: opportunity && opportunity.boardStage
					? opportunity.boardStage.id
					: null;
		if (stageId) {
			this.apiPathDictionary.POST = `board/stage/${stageId}/opportunity`;
		}
	}
}

export class OpportunitiesBoardStageViewModel extends BoardStageViewModel<
	Api.IBoardStage,
	Api.IOpportunity,
	Api.IOpportunityBoardStageRollup,
	OpportunityViewModel,
	Api.IOpportunitySearchRequest,
	OpportunitiesSearchController
> {
	constructor(userSession: UserSessionContext, stage: Api.IBoardStage, board?: OpportunitiesBoardViewModel) {
		// FOLLOWUP: Resolve
		// @ts-ignore
		super(userSession, stage, board);
	}

	@computed
	public get totalDealSize() {
		if (this.rollup) {
			return this.rollup.total || 0;
		}

		return 0;
	}

	public get searchController() {
		return this.mSearchController;
	}

	public mSetResource(resource: Api.IOpportunity) {
		super.mSetResource(resource);
	}

	protected createBoardItemViewModel(item: Api.IOpportunity): OpportunityViewModel {
		// FOLLOWUP: Resolve
		// @ts-ignore
		return new OpportunityViewModel(this.mUserSession, this, item);
	}

	protected createSearchController(): OpportunitiesSearchController {
		return OpportunitiesSearchController.createBoardStageSearchController(
			this.mUserSession,
			// FOLLOWUP: Resolve
			// @ts-ignore
			this.mboard as OpportunitiesBoardViewModel,
			this
		);
	}
}

export class OpportunitiesBoardViewModel extends BoardViewModel<Api.IBoard, Api.IBoardStage, Api.IOpportunity> {
	constructor(userSession: UserSessionContext, board?: Api.IBoard) {
		super(userSession, board);
	}

	// FOLLOWUP: Resolve
	// @ts-ignore
	protected createBoardStageViewModel(stage: Api.IBoardStage): OpportunitiesBoardStageViewModel {
		return new OpportunitiesBoardStageViewModel(this.mUserSession, stage, this);
	}

	public exportBoard = (exportRequest: Api.IOpportunitySearchRequest, includeActivity: boolean) => {
		const promise = new Promise<Api.ISystemJob>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.ISystemJob>) => {
				if (opResult.success) {
					resolve(opResult.value);
				} else {
					reject(opResult);
				}
			};

			const request: Api.IBoardExportRequest = {
				filter: exportRequest,
				id: this.id,
				includeActivity,
			};

			this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.ISystemJob>(
				`board/items/export`,
				'POST',
				request,
				onFinish,
				onFinish
			);
		});
		return promise;
	};
}

export class OpportunitiesViewModel extends ViewModel {
	private boardsCollectionController: ObservablePageCollectionControllerOld<Api.IBoard, OpportunitiesBoardViewModel>;

	constructor(userSession: UserSessionContext) {
		super(userSession);
		this.boardsCollectionController = new ObservablePageCollectionControllerOld<
			Api.IBoard,
			OpportunitiesBoardViewModel
		>(userSession.webServiceHelper, 'board', { type: 'Opportunity' }, this.createBoardViewModel);
	}

	@computed
	public get isBusy() {
		return this.loading || this.busy || this.boardsCollectionController.fetching;
	}

	@computed
	public get isFetchingBoards() {
		return this.boardsCollectionController.fetching;
	}

	@computed
	public get boards() {
		return this.boardsCollectionController.fetchResults;
	}

	@action
	public reset = () => {
		this.boardsCollectionController.reset();
		this.loading = false;
		this.busy = false;
	};

	@action
	public createBoard = (board: Api.IBoard) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise((resolve, reject) => {
				const boardVm = new OpportunitiesBoardViewModel(this.mUserSession);
				boardVm
					.save(board)
					.then(() => {
						runInAction(() => {
							this.busy = false;
							resolve(boardVm);
						});
					})
					.catch(reject);
			});
		}
	};

	public getBoards = (
		sortDescriptor?: Api.ISortDescriptor,
		pageSize?: number,
		params?: any
	): Bluebird<Api.IPageCollectionControllerFetchResult<Api.IBoard[]>> => {
		return this.boardsCollectionController.getNext(sortDescriptor, pageSize, params);
	};

	private createBoardViewModel = (board: Api.IBoard) => {
		return new OpportunitiesBoardViewModel(this.mUserSession, board);
	};
}

export class OpportunitiesEntityViewModel extends ViewModel {
	@observable.ref private mEntity: EntityViewModel;
	@observable.ref
	private mArchivedOpportunities: ObservablePageCollectionControllerOld<Api.IOpportunity, OpportunityViewModel>;
	@observable.ref private mOpportunities: ObservablePageCollectionControllerOld<Api.IOpportunity, OpportunityViewModel>;

	constructor(userSession: UserSessionContext, entity: EntityViewModel) {
		super(userSession);
		this.setEntity(entity);
	}

	@computed
	public get entity() {
		return this.mEntity;
	}

	@computed
	public get isFetchingOpportunities() {
		return this.mOpportunities.fetching;
	}

	@computed
	public get isFetchingArchivedOpportunities() {
		return this.mArchivedOpportunities.fetching;
	}

	@computed
	public get archivedOpportunities() {
		return this.mArchivedOpportunities.fetchResults;
	}

	@computed
	public get opportunities() {
		return this.mOpportunities.fetchResults;
	}

	@computed
	public get primaryContact() {
		if (this.mEntity instanceof ContactViewModel) {
			return this.mEntity as ContactViewModel;
		}
		return undefined;
	}

	@computed
	public get company() {
		if (this.mEntity instanceof CompanyViewModel) {
			return this.mEntity as CompanyViewModel;
		}
		return undefined;
	}

	@action
	public setEntity(entity: EntityViewModel) {
		this.mEntity = entity;

		let entityType = '';
		if (entity instanceof CompanyViewModel) {
			entityType = 'company';
		} else if (entity instanceof ContactViewModel) {
			entityType = 'contact';
		}

		if (entity.id && entityType) {
			this.mArchivedOpportunities = new ObservablePageCollectionControllerOld(
				this.userSession.webServiceHelper,
				`${entityType}/${entity.id}/opportunities`,
				{ archived: true },
				opportunity => this.createOpportunityViewModel(opportunity)
			);

			this.mOpportunities = new ObservablePageCollectionControllerOld(
				this.userSession.webServiceHelper,
				`${entityType}/${entity.id}/opportunities`,
				null,
				opportunity => this.createOpportunityViewModel(opportunity)
			);
		}
	}

	@action
	public reset() {
		this.mEntity = undefined;
		this.resetOpportunities();
		this.resetArchivedOpportunities();
	}

	@action
	public resetOpportunities = () => {
		if (this.mOpportunities) {
			this.mOpportunities.reset();
		}
	};

	@action
	public resetArchivedOpportunities = () => {
		if (this.mArchivedOpportunities) {
			this.mArchivedOpportunities.reset();
		}
	};

	@action
	public setOpportunities(values: OpportunityViewModel[]) {
		if (this.mOpportunities) {
			this.mOpportunities.fetchResults = values;
		}
	}

	@action
	public removeOpportunities(values: OpportunityViewModel[]) {
		if (this.mOpportunities) {
			this.mOpportunities.removeItems(values);
		}
	}

	@action
	public setArchivedOpportunities(values: OpportunityViewModel[]) {
		if (this.mArchivedOpportunities) {
			this.mArchivedOpportunities.fetchResults = values;
		}
	}

	@action
	public removeArchivedOpportunities(values: OpportunityViewModel[]) {
		if (this.mArchivedOpportunities) {
			this.mArchivedOpportunities.removeItems(values);
		}
	}

	public getArchivedOpportunities = (sortDescriptor?: Api.ISortDescriptor, pageSize?: number, params?: any) => {
		return this.mArchivedOpportunities.getNext(sortDescriptor, pageSize, params);
	};

	public getOpportunities = (sortDescriptor?: Api.ISortDescriptor, pageSize?: number, params?: any) => {
		return this.mOpportunities.getNext(sortDescriptor, pageSize, params);
	};

	private createOpportunityViewModel = (opportunityModel: Api.IOpportunity) => {
		return new OpportunityViewModel(this.mUserSession, null, opportunityModel);
	};
}

export class TagViewModel extends ViewModel {
	@observable private mAccountTag: Api.IAccountTag;
	@observable private mDeleting: boolean;
	@observable private mUpdating: boolean;
	@observable private mAlertViewModel: TagAlertViewModel;

	constructor(userSession: UserSessionContext, accountTag?: Api.IAccountTag) {
		super(userSession);
		this.setAccountTag(accountTag);
	}

	@computed
	public get id() {
		return this.mAccountTag.id;
	}

	@computed
	public get isDeleting() {
		return this.mDeleting;
	}

	@computed
	public get isUpdating() {
		return this.mUpdating;
	}

	@computed
	public get stats() {
		return this.mAccountTag.stats;
	}

	@computed
	public get value() {
		return this.mAccountTag.tag;
	}

	@computed
	public get name() {
		return this.mAccountTag.tag;
	}

	@computed
	public get category() {
		return this.mAccountTag.category;
	}

	@computed
	public get favoriteFor() {
		return this.mAccountTag.favoriteFor;
	}

	@computed
	public get source() {
		return this.mAccountTag.source;
	}

	@computed
	public get canChangeFavoriteFor() {
		if (this.mUpdating) {
			return false;
		}

		switch (this.favoriteFor) {
			case Api.FavoriteFor.Account:
				return this.isAdmin;

			default:
				return true;
		}
	}

	@computed
	public get isBusy() {
		return this.busy || this.loading;
	}

	@computed
	public get isLoaded() {
		return this.mAccountTag && this.mAccountTag.tag && !!this.mAccountTag.stats;
	}

	@computed
	public get tagAlert() {
		return this.mAlertViewModel;
	}

	@action
	public load() {
		if (!this.isBusy) {
			this.loading = true;
			return new Promise<Api.IAccountTag>((resolve, reject) => {
				const query = this.mAccountTag.tag || this.mAccountTag.id || '';
				const onFinish = action((opResult: Api.IOperationResult<Api.IAccountTag[]>) => {
					this.loading = false;
					if (opResult.success) {
						const lowerQuery = query.toLocaleLowerCase();
						const accountTag = opResult.value?.find(
							x => x.tag?.toLocaleLowerCase() === lowerQuery || (x.id && query === x.id)
						);
						if (accountTag) {
							this.setAccountTag(accountTag);
							resolve(accountTag);
						} else {
							const error: Api.IOperationResultNoValue = {
								systemCode: 404,
								systemMessage: `Tag ${query ? `"${query}" ` : ''}not found.`,
							};
							reject(error);
						}
					} else {
						reject(opResult);
					}
				});

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAccountTag[]>(
					`tag/search?query=${query}&expand=TagAlert`,
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
		}
	}

	@action
	public delete = () => {
		if (!this.isBusy) {
			this.mDeleting = true;
			return new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResultNoValue) => {
					this.mDeleting = false;
					if (opResult.success) {
						resolve(undefined);
					} else {
						reject(opResult);
					}
				});

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
					`tag/tags`,
					'DELETE',
					[this.mAccountTag ? this.mAccountTag.tag || '' : ''],
					onFinish,
					onFinish
				);
			});
		}
	};

	@action
	public setFavoriteFor = (favoriteFor: Api.FavoriteFor) => {
		this.mUpdating = true;
		return new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
			const onFinish = action((opResult: Api.IOperationResult<Api.IAccountTag>) => {
				this.mUpdating = false;
				if (opResult.success) {
					this.mSetAccountTag(opResult.value);
					resolve(undefined);
				} else {
					reject(opResult);
				}
			});

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAccountTag>(
				`tag/${this.id}/favorite?favoriteFor=${favoriteFor}&expand=TagAlert`,
				'PUT',
				null,
				onFinish,
				onFinish
			);
		});
	};

	public toJs = () => {
		return this.mAccountTag;
	};

	@action
	public setAccountTag = (accountTag: Api.IAccountTag) => {
		this.mSetAccountTag(accountTag);
	};

	private mSetAccountTag(accountTag: Api.IAccountTag) {
		this.mAccountTag = accountTag;
		this.mAlertViewModel = accountTag.tagAlert
			? new TagAlertViewModel(this.mUserSession, accountTag.tagAlert)
			: undefined;
	}
}

export class TagsViewModel extends ViewModel {
	@observable private deleting: boolean;
	@observable private merging: boolean;
	@observable private mSearchQuery: string;
	@observable private searching: boolean;
	@observable.ref private mActiveTag: TagViewModel;
	@observable.ref protected mSearchResults: ObservableCollection<TagViewModel>;
	@observable.ref protected mSelectedTags: ObservableCollection<TagViewModel>;
	protected mTagsPageCollectionController: BaseObservablePageCollectionController<
		Api.IAccountTag,
		TagViewModel,
		Api.ISortDescriptor
	>;

	constructor(userSession: UserSessionContext) {
		super(userSession);
		this.mTagsPageCollectionController = new BaseObservablePageCollectionController<
			Api.IAccountTag,
			TagViewModel,
			Api.ISortDescriptor
		>({
			apiParams: { expand: 'TagAlert' },
			apiPath: () => this.composeApiUrl({ urlPath: 'tag' }),
			client: userSession.webServiceHelper,
			itemUniqueIdentifierPropertyPath: 'value',
			transformer: this.createTagViewModel,
		});
		this.mSelectedTags = new ObservableCollection<TagViewModel>(null, 'value');
		this.mSearchResults = new ObservableCollection<TagViewModel>(null, 'value');
	}

	@computed
	public get isLoading() {
		return (
			this.loading ||
			(!this.mTagsPageCollectionController.hasFetchedFirstPage && this.mTagsPageCollectionController.isFetching)
		);
	}

	@computed
	public get searchQuery() {
		return this.mSearchQuery;
	}

	@computed
	public get searchResults() {
		return this.mSearchResults;
	}

	@computed
	public get isSearching() {
		return this.searching;
	}

	@computed
	public get isDeleting() {
		return this.deleting;
	}

	@computed
	public get isMerging() {
		return this.merging;
	}

	@computed
	public get activeTag() {
		return this.mActiveTag;
	}

	public set activeTag(value: TagViewModel) {
		this.mActiveTag = value;
	}

	@computed
	public get selectedTags() {
		return this.mSelectedTags;
	}

	@computed
	public get isBusy() {
		return this.busy || this.loading || this.mTagsPageCollectionController.isFetching || this.merging || this.deleting;
	}

	@computed
	public get isFetchingResults() {
		return this.mTagsPageCollectionController.isFetching;
	}

	@computed
	public get tags() {
		return this.mTagsPageCollectionController.fetchResults;
	}

	@computed
	public get totalCount() {
		return this.mTagsPageCollectionController.totalCount;
	}

	@action
	public removeTags = (tags: TagViewModel[]) => {
		this.mRemoveTags(tags);
	};

	@action
	public reset = () => {
		this.busy = false;
		this.deleting = false;
		this.loading = false;
		this.mActiveTag = null;
		this.merging = false;
		this.mSearchQuery = null;
		this.mSearchResults.clear();
		this.mSelectedTags.clear();
		this.mTagsPageCollectionController.reset();
		this.searching = false;
	};

	@action
	public resetSearch = () => {
		this.mSearchQuery = null;
		this.mSearchResults.clear();
		this.searching = false;
	};

	@action
	public createTag = (accountTag: Api.IAccountTag) => {
		return new Promise<TagViewModel>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.IAccountTag>) => {
				if (opResult.success) {
					const tag = this.createTagViewModel(opResult.value);
					resolve(tag);
				} else {
					reject(opResult);
				}
			};

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAccountTag>(
				this.composeApiUrl({ urlPath: 'tag' }),
				'POST',
				accountTag,
				onFinish,
				onFinish
			);
		});
	};

	@action
	public search = (query: string, sortDescriptor?: Api.ISortDescriptor) => {
		if (!this.searching) {
			this.searching = true;
			this.mSearchResults.clear();
			return new Promise<ObservableCollection<TagViewModel>>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IAccountTag[]>) => {
					runInAction(() => {
						this.searching = false;
						if (opResult.success) {
							this.mSearchQuery = query;
							this.mSearchResults.setItems((opResult.value || []).map(x => this.createTagViewModel(x)));
							resolve(this.mSearchResults);
						} else {
							reject(opResult);
						}
					});
				};

				const params: Api.IDictionary<string> = {
					expand: 'TagAlert',
					query,
				};
				if (sortDescriptor) {
					params.sort = sortDescriptor.sort;
					params.sortBy = sortDescriptor.sortBy;
				}

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAccountTag[]>(
					this.composeApiUrl({ queryParams: params, urlPath: 'tag/search' }),
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
		}

		return null;
	};

	@action
	public mergeTags = (tags: TagViewModel[], target: TagViewModel) => {
		if (!this.merging) {
			this.merging = true;
			return new Promise<TagViewModel>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IAccountTag>) => {
					runInAction(() => {
						const tagsToRemove: TagViewModel[] = [];
						(tags || []).forEach(x => {
							if (x !== target) {
								tagsToRemove.push(x);
							}
							VmUtils.setViewModelBusy(x, false);
						});
						this.mRemoveTags(tagsToRemove);
						this.merging = false;
						if (opResult.success) {
							target.setAccountTag(opResult.value);
							resolve(target);
						} else {
							reject(opResult);
						}
					});
				};

				const mergeRequest: Api.IMergeTagsRequest = {
					intoTag: target.value,
					sourceTags: (tags || []).map(x => {
						VmUtils.setViewModelBusy(x, true);
						return x.value;
					}),
				};

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAccountTag>(
					this.composeApiUrl({ queryParams: { expand: 'TagAlert' }, urlPath: 'tag/merge' }),
					'POST',
					mergeRequest,
					onFinish,
					onFinish
				);
			});
		}

		return null;
	};

	@action
	public deleteTags = (tags: TagViewModel[]) => {
		if (!this.deleting) {
			this.deleting = true;
			return new Promise<TagViewModel>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResultNoValue) => {
					runInAction(() => {
						(tags || []).forEach(x => VmUtils.setViewModelBusy(x, false));
						this.mRemoveTags(tags);
						this.deleting = false;
						if (opResult.success) {
							resolve(undefined);
						} else {
							reject(opResult);
						}
					});
				};

				const tagValues = (tags || []).map(x => {
					VmUtils.setViewModelBusy(x, true);
					return x.value;
				});

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IOperationResultNoValue>(
					this.composeApiUrl({ urlPath: 'tag/tags' }),
					'DELETE',
					tagValues,
					onFinish,
					onFinish
				);
			});
		}

		return null;
	};

	@action
	public getTags = (sortDescriptor?: Api.ISortDescriptor, pageSize?: number, params?: any) => {
		return this.mTagsPageCollectionController.getNext(sortDescriptor, pageSize, params);
	};

	public exportTags = async () => {
		try {
			const opResult = await this.userSession.webServiceHelper.callWebServiceAsync<Api.ISystemJob>(
				this.composeApiUrl({ urlPath: `tag/export` }),
				'POST'
			);
			if (opResult.success) {
				return opResult.value;
			}

			throw Api.asApiError(opResult);
		} catch (err) {
			throw Api.asApiError(err);
		}
	};

	/** @deprecated Please use getSuggestedTagsForContactsV2 instead */
	public getSuggestedTagsForContacts = (addKeepInTouchTag = false, pageSize = 5) => {
		return this.mGetSuggestedTagsWithPath<string>('contact/suggestedTags', {
			addKeepInTouchTag,
			pageSize,
		});
	};

	public getSuggestedTagsForContactsV2 = (addKeepInTouchTag = false, pageSize = 25) => {
		return this.mGetSuggestedTagsWithPath<Api.IAccountTag>('contact/suggestedTags/v2', { addKeepInTouchTag, pageSize });
	};

	public getSuggestedTagsForEmail = () => {
		return this.mGetSuggestedTagsWithPath<string>('email/suggestedTags');
	};

	private mGetSuggestedTagsWithPath = <T = string | Api.IAccountTag>(
		path: string,
		params?: Api.IDictionary<string | boolean | number>
	) => {
		return new Promise<T[]>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<T[]>) => {
				if (opResult.success) {
					resolve(opResult.value);
				} else {
					reject(opResult);
				}
			};

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<T[]>(
				this.composeApiUrl({ queryParams: params, urlPath: path }),
				'GET',
				null,
				onFinish,
				onFinish
			);
		});
	};

	private mRemoveTags = (tags: TagViewModel[]) => {
		this.mTagsPageCollectionController.fetchResults.removeItems(tags);

		tags.forEach(x => {
			// remove from active
			if (this.mActiveTag && x.id === this.mActiveTag.id) {
				this.mActiveTag = null;
			}
		});

		// remove from selected collection
		this.mSelectedTags.removeItems(tags);

		// remove from search results
		this.mSearchResults.removeItems(tags);
	};

	@action
	public checkTagForAutomation = (tagToCheck: string) => {
		return new Promise<Api.IAccountTag>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.IAccountTag>) => {
				if (opResult.success) {
					resolve(opResult.value);
				} else {
					reject(opResult);
				}
			};

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAccountTag>(
				this.composeApiUrl({ queryParams: { name: tagToCheck }, urlPath: 'tag/byName' }),
				'GET',
				null,
				onFinish,
				onFinish
			);
		});
	};

	@action
	public getDefaultTagsByIndustry = (industry: string) => {
		return new Promise<string[]>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<string[]>) => {
				if (opResult.success) {
					resolve(opResult.value);
				} else {
					reject(opResult);
				}
			};

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<string[]>(
				this.composeApiUrl({ queryParams: { industry }, urlPath: 'tag/byIndustry' }),
				'GET',
				null,
				onFinish,
				onFinish
			);
		});
	};

	protected createTagViewModel = (accountTag: Api.IAccountTag) => {
		return new TagViewModel(this.mUserSession, accountTag);
	};

	@action
	public deleteUnused = () => {
		return new Promise<void>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<void>) => {
				if (opResult.success) {
					resolve();
				} else {
					reject(opResult);
				}
			};

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<void>(
				this.composeApiUrl({ urlPath: 'tag/deleteUnused' }),
				'POST',
				null,
				onFinish,
				onFinish
			);
		});
	};

	@action
	public reevaluateTaggingGame = () => {
		return new Promise<Api.IOperationResult<number>>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<number>) => {
				if (opResult.success) {
					resolve(opResult);
				} else {
					reject(opResult);
				}
			};

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<number>(
				this.composeApiUrl({ urlPath: 'contact/reevaluateTaggingGame' }),
				'POST',
				null,
				onFinish,
				onFinish
			);
		});
	};
}

export class TagAlertViewModel extends BaseResourceViewModel<Partial<Api.ITagAlert>> {
	@observable.ref private mLastReportSendDate: Date;
	constructor(userSession: UserSessionContext, tagAlert?: Partial<Api.ITagAlert>) {
		super(userSession, tagAlert);
		this.apiBasePath = 'tag/alert';
	}

	@computed
	public get isLoaded() {
		return this.mResource && this.mResource.id && !!this.mResource.tag;
	}

	@computed
	public get interactionFilter() {
		return this.mResource.interactionFilter;
	}

	@computed
	public get contactCount() {
		// FOLLOWUP: Resolve
		// @ts-ignore
		return this.mResource.contactCount;
	}

	/** Time in days */
	@computed
	public get interactionInterval() {
		return this.mResource.interactionIntervalInDays;
	}

	@computed
	public get lastReportSendDate() {
		return this.mLastReportSendDate;
	}

	@computed
	public get id() {
		return this.mResource.id;
	}

	@computed
	public get tagValue() {
		return this.mResource.tag;
	}

	@computed
	public get tagNotifyOptions() {
		return this.mResource.sendAlertsTo;
	}

	@computed
	public get requireValidEmailAddress() {
		return this.mResource.requireValidEmailAddress;
	}

	@computed
	public get sendToDescription() {
		const sendAlertsTo = this.tagNotifyOptions;
		if (!sendAlertsTo) {
			return undefined;
		}

		return sendAlertsTo.resourceOwner
			? 'Contact Owner'
			: VmUtils.getDisplayName(sendAlertsTo.user as Api.IPrincipal, false);
	}

	protected mSetResource(tagAlert?: Api.ITagAlert) {
		super.mSetResource(
			tagAlert
				? {
						...tagAlert,
						interactionFilter: {
							user: null, // needed for mobx
							...tagAlert.interactionFilter,
						},
						requireValidEmailAddress: tagAlert.requireValidEmailAddress,
						sendAlertsTo: {
							user: null, // needed for mobx
							...tagAlert.sendAlertsTo,
						},
					}
				: null
		);
		if (tagAlert) {
			if (tagAlert.id) {
				this.setApiPathForMethod('GET', `tag/alert/${tagAlert.id}`);
			} else {
				this.setApiPathForMethod('GET', `tag/alert/bytag?tag=${tagAlert.tag}`);
			}
			this.mLastReportSendDate = tagAlert.lastReportSendDate ? new Date(tagAlert.lastReportSendDate) : null;
		} else {
			this.mLastReportSendDate = null;
		}
	}
}

export class ResourceReportingViewModel<
	TResourceAggregateActivity extends Api.IResourceAggregateActivity = Api.IResourceAggregateActivity,
	TResource extends object = any,
> {
	protected mBySearchQueryPagedCollectionController: FilteredPageCollectionController<
		TResourceAggregateActivity,
		IAggregateActivity<TResource>,
		Api.IEntityReportByTagsRequest
	>;
	protected mByTagsPagedCollectionController: FilteredPageCollectionController<
		TResourceAggregateActivity,
		IAggregateActivity<TResource>,
		Api.IEntityReportBySearchQueryRequest
	>;
	protected mPagedCollectionController: FilteredPageCollectionController<
		TResourceAggregateActivity,
		IAggregateActivity<TResource>,
		Api.IEntityReportByTagsRequest
	>;
	protected mReportingPathFragment: string;
	protected mUserSession: UserSessionContext;

	constructor(
		userSession: UserSessionContext,
		reportingPathFragment: string,
		itemUniqueIdentifierPropertyPath?: string
	) {
		this.transformResource = this.transformResource.bind(this);
		this.mReportingPathFragment = reportingPathFragment;
		this.mUserSession = userSession;

		this.mPagedCollectionController = new FilteredPageCollectionController<
			TResourceAggregateActivity,
			IAggregateActivity<TResource>,
			Api.IEntityReportByTagsRequest
		>({
			apiPath: `reports/${reportingPathFragment}`,
			client: this.mUserSession.webServiceHelper,
			itemUniqueIdentifierPropertyPath,
			transformer: this.transformResource,
		});

		this.mByTagsPagedCollectionController = new FilteredPageCollectionController<
			TResourceAggregateActivity,
			IAggregateActivity<TResource>,
			Api.IEntityReportByTagsRequest
		>({
			apiPath: `reports/${reportingPathFragment}/byTags`,
			client: this.mUserSession.webServiceHelper,
			itemUniqueIdentifierPropertyPath,
			transformer: this.transformResource,
		});

		this.mBySearchQueryPagedCollectionController = new FilteredPageCollectionController<
			TResourceAggregateActivity,
			IAggregateActivity<TResource>,
			Api.IEntityReportBySearchQueryRequest
		>({
			apiPath: `reports/${reportingPathFragment}/bySearchQuery`,
			client: this.mUserSession.webServiceHelper,
			itemUniqueIdentifierPropertyPath,
			transformer: this.transformResource,
		});
	}

	/** Unfiltered activity... i.e. not by tags, entitity, etc. */
	@computed
	public get activty() {
		return this.mPagedCollectionController.fetchResults;
	}

	@computed
	public get activtyByTags() {
		return this.mByTagsPagedCollectionController.fetchResults;
	}

	@computed
	public get activtyBySearchQuery() {
		return this.mBySearchQueryPagedCollectionController.fetchResults;
	}

	@computed
	public get isFetchingActivity() {
		return this.mBySearchQueryPagedCollectionController.isFetching;
	}

	@computed
	public get isFetchingActivityByTags() {
		return this.mByTagsPagedCollectionController.isFetching;
	}

	@computed
	public get isFetchingBySearchQuery() {
		return this.mBySearchQueryPagedCollectionController.isFetching;
	}

	@action
	public reset = () => {
		this.mBySearchQueryPagedCollectionController.reset();
		this.mByTagsPagedCollectionController.reset();
		this.mPagedCollectionController.reset();
	};

	public getActivity = (request?: Api.IEntityReportRequest, pageSize?: number, params?: Api.IDictionary<string>) => {
		return this.mPagedCollectionController.getNext(request, pageSize, params);
	};

	public getActivityById = (request: Api.IEntityReportByIdRequest) => {
		return new Promise<IAggregateActivity<TResource>>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<TResourceAggregateActivity>) => {
				runInAction(() => {
					if (opResult.success) {
						resolve(this.transformResource(opResult.value));
					} else {
						reject(opResult);
					}
				});
			};

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<TResourceAggregateActivity>(
				`reports/${this.mReportingPathFragment}/byEntity`,
				'POST',
				request,
				onFinish,
				onFinish
			);
		});
	};

	public getActivityByTags = (
		request?: Api.IEntityReportByTagsRequest,
		pageSize?: number,
		params?: Api.IDictionary<string>
	) => {
		return this.mByTagsPagedCollectionController.getNext(request, pageSize, params);
	};

	public getActivityBySearchQuery = (
		request?: Api.IEntityReportBySearchQueryRequest,
		pageSize?: number,
		params?: Api.IDictionary<string>
	) => {
		return this.mBySearchQueryPagedCollectionController.getNext(request, pageSize, params);
	};

	/** Subclasses should override this and provide a proper transformation */
	protected transformResource(model: any): IAggregateActivity<TResource> {
		return model;
	}
}

/** Only supports getActivityBySearchQuery and getActivity */
export class EmployeeReportingViewModel extends ResourceReportingViewModel<
	Api.IEmployeeAggregateActivity,
	UserViewModel
> {
	constructor(userSession: UserSessionContext) {
		super(userSession, 'employee', 'resource.id');
	}

	protected transformResource(
		contactAggregateActivity: Api.IEmployeeAggregateActivity
	): IAggregateActivity<UserViewModel> {
		const restProps = Api.excludeKeysOf(contactAggregateActivity, ['employee']);
		const result: IAggregateActivity<UserViewModel> = {
			...restProps,
			resource: new UserViewModel(this.mUserSession, contactAggregateActivity.employee),
		};
		return result;
	}
}

export enum KeepInTouchCommitmentInterval {
	Never = 0,
	Daily,
	MultipleTimesAWeek,
	Weekly,
}

export enum KeepInTouchCommitmentIntervalError {
	None = 0,
	SelectAtLeastOneDay,
	SelectAtLeasTwoDays,
	NeverNotSatisfied,
}

export class KeepInTouchCommitmentPreferencesViewModel {
	@observable
	private mKitCommitmentPreferences: Api.IKeepInTouchCommitmentPreferences;
	@observable protected busy: boolean;
	@observable protected mInterval: KeepInTouchCommitmentInterval;
	protected mUser: Api.IUser;
	protected mUserSession: UserSessionContext;

	constructor(userSession: UserSessionContext, user: Api.IUser) {
		this.mUser = user;
		this.mUserSession = userSession;
		this.reset();
	}

	@computed
	public get weeklyDays() {
		return this.mKitCommitmentPreferences.weeklyDays;
	}

	@action
	public setWeeklyDays = (weeklyDays: Api.DayOfWeek[]) => {
		this.mKitCommitmentPreferences.weeklyDays = weeklyDays || [];
	};

	@computed
	public get calendarBlockBusy() {
		return this.mKitCommitmentPreferences.calendarBlockBusy;
	}

	@action
	public setCalendarBlockBusy = (value: boolean) => {
		this.mKitCommitmentPreferences.calendarBlockBusy = value;
	};

	@computed
	public get calendarBlockInMinutes() {
		return this.mKitCommitmentPreferences.calendarBlockInMinutes;
	}

	@action
	public setCalendarBlockInMinutes = (value: number) => {
		this.mKitCommitmentPreferences.calendarBlockInMinutes = value;
	};

	@computed
	public get isDaily() {
		return this.mKitCommitmentPreferences.daily;
	}

	@action
	public setIsDaily = (value: boolean) => {
		this.mKitCommitmentPreferences.daily = value;
	};

	@computed
	public get isBusy() {
		return this.busy;
	}

	@computed
	public get error() {
		switch (this.mInterval) {
			case KeepInTouchCommitmentInterval.Daily: {
				this.mKitCommitmentPreferences.weeklyDays = [];
				break;
			}
			case KeepInTouchCommitmentInterval.MultipleTimesAWeek: {
				if ((this.mKitCommitmentPreferences.weeklyDays || []).length < 2) {
					return KeepInTouchCommitmentIntervalError.SelectAtLeasTwoDays;
				}
				break;
			}
			case KeepInTouchCommitmentInterval.Weekly: {
				if ((this.mKitCommitmentPreferences.weeklyDays || []).length < 1) {
					return KeepInTouchCommitmentIntervalError.SelectAtLeastOneDay;
				}
				break;
			}
			default: {
				if (
					this.mKitCommitmentPreferences.calendarBlockBusy !== false ||
					this.mKitCommitmentPreferences.daily !== false ||
					this.mKitCommitmentPreferences.weeklyDays !== null ||
					this.mKitCommitmentPreferences.weeklyDays.length > 0
				) {
					return KeepInTouchCommitmentIntervalError.NeverNotSatisfied;
				}
				break;
			}
		}

		return KeepInTouchCommitmentIntervalError.None;
	}

	@computed
	public get interval() {
		return this.mInterval;
	}

	@action
	public setInterval = (value: KeepInTouchCommitmentInterval) => {
		this.mInterval = value;
		if (value === KeepInTouchCommitmentInterval.Daily) {
			this.mKitCommitmentPreferences.daily = true;
			this.mKitCommitmentPreferences.weeklyDays = [];
		} else if (value === KeepInTouchCommitmentInterval.Never) {
			this.mKitCommitmentPreferences.calendarBlockBusy = false;
			this.mKitCommitmentPreferences.daily = false;
			this.mKitCommitmentPreferences.weeklyDays = [];
		} else {
			this.mKitCommitmentPreferences.daily = false;
			const weekdayCount = (this.mKitCommitmentPreferences.weeklyDays || []).length;
			if (value === KeepInTouchCommitmentInterval.Weekly && weekdayCount > 1) {
				this.mKitCommitmentPreferences.weeklyDays = [];
			}
		}
	};

	@action
	public reset = () => {
		this.mKitCommitmentPreferences = {
			calendarBlockBusy: false,
			daily: false,
			weeklyDays: [Api.DayOfWeek.Monday],
			...(this.mUser.userPreferences.keepInTouchCommitmentPreferences || {}),
		};

		if (this.mKitCommitmentPreferences.daily) {
			this.mKitCommitmentPreferences.weeklyDays = [];
			this.mInterval = KeepInTouchCommitmentInterval.Daily;
		} else {
			if (!this.mKitCommitmentPreferences.weeklyDays || this.mKitCommitmentPreferences.weeklyDays.length === 0) {
				this.mInterval = KeepInTouchCommitmentInterval.Never;
			} else {
				this.mInterval =
					(this.mKitCommitmentPreferences.weeklyDays || []).length > 1
						? KeepInTouchCommitmentInterval.MultipleTimesAWeek
						: KeepInTouchCommitmentInterval.Weekly;
			}
		}
	};

	public save = () => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IKeepInTouchCommitmentPreferences>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<Api.IKeepInTouchCommitmentPreferences>) => {
					runInAction(() => {
						this.busy = false;
						if (result.success) {
							this.mUser.userPreferences.keepInTouchCommitmentPreferences = result.value;
							resolve(result.value);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IKeepInTouchCommitmentPreferences>(
					`user/${this.mUser.id}/keepInTouchCommitmentPreferences`,
					'PUT',
					this.toJs(),
					onFinish,
					onFinish
				);
			});
		}

		return null;
	};

	public toJs = () => {
		return { ...this.mKitCommitmentPreferences };
	};
}

export class EntityRichContentViewModel<
	TEntity extends Api.IEntity = Api.IEntity,
	TEntityViewModel extends EntityViewModel<TEntity> = EntityViewModel<TEntity>,
> {
	@observable.ref
	private mRichContentPageCollectionController: ObservablePageCollectionController<
		Api.IRichContent,
		RichContentViewModel
	>;
	private mEntity: TEntityViewModel;

	constructor(entity: TEntityViewModel) {
		this.mEntity = entity;

		this.mRichContentPageCollectionController = new ObservablePageCollectionController<
			Api.IRichContent,
			RichContentViewModel
		>({
			apiPath: `richcontent/by${entity instanceof CompanyViewModel ? 'Company' : 'Contact'}/${this.mEntity.id}`,
			client: this.mEntity.userSession.webServiceHelper,
			transformer: this.mCreateRichContentVm,
		});
	}

	@computed
	public get controller() {
		return this.mRichContentPageCollectionController;
	}

	private mCreateRichContentVm = (richContentModel: Api.IRichContent) => {
		if (richContentModel && richContentModel._type === 'ActionItem') {
			return new ActionItemViewModel(this.mEntity.userSession, richContentModel as Api.IActionItem);
		}
		return new NoteViewModel(this.mEntity.userSession, richContentModel as Api.INote);
	};
}

export class EntityTimelineViewModel<
	TEntity extends Api.IEntity = Api.IEntity,
	TEntityViewModel extends EntityViewModel<TEntity> = EntityViewModel<TEntity>,
> {
	@observable
	private mTimelinePageCollectionController: ObservablePageCollectionController<
		Api.ITimelineEvent,
		TimelineEventViewModel
	>;
	private mEntity: TEntityViewModel;

	constructor(entity: TEntityViewModel) {
		this.mEntity = entity;

		this.mTimelinePageCollectionController = new ObservablePageCollectionController<
			Api.ITimelineEvent,
			TimelineEventViewModel
		>({
			apiPath: `timeline/${entity instanceof CompanyViewModel ? 'Company' : 'Contact'}/${this.mEntity.id}`,
			client: this.mEntity.userSession.webServiceHelper,
			httpMethod: 'GET',
			transformer: x => this.mCreateEventVm(x),
		});
	}

	@computed
	public get controller() {
		return this.mTimelinePageCollectionController;
	}

	protected mCreateEventVm = (event: Api.ITimelineEvent) => {
		switch (event?._type) {
			case 'ActionItemEvent': {
				return new ActionItemEventViewModel(this.mEntity.userSession, event as Api.IActionItemEvent);
			}
			case 'ConversationThreadEvent': {
				return new ConversationThreadEventViewModel(this.mEntity.userSession, event as Api.IConversationThreadEvent);
			}
			case 'SentEmailEvent': {
				return new SentEmailEventViewModel(this.mEntity.userSession, event as Api.ISentEmailEvent);
			}
			case 'HtmlNewsletterEvent': {
				return new HtmlNewsletterEventViewModel(this.mEntity.userSession, event as Api.IHtmlNewsletterEvent);
			}
			case 'NoteEvent': {
				return new NoteEventViewModel(this.mEntity.userSession, event as Api.INoteEvent);
			}
			case 'PhoneCallCompletedEvent': {
				return new PhoneCallCompletedEventViewModel(this.mEntity.userSession, event as Api.INoteEvent);
			}
			case 'UntrackedPhoneCallEvent': {
				return new UntrackedPhoneCallEventViewModel(this.mEntity.userSession, event as Api.INoteEvent);
			}
			case 'DealCreatedEvent': {
				return new DealCreatedEventViewModel(this.mEntity.userSession, event as Api.INoteEvent);
			}
			case 'DealUpdatedEvent': {
				return new DealUpdatedEventViewModel(this.mEntity.userSession, event as Api.INoteEvent);
			}
			case 'SkipLeadEvent': {
				return new SkipLeadEventViewModel(this.mEntity.userSession, event as Api.INoteEvent);
			}
			case 'PhoneCallEvent': {
				return new PhoneCallEventViewModel(this.mEntity.userSession, event);
			}
			case 'MeetingEvent': {
				return new MeetingEventViewModel(this.mEntity.userSession, event);
			}
			case 'FollowUpEvent': {
				return new FollowUpEventViewModel(this.mEntity.userSession, event);
			}
			case 'CancelledFollowUpEvent': {
				return new CancelledFollowUpEventViewModel(this.mEntity.userSession, event);
			}
			case 'RescheduledFollowUpEvent': {
				return new RescheduledFollowUpEventViewModel(this.mEntity.userSession, event);
			}
			case 'SurveyResponseEvent': {
				return new SurveyResponseEventViewModel(this.mEntity.userSession, event as Api.ISurveyResponseEvent);
			}
			case 'SatisfactionSurveyResponseEvent': {
				return new SatisfactionSurveyResponseEventViewModel(
					this.mEntity.userSession,
					event as Api.ISatisfactionSurveyResponseEvent
				);
			}
			case 'HandwrittenCardOrderEvent': {
				return new HandwrittenCardOrderEventViewModel(this.mEntity.userSession, event);
			}
			default: {
				break;
			}
		}

		return new TimelineEventViewModel(this.mEntity.userSession, event);
	};
}

export enum RichContentComposerResult {
	None = 0,
	Edit,
	Delete,
	Create,
}

export type RichContentComposerCompletion<
	TModel extends Api.IRichContent = Api.IRichContent,
	TViewModel extends RichContentViewModel<TModel> = RichContentViewModel<TModel>,
> = (richContent?: TViewModel, composerResult?: RichContentComposerResult) => void;

export class RichContentComposerViewModel<
	TModel extends Api.IRichContent = Api.IRichContent,
	TViewModel extends RichContentViewModel<TModel> = RichContentViewModel<TModel>,
> {
	@observable.ref protected mRichContentViewModel: TViewModel;
	protected mOnCompleteCallback: RichContentComposerCompletion<TModel, TViewModel>;
	protected mUserSession: UserSessionContext;

	constructor() {
		this.mCreateRichContentViewModel = this.mCreateRichContentViewModel.bind(this);
		this.mShowWithReferencedEntities = this.mShowWithReferencedEntities.bind(this);
		this.showWithReferencedEntities = this.showWithReferencedEntities.bind(this);
	}

	@computed
	public get richContent() {
		return this.mRichContentViewModel;
	}

	@action
	public show = (richContent: TViewModel, onComplete?: RichContentComposerCompletion<TModel, TViewModel>) => {
		this.mRichContentViewModel = richContent;
		this.mOnCompleteCallback = onComplete;
	};

	@action
	public showWithReferencedEntities(
		referencedEntities: EntityViewModel[],
		contactIds?: string[],
		companyIds?: string[],
		onComplete?: RichContentComposerCompletion<TModel, TViewModel>
	) {
		return this.mShowWithReferencedEntities(referencedEntities, contactIds, companyIds, onComplete);
	}

	@action
	public reset = () => {
		this.mRichContentViewModel = null;
		this.mOnCompleteCallback = null;
	};

	public setUserSession = (userSession: UserSessionContext) => {
		this.mUserSession = userSession;
	};

	public onComplete = (richContent?: TViewModel, composerResult?: RichContentComposerResult) => {
		if (this.mOnCompleteCallback) {
			this.mOnCompleteCallback(richContent, composerResult);
		}

		this.reset();
	};

	/** Subclasses should implement this */
	protected mCreateRichContentViewModel(_: TModel): TViewModel;
	protected mCreateRichContentViewModel(): TViewModel {
		throw new Error('Not Implemented');
	}

	protected mShowWithReferencedEntities(
		referencedEntities: EntityViewModel[],
		contactIds?: string[],
		companyIds?: string[],
		onComplete?: RichContentComposerCompletion<TModel, TViewModel>
	) {
		const entities: EntityViewModel[] = [...(referencedEntities || [])];
		const loadPromise = new Bluebird<EntityViewModel[]>((resolve, _, onCancel) => {
			let canceled = false;
			if (onCancel) {
				onCancel(() => {
					canceled = true;
				});
			}

			const count = (contactIds || []).length + (companyIds || []).length;
			if (count === 0) {
				if (!canceled) {
					resolve(entities);
				}
				return;
			}

			const promises = [
				ContactsViewModel.getAllByIds(this.mUserSession, contactIds),
				CompaniesViewModel.getAllByIds(this.mUserSession, companyIds),
			];
			const promise = Promise.all<EntityViewModel[]>(promises);
			promise.then(loadedEntityCollections => {
				if (!canceled) {
					loadedEntityCollections.forEach(x => x.forEach(y => entities.push(y)));
					resolve(entities);
				}
			});
		});

		loadPromise.then(allEntities => {
			if (!this.mRichContentViewModel) {
				const richContentModel = Api.createRichContentWithReferencedEntities<TModel>(
					(allEntities || []).map(x => x.toJs())
				);
				const richContentViewModel = this.mCreateRichContentViewModel(richContentModel);
				this.show(richContentViewModel, onComplete);
			}
		});
		return loadPromise;
	}
}

export class NoteComposerViewModel extends RichContentComposerViewModel<Api.INote, NoteViewModel> {
	protected mCreateRichContentViewModel(noteModel: Api.INote) {
		return new NoteViewModel(this.mUserSession, noteModel);
	}
}

export class ActionItemComposerViewModel extends RichContentComposerViewModel<Api.IActionItem, ActionItemViewModel> {
	protected mCreateRichContentViewModel(actionItemModel: Api.IActionItem) {
		return new ActionItemViewModel(this.mUserSession, actionItemModel);
	}
}

export interface IDropdownOption<T> {
	title: string;
	value?: T;
}

export const campaignDateRangeOptions: IDropdownOption<number>[] = [
	{
		title: 'Last 30 days',
		value: 30,
	},
	{
		title: 'Last 90 days',
		value: 90,
	},
	{
		title: 'Last 180 days',
		value: 180,
	},
];

export class CampaignsReportingViewModel<
	TViewModel extends CampaignViewModel = CampaignViewModel,
> extends PagedViewModel<Api.ICampaign, TViewModel, Api.ICampaignReportRequest> {
	@observable.ref private mCampaignsCategoryFilters: Api.EmailReportingCategory[];
	@observable private mCampaignsReportType:
		| Api.BulkEmailReportType.Campaigns
		| Api.BulkEmailReportType.IndividualEmails = Api.BulkEmailReportType.Campaigns;
	@observable protected mSelectedUserId: string = undefined;
	@observable public mSelectedCampaignStatus: Api.EmailSendStatus;
	@observable public pageSize: number;
	@observable.ref protected mSelectedDateRangeOption: IDropdownOption<number> = campaignDateRangeOptions[0];
	@observable.ref public dateRange: { startDate?: Date; endDate?: Date };
	@observable.ref public resourceSelectorId: string;

	constructor(userSession: UserSessionContext, user?: Partial<Api.IUser>) {
		super(userSession);
		this.reset = this.reset.bind(this);

		this.mSelectedUserId = user?.id || (this.isAdmin ? undefined : userSession.user.id);
		this.mCampaignTransformer = this.mCampaignTransformer.bind(this);
		this.pageSize = 100;

		this.mPagedCollectionController = new FilteredPageCollectionController<
			Api.ICampaign,
			TViewModel,
			Api.ICampaignReportRequest
		>({
			apiPath: this.apiPathBase,
			client: this.mUserSession.webServiceHelper,
			transformer: this.mCampaignTransformer,
		});
	}

	@computed
	public get campaignsReportType() {
		return this.mCampaignsReportType;
	}

	@computed
	public get campaignsCategoryFilters() {
		return this.mCampaignsCategoryFilters;
	}

	@computed
	public get request(): Api.ICampaignReportRequest {
		const property =
			this.mCampaignsReportType === Api.BulkEmailReportType.Campaigns
				? Api.BulkEmailFilterProperty.OnlyCampaigns
				: Api.BulkEmailFilterProperty.IndividualSends;

		const filter: Api.IFilterCriteria<Api.BulkEmailFilterProperty> = {
			criteria: [{ property }],
			op: Api.FilterOperator.And,
		};

		if (this.mSelectedUserId) {
			filter.criteria.push({
				property: Api.BulkEmailFilterProperty.User,
				value: this.mSelectedUserId,
			});
		}

		if (this.mCampaignsCategoryFilters?.length > 0) {
			const categoryFilter: Api.IFilterCriteria<Api.BulkEmailFilterProperty> = {
				criteria: [],
				op: Api.FilterOperator.Or,
			};
			this.mCampaignsCategoryFilters.forEach(categoryOrProperty => {
				categoryFilter.criteria.push(
					categoryOrProperty === Api.BulkEmailFilterProperty.OtherIndividualSends
						? {
								property: Api.BulkEmailFilterProperty.OtherIndividualSends,
							}
						: {
								property: Api.BulkEmailFilterProperty.Category,
								value: categoryOrProperty,
							}
				);
			});

			filter.criteria.push(categoryFilter);
		}

		if (this.mSelectedCampaignStatus) {
			filter.criteria.push({
				property: Api.BulkEmailFilterProperty.Status,
				value: this.mSelectedCampaignStatus,
			});
		}

		if (this.resourceSelectorId) {
			filter.criteria.push({
				property: Api.BulkEmailFilterProperty.ResourceSelector,
				value: this.resourceSelectorId,
			});
		}

		const request = {
			endDate: this.dateRange?.endDate,
			filter,
			startDate: this.dateRange?.startDate,
			userId: this.mSelectedUserId,
		};

		return request;
	}

	@computed
	public get selectedDateRangeOption() {
		return this.mSelectedDateRangeOption;
	}

	@action
	public setSelectedDateRangeOption(value: IDropdownOption<number>) {
		this.mSelectedDateRangeOption = value;
		this.dateRange = {
			startDate: moment().startOf('day').subtract(this.mSelectedDateRangeOption.value, 'days').toDate(),
		};
	}

	@action
	load(params?: Api.IDictionary): Promise<Api.IPageCollectionControllerFetchResult<Api.ICampaign[]>> {
		return this.fetch(params) as any;
	}

	public set campaignReportType(type: Api.BulkEmailReportType.Campaigns | Api.BulkEmailReportType.IndividualEmails) {
		this.mCampaignsReportType = type;
	}

	public set categoryFilters(categories: Api.EmailReportingCategory[]) {
		this.mCampaignsCategoryFilters = categories;
	}

	protected mCampaignTransformer(campaign: Api.ICampaign) {
		return new CampaignViewModel(this.userSession, campaign).impersonate(this.mImpersonationContext);
	}

	public readonly apiPathBase = 'reports/campaign';

	public get dateRangeOptions() {
		return campaignDateRangeOptions;
	}

	public set selectedUserId(userId: string | undefined) {
		this.mSelectedUserId = userId;
	}

	public get selectedCampaignStatus() {
		return this.mSelectedCampaignStatus;
	}

	public set selectedCampaignStatus(status: Api.EmailSendStatus) {
		this.mSelectedCampaignStatus = status;
	}

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate(impersonationContext);
		this.mPagedCollectionController.impersonate(impersonationContext);
		return this;
	}

	public reset(): void {
		super.reset();
		this.mCampaignsCategoryFilters = [];
		this.mCampaignsReportType = null;
		this.mSelectedDateRangeOption = null;
		this.mSelectedUserId = null;
	}
}

export interface ICampaignEmailRecipient {
	contact: ContactViewModel;
	emailAddress: string;
	hasReplied?: boolean;
	displayName?: string;
}

/** One email in a larger campaign */
export class CampaignEmailViewModel extends ViewModel {
	private mLoadingPromise: Promise<Api.ICampaignEmailContent>;
	@observable.ref private mModel: Partial<Api.ICampaignEmailContent>;
	@observable.ref private mRecipients: ICampaignEmailRecipient[];

	constructor(userSession: UserSessionContext, model: Partial<Api.ICampaignEmailContent>) {
		super(userSession);
		this.mSetModel = this.mSetModel.bind(this);
		this.mSetModel(model);
	}

	@computed
	public get hasContent() {
		return !!this.mModel.htmlContent;
	}

	@computed
	public get sender() {
		return this.mModel.creator;
	}

	@computed
	public get subject() {
		return this.mModel.subject;
	}

	@computed
	public get status() {
		return this.mModel.status;
	}

	@computed
	public get senderName() {
		if (!this.sender) {
			return undefined;
		}

		return VmUtils.getDisplayName(this.sender);
	}

	@computed
	public get senderEmail() {
		const sender = this.sender;
		return sender && sender.primaryEmail ? sender.primaryEmail.value : undefined;
	}

	@computed
	public get tags() {
		return this.mModel.tags;
	}

	@computed
	public get htmlContent() {
		return this.mModel.htmlContent;
	}

	// TODO: remove contactViewModel when the api stops returning it
	@computed
	public get contactViewModel() {
		return this.recipients && this.recipients.length > 0 ? this.recipients[0].contact : null;
	}

	@computed
	public get displayName() {
		return this.recipients && this.recipients.length > 0
			? this.recipients[0].displayName ?? this.recipients[0].contact.name
			: null;
	}

	@computed
	public get recipients() {
		return this.mRecipients;
	}

	@computed
	public get clickDate() {
		return this.mModel?.clickDate ? new Date(this.mModel.clickDate) : null;
	}

	@computed
	public get clickTargets() {
		return this.mModel?.clickTargets;
	}

	@action
	public load(): Promise<Api.ICampaignEmailContent> {
		if (this.mLoadingPromise) {
			return this.mLoadingPromise;
		}

		this.loaded = false;
		this.busy = true;
		this.loading = true;

		this.mLoadingPromise = new Promise((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.ICampaignEmailContent>) => {
				runInAction(() => {
					this.loading = false;
					this.mLoadingPromise = null;
					this.busy = false;
					if (opResult.success) {
						this.mSetModel(opResult.value);
						this.loaded = true;
						resolve(opResult.value);
					} else {
						this.loaded = false;
						reject(opResult);
					}
				});
			};

			this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.ICampaignEmailContent>(
				`reports/campaign/transactions/${this.id}`,
				'GET', // Method
				null, // Body (null if it's a GET operation)
				onFinish,
				onFinish
			);
		});

		return this.mLoadingPromise;
	}

	public get id() {
		return this.mModel.id;
	}

	public get sentDate() {
		return this.mModel.sentDate;
	}

	public get viewedDate() {
		return this.mModel.openDate;
	}

	public get repliedDate() {
		return this.mModel.repliedDate;
	}

	public get isCustom() {
		return this.mModel.isCustom;
	}

	public toJs() {
		return this.mModel;
	}

	public applyUpdate = (update: Api.IEmailUpdate) => {
		if (update.id === this.mModel?.id) {
			this.mModel = {
				...this.mModel,
				...Api.selectKeysOf(update, ['openDate', 'repliedDate', 'sentDate', 'status']),
			};
		}
	};

	private mSetModel(model: Partial<Api.ICampaignEmailContent>) {
		this.mModel = model;
		this.mRecipients = (model.toRecipients || []).map<ICampaignEmailRecipient>(x => {
			return {
				contact: new ContactViewModel(this.userSession, x.contact),
				emailAddress: x.sentToEmailAddress,
				hasReplied: x.hasReplied,
				displayName: x.displayName,
			};
		});
	}
}

export class CampaignEmailsByStatusViewModel extends PagedViewModel<
	Api.ICampaignEmail,
	CampaignEmailViewModel,
	Api.ICampaignTransactionsRequest
> {
	@observable public readonly status: Api.EmailTransactionStatus;
	@observable.ref private mLastCampaignUpdate: Api.IBulkEmailUpdate;
	private mId: string;
	private mIsGroup: boolean;

	@observable
	private mIndex: number;

	@observable
	public fragment?: string;

	public pageSize = 25;

	public isClicked: boolean;

	constructor(
		userSession: UserSessionContext,
		campaignId: string,
		status: Api.EmailTransactionStatus,
		isGroup = false,
		isClicked = false
	) {
		super(userSession);

		this.mId = campaignId;
		this.status = status;
		this.mIndex = 0;
		this.mIsGroup = isGroup;
		this.isClicked = isClicked;

		this.mPagedCollectionController = new FilteredPageCollectionController<
			Api.ICampaignEmail,
			CampaignEmailViewModel,
			Api.ICampaignTransactionsRequest
		>({
			apiPath: 'reports/campaign/Transactions',
			client: this.mUserSession.webServiceHelper,
			transformer: this.transformer,
		});
	}

	@computed
	public get itemsCount() {
		if (this.mLastCampaignUpdate) {
			switch (this.status) {
				case Api.EmailTransactionStatus.Bounced:
				case Api.EmailTransactionStatus.Failed: {
					return this.mLastCampaignUpdate.failedCount;
				}
				case Api.EmailTransactionStatus.Sent: {
					return this.mLastCampaignUpdate.sentSuccessfullyCount;
				}
				case Api.EmailTransactionStatus.Opened: {
					return this.mLastCampaignUpdate.openCount;
				}
				case Api.EmailTransactionStatus.Queued: {
					return (
						this.mLastCampaignUpdate.totalEmails -
						this.mLastCampaignUpdate.failedCount -
						this.mLastCampaignUpdate.sentSuccessfullyCount
					);
				}
				case Api.EmailTransactionStatus.Replied: {
					return this.mLastCampaignUpdate.replyCount;
				}
				default: {
					break;
				}
			}
		}
		return this.mPagedCollectionController.totalCount;
	}

	@computed
	public get selectedItem() {
		const index = this.selectedIndex;
		return index >= 0 ? this.items.getByIndex(index) : undefined;
	}

	@computed
	public get selectedIndex() {
		if (!this.isLoaded || this.items.length < 1) {
			return -1;
		}

		if (this.mIndex >= this.items.length) {
			return this.items.length - 1;
		}

		return this.mIndex;
	}

	public set selectedIndex(index: number) {
		this.mIndex = index;
	}

	public get request(): Api.ICampaignTransactionsRequest {
		const request: Api.ICampaignTransactionsRequest = {
			expandGroup: this.mIsGroup,
			fragment: this.fragment,
			id: this.mId,
			status: this.status,
		};
		if (this.isClicked) {
			request.clickedTransactions = true;
			request.status = null;
		}
		return request;
	}

	@action
	public applyUpdates = (campaignUpdate?: Api.IBulkEmailUpdate, emailUpdates?: Api.IEmailUpdate[]) => {
		if (campaignUpdate) {
			this.mLastCampaignUpdate = campaignUpdate;
		}
		emailUpdates?.forEach(x => this.mPagedCollectionController.fetchResults.getById(x.id)?.applyUpdate(x));
	};

	private transformer = (model: Api.ICampaignEmail) => {
		return new CampaignEmailViewModel(this.mUserSession, model);
	};

	@action
	public reset() {
		super.reset();
		this.mLastCampaignUpdate = null;
	}
}

/** The individual campaign reporting page with tabs including queued, sent, replied */
export class CampaignViewModel extends ViewModel {
	@observable protected mLoadingDefaultMessageContent: boolean;
	@observable.ref protected mModel?: Api.ICampaign;
	@observable.ref
	protected mDefaultMessageContent: Api.IRawRichTextContentState;
	@observable.ref protected mEmailBodyTemplate: Api.ITemplate;
	@observable.ref protected mLoadingPromise: Promise<Api.ICampaign>;

	constructor(userSession: UserSessionContext, model: Api.ICampaign) {
		super(userSession);
		this.mSetModel(model);
	}

	@computed
	public get isBusy() {
		return this.busy || this.loading || this.mLoadingDefaultMessageContent;
	}

	@computed
	public get emailBodyTemplate() {
		return this.mEmailBodyTemplate;
	}

	@computed
	public get isLoadingDefaultMessageContent() {
		return !!this.mLoadingDefaultMessageContent;
	}

	@computed
	public get id() {
		return this.mModel?.id;
	}

	@computed
	public get templateReference() {
		return this.mModel?.templateReference;
	}

	@computed
	public get defaultMessageContent() {
		return this.mDefaultMessageContent;
	}

	@computed
	public get lastDetailedStatus() {
		return this.mModel?.lastDetailedStatus;
	}

	@computed
	public get subject() {
		return this.mModel?.subject;
	}

	@computed
	public get name() {
		return this.mModel?.name;
	}

	@computed
	public get creator() {
		return this.mModel?.creator;
	}

	@computed
	public get totalCount() {
		return this.mModel?.totalEmails;
	}

	@computed
	public get repliedCount() {
		return this.mModel?.replyCount;
	}

	@computed
	public get openCount() {
		return this.mModel?.openCount;
	}

	@computed
	public get sentCount() {
		return this.mModel?.sentSuccessfullyCount;
	}

	@computed
	public get noEmailAddressCount() {
		return this.mModel?.noEmailAddressCount || 0;
	}

	@computed
	public get queuedCount() {
		return this.totalCount - this.sentCount - this.failedCount - this.noEmailAddressCount;
	}

	@computed
	public get filterRequest() {
		return this.mModel?.filterRequest;
	}

	@computed
	public get failedCount() {
		return this.mModel?.failedCount;
	}

	@computed
	public get isFinishedSending() {
		return !!this.mModel?.completedDate;
	}

	@computed
	public get completedDate() {
		return this.mModel?.completedDate ? new Date(this.mModel.completedDate) : null;
	}

	@computed
	public get cancelDate() {
		return this.mModel?.cancelledDate ? new Date(this.mModel.cancelledDate) : null;
	}

	@computed
	public get creationDate() {
		return this.mModel?.creationDate ? new Date(this.mModel.creationDate) : null;
	}

	@computed
	public get lastModifiedDate() {
		return this.mModel?.lastModifiedDate ? new Date(this.mModel.lastModifiedDate) : null;
	}

	@computed
	public get lastCommErrorDate() {
		return this.mModel?.lastCommErrorDate;
	}

	@computed
	public get schedule() {
		return this.mModel?.schedule;
	}

	@computed
	public get isScheduledToSend() {
		return !this.mModel?.cancelledDate && !this.mModel?.completedDate && !!this.mModel?.schedule;
	}

	@computed
	public get expirationDate() {
		return this.mModel?.schedule?.expirationDate;
	}

	@computed
	public get sendToHousehold() {
		return this.mModel?.sendToHousehold;
	}

	@computed
	public get canEditEmailContent() {
		const readyOrQueued = this.status === Api.EmailSendStatus.Ready || this.status === Api.EmailSendStatus.Queued;
		return (
			readyOrQueued &&
			this.creator?.id === this.mUserSession?.user?.id &&
			// editing newsletter content from here is disabled for now
			!this.categories?.some(x => x === Api.EmailCategories.HtmlNewsletter)
		);
	}

	@computed
	public get categories() {
		return this.mModel?.categories;
	}

	@action
	public cancelSchedule = () => {
		return this.mUpdateSchedule('DELETE');
	};

	@computed
	public get actor() {
		return this.mModel?.actor;
	}

	@computed
	public get approvalRequests() {
		return this.mModel?.approvalRequests;
	}

	@computed
	public get status() {
		return this.mModel?.status;
	}

	@computed
	public get hasStarted() {
		if (this.isScheduledToSend) {
			return new Date(this.mModel.schedule.startDate) < new Date();
		}
		return false;
	}

	@computed
	public get groupId() {
		return this.mModel?.groupId;
	}

	@computed
	public get endResolutionDate() {
		return this.mModel?.endResolutionDate ? new Date(this.mModel.endResolutionDate) : null;
	}

	@computed
	public get hasResolvedTransactions() {
		return this.mModel?.hasResolvedTransactions;
	}

	@computed
	public get isInFinalStatus() {
		return (
			this.status === Api.EmailSendStatus.Complete ||
			this.status === Api.EmailSendStatus.Cancelled ||
			this.status === Api.EmailSendStatus.Rejected
		);
	}

	@computed
	public get machineOpenObserved() {
		return this.mModel?.machineOpenObserved;
	}

	public toJs() {
		return this.mModel;
	}

	@action
	public load(): Promise<Api.ICampaign> {
		if (!this.mLoadingPromise) {
			this.loaded = false;
			this.busy = true;
			this.loading = true;

			this.mLoadingPromise = new Promise((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.ICampaign>) => {
					runInAction(() => {
						this.mLoadingPromise = null;
						this.loading = false;
						this.busy = false;
						if (opResult.success) {
							this.mSetModel(opResult.value);
							this.loaded = true;
							resolve(opResult.value);
						} else {
							this.loaded = false;
							reject(opResult);
						}
					});
				};

				this.userSession.webServiceHelper.callWebServiceWithOperationResults<Api.ICampaign>(
					this.composeApiUrl({ urlPath: `email/${this.mModel.id}` }),
					'GET', // Method
					null, // Body (null if it's a GET operation)
					onFinish,
					onFinish
				);
			});
		}

		return this.mLoadingPromise;
	}

	@action
	public setCampaign = (campaign: Api.ICampaign) => {
		this.mSetModel(campaign);
	};

	public loadEmailBodyTemplate = async () => {
		if (!this.isBusy && this.mModel?.templateReference?.templateId) {
			this.busy = true;
			try {
				const opResult = await this.mLoadEmailBodyTemplate();
				this.busy = false;
				return opResult;
			} catch (err) {
				this.busy = false;
				throw Api.asApiError(err);
			}
		}
	};

	public loadDefaultMessageContent = (forceReload?: boolean) => {
		if (this.mDefaultMessageContent && !forceReload) {
			return Promise.resolve<Api.IOperationResult<Api.IRawRichTextContentState>>({
				success: true,
				systemCode: 200,
				value: this.mDefaultMessageContent,
			});
		}

		if (!this.isBusy) {
			this.mLoadingDefaultMessageContent = true;
			const promise = new Promise<Api.IOperationResult<Api.IRawRichTextContentState>>((resolve, reject) => {
				this.userSession.webServiceHelper
					.callWebServiceAsync<Api.IRawRichTextContentState>(
						this.composeApiUrl({ urlPath: `reports/campaign/${this.mModel.id}/content` }),
						'GET'
					)
					.then(opResult => {
						runInAction(() => {
							this.mLoadingDefaultMessageContent = false;
							this.mDefaultMessageContent = opResult.value;
							resolve(opResult);
						});
					})
					.catch(e => {
						this.mLoadingDefaultMessageContent = false;
						const err = Api.asApiError(e);
						reject(err);
					});
			});
			return promise;
		}
	};

	private mLoadEmailBodyTemplate = async (): Promise<Api.IOperationResult<Api.ITemplate>> => {
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ITemplate>(
			this.composeApiUrl({ urlPath: `template/${this.mModel?.templateReference?.templateId}` }),
			'GET'
		);

		if (!opResult.success) {
			throw opResult;
		}

		this.mEmailBodyTemplate = opResult.value;
		return opResult;
	};

	private mUpdateSchedule = (method: 'PUT' | 'DELETE', schedule?: Api.IScheduledSend, timeZone?: string) => {
		if (!this.isBusy) {
			this.busy = true;
			const promise = new Promise<Api.IOperationResult<Api.ICampaign>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.ICampaign>) => {
					if (opResult.success) {
						this.busy = false;
						this.mSetModel(opResult.value);
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ICampaign>(
					this.composeApiUrl({
						queryParams: {
							timeZone,
						},
						urlPath: `email/${this.mModel.id}/scheduledsend`,
					}),
					method,
					method === 'PUT' ? schedule : null,
					onFinish,
					onFinish
				);
			});
			return promise;
		}
	};

	private mSetModel = (model: Api.ICampaign) => {
		this.mModel = model;
	};
}

export class EmailWorkloadViewModel extends Api.ImpersonationBroker {
	protected mUserSession: UserSessionContext;

	constructor(userSession: UserSessionContext) {
		super();
		this.mUserSession = userSession;
	}

	public getScheduledSendEstimate = (schedule: Api.IScheduledSend, numberOfEmails: number, timeZone?: string) => {
		return new Promise<Api.IOperationResult<Api.IEmailSendEstimate>>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.IEmailSendEstimate>) => {
				if (opResult.success) {
					resolve(opResult);
				} else {
					reject(opResult);
				}
			};
			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IEmailSendEstimate>(
				this.composeApiUrl({
					queryParams: {
						numberOfEmails,
						timeZone,
					},
					urlPath: 'email/scheduledsend/estimate',
				}),
				'POST',
				schedule,
				onFinish,
				onFinish
			);
		});
	};

	public getBulkScheduledSendEstimate = (
		email: Api.IEmailMessageCompose<Api.IFollowUpOptions>,
		numberOfEmails: number,
		timeZone?: string
	) => {
		return new Promise<Api.IOperationResult<Api.IEmailSendEstimate>>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.IEmailSendEstimate>) => {
				if (opResult.success) {
					resolve(opResult);
				} else {
					reject(opResult);
				}
			};
			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IEmailSendEstimate>(
				this.composeApiUrl({
					queryParams: {
						numberOfEmails,
						timeZone,
					},
					urlPath: 'email/scheduledsend/bulkEmailEstimate',
				}),
				'POST',
				email,
				onFinish,
				onFinish
			);
		});
	};

	public getWorkload = (timeZone?: string) => {
		return new Promise<Api.IOperationResult<Api.IEmailWorkload>>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.IEmailWorkload>) => {
				if (opResult.success) {
					resolve(opResult);
				} else {
					reject(opResult);
				}
			};

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IEmailWorkload>(
				this.composeApiUrl({ queryParams: { timeZone }, urlPath: 'email/user/workload' }),
				'GET',
				null,
				onFinish,
				onFinish
			);
		});
	};
}

export class ImportContactsViewModel<TFile extends Blob = Blob> extends ViewModel {
	@observable.ref protected mFile: TFile;
	@observable.ref private mOptions: ImportContactsOptionsViewModel;
	@observable.ref private mPreview: Api.IImportContactPreview;
	@observable.ref private mSystemJob: SystemJobViewModel;
	private mAccountId?: string;
	private mUserId?: string;

	constructor(userSession: UserSessionContext, accountId?: string, userId?: string) {
		super(userSession);
		this.importFromFile = this.importFromFile.bind(this);
		this.mOptions = new ImportContactsOptionsViewModel(this.mUserSession);

		this.mAccountId = accountId;
		this.mUserId = userId;
	}

	@computed
	public get options() {
		return this.mOptions;
	}

	@computed
	public get preview() {
		return this.mPreview;
	}

	@computed
	public get systemJob() {
		return this.mSystemJob;
	}

	@computed
	public get file() {
		return this.mFile;
	}

	public importFromFile(
		file: TFile,
		contentType?: Api.ImportContactsContentType,
		deleteRenewalKeyFacts?: boolean
	): Promise<Api.IOperationResult<Api.IImportContactPreview>> {
		if (this.isBusy) {
			return Promise.resolve(undefined);
		}

		this.busy = true;
		if (contentType) {
			this.mOptions.setContentType(contentType);
		}
		if (deleteRenewalKeyFacts) {
			this.mOptions.setDeleteRenewalKeyFacts(deleteRenewalKeyFacts);
		}
		const promise = new Promise<Api.IOperationResult<Api.IImportContactPreview>>((resolve, reject) => {
			const data = new FormData();
			data.append('file', file);

			const onFinish = action((opResult: Api.IOperationResult<Api.IImportContactPreview>) => {
				this.busy = false;
				if (opResult.success) {
					this.mFile = file;
					if (this.mSetPreview(opResult.value)) {
						resolve(opResult);
					} else {
						reject({
							success: false,
							systemCode: 500,
							systemMessage:
								'Please make sure the spreadsheet you uploaded has a header row. If it does, please make sure at least one header is something we can recognize, like "Name" or "Email".',
						});
					}
				} else {
					reject(opResult);
				}
			});

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IImportContactPreview>(
				this.getUrl(),
				'POST',
				data,
				onFinish,
				onFinish
			);
		});

		return promise;
	}

	public executeImport = (mergeByName = true) => {
		if (!this.isBusy && this.mPreview && this.mPreview.id) {
			this.mOptions.setAllowMatchingByName(mergeByName);
			this.busy = true;
			const promise = new Promise<Api.IOperationResult<Api.IImportContactsJob>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IImportContactsJob>) => {
					this.busy = false;
					if (opResult.success) {
						// copy over the options from the system job...
						// polling will wipe these values out if we don't
						this.mOptions = new ImportContactsOptionsViewModel(
							this.mUserSession,
							opResult.value.options || this.mOptions.toJs()
						);
						this.mSetSystemJob(opResult.value);
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IImportContactsJob>(
					this.getUrl(this.mPreview.id),
					'PUT',
					this.mOptions.toJs(),
					onFinish,
					onFinish
				);
			});
			return promise;
		}
	};

	protected getUrl(jobId?: string) {
		const url = this.mAccountId
			? `admin/contactOperations/${this.mAccountId}/${this.mUserId}/importFromFile`
			: 'contact/importFromFile';
		return !jobId ? url : `${url}/${jobId}`;
	}

	protected mSetPreview = (preview: Api.IImportContactPreview) => {
		this.mPreview = preview;
		this.loaded = true;

		// create suggested mapping
		const headerNameToField: Api.IDictionary<Api.IImportContactFieldConfig> = {};
		(this.mPreview.header || []).forEach(headerName => {
			let cleanedHeaderName = headerName || '';
			if (headerName.includes('[[Tag]]')) {
				cleanedHeaderName = 'Tags';
			}
			const lowerHeaderName = cleanedHeaderName.toLocaleLowerCase().trim();
			if (lowerHeaderName) {
				const matchingField = (this.mPreview.fields || []).find(field => {
					const matchParams = new Set([
						(field.propertyName || '').toLocaleLowerCase(),
						(field.name || '').toLocaleLowerCase(),
						...(field.aliases || []).map(a => a.toLocaleLowerCase()),
					]);
					return matchParams.has(lowerHeaderName);
				});
				if (matchingField) {
					headerNameToField[headerName] = matchingField;
				}
			}
		});
		this.mOptions.setHeaderToFieldMapping(headerNameToField);
		return Object.keys(headerNameToField).length > 0;
	};

	private mSetSystemJob = (systemJob: Api.IImportContactsJob) => {
		this.mSystemJob = new SystemJobViewModel(this.mUserSession, systemJob);
	};
}

export class ImportContactsOptionsViewModel implements Api.IImportContactsOptions {
	@observable private mOptions: Api.IImportContactsOptions;
	@observable.ref
	private mHeaderNameToFieldMap: Api.IDictionary<Api.IImportContactFieldConfig>;
	private mUserSession: UserSessionContext;

	constructor(userSession: UserSessionContext, options?: Api.IImportContactsOptions) {
		this.mUserSession = userSession;
		this.mReset(options);
	}

	@computed
	public get tags() {
		return this.mOptions.tags || [];
	}

	@action
	public setTags = (tags: string[]) => {
		this.mOptions.tags = tags;
	};

	@action
	public setContentType(contentType: Api.ImportContactsContentType) {
		this.mOptions.contentType = contentType;
	}

	@computed
	public get contentType() {
		return this.mOptions.contentType;
	}

	@computed
	public get headerNameToFieldMap() {
		return this.mHeaderNameToFieldMap;
	}

	@action
	public setHeaderToFieldMapping(headerNameToFieldMap: Api.IDictionary<Api.IImportContactFieldConfig>) {
		this.mHeaderNameToFieldMap = headerNameToFieldMap || {};
		this.mOptions.fieldMapping = Object.keys(this.mHeaderNameToFieldMap).reduce<Api.IDictionary<string>>(
			(result, headerName) => {
				if (this.mHeaderNameToFieldMap[headerName]) {
					result[headerName] = this.mHeaderNameToFieldMap[headerName].propertyName;
				}
				return result;
			},
			{}
		);
	}

	@computed
	/** { [headerName]: propertyName } */
	public get fieldMapping() {
		return this.mOptions.fieldMapping;
	}

	@action
	public setUseEmailAddressAsAlternativeKey(useEmailAddressAsAlternativeKey: boolean) {
		this.mOptions.useEmailAddressAsAlternativeKey = useEmailAddressAsAlternativeKey;
	}

	@computed
	public get useEmailAddressAsAlternativeKey() {
		return this.mOptions.useEmailAddressAsAlternativeKey;
	}

	@action
	public setVisibility(visibility: string) {
		this.mOptions.visibility = visibility;
	}

	@computed
	public get visibility() {
		return this.mOptions.visibility;
	}

	@action
	public setPreserveExistingFieldValues(values: Api.IPreservableFieldValues[]) {
		this.mOptions.preserveExistingFieldValues = values;
	}

	@computed
	public get preserveExistingFieldValues() {
		return this.mOptions.preserveExistingFieldValues;
	}

	@action
	public setAllowMatchingByName(allowMatchingByName: boolean) {
		this.mOptions.allowMatchingByName = allowMatchingByName;
	}

	@computed
	public get deleteRenewalKeyFacts() {
		return this.mOptions.deleteRenewalKeyFacts;
	}
	@action
	public setDeleteRenewalKeyFacts(deleteRenewalKeyFacts: boolean) {
		this.mOptions.deleteRenewalKeyFacts = deleteRenewalKeyFacts;
	}

	@computed
	public get allowMatchingByName() {
		return this.mOptions.allowMatchingByName;
	}

	@action
	public setExcludeBaseTag(value: boolean) {
		this.mOptions.excludeBaseTag = value;
	}

	@computed
	public get excludeBaseTag() {
		return this.mOptions.excludeBaseTag;
	}

	@action
	public reset = () => {
		this.mReset();
	};

	public toJs = () => {
		return this.mOptions;
	};

	private mReset = (options?: Api.IImportContactsOptions) => {
		this.mOptions = {
			allowMatchingByName: true,
			contentType: Api.ImportContactsContentType.csv,
			excludeBaseTag: false,
			fieldMapping: {},
			preserveExistingFieldValues: [],
			tags: [],
			useEmailAddressAsAlternativeKey: false,
			visibility: VmUtils.getDefaultVisibility(this.mUserSession.user),
			...(options || {}),
		};
		this.mHeaderNameToFieldMap = {};
	};
}

export class TagOwnerReportSystemJobViewModel extends SystemJobViewModel<Api.ISystemJob> {
	@observable private mReportPdfDownloadUrl: string;
	private accountId?: string;
	private baseRoute: string;
	private query: string;

	public static createWithOwnerReportId = (userSession: UserSessionContext, ownerReportId: string) => {
		return new TagOwnerReportSystemJobViewModel(userSession, {
			additionalFields: {
				OwnerReportId: ownerReportId,
			},
		});
	};

	/**
	 * @param {string} accountId - Optional parameter used for Levitate system admins to run report for a specific
	 *   account.
	 */
	constructor(userSession: UserSessionContext, systemJob?: Api.ISystemJob, accountId?: string) {
		super(userSession, systemJob);

		this.accountId = accountId;
		this.baseRoute = this.accountId ? `admin/tagAlertsOperations/report` : `reports/tagOwner`;
		this.query = this.accountId ? `?accountId=${accountId}` : ``;
	}

	@computed
	public get ownerReportId() {
		return this.mSystemJob ? (this.mSystemJob.additionalFields || {})?.OwnerReportId : null;
	}

	@computed
	public get isLoaded() {
		return (this.mSystemJob && this.mSystemJob.id && !!this.ownerReportId) || this.loaded;
	}

	@computed
	public get ownerReportPdfDownloadUrl() {
		return this.mReportPdfDownloadUrl;
	}

	@action
	public runReport = () => {
		if (!this.mSystemJob && !this.isBusy) {
			this.loading = true;
			return new Promise<Api.IOperationResult<Api.ISystemJob>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.ISystemJob>) => {
					this.loading = false;
					if (opResult.success) {
						this.setSystemJob(opResult.value);
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});

				const path = `${this.baseRoute}/run${this.query}`;

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ISystemJob>(
					path,
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
		}
	};

	@action
	public getReportPdfDownloadUrl = () => {
		if (this.ownerReportId && !this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<string>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<string>) => {
					this.busy = false;
					if (opResult.success) {
						this.mReportPdfDownloadUrl = opResult.value;
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});

				const path = `${this.baseRoute}/${this.ownerReportId}/pdf${this.query}`;
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<string>(
					path,
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
		}
	};
}

export class UnclassifiedContactsViewModel extends ViewModel {
	private mPageCollectionController: FilteredPageCollectionController<Api.IClassifyContact, ClassifyContactViewModel>;

	constructor(userSession: UserSessionContext) {
		super(userSession);
		this.mPageCollectionController = new FilteredPageCollectionController<
			Api.IClassifyContact,
			ClassifyContactViewModel
		>({
			apiPath: 'contact/classification/suggestions/filter',
			client: this.userSession.webServiceHelper,
			transformer: this.createClassifyContactViewModel,
		});
	}

	@computed
	public get isBusy() {
		return this.busy || this.loading || this.mPageCollectionController.isFetching;
	}

	@computed
	public get totalCount() {
		return this.mPageCollectionController.totalCount;
	}

	@action
	public fetchUnclassifiedContacts(
		excludedIds: { excludeContactIds: string[] },
		pageSize?: number,
		params?: Api.IDictionary
	) {
		return this.mPageCollectionController.getNext(excludedIds, pageSize, params);
	}

	@computed
	public get unclassifiedContacts() {
		return this.mPageCollectionController.fetchResults;
	}

	@action
	public resetUnclassifiedContacts = () => {
		this.mPageCollectionController.reset();
	};

	private createClassifyContactViewModel = (item: Api.IClassifyContact) => {
		return new ClassifyContactViewModel(this.mUserSession, item);
	};
}

export class EmailScannersViewModel extends ViewModel {
	@observable.ref protected mAccount: Api.IAccount;
	@observable.ref protected mEmailScanners: Api.IEmailScanner[];
	@observable.ref protected mCustomLeadParsers: Api.ICustomLeadParser[];

	constructor(userSession: UserSessionContext, account: Api.IAccount) {
		super(userSession);
		this.mAccount = account;
		this.mEmailScanners = this.getEmailScanners();
		this.mCustomLeadParsers = this.getCustomLeadParsers();
	}

	@computed
	public get emailScanners() {
		return this.mEmailScanners;
	}

	@computed
	public get customLeadParsers() {
		return this.mCustomLeadParsers;
	}

	protected baseApiRoute() {
		return 'account/features/emailscan';
	}

	protected callAsync = async (path: string, method: Api.HTTPMethod, body?: any) => {
		this.busy = true;
		try {
			const value = await this.mUserSession.webServiceHelper.callAsync<Api.IEmailScanFeatures>(
				this.composeApiUrl({ urlPath: `${this.baseApiRoute()}${path}` }),
				method,
				body
			);
			runInAction(() => {
				this.mAccount.features.emailScan = {
					...this.mAccount.features.emailScan,
					...value,
				};
				this.mEmailScanners = this.getEmailScanners();
				this.mCustomLeadParsers = this.getCustomLeadParsers();
			});
		} finally {
			runInAction(() => {
				this.busy = false;
			});
		}
	};

	public createCustomLeadParser = (parserData: Api.ICustomLeadParser) =>
		this.callAsync('/customLeadParser', 'POST', parserData);
	public deleteCustomLeadParser = (id: string) => this.callAsync(`/customLeadParser/${id}`, 'DELETE');
	public enableCustomLeadParser = (id: string) => this.callAsync(`/customLeadParser/${id}/toggleEnable`, 'PATCH');
	public enableEmailScanner = (id: string) => this.callAsync(`/${id}/toggleEnable`, 'PATCH');
	public updateCustomLeadParser = (parserData: Api.ICustomLeadParser) =>
		this.callAsync(`/customLeadParser/${parserData.id}`, 'PUT', parserData);

	protected getCustomLeadParsers() {
		return this.mAccount?.features?.emailScan?.customLeadParsers || [];
	}

	protected getEmailScanners() {
		const scanners = this.getDefaultScannersByIndustry().concat(this.getEnabledScanners());
		return Array.from(new Set(scanners)).map(s => ({
			...s,
			isDisabled: !VmUtils.isIntegrationEnabledForAccount(s.id, this.mAccount),
		}));
	}

	protected getEnabledScanners() {
		const enabledScanners =
			this.mAccount.features?.emailScan?.enabledScanners?.filter(
				x => !Api.EmailScannersBlockedFromConfiguration.has(x)
			) ?? [];

		if (enabledScanners.length === 0) {
			return [];
		}

		return Api.AllEmailScanners.filter(
			scanner => enabledScanners.findIndex(enabledScanner => enabledScanner === scanner.id) >= 0
		);
	}

	protected getDefaultScannersByIndustry() {
		switch (this.mAccount.additionalInfo?.industry) {
			case Api.Industry.Accounting:
				return Api.AllEmailScanners.filter(x => {
					return x.id === Api.EmailScannerId.EndorsedLocalProviders;
				});
			case Api.Industry.RealEstate:
			case Api.Industry.Mortgage:
				return Api.AllEmailScanners.filter(x => {
					return (
						x.id !== Api.EmailScannerId.EndorsedLocalProviders &&
						x.id !== Api.EmailScannerId.TrustedChoice &&
						x.id !== Api.EmailScannerId.WizardCalls &&
						x.id !== Api.EmailScannerId.Everquote &&
						x.id !== Api.EmailScannerId.Datalot &&
						x.id !== Api.EmailScannerId.SmartVestor
					);
				});
			case Api.Industry.Insurance:
				return Api.AllEmailScanners.filter(x => {
					return (
						x.id === Api.EmailScannerId.EndorsedLocalProviders ||
						x.id === Api.EmailScannerId.TrustedChoice ||
						x.id === Api.EmailScannerId.WizardCalls ||
						x.id === Api.EmailScannerId.Everquote ||
						x.id === Api.EmailScannerId.Datalot ||
						x.id === Api.EmailScannerId.Forge3
					);
				});
			case Api.Industry.Financial:
				return Api.AllEmailScanners.filter(
					x => x.id === Api.EmailScannerId.SmartVestor || x.id === Api.EmailScannerId.EndorsedLocalProviders
				);
			case Api.Industry.Legal:
				return Api.AllEmailScanners.filter(x => x.id === Api.EmailScannerId.NOLO);
			default:
				return [];
		}
	}

	@action
	public loadStats = async () => {
		this.busy = true;
		try {
			const value = await this.mUserSession.webServiceHelper.callAsync<Api.IEmailScannerStats[]>(
				this.composeApiUrl({ urlPath: `${this.baseApiRoute()}/stats` }),
				'GET'
			);
			runInAction(() => {
				try {
					this.mEmailScanners = this.mEmailScanners.map(scanner => {
						const stats = value.find(stat => {
							return stat.emailScanner === scanner.id;
						});
						return stats ? { ...scanner, stats } : scanner;
					});

					this.mCustomLeadParsers = this.mCustomLeadParsers.map(parser => {
						const stats = value.find(stat => stat.id === parser.id);
						return stats ? { ...parser, stats } : parser;
					});
				} catch (error) {
					// FOLLOWUP: Resolve
					// @ts-ignore
					throw Api.asApiError(new Error(`Failed to map stats: ${error.message}`));
				}
			});
		} finally {
			this.busy = false;
		}
	};
}
export class AccountIntegrationsViewModel extends ViewModel {
	@observable.ref protected mAccount: Api.IAccount;
	@observable.ref protected mAms360Vm: ConfigurableIntegrationViewModel;
	@observable.ref protected mHawksoftVm: ConfigurableIntegrationViewModel;
	@observable.ref protected mRedtailVm: ConfigurableIntegrationViewModel;
	@observable.ref protected mEclipseVm: ConfigurableIntegrationViewModel;
	@observable.ref protected mClioVm: ConfigurableIntegrationViewModel;
	@observable.ref protected mEzLynxVm: ConfigurableIntegrationViewModel;
	@observable.ref protected mXanatekVm: ConfigurableIntegrationViewModel;
	@observable.ref protected mEpicVm: ConfigurableIntegrationViewModel;
	@observable.ref protected mQQVm: ConfigurableIntegrationViewModel;
	@observable.ref protected mHubspotVm: ConfigurableIntegrationViewModel;
	@observable.ref protected mSalesforceVm: ConfigurableIntegrationViewModel;
	@observable.ref protected mWealthboxVm: ConfigurableIntegrationViewModel;
	@observable.ref protected mQBOVm: ConfigurableIntegrationViewModel;
	@observable.ref protected mXeroVm: ConfigurableIntegrationViewModel;
	@observable.ref protected mMyCaseVm: ConfigurableIntegrationViewModel;
	@observable.ref protected mDonorPerfectVm: ConfigurableIntegrationViewModel;
	public readonly allIntegrations: ConfigurableIntegrationViewModel[];

	constructor(userSession: UserSessionContext, account: Api.IAccount) {
		super(userSession);
		this.mAccount = account;
		/** Note: if adding a new one, don't forget to add it to the readonly this.allIntegrations collection! */
		this.mAms360Vm = new ConfigurableIntegrationViewModel(userSession, account, Api.ConfigurableIntegrationType.Ams360);
		this.mHawksoftVm = new ConfigurableIntegrationViewModel(
			userSession,
			account,
			Api.ConfigurableIntegrationType.HawkSoft
		);
		this.mRedtailVm = new ConfigurableIntegrationViewModel(
			userSession,
			account,
			Api.ConfigurableIntegrationType.Redtail
		);
		this.mEclipseVm = new ConfigurableIntegrationViewModel(
			userSession,
			account,
			Api.ConfigurableIntegrationType.Eclipse
		);
		this.mClioVm = new ConfigurableIntegrationViewModel(userSession, account, Api.ConfigurableIntegrationType.Clio);
		this.mEzLynxVm = new ConfigurableIntegrationViewModel(userSession, account, Api.ConfigurableIntegrationType.EzLynx);
		this.mXanatekVm = new ConfigurableIntegrationViewModel(
			userSession,
			account,
			Api.ConfigurableIntegrationType.GeneralCsv
		);
		this.mEpicVm = new ConfigurableIntegrationViewModel(userSession, account, Api.ConfigurableIntegrationType.Epic);
		this.mQQVm = new ConfigurableIntegrationViewModel(userSession, account, Api.ConfigurableIntegrationType.QQ);
		this.mHubspotVm = new ConfigurableIntegrationViewModel(
			userSession,
			account,
			Api.ConfigurableIntegrationType.HubSpot
		);
		this.mSalesforceVm = new ConfigurableIntegrationViewModel(
			userSession,
			account,
			Api.ConfigurableIntegrationType.Salesforce
		);
		this.mWealthboxVm = new ConfigurableIntegrationViewModel(
			userSession,
			account,
			Api.ConfigurableIntegrationType.Wealthbox
		);
		this.mQBOVm = new ConfigurableIntegrationViewModel(
			userSession,
			account,
			Api.ConfigurableIntegrationType.QuickBooksOnline
		);
		this.mXeroVm = new ConfigurableIntegrationViewModel(userSession, account, Api.ConfigurableIntegrationType.Xero);
		this.mMyCaseVm = new ConfigurableIntegrationViewModel(userSession, account, Api.ConfigurableIntegrationType.MyCase);
		this.mDonorPerfectVm = new ConfigurableIntegrationViewModel(
			userSession,
			account,
			Api.ConfigurableIntegrationType.DonorPerfect
		);

		this.allIntegrations = [
			this.mAms360Vm,
			this.mHawksoftVm,
			this.mRedtailVm,
			this.mEclipseVm,
			this.mClioVm,
			this.mEzLynxVm,
			this.mXanatekVm,
			this.mEpicVm,
			this.mQQVm,
			this.mHubspotVm,
			this.mSalesforceVm,
			this.mWealthboxVm,
			this.mQBOVm,
			this.mXeroVm,
			this.mMyCaseVm,
			this.mDonorPerfectVm,
		];
	}

	public getByType(type: Api.ConfigurableIntegrationType) {
		return this.allIntegrations.find(x => x.type === type);
	}

	@computed
	public get ams360() {
		return this.mAms360Vm;
	}

	@computed
	public get hawksoft() {
		return this.mHawksoftVm;
	}

	@computed
	public get eclipse() {
		return this.mEclipseVm;
	}

	@computed
	public get redtail() {
		return this.mRedtailVm;
	}

	@computed
	public get clio() {
		return this.mClioVm;
	}

	@computed
	public get ezlynx() {
		return this.mEzLynxVm;
	}

	@computed
	public get xanatek() {
		return this.mXanatekVm;
	}

	@computed
	public get epic() {
		return this.mEpicVm;
	}

	@computed
	public get qq() {
		return this.mQQVm;
	}

	@computed
	public get hubspot() {
		return this.mHubspotVm;
	}

	@computed
	public get salesforce() {
		return this.mSalesforceVm;
	}

	@computed
	public get wealthbox() {
		return this.mWealthboxVm;
	}

	@computed
	public get qbo() {
		return this.mQBOVm;
	}

	@computed
	public get xero() {
		return this.mXeroVm;
	}

	@computed
	public get mycase() {
		return this.mMyCaseVm;
	}

	@computed
	public get donorPerfect() {
		return this.mDonorPerfectVm;
	}

	@computed
	public get account() {
		return this.mAccount;
	}

	protected getLoadRoute() {
		return 'account/integrations';
	}

	public async load() {
		if (!this.isBusy) {
			this.loading = true;
			try {
				const value = await this.mUserSession.webServiceHelper.callAsync<Api.IAccountIntegrations>(
					this.getLoadRoute(),
					'GET'
				);
				runInAction(() => {
					if (value?.ams360) {
						this.ams360.setIntegration(value.ams360);
					}
					if (value?.hawkSoft) {
						this.hawksoft.setIntegration(value.hawkSoft);
					}
					if (value?.redtail) {
						this.redtail.setIntegration(value.redtail);
					}
					if (value?.eclipse) {
						this.eclipse.setIntegration(value.eclipse);
					}
					if (value?.clio) {
						this.clio.setIntegration(value.clio);
					}
					if (value?.ezLynxCsv) {
						this.ezlynx.setIntegration(value.ezLynxCsv);
					}
					if (value?.epicCsv) {
						this.epic.setIntegration(value.epicCsv);
					}
					if (value?.qqCatalyst) {
						this.mQQVm.setIntegration(value.qqCatalyst);
					}
					if (value?.hubSpot) {
						this.mHubspotVm.setIntegration(value.hubSpot);
					}
					if (value?.wealthbox) {
						this.mWealthboxVm.setIntegration(value.wealthbox);
					}
					if (value?.mergeAccounting) {
						if (value?.mergeAccounting?.mergeAccountingProvider === Api.MergeAccountingProvider.QuickBooksOnline) {
							this.mQBOVm.setIntegration(value.mergeAccounting);
						}

						if (value?.mergeAccounting?.mergeAccountingProvider === Api.MergeAccountingProvider.Xero) {
							this.mXeroVm.setIntegration(value.mergeAccounting);
						}
					}
					if (value?.mergeCrm) {
						if (value?.mergeCrm?.mergeCrmProvider === Api.MergeCrmProvider.Salesforce) {
							this.mSalesforceVm.setIntegration(value.mergeCrm);
						}
					}
					if (value?.generalCsv) {
						if (value?.generalCsv?.generalCsvProvider === Api.GeneralCsvProvider.Xanatek) {
							this.mXanatekVm.setIntegration(value.generalCsv);
						}
					}
					if (value?.myCase) {
						this.mMyCaseVm.setIntegration(value.myCase);
					}
					if (value?.donorPerfect) {
						this.mDonorPerfectVm.setIntegration(value.donorPerfect);
					}
					return value;
				});
			} finally {
				runInAction(() => {
					this.busy = false;
				});
			}
		}
	}
}

export class ConfigurableIntegrationViewModel extends ViewModel {
	@observable.ref protected levitateUser: UserViewModel;
	@observable.ref protected mIntegration: Api.IConfigurableIntegration;
	@observable.ref protected mLoadingPromise: Promise<
		[Api.IOperationResult<Api.IAccount>, Api.IConfigurableIntegration]
	>;
	@observable.ref protected mAccount: Api.IAccount;
	/*
	 * This is only used for FE decisions. Any API interactions should rely on apiType
	 */
	public readonly type: Api.ConfigurableIntegrationType;
	protected readonly apiType: Api.ConfigurableIntegrationType;
	protected mUsers: ObservableCollection<ConfigurableIntegrationUserViewModel>;
	@observable.ref public availableSegmentingTags: string[];

	constructor(
		userSession: UserSessionContext,
		account: Api.IAccount,
		type: Api.ConfigurableIntegrationType,
		integration?: Api.IConfigurableIntegration
	) {
		super(userSession);
		this.mAccount = account;
		this.mUsers = new ObservableCollection([], 'id');
		this.type = type;
		this.apiType = Api.resolveIntegrationProviderToApiType(type);

		if (integration) {
			this.setIntegration(integration);
		}
	}

	@computed
	public get account() {
		return this.mAccount;
	}

	@computed
	public get isLoaded() {
		return this.mAccount?.id && this.mAccount?.companyName && !!this.mIntegration.agencyId;
	}

	@computed
	public get users() {
		return this.mUsers;
	}

	@computed
	public get enabled() {
		return this.mIntegration?.enabled;
	}

	@computed
	public get configured() {
		return this.mIntegration?.configured;
	}

	@computed
	public get invalidOwnerUsers() {
		const users = this.mUsers.filter(x => {
			return this.mIntegration.invalidOwnerships.map(y => y.integrationOwnerId).includes(x.id);
		});
		return VmUtils.dedupeById(users);
	}

	@action
	public reset = () => {
		this.mUsers = new ObservableCollection([], 'id');
	};

	public toJs = () => {
		return this.mIntegration;
	};
	@computed
	public get availableTags(): ObservableCollection<Api.IConfigurableIntegration> {
		return this.availableTags;
	}

	@action
	public async getTags() {
		if (!this.isBusy) {
			this.loading = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<string[]>(
				this.getAvailableIntegrationTags(),
				'GET'
			);
			this.loading = false;
			if (!opResult.success) {
				throw opResult;
			}
			this.availableSegmentingTags = opResult.value;
			return opResult;
		}
		return null;
	}

	public getUserMap = () => {
		return this.mUsers.map<Api.IIntegrationUserMapping>(x => {
			return {
				integrationId: x.id,
				levitateUser: x.levitateUser?.id ? { id: x.levitateUser.id } : null,
			};
		});
	};

	protected getConfigureRoute(): string {
		return `account/integrations/configure/${this.apiType}`;
	}

	public async configure(body: Partial<Api.IConfigurableIntegration>) {
		if (!this.isBusy) {
			this.loading = true;
			let generalBody: Api.IGeneralCsvIntegration = null;
			let mergeAccountingBody: Api.IMergeAccountingIntegration = null;
			let mergeCrmBody: Api.IMergeCrmIntegration = null;
			if (Api.isGeneralCsvIntegration(this.apiType)) {
				generalBody = {
					generalCsvProvider: this.type as unknown as Api.GeneralCsvProvider, // same underlying value
					...body,
				};
			}
			if (Api.isMergeAccountingIntegration(this.type)) {
				mergeAccountingBody = {
					...body,
					mergeAccountingProvider: this.type as unknown as Api.MergeAccountingProvider,
				};
			}
			if (Api.isMergeCrmIntegration(this.type)) {
				mergeCrmBody = {
					...body,
					mergeCrmProvider: this.type as unknown as Api.MergeCrmProvider,
				};
			}
			try {
				const value = await this.mUserSession.webServiceHelper.callAsync<Api.IConfigurableIntegration>(
					this.getConfigureRoute(),
					'POST',
					generalBody ?? mergeAccountingBody ?? mergeCrmBody ?? body
				);
				runInAction(() => {
					this.setIntegration(value);
				});
				return value;
			} finally {
				runInAction(() => {
					this.loading = false;
				});
			}
		}
	}

	protected getLoadAccountRoute() {
		return `account/${this.account.id}`;
	}

	protected getLoadIntegrationRoute() {
		return `account/integrations/${this.apiType}`;
	}

	public async load() {
		if (!this.isBusy && this.account.id) {
			this.loading = true;
			const accountPromise = this.mUserSession.webServiceHelper.callAsync<Api.IAccount>(
				this.getLoadAccountRoute(),
				'GET'
			);

			const integrationPromise = this.mUserSession.webServiceHelper.callAsync<Api.IConfigurableIntegration>(
				this.getLoadIntegrationRoute(),
				'GET'
			);

			try {
				const values = await Promise.all([accountPromise, integrationPromise]);
				runInAction(() => {
					this.loading = false;
					this.mLoadingPromise = null;

					this.mAccount = values[0];
					this.setIntegration(values[1]);
				});
				return values;
			} finally {
				runInAction(() => {
					this.loading = false;
				});
			}
		}
	}

	@action
	public setIntegration(integration: Api.IConfigurableIntegration) {
		this.mUsers.clear();

		if (integration) {
			this.mUsers.addAll(
				(integration.userMap || []).map(x => {
					return new ConfigurableIntegrationUserViewModel(this.mUserSession, this.mAccount, x);
				})
			);
		}
		this.mIntegration = integration;
	}

	protected getSetUserMapRoute() {
		return `account/integrations/${this.apiType}/userMap`;
	}

	public setUserMap = async (userMap: Api.IIntegrationUserMapping[] = this.getUserMap()) => {
		if (!this.isBusy) {
			this.busy = true;
			try {
				const value = await this.mUserSession.webServiceHelper.callAsync<Api.IIntegrationUserMapping[]>(
					this.getSetUserMapRoute(),
					'PUT',
					userMap
				);
				runInAction(() => {
					this.mIntegration = {
						...this.mIntegration,
						userMap,
					};
					return value;
				});
			} finally {
				runInAction(() => {
					this.busy = false;
				});
			}
		}
	};

	protected getAvailableIntegrationTags() {
		return `impersonate/${this.account.id}/account/integrations/availableTags`;
	}
}

export class ConfigurableIntegrationUserViewModel extends ViewModel {
	@observable.ref protected mIntegrationUser: Api.IIntegrationUserMapping;
	@observable.ref public levitateUser: UserViewModel;
	@observable.ref public readonly account: Api.IAccount;
	constructor(userSession: UserSessionContext, account: Api.IAccount, integrationUser: Api.IIntegrationUserMapping) {
		super(userSession);
		this.account = account;
		this.mSetUser(integrationUser);
	}

	@computed
	public get id() {
		return this.mIntegrationUser?.integrationId;
	}

	@computed
	public get status(): Api.ActivationStatus {
		return this.levitateUser?.activationStatus;
	}

	@computed
	public get statusDescription(): 'Connected' | 'Not Connected' | 'Invited' | 'Deactivated' {
		switch (this.status) {
			case Api.ActivationStatus.ACTIVE: {
				return 'Connected';
			}
			case Api.ActivationStatus.CANCELED:
			case Api.ActivationStatus.DEACTIVATED: {
				return 'Deactivated';
			}
			case Api.ActivationStatus.INIT: {
				return 'Invited';
			}
			default: {
				break;
			}
		}

		return 'Not Connected';
	}

	protected mSetUser(integrationUser: Api.IIntegrationUserMapping) {
		this.mIntegrationUser = integrationUser;
		this.levitateUser =
			integrationUser.levitateUser && integrationUser.levitateUser.id
				? new UserViewModel(this.mUserSession, integrationUser.levitateUser)
				: undefined;
	}
}

export class AutomationTemplateEditorStep<T extends AutomationStepViewModel = AutomationStepViewModel> {
	@observable public readonly uuid: string;
	@observable.ref public automationStep: T;
	constructor(step?: T) {
		this.uuid = uuidgen();
		this.automationStep = step;
	}
}

export abstract class AutomationStepViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
	TAutomationStep extends Api.IAutomationStep = Api.IAutomationStep,
> extends ViewModel {
	@observable public readonly uuid: string;
	@observable public showRequiredFieldError: boolean;
	@observable.ref protected mModel: TAutomationStep;
	@observable.ref
	protected mAutomationTemplate: AutomationTemplateViewModel<TUserSession>;
	@observable.shallow protected mConditionalSteps: Partial<{
		[key in Api.AutomationStepOutcome]: AutomationStepViewModel;
	}>;
	protected readonly: boolean;
	public readonly templateId: string;
	public static StepTypesSupportingImmediateScheduleCriteria = [
		Api.AutomationStepType.AddTag,
		Api.AutomationStepType.RemoveTag,
		Api.AutomationStepType.Email,
		Api.AutomationStepType.Texting,
	];

	constructor(userSession: TUserSession, model: TAutomationStep, templateId: string, readonly = false) {
		super(userSession);
		this.templateId = templateId;
		this.readonly = readonly;
		this.mConditionalSteps = {};
		this.uuid = uuidgen();
		this.impersonate = this.impersonate.bind(this);
		this.clone = this.clone.bind(this);
		this.update = this.update.bind(this);
		this.mSetModel = this.mSetModel.bind(this);
		this.mSetAutomationTemplate = this.mSetAutomationTemplate.bind(this);
		this.mSetModel(model);
	}

	@computed
	public get indentationLevel() {
		if (this.automationTemplate) {
			let level = 1;
			let template = this.automationTemplate;
			const ids = new Set<string>([template.id]);
			while (template !== null) {
				if (template.parentTemplate && template.parentTemplate.id && !ids.has(template.parentTemplate.id)) {
					template = template.parentTemplate;
					ids.add(template.id);
					level++;
				} else {
					template = null;
				}
			}
			return level;
		}
		return 0;
	}

	@computed
	public get automationTemplate() {
		return this.mAutomationTemplate;
	}

	public set automationTemplate(template: AutomationTemplateViewModel<TUserSession>) {
		this.mSetAutomationTemplate(template);
	}

	@computed
	public get canEdit() {
		return !this.readonly && (this.isAdmin || this.isImpersonatingAccountAdmin);
	}

	@computed
	public get conditionalSteps() {
		return this.mConditionalSteps;
	}

	@computed
	public get id() {
		return this.mModel?.id;
	}

	@computed
	public get name() {
		return this.mModel?.name;
	}

	@computed
	public get schedule() {
		return this.mModel?.schedule;
	}

	@computed
	public get templateVersionId() {
		return this.mModel?.templateVersionId;
	}

	@computed
	public get sendFromOptions() {
		return this.mModel?.sendFromOptions;
	}

	public get type() {
		return this.mGetType();
	}

	public get model() {
		return this.mModel;
	}

	/** Note: you cannot change conditional steps using this endpoint */
	@action
	public update(step: TAutomationStep) {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<TAutomationStep>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<TAutomationStep>) => {
					this.busy = false;
					if (opResult.success) {
						this.mSetModel(opResult.value);
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<TAutomationStep>(
					this.composeApiUrl({ urlPath: `AutomationTemplate/${this.templateId}/Step/${this.mGetType()}/${this.id}` }),
					'PUT',
					step,
					onFinish,
					onFinish
				);
			});
		}
	}

	@action
	public addConditionalStep = async (step: Api.IAutomationStep, outcome: Api.AutomationStepOutcome) => {
		if (!this.isBusy) {
			this.busy = true;

			const stepType = VmUtils.Automations.steps.getAutomationStepTypeForAutomationStep(step);
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<TAutomationStep>(
				this.composeApiUrl({
					urlPath: `AutomationTemplate/${this.templateId}/Step/${this.id}/${encodeURIComponent(
						outcome
					)}/${encodeURIComponent(stepType)}`,
				}),
				'POST',
				step
			);

			this.busy = false;
			if (opResult.success) {
				this.mSetModel(opResult.value);
				return opResult;
			} else {
				throw opResult;
			}
		}
	};

	@action
	public removeConditionalStep = async (step: Api.IAutomationStep | AutomationStepViewModel) => {
		if (!this.isBusy) {
			this.busy = true;

			const map = this.conditionalSteps || {};
			const matchingConditionalStep = Object.values(map).find(x => x.id === step.id);
			if (!matchingConditionalStep) {
				throw Api.asApiError('Step provided does not appear to be a conditional step for the target step.');
			}
			const outcome = Object.keys(map).find(
				x => map[x as Api.AutomationStepOutcome]?.id === matchingConditionalStep.id
			) as Api.AutomationStepOutcome;

			try {
				const opResult = await (step instanceof AutomationStepViewModel
					? (step as AutomationStepViewModel).delete()
					: this.mUserSession.webServiceHelper.callWebServiceAsync(
							this.composeApiUrl({
								urlPath: `AutomationTemplate/${
									this.templateId
								}/Step/${VmUtils.Automations.steps.getAutomationStepTypeForAutomationStep(step)}/${step.id}`,
							}),
							'DELETE'
						));
				this.busy = false;

				if (opResult.success) {
					const model: TAutomationStep = {
						...this.mModel,
						conditionalSteps: {
							...this.mModel.conditionalSteps,
						},
					};
					delete model.conditionalSteps[outcome];
					this.mSetModel(model);
					return model;
				} else {
					throw opResult;
				}
			} catch (error) {
				this.busy = false;
				throw Api.asApiError(error);
			}
		}
	};

	@action
	public delete = () => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<Api.IAutomationTemplate>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomationTemplate>) => {
					this.busy = false;
					if (opResult.success) {
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({ urlPath: `AutomationTemplate/${this.templateId}/Step/${this.mGetType()}/${this.id}` }),
					'DELETE',
					null,
					onFinish,
					onFinish
				);
			});
		}
	};

	@computed
	public get hasAllRequiredInfo() {
		return VmUtils.Automations.steps.hasRequiredInfo(this.toJs());
	}

	public clone<T extends AutomationStepViewModel = AutomationStepViewModel>() {
		return createAutomationStepViewModel(this, toJS(this.toJs()), this.templateId, this.readonly) as any as T;
	}

	public toJs() {
		return {
			...this.mModel,
			conditionalSteps: {
				...this.mModel.conditionalSteps,
			},
		};
	}

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate(impersonationContext);
		if (this.mConditionalSteps) {
			Object.keys(this.mConditionalSteps).forEach(x => {
				this.mConditionalSteps[x as Api.AutomationStepOutcome]?.impersonate(impersonationContext);
			});
		}
		return this;
	}

	protected abstract mGetType(): Api.AutomationStepType;

	protected mSetAutomationTemplate(template: AutomationTemplateViewModel<TUserSession>) {
		this.mAutomationTemplate = template;
		Object.keys(this.mConditionalSteps || {}).forEach(x => {
			const step = this.mConditionalSteps?.[x as Api.AutomationStepOutcome];
			if (step) {
				step.automationTemplate = template;
			}
		});
	}

	public mSetModel(model: TAutomationStep) {
		this.mModel = model;
		const conditionalSteps: Partial<{
			[key in Api.AutomationStepOutcome]: AutomationStepViewModel;
		}> = {};
		if (this.mModel?.conditionalSteps) {
			Object.keys(this.mModel.conditionalSteps).forEach(x => {
				const key = x as Api.AutomationStepOutcome;
				const step = createAutomationStepViewModel(
					this,
					this.mModel.conditionalSteps[key],
					this.templateId,
					this.readonly
				);
				conditionalSteps[key] = step;
			});
		}

		this.mConditionalSteps = conditionalSteps;
	}
}

export class TextingAutomationStepViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends AutomationStepViewModel<TUserSession, Api.ITextingAutomationStep> {
	@computed
	public get content() {
		return this.mModel?.content;
	}

	@computed
	public get attachments() {
		return this.mModel?.attachments;
	}

	@action
	public addAttachments(attachments: AttachmentsToBeUploadedViewModel<File>) {
		if (!this.isBusy && attachments?.count > 0) {
			this.busy = true;
			return new Promise<Api.IOperationResult<Api.ITextingAutomationStep>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.ITextingAutomationStep>) => {
					this.busy = false;
					if (opResult.success) {
						this.mSetModel(opResult.value);
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});

				const formData = new FormData();
				attachments.attachments.forEach(x => formData.append('files', x));
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({ urlPath: `AutomationTemplate/${this.templateId}/Step/Texting/${this.id}/Attachment` }),
					'POST',
					formData,
					onFinish,
					onFinish
				);
			});
		}
	}

	@action
	public removeAttachments(attachmentId: string, stepId: string) {
		if (!this.isBusy && attachmentId) {
			this.busy = true;
			return new Promise<Api.IOperationResult<Api.ITextingAutomationStep>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.ITextingAutomationStep>) => {
					this.busy = false;
					if (opResult.success) {
						this.mSetModel(opResult.value);
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({
						urlPath: `AutomationTemplate/${
							this.templateId
						}/Step/${this.mGetType()}/${stepId}/Attachment/${attachmentId}`,
					}),
					'DELETE',
					null,
					onFinish,
					onFinish
				);
			});
		}
	}

	protected mGetType(): Api.AutomationStepType {
		return Api.AutomationStepType.Texting;
	}
}

export class SwitchAutomationStepViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends AutomationStepViewModel<TUserSession, Api.ISwitchAutomationStep> {
	@observable.ref protected mTemplates: AutomationTemplateViewModel[];

	constructor(userSession: TUserSession, model: Api.ISwitchAutomationStep, templateId: string, readonly?: boolean) {
		super(userSession, model, templateId, readonly);
	}

	protected mSetAutomationTemplate(template: AutomationTemplateViewModel<TUserSession>) {
		super.mSetAutomationTemplate(template);
		this.mTemplates.forEach(x => {
			x.parentTemplate = template;
			if (!x.supportedStepModelTypes && template?.supportedStepModelTypes) {
				x.supportedStepModelTypes = template.supportedStepModelTypes;
			}
		});
	}

	@computed
	public get hasAllRequiredInfo() {
		return this.mTemplates?.reduce<boolean>((result, x) => {
			return (
				result &&
				x.draftStepReferences?.reduce<boolean>((res, y) => {
					return res && VmUtils.Automations.steps.hasRequiredInfo(y);
				}, true)
			);
		}, true);
	}

	@computed
	public get cases() {
		return this.mModel?.cases;
	}

	@computed
	public get templates() {
		return this.mTemplates;
	}

	@computed
	public get isLoaded() {
		return this.loaded && this.mTemplates.every(x => x.isLoaded);
	}

	@action
	public loadAllTemplates = async () => {
		if (!this.isBusy) {
			this.busy = true;

			try {
				const results = await Promise.all(this.mTemplates.map(x => x.load()));
				this.busy = false;
				return results;
			} catch (err) {
				this.busy = false;
				throw err;
			}
		}
	};

	@action
	public addCase = async (caseStatement: Api.IAutomationStepCaseStatementBase) => {
		if (!this.isBusy) {
			this.busy = true;

			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ISwitchAutomationStep>(
				this.composeApiUrl({
					urlPath: `AutomationTemplate/${this.templateId}/Step/${this.mGetType()}/${this.id}/Case`,
				}),
				'POST',
				caseStatement
			);

			this.busy = false;
			if (opResult.success) {
				// gather new cases and filter out deleted
				let cases = this.mModel?.cases || [];
				let casesToAdd = [...(opResult.value.cases || [])];
				cases = cases.filter(x => !!casesToAdd.find(y => x.id === y.id));
				casesToAdd = casesToAdd.filter(x => !cases.find(y => y.id === x.id));

				// create new tempaltes and filter out deleted
				const templateIds = new Set(opResult.value.automationTemplateIds || []);
				const templates = [
					...(this.mTemplates || []).filter(x => templateIds.has(x.id)),
					...casesToAdd.map(x => this.createTemplateFromCase(x)),
				];

				const defaultCaseIndex = cases.findIndex(x => x.isDefault);
				if (defaultCaseIndex >= 0) {
					// move the default case to the end of the list
					casesToAdd.push(cases.splice(defaultCaseIndex, 1)[0]);
				}
				runInAction(() => {
					// intentionally modifying the model directly
					this.mModel = {
						...(this.mModel || {}),
						...opResult.value,
						automationTemplateIds: Array.from(templateIds),
						cases: [...cases, ...casesToAdd],
					};
					this.mTemplates = templates;
				});
				return opResult;
			} else {
				throw opResult;
			}
		}
	};

	@action
	public removeCase = async (caseStatement: Api.IAutomationStepCaseStatement) => {
		if (!this.isBusy) {
			this.busy = true;

			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ISwitchAutomationStep>(
				this.composeApiUrl({
					urlPath: `AutomationTemplate/${this.templateId}/Step/${this.mGetType()}/${this.id}/Case/${caseStatement.id}`,
				}),
				'DELETE'
			);

			this.busy = false;
			if (opResult.success) {
				runInAction(() => {
					// intentionally modifying the model directly
					this.mModel = {
						...this.mModel,
						automationTemplateIds: (this.mModel?.automationTemplateIds || []).filter(
							x => x !== caseStatement.automationTemplateId
						),
						cases: (this.mModel?.cases || []).filter(x => x.id !== caseStatement.id),
					};
					this.mTemplates = (this.mTemplates || []).filter(x => x.id !== caseStatement.automationTemplateId);
				});
				return opResult;
			} else {
				throw opResult;
			}
		}
	};

	@action
	public updateCase = async (id: string, caseStatement: Api.IAutomationStepCaseStatementBase) => {
		const index = this.mModel.cases.findIndex(x => x.id === id);
		if (!this.isBusy && index >= 0) {
			this.busy = true;

			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ISwitchAutomationStep>(
				this.composeApiUrl({
					urlPath: `AutomationTemplate/${this.templateId}/Step/${this.mGetType()}/${this.id}/Case/${id}`,
				}),
				'PUT',
				caseStatement
			);

			this.busy = false;
			if (opResult.success) {
				runInAction(() => {
					// intentionally modifying the model directly
					const cases = [...(this.mModel.cases || [])];
					cases.splice(
						index,
						1,
						opResult.value.cases.find(x => x.id === id)
					);
					this.mModel = {
						...this.mModel,
						cases,
					};
				});
				return opResult;
			} else {
				throw opResult;
			}
		}
	};

	public getTemplateForCase = (caseStatement: Api.IAutomationStepCaseStatement) => {
		return caseStatement?.automationTemplateId
			? this.mTemplates.find(x => x.id === caseStatement.automationTemplateId)
			: null;
	};

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate(impersonationContext);
		this.mTemplates.forEach(x => x.impersonate(impersonationContext));
		return this;
	}

	protected mGetType(): Api.AutomationStepType {
		return Api.AutomationStepType.Switch;
	}

	public mSetModel(model: Api.ISwitchAutomationStep) {
		super.mSetModel(model);
		this.mTemplates = model.cases?.length > 0 ? model.cases.map(this.createTemplateFromCase.bind(this)) : [];
	}

	protected createTemplateFromCase(caseStatement: Api.IAutomationStepCaseStatement) {
		const template = new AutomationTemplateViewModel(this.mUserSession, {
			id: caseStatement.automationTemplateId,
			templateType: Api.TemplateType.Automation,
		}).impersonate(this.impersonationContext);
		template.parentTemplate = this.automationTemplate;
		if (this.automationTemplate?.supportedStepModelTypes) {
			template.supportedStepModelTypes = this.automationTemplate.supportedStepModelTypes;
		}
		return template;
	}
}

export class AddTagAutomationStepViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends AutomationStepViewModel<TUserSession, Api.IAddTagAutomationStep> {
	@computed
	public get tagName() {
		return this.mModel?.tagName;
	}

	protected mGetType(): Api.AutomationStepType {
		return Api.AutomationStepType.AddTag;
	}
}

export class RemoveTagAutomationStepViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends AutomationStepViewModel<TUserSession, Api.IRemoveTagAutomationStep> {
	@computed
	public get tagName() {
		return this.mModel?.tagName;
	}

	protected mGetType(): Api.AutomationStepType {
		return Api.AutomationStepType.RemoveTag;
	}
}

export class NoActionAutomationStepViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends AutomationStepViewModel<TUserSession, Api.INoActionAutomationStep> {
	public mGetType(): Api.AutomationStepType {
		return Api.AutomationStepType.NoAction;
	}
}

export class HwcAutomationStepViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends AutomationStepViewModel<TUserSession, Api.HandwrittenCardAutomationStep> {
	public mGetType(): Api.AutomationStepType {
		return Api.AutomationStepType.HandwrittenCard;
	}
}

export class ActionItemAutomationStepViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends AutomationStepViewModel<TUserSession, Api.IActionItemAutomationStep> {
	@computed
	public get content() {
		return this.mModel?.content;
	}

	protected mGetType(): Api.AutomationStepType {
		return Api.AutomationStepType.ActionItem;
	}
}

export class ComposeAutomationEmailMessageViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends EmailMessageViewModel<File> {
	constructor(
		userSession: TUserSession,
		setDefaultKeepInTouchFrequency?: boolean,
		emailDraft?: Api.IEmailMessageDraft,
		maxAttachmentByteCount?: number
	) {
		super(userSession, setDefaultKeepInTouchFrequency, emailDraft);
		this.mNewAttachments = new AttachmentsToBeUploadedViewModel([], maxAttachmentByteCount || undefined);
	}
}

export class EmailAutomationStepViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends AutomationStepViewModel<TUserSession, Api.IEmailAutomationStep> {
	@computed
	public get tagToAddOnClick() {
		return this.mModel?.tagToAddOnClick;
	}

	@computed
	public get sendToSender() {
		return this.mModel?.options?.sendToSender;
	}

	@computed
	public get content() {
		return this.mModel?.content;
	}

	@computed
	public get options() {
		return this.mModel?.options;
	}

	@computed
	public get signatureTemplateId() {
		return this.mModel?.options?.signatureTemplateId;
	}

	@computed
	public get subject() {
		return this.mModel?.options?.subject;
	}

	@computed
	public get templateReference() {
		return this.mModel?.options?.templateReference;
	}

	public toEmailMessageDraft = (): Api.IEmailMessageDraft => {
		return {
			attachments: this.mModel?.attachments,
			content: this.mModel?.content,
			options: {
				followUpIfNoResponseInDays: this.mModel?.options?.followUpIfNoResponseInDays,
				keepInTouchFrequency: this.mModel?.options?.keepInTouchFrequency,
				noteVisibility: this.mModel?.options?.noteVisibility,
				saveAsNote: this.mModel?.options?.saveAsNote,
			},
			signatureTemplateId: this.mModel?.options?.signatureTemplateId,
			subject: this.mModel?.options?.subject,
			templateReference: this.mModel?.options?.templateReference,
		};
	};

	protected mGetType(): Api.AutomationStepType {
		return Api.AutomationStepType.Email;
	}
}

export const createAutomationStepViewModel = <T extends Api.IAutomationStep>(
	parentViewModel: ViewModel,
	model: T,
	templateId: string,
	readonly = false
) => {
	let vm: AutomationStepViewModel = null;
	switch (model._type) {
		case 'EmailAutomationStep': {
			vm = new EmailAutomationStepViewModel(
				parentViewModel.userSession,
				model as any as Api.IEmailAutomationStep,
				templateId,
				readonly
			);
			break;
		}
		case 'ActionItemAutomationStep': {
			vm = new ActionItemAutomationStepViewModel(parentViewModel.userSession, model, templateId, readonly);
			break;
		}
		case 'AddTagAutomationStep': {
			vm = new AddTagAutomationStepViewModel(
				parentViewModel.userSession,
				model as any as Api.IAddTagAutomationStep,
				templateId,
				readonly
			);
			break;
		}
		case 'RemoveTagAutomationStep': {
			vm = new RemoveTagAutomationStepViewModel(
				parentViewModel.userSession,
				model as any as Api.IRemoveTagAutomationStep,
				templateId,
				readonly
			);
			break;
		}
		case 'TextingAutomationStep': {
			vm = new TextingAutomationStepViewModel(
				parentViewModel.userSession,
				model as any as Api.ITextingAutomationStep,
				templateId,
				readonly
			);
			break;
		}
		case 'SwitchAutomationStep': {
			vm = new SwitchAutomationStepViewModel(
				parentViewModel.userSession,
				model as any as Api.ISwitchAutomationStep,
				templateId,
				readonly
			);
			break;
		}
		case 'NoActionAutomationStep': {
			vm = new NoActionAutomationStepViewModel(
				parentViewModel.userSession,
				model as any as Api.INoActionAutomationStep,
				templateId,
				readonly
			);
			break;
		}
		case 'HandwrittenCardAutomationStep': {
			vm = new HwcAutomationStepViewModel(
				parentViewModel.userSession,
				model as any as Api.INoActionAutomationStep,
				templateId,
				readonly
			);
			break;
		}
		default: {
			break;
		}
	}
	vm?.impersonate(parentViewModel.impersonationContext);
	if (parentViewModel instanceof AutomationTemplateViewModel) {
		vm.automationTemplate = parentViewModel;
	} else if (parentViewModel instanceof AutomationStepViewModel) {
		vm.automationTemplate = parentViewModel.automationTemplate;
	} else if (parentViewModel instanceof AutomationTemplateVersionViewModel) {
		vm.automationTemplate = parentViewModel.template;
	}
	return vm;
};

export class AutomationTemplateVersionViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends ViewModel {
	@observable public readonly uuid: string;
	@observable.ref protected mModel: Api.IAutomationTemplateVersion;
	@observable.ref
	protected mSteps: ObservableCollection<AutomationStepViewModel>;
	@observable.ref protected mTemplateId: string;
	@observable.ref
	protected mTemplate: AutomationTemplateViewModel<TUserSession>;
	protected readonly: boolean;

	constructor(userSession: TUserSession, model: Api.IAutomationTemplateVersion, templateId: string, readonly = false) {
		super(userSession);
		this.mTemplateId = templateId;
		this.readonly = readonly;
		this.mSteps = new ObservableCollection([], 'id');
		this.mSetModel = this.mSetModel.bind(this);
		this.mSetModel(model);
		this.uuid = uuidgen();
	}

	@computed
	public get template() {
		return this.mTemplate;
	}

	public set template(template: AutomationTemplateViewModel<TUserSession>) {
		this.mTemplate = template;
		this.mSteps?.forEach(x => (x.automationTemplate = template));
	}

	@computed
	public get steps() {
		return this.mSteps;
	}

	@computed
	public get templateId() {
		return this.mTemplateId;
	}

	@computed
	public get trigger() {
		return this.mModel.trigger;
	}

	public toJs() {
		return this.mModel;
	}

	protected mSetModel(model: Api.IAutomationTemplateVersion) {
		this.mModel = model;
		this.mSteps.setItems(
			(model?.steps || [])
				.map(x => createAutomationStepViewModel(this, x, this.templateId, this.readonly))
				.filter(x => x)
		);
	}
}

export class AutomationTemplateViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends ViewModel {
	@observable public cancelling: boolean;
	@observable public gettingInProgressCount: boolean;
	@observable public readonly uuid: string;
	@observable.ref protected mDraft: AutomationTemplateVersionViewModel<TUserSession>;
	@observable.ref protected mLoadPromise: Promise<Api.IOperationResult<Api.IAutomationTemplate>>;
	@observable.ref public mModel: Api.IAutomationTemplate | Api.IAutomationTemplateReference;
	@observable.ref
	protected mPublished: AutomationTemplateVersionViewModel<TUserSession>;
	@observable.ref
	public parentTemplate: AutomationTemplateViewModel<TUserSession>;
	@observable.ref
	protected mSupportedStepModelTypes: Api.AutomationStepModelType[];

	constructor(
		userSession: TUserSession,
		model: Api.IAutomationTemplate,
		supportedStepModelTypes?: Api.AutomationStepModelType[]
	) {
		super(userSession);
		this.uuid = uuidgen();
		this.cancelling = false;
		this.gettingInProgressCount = false;
		this.mSetModel = this.mSetModel.bind(this);
		this.mSetModel(model);
		this.mSupportedStepModelTypes = supportedStepModelTypes;
		this.mLoadSupportedStepModelTypes = this.mLoadSupportedStepModelTypes.bind(this);
	}

	@computed
	public get supportedStepModelTypes(): Api.AutomationStepModelType[] {
		return this.mSupportedStepModelTypes;
	}

	public set supportedStepModelTypes(types: Api.AutomationStepModelType[]) {
		this.mSupportedStepModelTypes = types;
	}

	@computed
	public get rootTemplate(): AutomationTemplateViewModel<TUserSession> {
		return this.parentTemplate?.rootTemplate || this;
	}

	@computed
	public get published() {
		return this.mPublished;
	}

	@computed
	public get lastModifiedMoment() {
		return this.mModel?.lastModifiedDate ? moment(this.mModel.lastModifiedDate) : null;
	}

	@computed
	public get runtimeDays() {
		return 'runtimeDays' in this.mModel ? this.mModel.runtimeDays : 1;
	}

	@computed
	public get hasPublishedVersion() {
		return !!this.publishedVersion || this.publishedStepReferences?.length > 0;
	}

	@computed
	public get hasDraftVersion() {
		return !!this.draftVersion || this.draftStepReferences?.length > 0;
	}

	@computed
	public get isLoaded() {
		return this.loaded || (this.mModel?.status && this.mModel?.name && !!this.mModel.stats);
	}

	@computed
	public get allStepsLoaded() {
		return (
			this.hasPublishedVersion &&
			!!this.publishedStepReferences.every(x => !!(x as Api.IAutomationStep).templateVersionId)
		);
	}

	@computed
	public get id() {
		return this.mModel?.id;
	}

	@computed
	public get canEdit() {
		return (
			this.mModel?.status !== Api.TemplateStatus.Archived &&
			(this.isAdmin || this.isImpersonatingAccountAdmin) &&
			this.mModel?.scope !== Api.TemplateScope.Industry
		);
	}

	@computed
	public get creator() {
		return this.mModel?.creator;
	}

	@computed
	public get name() {
		return this.mModel?.name;
	}

	@computed
	public get publishedVersion() {
		return this.mPublished;
	}

	@computed
	public get draftVersion() {
		return this.mDraft;
	}

	@computed
	public get stats() {
		return this.mModel?.stats;
	}

	@computed
	public get scope() {
		return this.mModel?.scope;
	}

	@computed
	public get isUpdatingStep() {
		return [...(this.mDraft?.steps?.toArray() || []), ...(this.mPublished?.steps.toArray() || [])].some(x => x.isBusy);
	}

	@computed
	public get contactFilter() {
		return this.mModel?.contactFilter;
	}

	@computed
	public get status() {
		return this.mModel?.status;
	}

	@computed
	public get cancelOnReply() {
		return this.mModel?.cancelOnReply;
	}

	@computed
	public get sendToHousehold() {
		return this.mModel?.sendToHousehold;
	}

	@computed
	public get publishedStepReferences(): Api.IAutomationStepReference[] {
		const asTemplate = this.mModel as Api.IAutomationTemplate;
		const asTemplateRef = this.mModel as Api.IAutomationTemplateReference;
		return asTemplate?.published?.steps || asTemplateRef?.publishedSteps || [];
	}

	@computed
	public get draftStepReferences(): Api.IAutomationStepReference[] {
		const asTemplate = this.mModel as Api.IAutomationTemplate;
		const asTemplateRef = this.mModel as Api.IAutomationTemplateReference;
		return asTemplate?.draft?.steps || asTemplateRef?.draftSteps || [];
	}

	@computed
	public get draftTriggerReference(): Api.IAutomationTrigger {
		const asTemplate = this.mModel as Api.IAutomationTemplate;
		const asTemplateRef = this.mModel as Api.IAutomationTemplateReference;
		return asTemplate?.draft?.trigger || asTemplateRef?.draftTrigger;
	}

	@computed
	public get publishedTriggerReference(): Api.IAutomationTrigger {
		const asTemplate = this.mModel as Api.IAutomationTemplate;
		const asTemplateRef = this.mModel as Api.IAutomationTemplateReference;
		return asTemplate?.published?.trigger || asTemplateRef?.publishedTrigger;
	}
	@action
	public load() {
		if (this.mLoadPromise) {
			return this.mLoadPromise;
		}
		if (!this.isBusy) {
			this.loading = true;
			const p = new Promise<Api.IOperationResult<Api.IAutomationTemplate>>((resolve, reject) => {
				const loadPromise = this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAutomationTemplate>(
					this.composeApiUrl({ urlPath: `AutomationTemplate/${this.id}` }),
					'GET',
					null
				);
				const supportedStepsPromise = !this.mSupportedStepModelTypes
					? this.loadSupportedStepModelTypes()
					: Promise.resolve<Api.AutomationStepModelType[]>(this.mSupportedStepModelTypes);
				Promise.all([loadPromise, supportedStepsPromise])
					.then(([opResult]) => {
						this.loading = false;
						this.mLoadPromise = null;
						if (opResult.success) {
							this.mSetModel(opResult.value);
							resolve(opResult);
						} else {
							reject(opResult);
						}
					})
					.catch(error => {
						this.loading = false;
						this.mLoadPromise = null;
						reject(error);
					});
			});
			this.mLoadPromise = p;
			return p;
		}
	}

	@action
	public loadSupportedStepModelTypes = () => {
		return this.mLoadSupportedStepModelTypes();
	};

	@action
	public createAutomationForResouceSelector = async (
		resourceSelectorId: Api.ResourceSelectorId,
		request: Api.ICreateAutomationForResourceSelectorRequest,
		excludeExpired = true
	) => {
		if (!this.isBusy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ICreateAutomationsResult>(
				this.composeApiUrl({
					queryParams: { excludeExpired },
					urlPath: `automation/ResourceSelector/${resourceSelectorId}`,
				}),
				'POST',
				request
			);

			this.busy = false;
			if (opResult.success) {
				return opResult;
			} else {
				throw opResult;
			}
		}
	};

	@action
	public duplicate(name: string) {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<AutomationTemplateViewModel>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomationTemplate>) => {
					this.busy = false;
					if (opResult.success) {
						const template = new AutomationTemplateViewModel(this.mUserSession, opResult.value);
						template.impersonate(this.mImpersonationContext);
						resolve(template);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAutomationTemplate>(
					this.composeApiUrl({ queryParams: { name }, urlPath: `AutomationTemplate/${this.id}/clone` }),
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
		}
	}

	@action
	// @ts-ignore
	public reorderSteps = <TViewModel extends AutomationStepViewModel = AutomationStepViewModel>(
		order: string[]
		// @ts-ignore
	): Promise<TViewModel[]> => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<TViewModel[]>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<string[]>) => {
					this.busy = false;
					if (opResult.success) {
						const steps: TViewModel[] = [];
						order.forEach(x => {
							const step = this.draftVersion?.steps.find(y => y.id === x);
							if (step) {
								steps.push(step as any);
							}
						});
						this.draftVersion?.steps.setItems(steps);
						resolve(steps);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({ urlPath: `AutomationTemplate/${this.id}/stepOrder` }),
					'PUT',
					order,
					onFinish,
					onFinish
				);
			});
		}
	};

	@action
	public duplicateStep = <TViewModel extends AutomationStepViewModel = AutomationStepViewModel>(
		step: TViewModel,
		beforeStepId?: string,
		schedule?: Api.IAutomationStepSchedule
	) => {
		const stepModel = step.toJs();
		const cloneStepModel = toJS(stepModel) as typeof stepModel;
		delete cloneStepModel.id;
		if (schedule) {
			cloneStepModel.schedule = schedule;
		}
		return this.addStep(cloneStepModel, beforeStepId);
	};

	@action
	public removeStep = async <TViewModel extends AutomationStepViewModel = AutomationStepViewModel>(
		step: TViewModel
	) => {
		if (!this.isBusy) {
			this.busy = true;
			try {
				const opResult = await step.delete();
				this.busy = false;
				if (opResult.success) {
					this.mSetModel(opResult.value);
				}
				return opResult;
			} catch (err) {
				this.busy = false;
				return Api.asApiError(err);
			}
		}
	};

	public onAfterAddStep: <
		TModel extends Api.IAutomationStep = Api.IAutomationStep,
		TViewModel extends AutomationStepViewModel = AutomationStepViewModel,
	>(
		stepModel: TModel,
		beforeStepId?: string
	) => Promise<TViewModel | undefined> = async <
		TModel extends Api.IAutomationStep = Api.IAutomationStep,
		TViewModel extends AutomationStepViewModel = AutomationStepViewModel,
	>(
		stepModel: TModel,
		beforeStepId?: string
	) => {
		const step: TViewModel = createAutomationStepViewModel(this, stepModel, this.id, !this.canEdit) as any;
		// could be first action after publish, thus, this.draftVersion === null
		const steps = this.draftVersion?.steps.length > 0 ? this.draftVersion?.steps : this.publishedVersion?.steps;
		const insertAtIndex =
			(beforeStepId ? steps?.backingArray.findIndex(x => x.id === beforeStepId) : 0) ?? steps?.length;
		// Cache these. The POST call does not create conditional steps. We will add them manually later.
		const conditionalSteps = stepModel.conditionalSteps || {};
		this.draftVersion?.steps.splice(insertAtIndex, 0, step);

		// could have conditional steps... add them
		const activeConditionalStepKeys = Object.keys(conditionalSteps).filter(
			x => !!conditionalSteps[x as Api.AutomationStepOutcome]
		) as Api.AutomationStepOutcome[];
		if (activeConditionalStepKeys.length > 0) {
			await Promise.all(activeConditionalStepKeys.map(x => step.addConditionalStep(conditionalSteps[x], x)));
		}
		return step;
	};

	@action
	public addStep: <
		TModel extends Api.IAutomationStep = Api.IAutomationStep,
		TViewModel extends AutomationStepViewModel = AutomationStepViewModel,
	>(
		stepModel: TModel,
		beforeStepId?: string
	) => Promise<TViewModel | undefined> = async <TModel extends Api.IAutomationStep = Api.IAutomationStep>(
		stepModel: TModel,
		beforeStepId?: string
	) => {
		if (!this.isBusy) {
			this.busy = true;
			const createStepOpResult = await this.mUserSession.webServiceHelper.callWebServiceAsync(
				this.composeApiUrl({
					queryParams: beforeStepId ? { beforeId: beforeStepId } : undefined,
					urlPath: `AutomationTemplate/${this.id}/Step/${Api.apiModelTypeToAutomationStepType(stepModel._type)}`,
				}),
				'POST',
				stepModel
			);
			this.busy = false;
			if (createStepOpResult.success) {
				return this.onAfterAddStep(createStepOpResult.value as TModel, beforeStepId);
			} else {
				throw createStepOpResult;
			}
		}
	};

	@action
	public replaceStep = async <
		TModel extends Api.IAutomationStep = Api.IAutomationStep,
		TViewModel extends AutomationStepViewModel = AutomationStepViewModel,
	>(
		stepModel: TModel,
		stepModelToReplace: TModel
	) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<TViewModel>((resolve, reject) => {
				// could be first action after publish, thus, this.draftVersion === null
				const steps = this.draftVersion?.steps.length > 0 ? this.draftVersion?.steps : this.publishedVersion?.steps;
				const indexOfStepToReplace = steps?.backingArray.findIndex(x => x.id === stepModelToReplace.id);
				const beforeStepId =
					indexOfStepToReplace + 1 < steps?.length ? steps?.getByIndex(indexOfStepToReplace + 1)?.id : null;
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomationStep>) => {
					this.busy = false;
					if (opResult.success) {
						const step: TViewModel = createAutomationStepViewModel(this, opResult.value, this.id, !this.canEdit) as any;
						this.draftVersion.steps.splice(indexOfStepToReplace, 1, step);
						resolve(step);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper
					.callWebServiceAsync<Api.IAutomationTemplate>(
						this.composeApiUrl({
							urlPath: `AutomationTemplate/${this.id}/Step/${Api.apiModelTypeToAutomationStepType(
								stepModelToReplace._type
							)}/${stepModelToReplace.id}`,
						}),
						'DELETE',
						null
					)
					.then(deleteResponse => {
						if (!deleteResponse.success) {
							onFinish(deleteResponse as any);
							return;
						}

						this.mSetModel(deleteResponse.value);
						this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAutomationStep>(
							this.composeApiUrl({
								queryParams: beforeStepId ? { beforeId: beforeStepId } : undefined,
								urlPath: `AutomationTemplate/${this.id}/Step/${Api.apiModelTypeToAutomationStepType(stepModel._type)}`,
							}),
							'POST',
							stepModel,
							onFinish,
							onFinish
						);
					});
			});
		}
	};

	@action
	public setName = (name: string) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<Api.IAutomationTemplate>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomationTemplate>) => {
					this.busy = false;
					if (opResult.success) {
						this.mSetModel(opResult.value);
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({ queryParams: { name }, urlPath: `AutomationTemplate/${this.id}/Name` }),
					'PUT',
					null,
					onFinish,
					onFinish
				);
			});
		}
	};

	@action
	public setCancelOnReply = (cancelOnReply: boolean) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<Api.IAutomationTemplate>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomationTemplate>) => {
					this.busy = false;
					if (opResult.success) {
						this.mSetModel(opResult.value);
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({
						queryParams: {
							cancelOnReply,
						},
						urlPath: `AutomationTemplate/${this.id}/CancelOnReply`,
					}),
					'PUT',
					null,
					onFinish,
					onFinish
				);
			});
		}
	};

	@action
	public delete = () => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<Api.IAutomationTemplate>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomationTemplate>) => {
					this.busy = false;
					if (opResult.success) {
						this.mSetModel(opResult.value);
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({ urlPath: `AutomationTemplate/${this.id}` }),
					'DELETE',
					null,
					onFinish,
					onFinish
				);
			});
		}
	};

	@action
	public setStatus = (status: Api.TemplateStatus) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<Api.IAutomationTemplate>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomationTemplate>) => {
					this.busy = false;
					if (opResult.success) {
						this.mSetModel(opResult.value);
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({
						queryParams: {
							status,
						},
						urlPath: `AutomationTemplate/${this.id}/Status`,
					}),
					'PUT',
					null,
					onFinish,
					onFinish
				);
			});
		}
	};

	@action
	public restoreKeyFacts = () => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResultNoValue>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResultNoValue) => {
					this.busy = false;
					if (opResult.success) {
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({
						queryParams: {
							status,
						},
						urlPath: `AutomationTemplate/${this.id}/RestoreKeyFacts`,
					}),
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
		}
	};

	@action
	public clearPendingAutomations = () => {
		return new Promise<Api.IOperationResult<number>>((resolve, reject) => {
			const onFinish = action((opResult: Api.IOperationResult<number>) => {
				this.busy = false;
				if (opResult.success) {
					resolve(opResult);
				} else {
					reject(opResult);
				}
			});
			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
				this.composeApiUrl({ urlPath: `AutomationTemplate/${this.id}/ClearOnHoldAutomations` }),
				'POST',
				null,
				onFinish,
				onFinish
			);
		});
	};

	@action
	public commitDraft = () => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<Api.IAutomationTemplate>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomationTemplate>) => {
					this.busy = false;
					if (opResult.success) {
						this.mSetModel(opResult.value);
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAutomationTemplate>(
					this.composeApiUrl({ urlPath: `AutomationTemplate/${this.id}/Draft/commit` }),
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
		}
	};

	@action
	public previewStartForContacts = (createAutomationRequest: Api.ICreateAutomationRequest) => {
		const getUniqueValuesBasedOnIds = (contacts: Partial<Api.IProjectedContact>[]) => {
			const uniqueIds = new Set<string>();
			const vals = (contacts || []).filter(x => {
				if (!uniqueIds.has(x.id)) {
					uniqueIds.add(x.id);
					return true;
				}
				return false;
			});
			return vals;
		};

		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<Api.IPreviewCreateAutomationResult>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IPreviewCreateAutomationResult>) => {
					this.busy = false;
					if (opResult.success) {
						// Api can return more than one copy of the same contact
						opResult.value.excludeContacts = getUniqueValuesBasedOnIds(opResult.value.excludeContacts || []);

						opResult.value.contactOwners = getUniqueValuesBasedOnIds(opResult.value.contactOwners || []);

						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({ urlPath: `Automation/${this.id}/Contact/preview` }),
					'POST',
					createAutomationRequest,
					onFinish,
					onFinish
				);
			});
		}
	};

	@action
	public startForContacts = async (createAutomationRequest: Api.ICreateAutomationRequest) => {
		if (!this.isBusy) {
			this.busy = true;

			try {
				const result = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IOperationResultNoValue>(
					this.composeApiUrl({ urlPath: `Automation/${this.id}/Contact` }),
					'POST',
					createAutomationRequest
				);

				return result;
			} finally {
				this.busy = false;
			}
		}
	};

	@action
	public createAutomationForContact = (contact: string | ContactViewModel | Api.IContact, start = false) => {
		if (!this.isBusy) {
			this.busy = true;
			const contactId = typeof contact === 'string' ? contact : contact?.id;
			return new Promise<Api.IOperationResult<Api.IAutomation>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomation>) => {
					this.busy = false;
					if (opResult.success) {
						if (contact instanceof ContactViewModel) {
							contact.addInProgressAutomations([{ automationId: opResult.value.id, name: this.name }]);
						}
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({
						queryParams: { start },
						urlPath: `Automation/${this.id}/Contact/${contactId}`,
					}),
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
		}
	};

	@action
	public addTrigger = <T extends Api.IAutomationTrigger = Api.IAutomationTrigger>(trigger: T) => {
		if (!this.isBusy) {
			let triggerPath: string = null;
			switch (trigger?._type) {
				case Api.AutomationTriggerType.Tag: {
					triggerPath = 'Tag';
					break;
				}
				case Api.AutomationTriggerType.NewLead: {
					triggerPath = 'NewLead';
					break;
				}
				case Api.AutomationTriggerType.ResourceSelector: {
					triggerPath = 'ResourceSelector';
					break;
				}
				case Api.AutomationTriggerType.NewClient: {
					triggerPath = 'NewClient';
					break;
				}
				case Api.AutomationTriggerType.NewDonor: {
					triggerPath = 'NewDonor';
					break;
				}
				case Api.AutomationTriggerType.Texting: {
					triggerPath = 'TextingCampaign';
					break;
				}
				case Api.AutomationTriggerType.Meeting: {
					triggerPath = 'MeetingReminder';
					break;
				}
				case Api.AutomationTriggerType.EventRegistration: {
					triggerPath = 'EventRegistration';
					break;
				}
				default: {
					break;
				}
			}
			if (triggerPath) {
				this.busy = true;
				return new Promise<Api.IOperationResult<Api.IAutomationTemplate>>((resolve, reject) => {
					const onFinish = action((opResult: Api.IOperationResult<Api.IAutomationTemplate>) => {
						this.busy = false;
						if (opResult.success) {
							this.mSetModel(opResult.value);
							resolve(opResult);
						} else {
							reject(opResult);
						}
					});

					this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
						this.composeApiUrl({ urlPath: `automationTemplate/${this.id}/trigger/${triggerPath}` }),
						'PUT',
						trigger,
						onFinish,
						onFinish
					);
				});
			}
		}
	};

	@action
	public removeTrigger = () => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<Api.IAutomationTemplate>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomationTemplate>) => {
					this.busy = false;
					if (opResult.success) {
						this.mSetModel(opResult.value);
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({ urlPath: `automationTemplate/${this.id}/trigger` }),
					'DELETE',
					null,
					onFinish,
					onFinish
				);
			});
		}
	};

	public toJs() {
		return this.mModel;
	}

	@action
	public async cancelAllInProgress() {
		if (!this.isBusy) {
			this.cancelling = true;
			try {
				await this.mUserSession.webServiceHelper.callWebServiceAsync<number>(
					this.composeApiUrl({ urlPath: `automationTemplate/${this.id}/cancelall` }),
					'POST'
				);

				await this.load();
				this.cancelling = false;
			} catch (err) {
				this.cancelling = false;
				throw Api.asApiError(err);
			}
		}
	}

	protected async mLoadSupportedStepModelTypes() {
		const supportedStepModelTypesOp = await this.mUserSession.webServiceHelper.callWebServiceAsync<
			Api.AutomationStepModelType[]
		>(this.composeApiUrl({ urlPath: `AutomationTemplate/${this.id}/AvailableStepTypes` }), 'GET');
		this.mSupportedStepModelTypes = (supportedStepModelTypesOp.success ? supportedStepModelTypesOp.value : []) || [];
		return this.mSupportedStepModelTypes;
	}

	public mSetModel(model: Api.IAutomationTemplate) {
		this.mModel = model;
		this.mPublished = model?.published
			? new AutomationTemplateVersionViewModel(this.mUserSession, model.published, this.id, !this.canEdit).impersonate(
					this.impersonationContext
				)
			: null;
		if (this.mPublished) {
			this.mPublished.template = this;
		}
		this.mDraft = model?.draft
			? new AutomationTemplateVersionViewModel(this.mUserSession, model.draft, this.id, !this.canEdit).impersonate(
					this.impersonationContext
				)
			: null;
		if (this.mDraft) {
			this.mDraft.template = this;
		}
	}
}

export class AutomationsOnHoldViewModel<TContext = any> extends ViewModel {
	@observable.ref private mAllAutomationsOnHold: AutomationOnHoldViewModel[] = [];
	@observable.ref private mMyAutomationsOnHold: AutomationOnHoldViewModel[] = [];
	@observable.ref public resourceId: Api.ResourceSelectorId;
	@observable.ref public context: TContext;

	public static instanceForResource = <STContext = any>(
		userSession: UserSessionContext,
		automationsOnHold: Api.IAutomationOnHold[],
		resourceId: Api.ResourceSelectorId,
		context?: STContext
	) => {
		const result = new AutomationsOnHoldViewModel(userSession);
		result.resourceId = resourceId;
		result.context = context;
		result.mAllAutomationsOnHold = (automationsOnHold || []).map(result.mCreateAutomationOnHoldViewModel);
		return result;
	};

	constructor(userSession: UserSessionContext, automations?: Api.IAutomationOnHold[], includeAll = false) {
		super(userSession);
		if (automations) {
			this.mAllAutomationsOnHold = includeAll
				? automations.map(this.mCreateAutomationOnHoldViewModel)
				: automations.map(this.mCreateAutomationOnHoldViewModel);
		}
	}

	@computed
	public get myAutomationsPending() {
		// when there are contacts to show
		return this.mMyAutomationsOnHold.filter(x => !x.noneLeft);
	}

	@computed
	public get myAutomationsComplete() {
		return this.mMyAutomationsOnHold?.every(x => x.allComplete) && !this.isLoading;
	}

	@computed
	public get allAutomationsPending() {
		// when there are contacts to show
		return this.mAllAutomationsOnHold.filter(x => !x.noneLeft);
	}

	@computed
	public get allAutomationsComplete() {
		return this.mAllAutomationsOnHold?.every(x => x.allComplete) && !this.isLoading;
	}

	@computed
	public get noMyContactsLeft() {
		return this.mMyAutomationsOnHold?.every(x => x.noneLeft);
	}

	@computed
	public get noAllContactsLeft() {
		return this.mAllAutomationsOnHold?.every(x => x.noneLeft);
	}

	@action
	public loadAutomationsOnHold = (includeAll = false) => {
		if (!this.isBusy) {
			this.loading = true;
			return new Promise<Api.IOperationResult<Api.IAutomationOnHold[]>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomationOnHold[]>) => {
					this.loading = false;
					this.loaded = true;
					if (opResult.success) {
						if (includeAll) {
							this.mAllAutomationsOnHold = opResult.value.map(x => this.mCreateAutomationOnHoldViewModel(x));
						} else {
							this.mMyAutomationsOnHold = opResult.value.map(x => this.mCreateAutomationOnHoldViewModel(x));
						}
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({ queryParams: { includeAll }, urlPath: `automationOnHold` }),
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
		}
	};

	private mCreateAutomationOnHoldViewModel = (automation: Api.IAutomationOnHold) => {
		return new AutomationOnHoldViewModel(this.mUserSession, automation).impersonate(this.mImpersonationContext);
	};
}

export class AutomationOnHoldViewModel extends ViewModel {
	@observable.ref private automationOnHold: Api.IAutomationOnHold;
	@observable.ref
	private contactsController: ObservablePageCollectionController<Api.IContact, ContactViewModel>;
	@observable.ref
	private mSelectedContacts: ObservableCollection<ContactViewModel>;
	@observable.ref
	private mApprovedContacts: ObservableCollection<ContactViewModel>;
	@observable.ref
	private mExcludedContacts: ObservableCollection<ContactViewModel>;
	@observable private allSelected: boolean;

	@observable private sendAutomationType: Api.AutomationSelectionType;

	@observable private sendAutomationAs: Api.IUser | null;

	constructor(userSession: UserSessionContext, automation: Api.IAutomationOnHold) {
		super(userSession);
		this.automationOnHold = automation;
		this.contactsController = new ObservablePageCollectionController<Api.IContact, ContactViewModel>({
			apiPath: `automationOnHold/${automation.template.id}/contact`,
			client: userSession.webServiceHelper,
			transformer: this.mCreateContactViewModel,
		});
		this.mSelectedContacts = new ObservableCollection<ContactViewModel>(null, 'id');
		this.mApprovedContacts = new ObservableCollection<ContactViewModel>(null, 'id');
		this.mExcludedContacts = new ObservableCollection<ContactViewModel>(null, 'id');
		this.allSelected = false;
		this.sendAutomationType = Api.AutomationSelectionType.Myself;
		this.sendAutomationAs = null;
	}

	@computed
	public get allComplete() {
		return this.approvedContacts.length === this.contacts.length && this.contacts.length > 0 && this.contactsLoaded;
	}

	@computed
	public get noneLeft() {
		return this.contacts.length === 0 && this.contactsLoaded;
	}

	@computed
	public get automation() {
		return this.automationOnHold;
	}

	@action
	public resetContactController = () => {
		this.contactsController.reset();
	};

	@action
	public loadContacts = (includeAll = false) => {
		return this.contactsController.getNext(null, 25, { includeAll });
	};

	@computed
	public get contactsLoaded() {
		return this.contactsController.hasFetchedFirstPage;
	}

	@computed
	public get contacts() {
		return this.contactsController.fetchResults;
	}

	@computed
	public get isBusy() {
		return this.busy || this.loading || this.contactsController.isFetching;
	}

	@computed
	public get selectedContacts() {
		return this.mSelectedContacts;
	}

	@computed
	public get excludedContacts() {
		return this.mExcludedContacts;
	}

	@computed
	public get approvedContacts() {
		return this.mApprovedContacts;
	}

	public isContactSelected = (contact: ContactViewModel) => {
		return this.selectedContacts.has(contact);
	};

	@action
	private selectAll = () => {
		this.mSelectedContacts.clear();
		this.contacts.forEach(x => {
			if (!this.approvedContacts.has(x)) {
				this.mSelectedContacts.add(x);
			}
		});
	};

	@computed
	public get selectAllChecked() {
		return this.allSelected;
	}

	@computed
	public get sendAutomationAsType() {
		return this.sendAutomationType;
	}

	public set sendAutomationAsType(type: Api.AutomationSelectionType) {
		this.sendAutomationType = type;
	}

	@computed
	public get sendAutomationAsUser() {
		return this.sendAutomationAs;
	}

	public set sendAutomationAsUser(user: Api.IUser | null) {
		this.sendAutomationAs = user;
	}

	@action
	public setSelectAllChecked = (yes: boolean) => {
		this.mExcludedContacts.clear();
		if (yes) {
			this.allSelected = true;
			this.selectAll();
		} else {
			this.allSelected = false;
			this.mSelectedContacts.clear();
		}
	};

	@action
	public selectContacts = (contacts: ContactViewModel[]) => {
		contacts.forEach(x => {
			if (!this.approvedContacts.has(x)) {
				this.mSelectedContacts.add(x);
			}
		});
	};

	@action
	public deselectContacts = (contacts: ContactViewModel[]) => {
		this.mSelectedContacts.removeItems(contacts);
	};

	@action
	public approveSelectedContacts = (includeAll = false) => {
		if (!this.isBusy && (this.selectedContacts.length || this.selectAllChecked)) {
			this.loading = true;
			return new Promise<Api.IOperationResult<Api.IAutomationOnHold[]>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomationOnHold[]>) => {
					this.loading = false;
					if (opResult.success) {
						this.mApprovedContacts.addAll(this.selectedContacts.toArray());
						this.selectedContacts.clear();
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});

				let body: Api.ICreateAutomationRequest = {
					includeContactIds: this.allSelected ? null : [...this.selectedContacts.map(x => x.id)],
				};

				if (
					this.userSession.account.isAdmin &&
					this.userSession.account.features.automation.allowAdminToStartOnBehalf
				) {
					body = this.isAdminOptions(body);
				}

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({
						queryParams: { includeAll },
						urlPath: `automationOnHold/${this.automation.template.id}`,
					}),
					'POST',
					body,
					onFinish,
					onFinish
				);
			});
		}
	};

	@action
	public approveAllButExcludedContacts = (includeAll = false) => {
		if (!this.isBusy) {
			this.loading = true;
			return new Promise<Api.IOperationResult<Api.IAutomationOnHold[]>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomationOnHold[]>) => {
					this.loading = false;
					if (opResult.success) {
						this.selectedContacts.forEach(x => {
							if (!this.excludedContacts.has(x)) {
								this.mApprovedContacts.add(x);
							}
						});
						this.selectedContacts.clear();
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});

				let body: Api.ICreateAutomationRequest = {
					excludeContactIds: [...this.excludedContacts.map(x => x.id)],
				};

				if (
					this.userSession.account.isAdmin &&
					this.userSession.account.features.automation.allowAdminToStartOnBehalf
				) {
					body = this.isAdminOptions(body);
				}

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({
						queryParams: { includeAll },
						urlPath: `automationOnHold/${this.automation.template.id}`,
					}),
					'POST',
					body,
					onFinish,
					onFinish
				);
			});
		}
	};

	private isAdminOptions = (body: Api.ICreateAutomationRequest) => {
		const newBody = {
			...body,
			sendFromOptions: {
				mode:
					this.sendAutomationAsType === Api.AutomationSelectionType.ContactOwners
						? Api.SendEmailFrom.ContactOwner
						: Api.SendEmailFrom.CurrentUser,
			} as Api.ISendFromOptions,
		};
		if (this.sendAutomationAsType === Api.AutomationSelectionType.Employee && this.sendAutomationAs) {
			newBody.sendFromOptions.mode = Api.SendEmailFrom.SelectedUser;
			newBody.sendFromOptions.selectedUser = this.sendAutomationAs.id;
		} else if (this.sendAutomationAsType === Api.AutomationSelectionType.Myself) {
			newBody.sendFromOptions.mode = Api.SendEmailFrom.SelectedUser;
			newBody.sendFromOptions.selectedUser = this.userSession.user?.id;
		}

		return newBody;
	};

	@action
	public rejectContact = (contact: ContactViewModel, includeAll = false) => {
		if (!this.isBusy) {
			this.loading = true;
			return new Promise<Api.IOperationResult<Api.IAutomationOnHold[]>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomationOnHold[]>) => {
					this.loading = false;
					if (opResult.success) {
						this.contacts.removeItems([contact]);
						this.mSelectedContacts.clear();
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});

				const body: Api.ICreateAutomationRequest = {
					includeContactIds: [contact.id],
				};

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({
						queryParams: { includeAll },
						urlPath: `automationOnHold/${this.automation.template.id}`,
					}),
					'DELETE',
					body,
					onFinish,
					onFinish
				);
			});
		}
	};

	private mCreateContactViewModel = (contact: Api.IContact) => {
		return new ContactViewModel(this.mUserSession, contact);
	};
}

export class AutomationStepStatusEmailMessageViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends EmailMessageViewModel<File> {
	@observable.ref protected mAutomation?: AutomationViewModel;
	@observable.ref
	protected mStepStatus: Api.IAutomationStepStatus<Api.IEmailAutomationStep>;

	constructor(
		userSession: TUserSession,
		stepStatus: Api.IAutomationStepStatus<Api.IEmailAutomationStep>,
		automation?: AutomationViewModel,
		setDefaultKeepInTouchFrequency?: boolean
	) {
		super(userSession, setDefaultKeepInTouchFrequency, {
			content: stepStatus?.step?.content,
			options: {
				followUpIfNoResponseInDays: stepStatus?.step?.options?.followUpIfNoResponseInDays,
				keepInTouchFrequency: stepStatus?.step?.options?.keepInTouchFrequency,
				noteVisibility: stepStatus?.step?.options?.noteVisibility || VmUtils.getDefaultVisibility(userSession.user),
				saveAsNote:
					stepStatus?.step?.options && Object.prototype.hasOwnProperty.call(stepStatus.step.options, 'saveAsNote')
						? stepStatus.step.options.saveAsNote
						: true,
			},
			signatureTemplateId: stepStatus?.step?.options?.signatureTemplateId,
			subject: stepStatus?.step?.options?.subject,
			templateReference: stepStatus?.step?.options?.templateReference,
		});
		this.mStepStatus = stepStatus;
		this.mAutomation = automation;
	}

	@computed
	public get automation() {
		return this.mAutomation;
	}

	@computed
	public get savedAttachments() {
		return this.mStepStatus?.step?.attachments;
	}

	@action
	public async update() {
		if (!this.isBusy) {
			this.busy = true;
			try {
				const contentPromise = this.mUserSession.webServiceHelper.callWebServiceAsync(
					this.composeApiUrl({ urlPath: `Automation/Step/${this.mStepStatus?.id}/Content` }),
					'PUT',
					this.content
				);
				const emailOptions: Api.IEmailMessageOptions = {
					...(this.mStepStatus?.step?.options || {}),
					followUpIfNoResponseInDays: this.options.followUpIfNoResponseInDays,
					keepInTouchFrequency: this.options.keepInTouchFrequency,
					noteVisibility: this.options.noteVisibility,
					saveAsNote: this.options.saveAsNote,
					subject: this.subject,
				};
				const optionsPromise = this.mUserSession.webServiceHelper.callWebServiceAsync(
					this.composeApiUrl({ urlPath: `Automation/Step/${this.mStepStatus?.id}/EmailOptions` }),
					'PUT',
					emailOptions
				);
				const opResults = await Promise.all<Api.IOperationResult<Api.IAutomationStepStatus<Api.IEmailAutomationStep>>>([
					contentPromise,
					optionsPromise,
				]);
				const errorOpResult = opResults.find(x => !x.success);
				if (errorOpResult) {
					throw errorOpResult;
				}
				this.mStepStatus = {
					...this.mStepStatus,
					step: {
						...(this.mStepStatus?.step || {}),
						content: this.content,
						options: emailOptions,
					},
				};

				this.mAutomation?.updateStepStatus(this.mStepStatus);

				if (this.mNewAttachments.count > 0) {
					const formData = new FormData();
					this.mNewAttachments.attachments.forEach(x => formData.append('files', x));
					const attachmentsOpResult = await this.mUserSession.webServiceHelper.callWebServiceAsync(
						this.composeApiUrl({ urlPath: `Automation/Step/${this.mStepStatus?.id}/Attachment` }),
						'POST',
						formData
					);
					if (!attachmentsOpResult.success) {
						throw attachmentsOpResult;
					}
					opResults.push(attachmentsOpResult);
					this.mStepStatus = attachmentsOpResult.value;
					this.mAutomation?.updateStepStatus(this.mStepStatus);
				}
				this.busy = false;
				return opResults;
			} catch (err) {
				this.busy = false;
				return Api.asApiError(err);
			}
		}
	}

	@action
	public addAttachments(attachments: AttachmentsToBeUploadedViewModel<File>) {
		if (!this.isBusy && attachments?.count > 0) {
			this.busy = true;
			return new Promise<Api.IOperationResult<Api.IAutomationStepStatus<Api.IEmailAutomationStep>>>(
				(resolve, reject) => {
					const onFinish = action(
						(opResult: Api.IOperationResult<Api.IAutomationStepStatus<Api.IEmailAutomationStep>>) => {
							this.busy = false;
							if (opResult.success) {
								this.mStepStatus = opResult.value;
								resolve(opResult);
							} else {
								reject(opResult);
							}
						}
					);

					const formData = new FormData();
					attachments.attachments.forEach(x => formData.append('files', x));
					this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
						this.composeApiUrl({ urlPath: `Automation/Step/${this.mStepStatus?.id}/Attachment` }),
						'POST',
						formData,
						onFinish,
						onFinish
					);
				}
			);
		}
	}

	@action
	public removeAttachment(attachment: Api.IFileAttachment) {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<Api.IAutomationStepStatus<Api.IEmailAutomationStep>>>(
				(resolve, reject) => {
					const onFinish = action(
						(opResult: Api.IOperationResult<Api.IAutomationStepStatus<Api.IEmailAutomationStep>>) => {
							this.busy = false;
							if (opResult.success) {
								this.mStepStatus = opResult.value;
								resolve(opResult);
							} else {
								reject(opResult);
							}
						}
					);
					this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
						this.composeApiUrl({ urlPath: `Automation/Step/${this.mStepStatus?.id}/Attachment/${attachment.id}` }),
						'DELETE',
						null,
						onFinish,
						onFinish
					);
				}
			);
		}
	}
}

export class ContactAutomationsViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends ViewModel {
	@observable.ref private mLoadingPromise: Promise<Api.IPageCollectionControllerFetchResult<Api.IAutomation>>;
	public readonly contact: ContactViewModel;
	protected mAutomationsPagedCollectionController: ObservablePageCollectionController<
		Api.IAutomation,
		AutomationViewModel
	>;

	constructor(userSession: TUserSession, contact: ContactViewModel) {
		super(userSession);
		this.contact = contact;
		this.mAutomationsPagedCollectionController = new ObservablePageCollectionController<
			Api.IAutomation,
			AutomationViewModel
		>({
			apiPath: () => this.composeApiUrl({ urlPath: `contact/${this.contact.id}/Automation` }),
			client: this.mUserSession.webServiceHelper,
			transformer: this.mCreateAutomationViewModel,
		});
	}

	@computed
	public get isBusy() {
		return this.busy || this.isLoading || this.mAutomationsPagedCollectionController.isFetching;
	}

	@computed
	public get isLoading() {
		return this.loading || !!this.mLoadingPromise;
	}

	@computed
	public get automations() {
		return this.mAutomationsPagedCollectionController.fetchResults;
	}

	@action
	public load() {
		if (!this.isBusy && !this.mLoadingPromise) {
			this.mAutomationsPagedCollectionController.reset();
			this.mLoadingPromise = this.mAutomationsPagedCollectionController.getNext() as any;
			const onFinish = (): void => (this.mLoadingPromise = null);
			this.mLoadingPromise.then(onFinish).catch(onFinish);
		}
		return this.mLoadingPromise;
	}

	protected mCreateAutomationViewModel = (automationModel: Api.IAutomation) => {
		return new AutomationViewModel(this.mUserSession, automationModel);
	};
}

export interface IAutomationTemplatesLoadOptions {
	forAnyTrigger?: boolean;
	industry?: Api.Industry;
	types?: ('archived' | 'disabled' | 'draft' | 'published' | 'industry')[];
}

interface IAvailableAutomationTriggers {
	[Api.AutomationTriggerType.Tag]?: Api.IAvailableAutomationTrigger<Api.ITagAutomationTrigger>;
	[Api.AutomationTriggerType.Texting]?: Api.IAvailableAutomationTrigger<Api.ITextingCampaignAutomationTrigger>;
	[Api.AutomationTriggerType.NewClient]?: Api.IAvailableAutomationTrigger<Api.INewClientAutomationTrigger>[];
	[Api.AutomationTriggerType.NewDonor]?: Api.IAvailableAutomationTrigger<Api.NewDonorAutomationTrigger>;
	[Api.AutomationTriggerType.NewLead]?: Partial<
		{
			[key in (typeof Api.AllEmailScannerIds)[number]]: Api.IAvailableAutomationTrigger<Api.INewLeadAutomationTrigger>;
		} & {
			[Api.EmailScannerId.Custom]: Api.IAvailableAutomationTrigger<Api.INewLeadAutomationTrigger>[];
		}
	>;
	[Api.AutomationTriggerType.ResourceSelector]?: {
		[key in Api.ResourceSelectorId]?: Api.IAvailableAutomationTrigger<Api.IResourceSelectorAutomationTrigger>;
	};
	[Api.AutomationTriggerType.Meeting]?: Api.IAvailableAutomationTrigger<Api.IAutomationTrigger>;
	[Api.AutomationTriggerType.EventRegistration]?: Api.IAvailableAutomationTrigger<Api.IEventRegistrationTrigger>;
}

export class AutomationTemplatesViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends ViewModel<TUserSession> {
	@observable.ref mAvailableTriggers: Partial<IAvailableAutomationTriggers>;
	@observable.ref protected loadingTemplatesByResourceId: boolean;
	@observable.ref protected mLoadingPromise: Promise<any>;
	protected mArchived: ObservableCollection<AutomationTemplateViewModel<TUserSession>>;
	protected mDisabled: ObservableCollection<AutomationTemplateViewModel<TUserSession>>;
	protected mDrafts: ObservableCollection<AutomationTemplateViewModel<TUserSession>>;
	protected mIndustry: ObservableCollection<AutomationTemplateViewModel<TUserSession>>;
	protected mPublished: ObservableCollection<AutomationTemplateViewModel<TUserSession>>;
	public static defaultLoadOptions: IAutomationTemplatesLoadOptions = {
		types: ['disabled', 'draft', 'published', 'industry'],
	};

	constructor(userSession: TUserSession) {
		super(userSession);
		this.mArchived = new ObservableCollection<AutomationTemplateViewModel<TUserSession>>([], 'id');
		this.mDisabled = new ObservableCollection<AutomationTemplateViewModel<TUserSession>>([], 'id');
		this.mDrafts = new ObservableCollection<AutomationTemplateViewModel<TUserSession>>([], 'id');
		this.mIndustry = new ObservableCollection<AutomationTemplateViewModel<TUserSession>>([], 'id');
		this.mPublished = new ObservableCollection<AutomationTemplateViewModel<TUserSession>>([], 'id');
	}

	@computed
	public get availableTriggers() {
		return this.mAvailableTriggers;
	}

	@computed
	public get canCreate() {
		return this.isAdmin;
	}

	@computed
	public get isBusy() {
		return this.busy || this.isLoading;
	}

	@computed
	public get isLoading() {
		return this.loading || !!this.mLoadingPromise;
	}

	@computed
	public get isLoadingTemplatesByResourceId() {
		return this.loadingTemplatesByResourceId;
	}

	@computed
	public get active() {
		return this.mPublished.toArray();
	}

	@computed
	public get industry() {
		return this.mIndustry.toArray();
	}

	@computed
	public get inactive() {
		return [...this.mDrafts.toArray(), ...this.mDisabled.toArray(), ...this.mArchived.toArray()];
	}

	/**
	 * Get templates where the trigger is a of a specific resource selector
	 *
	 * @param resourceSelectorId
	 * @returns AutomationTemplateViewModel[]
	 */
	@action
	public getTemplatesByResourceId = async (resourceSelectorId: Api.ResourceSelectorId, excludeExpired = true) => {
		if (!this.loadingTemplatesByResourceId) {
			this.loadingTemplatesByResourceId = true;

			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAutomationTemplate[]>(
				this.composeApiUrl({
					queryParams: { excludeExpired },
					urlPath: `automationTemplate/ResourceSelector/${resourceSelectorId}`,
				}),
				'GET'
			);
			this.loadingTemplatesByResourceId = false;
			if (opResult.success) {
				const templates = opResult.value.map(x => {
					const template = new AutomationTemplateViewModel(this.mUserSession, x);
					if (this.mImpersonationContext) {
						template.impersonate(this.mImpersonationContext);
					}
					return template;
				});
				return templates;
			} else {
				throw opResult;
			}
		}
	};

	@action
	public create = async (name?: string, trigger?: Api.IAutomationTrigger) => {
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAutomationTemplate>(
			this.composeApiUrl({
				queryParams: {
					name:
						name ||
						VmUtils.Automations.getDefaultNameForNewAutomationTemplate(
							trigger,
							this.impersonationContext ? this.impersonationContext : this.mUserSession
						),
				},
				urlPath: 'automationTemplate',
			}),
			'POST'
		);
		if (opResult.success) {
			const template = new AutomationTemplateViewModel(this.mUserSession, opResult.value);
			if (this.mImpersonationContext) {
				template.impersonate(this.mImpersonationContext);
			}

			// add the trigger
			if (trigger) {
				try {
					await template.addTrigger(trigger);
				} catch (err) {
					try {
						await template.delete();
					} catch (delErr) {
						// eat this
					}
					throw err;
				}
			}
			return template;
		} else {
			throw opResult;
		}
	};

	@action
	public disableTemplate = (template: AutomationTemplateViewModel) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise((resolve, reject) => {
				const promise = template.setStatus(Api.TemplateStatus.Disabled);
				promise
					.then(
						action(opResult => {
							this.busy = false;
							const items = [template];
							this.mPublished.removeItems(items);
							this.mArchived.removeItems(items);
							this.mDrafts.removeItems(items);
							this.mDisabled.addAll(items);
							resolve(opResult);
						})
					)
					.catch(err => {
						this.busy = false;
						reject(err);
					});
			});
		}
	};

	@action
	public restoreKeyFacts = (template: AutomationTemplateViewModel) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise((resolve, reject) => {
				const promise = template.restoreKeyFacts();
				promise
					.then(
						action(opResult => {
							this.busy = false;
							resolve(opResult);
						})
					)
					.catch(err => {
						this.busy = false;
						reject(err);
					});
			});
		}
	};

	@action
	public clearPending = (template: AutomationTemplateViewModel) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<number>>((resolve, reject) => {
				const promise = template.clearPendingAutomations();
				promise
					.then(
						action(opResult => {
							this.busy = false;
							resolve(opResult);
						})
					)
					.catch(err => {
						this.busy = false;
						reject(err);
					});
			});
		}
	};

	@action
	public deleteTemplate = (template: AutomationTemplateViewModel) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise((resolve, reject) => {
				const promise = template.delete();
				promise
					.then(
						action(opResult => {
							this.busy = false;
							const items = [template];
							this.mPublished.removeItems(items);
							this.mDrafts.removeItems(items);
							this.mDisabled.removeItems(items);
							if (template.publishedStepReferences && template.publishedStepReferences.length > 0) {
								this.mArchived.addAll(items);
							}
							resolve(opResult);
						})
					)
					.catch(err => {
						this.busy = false;
						reject(err);
					});
			});
		}
	};

	@action
	public duplicateTemplate = (template: AutomationTemplateViewModel, name: string) => {
		if (!this.isBusy) {
			this.busy = true;
			const promise = template.duplicate(name);
			if (promise) {
				promise
					.then(
						action((newTemplate: AutomationTemplateViewModel) => {
							this.busy = false;
							switch (newTemplate.status) {
								case Api.TemplateStatus.Archived: {
									this.mArchived.add(newTemplate);
									break;
								}
								case Api.TemplateStatus.Disabled: {
									this.mDisabled.add(newTemplate);
									break;
								}
								case Api.TemplateStatus.Draft: {
									this.mDrafts.add(newTemplate);
									break;
								}
								case Api.TemplateStatus.Published: {
									this.mPublished.add(newTemplate);
									break;
								}
								default: {
									break;
								}
							}
						})
					)
					.catch(() => {
						this.busy = false;
					});
			}
			return promise;
		}
	};

	@action
	public load(options = AutomationTemplatesViewModel.defaultLoadOptions) {
		if (!this.mLoadingPromise) {
			const archivedPromise =
				options?.types?.indexOf('archived') > -1
					? this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAutomationTemplate[]>(
							this.composeApiUrl({
								queryParams: {
									forAnyTrigger: options?.forAnyTrigger || false,
									status: Api.TemplateStatus.Archived,
								},
								urlPath: 'automationTemplate',
							}),
							'GET'
						)
					: null;
			const disabledPromise =
				options?.types?.indexOf('disabled') > -1
					? this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAutomationTemplate[]>(
							this.composeApiUrl({
								queryParams: {
									forAnyTrigger: options?.forAnyTrigger || false,
									status: Api.TemplateStatus.Disabled,
								},
								urlPath: 'automationTemplate',
							}),
							'GET'
						)
					: null;
			const draftPromise =
				options?.types?.indexOf('draft') > -1
					? this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAutomationTemplate[]>(
							this.composeApiUrl({
								queryParams: {
									forAnyTrigger: options?.forAnyTrigger || false,
									status: Api.TemplateStatus.Draft,
								},
								urlPath: 'automationTemplate',
							}),
							'GET'
						)
					: null;
			const publishedPromise =
				options?.types?.indexOf('published') > -1
					? this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAutomationTemplate[]>(
							this.composeApiUrl({
								queryParams: {
									forAnyTrigger: options?.forAnyTrigger || false,
									status: Api.TemplateStatus.Published,
								},
								urlPath: 'automationTemplate',
							}),
							'GET'
						)
					: null;
			const industryPromise =
				options?.types?.indexOf('industry') > -1
					? this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAutomationTemplate[]>(
							this.composeApiUrl({
								queryParams: {
									industry:
										options?.industry ||
										this.mUserSession?.account?.toJs().additionalInfo.industry ||
										Api.Industry.Miscellaneous,
								},
								urlPath: 'automationTemplate/system',
							}),
							'GET'
						)
					: null;

			const promise = Promise.all([publishedPromise, draftPromise, disabledPromise, archivedPromise, industryPromise]);
			this.mLoadingPromise = promise;
			promise
				.then(([published, drafts, disabled, archived, industry]) => {
					runInAction(() => {
						this.mPublished.setItems(published?.success ? published.value.map(this.createAutomationTemplate) : []);
						this.mArchived.setItems(archived?.success ? archived.value.map(this.createAutomationTemplate) : []);
						this.mDisabled.setItems(disabled?.success ? disabled.value.map(this.createAutomationTemplate) : []);
						this.mDrafts.setItems(drafts?.success ? drafts.value.map(this.createAutomationTemplate) : []);
						this.mIndustry.setItems(industry?.success ? industry.value.map(this.createAutomationTemplate) : []);
						this.mLoadingPromise = null;
						this.loaded = true;
					});
				})
				.catch(() => {
					this.mLoadingPromise = null;
				});
		}
		return this.mLoadingPromise;
	}

	@action
	public loadTemplatesForIndustry(industry: Api.Industry) {
		if (!this.isBusy) {
			this.busy = true;
			const promise = this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAutomationTemplate[]>(
				this.composeApiUrl({ queryParams: { industry }, urlPath: 'automationTemplate/system' }),
				'GET'
			);
			promise
				.then(
					action(opResult => {
						this.busy = false;
						this.mIndustry.setItems(opResult.success ? opResult.value.map(this.createAutomationTemplate) : []);
					})
				)
				.catch(() => {
					this.busy = false;
				});
			return promise;
		}
	}

	@action
	public loadTemplatesForStatus(status: Api.TemplateStatus) {
		if (!this.isBusy) {
			this.busy = true;
			const promise = this.mUserSession.webServiceHelper
				.callWebServiceAsync<Api.IAutomationTemplate[]>(
					this.composeApiUrl({ queryParams: { status }, urlPath: `automationTemplate` }),
					'GET'
				)
				.then(opResult => {
					if (opResult.success) {
						const templates = opResult.value.map(this.createAutomationTemplate);
						runInAction(() => {
							switch (status) {
								case Api.TemplateStatus.Archived: {
									this.mArchived.setItems(templates);
									break;
								}
								case Api.TemplateStatus.Published: {
									this.mPublished.setItems(templates);
									break;
								}
								case Api.TemplateStatus.Draft: {
									this.mDrafts.setItems(templates);
									break;
								}
								case Api.TemplateStatus.Disabled: {
									this.mDisabled.setItems(templates);
									break;
								}
								default: {
									break;
								}
							}
							this.busy = false;
						});
					}
				})
				.catch(() => {
					this.busy = false;
				});
			return promise;
		}
	}

	@action
	public loadTriggers() {
		if (!this.isBusy) {
			this.busy = true;

			return new Promise<Api.IAvailableAutomationTrigger<Api.IAutomationTrigger>[]>((resolve, reject) => {
				this.mUserSession.webServiceHelper
					.callWebServiceAsync<Api.IAvailableAutomationTrigger[]>(
						this.composeApiUrl({ urlPath: 'automationTemplate/trigger' }),
						'GET'
					)
					.then(opResult => {
						runInAction(() => {
							this.busy = false;

							if (opResult.success) {
								const triggers: IAvailableAutomationTriggers = {
									[Api.AutomationTriggerType.ResourceSelector]: {},
									[Api.AutomationTriggerType.NewClient]: [],
									[Api.AutomationTriggerType.NewLead]: {
										[Api.EmailScannerId.Custom]: [],
									},
								};
								opResult.value.forEach(at => {
									switch (at.trigger._type) {
										case Api.AutomationTriggerType.Texting: {
											triggers[Api.AutomationTriggerType.Texting] = at;
											break;
										}
										case Api.AutomationTriggerType.Tag: {
											triggers[Api.AutomationTriggerType.Tag] = at;
											break;
										}
										case Api.AutomationTriggerType.ResourceSelector: {
											const ats = triggers[Api.AutomationTriggerType.ResourceSelector];
											const t = at.trigger as Api.IResourceSelectorAutomationTrigger;
											ats[t.resourceSelector] = at;
											break;
										}
										case Api.AutomationTriggerType.NewLead: {
											const ats = triggers[Api.AutomationTriggerType.NewLead];
											const t = at.trigger as Api.INewLeadAutomationTrigger;

											// Note: only works for one scanner id atm.
											const scannerId = t.emailScannerIds?.[0];
											if (scannerId === Api.EmailScannerId.Custom) {
												const mAt = at as Api.IAvailableAutomationTrigger<Api.INewLeadAutomationTrigger>;
												if (mAt.trigger?.customEmailScannerIds?.length > 0) {
													const customScanners = ats[Api.EmailScannerId.Custom];
													customScanners.push(mAt);
												}
											} else {
												ats[scannerId] = at;
											}
											break;
										}
										case Api.AutomationTriggerType.NewClient: {
											const ats = triggers[Api.AutomationTriggerType.NewClient];
											ats.push(at);
											break;
										}
										case Api.AutomationTriggerType.NewDonor: {
											triggers[Api.AutomationTriggerType.NewDonor] = at;
											break;
										}
										case Api.AutomationTriggerType.Meeting: {
											triggers[Api.AutomationTriggerType.Meeting] = at;
											break;
										}
										case Api.AutomationTriggerType.EventRegistration: {
											triggers[Api.AutomationTriggerType.EventRegistration] = at;
											break;
										}
										default: {
											break;
										}
									}
								});

								// must remove custom array if size is 0. Otherwise the trigger might appear as an available option when it isn't.
								if (triggers.NewLeadAutomationTrigger[Api.EmailScannerId.Custom].length === 0) {
									delete triggers.NewLeadAutomationTrigger[Api.EmailScannerId.Custom];
								}
								this.mAvailableTriggers = triggers;
								resolve(opResult.value);
							} else {
								reject(opResult);
							}
						});
					});
			});
		}
	}

	@computed
	public get supportedAvailableTriggerTypes() {
		let result: Api.IAvailableAutomationTrigger[] = [];

		// new client
		const newClientTriggers = this.mAvailableTriggers?.[Api.AutomationTriggerType.NewClient] || [];
		result = result.concat(
			newClientTriggers.sort((x, y) => {
				if (x?.trigger.clientType?.indexOf(Api.NewClientType.Any) >= 0) {
					return -1;
				} else {
					const index = x?.trigger?.clientType?.indexOf(Api.NewClientType.Commercial) || -1;
					if (index >= 0 && index < y?.trigger?.clientType?.indexOf(Api.NewClientType.Commercial)) {
						return -1;
					}
				}
				return 0;
			})
		);

		// new lead
		if (Object.keys(this.mAvailableTriggers?.[Api.AutomationTriggerType.NewLead] || {}).length > 0) {
			result.push({
				trigger: VmUtils.Automations.triggers.defaults.NewLeadAutomationTrigger,
			});
		}

		// new donor
		const donorTrigger = this.mAvailableTriggers?.[Api.AutomationTriggerType.NewDonor];
		if (donorTrigger) {
			result.push(donorTrigger);
		}

		// resource
		const resourceIdTriggers = this.mAvailableTriggers?.[Api.AutomationTriggerType.ResourceSelector] || {};
		const keys = Object.keys(resourceIdTriggers) as Api.ResourceSelectorId[];
		keys.forEach(x => result.push(resourceIdTriggers[x]));

		// texting
		const textingTrigger = this.mAvailableTriggers?.[Api.AutomationTriggerType.Texting];
		if (textingTrigger) {
			result.push(textingTrigger);
		}

		// tag
		const tagTrigger = this.mAvailableTriggers?.[Api.AutomationTriggerType.Tag];
		if (tagTrigger) {
			result.push(tagTrigger);
		}

		const meetingTrigger = this.mAvailableTriggers?.[Api.AutomationTriggerType.Meeting];
		if (meetingTrigger) {
			result.push(meetingTrigger);
		}

		const eventRegTrigger = this.mAvailableTriggers?.[Api.AutomationTriggerType.EventRegistration];
		if (eventRegTrigger) {
			result.push(eventRegTrigger);
		}

		// manual
		result.push({
			trigger: {
				_type: Api.AutomationTriggerType.Manual as any,
			},
		});
		return result;
	}

	@computed
	public get availableLeadParserTriggers() {
		const availableNewLeadTriggers = this.mAvailableTriggers?.[Api.AutomationTriggerType.NewLead] || {};
		const keys = Object.keys(availableNewLeadTriggers);
		const result: Api.IAvailableAutomationTrigger<Api.INewLeadAutomationTrigger>[] = [];
		let custom: Api.IAvailableAutomationTrigger<Api.INewLeadAutomationTrigger>[] = null;
		keys.forEach(id => {
			if (id === Api.EmailScannerId.Custom) {
				custom = availableNewLeadTriggers[id] || [];
			} else {
				result.push(availableNewLeadTriggers[id as Api.EmailScannerId]);
			}
		});

		return custom ? [...result, ...custom] : result;
	}

	@action
	public reset = () => {
		this.mArchived.clear();
		this.mDisabled.clear();
		this.mDrafts.clear();
		this.mIndustry.clear();
		this.mPublished.clear();
		this.mAvailableTriggers = null;
	};

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate(impersonationContext);
		[this.mArchived, this.mDisabled, this.mDrafts, this.mIndustry, this.mPublished].forEach(x =>
			x?.forEach(y => y?.impersonate(impersonationContext))
		);
		return this;
	}

	protected createAutomationTemplate = (model: Api.IAutomationTemplate) => {
		const template = new AutomationTemplateViewModel(this.mUserSession, model);
		if (this.mImpersonationContext) {
			template.impersonate(this.mImpersonationContext);
		}
		return template;
	};
}

export class SubverticalsViewModel extends ViewModel {
	constructor(userSession: UserSessionContext) {
		super(userSession);
	}

	@action
	public async updateSubverticalSettings(subverticalSettings: Api.ISubverticalSettings) {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IAccount>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IAccount>) => {
					runInAction(() => {
						if (opResult.success) {
							this.busy = false;
							resolve(opResult.value);
						} else {
							this.busy = false;
							reject(opResult);
						}
					});
				};

				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAccount>(
					this.composeApiUrl({ urlPath: 'Account/preferences/subverticalSettings' }),
					'PUT',
					subverticalSettings,
					onFinish,
					onFinish
				);
			});
		}
	}
}

export class AutomationReportViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
	TAutomationStep extends Api.IAutomationStep = Api.IAutomationStep,
	TAutomationStepVm extends AutomationStepViewModel<TUserSession, TAutomationStep> = AutomationStepViewModel<
		TUserSession,
		TAutomationStep
	>,
> extends ViewModel {
	@observable.ref protected mAutomation: Api.IAutomationReport<TAutomationStep>;
	@observable.ref protected mSampleContacts: ContactViewModel[];
	@observable.ref protected mAutomationStep: TAutomationStepVm;

	constructor(userSession: TUserSession, automation: Api.IAutomationReport<TAutomationStep>) {
		super(userSession);

		this.mAutomation = automation;
		this.mSampleContacts = (this.mAutomation.contacts || []).map(x => new ContactViewModel(this.mUserSession, x));
		if (automation?.automationStep) {
			this.mAutomationStep = createAutomationStepViewModel<TAutomationStep>(
				this,
				automation.automationStep as TAutomationStep,
				automation.templateId
			) as TAutomationStepVm;
		}
	}

	@computed
	public get status() {
		return this.mAutomation.status;
	}

	@computed
	public get automationId() {
		return this.mAutomation.automationId;
	}

	@computed
	public get automationName() {
		return this.mAutomation.name;
	}

	@computed
	public get creator() {
		const actualSteps = this.mAutomation.steps.filter(i => i._type !== 'SwitchAutomationStep');
		if (actualSteps.length === 1 && actualSteps[0].sender?.id) {
			return actualSteps[0].sender;
		}

		return this.mAutomation.creator;
	}

	@computed
	public get dueDate() {
		return this.mAutomation.dueDate;
	}

	@computed
	public get automationStep() {
		return this.mAutomation.automationStep;
	}

	@computed
	public get templateId() {
		return this.mAutomation.templateId;
	}

	@computed
	public get id() {
		return this.mAutomation.id;
	}

	@computed
	public get sampleContacts() {
		return this.mSampleContacts;
	}

	@computed
	public get stepCount() {
		return this.mAutomation.stepCount;
	}

	@computed
	public get step() {
		return this.mAutomation.automationStep;
	}

	@computed
	public get stepIndex() {
		return this.mAutomation.stepIndex;
	}
}

export class ContentCalenderSuggestionViewModel extends ViewModel {
	@observable.ref protected mSuggestion: Api.IContentCalendarSuggestion;
	@observable.ref protected mTemplate: Api.ITemplate;
	@observable.ref protected rejected: boolean;
	@observable.ref protected mMatchingAccountTags: Api.IAccountTag[];
	@observable.ref public userSelectedNewTags: boolean;

	constructor(userSession: UserSessionContext, suggestion?: Api.IContentCalendarSuggestion) {
		super(userSession);
		this.mSuggestion = suggestion;
	}

	@computed
	public get id() {
		return this.mSuggestion?.id;
	}

	@computed
	public get template() {
		return this.mTemplate;
	}

	@computed
	public get accountAges() {
		return this.mSuggestion?.accountAges;
	}

	@computed
	public get archivedDate() {
		return this.mSuggestion?.archivedDate;
	}

	@computed
	public get isArchived() {
		return this.mSuggestion?.archived;
	}

	@computed
	public get filter() {
		return this.mSuggestion?.filter;
	}

	@computed
	public get industries() {
		return this.mSuggestion?.industries;
	}

	@computed
	public get minimumDurationInDays() {
		return this.mSuggestion?.minimumDurationInDays;
	}

	@computed
	public get subverticals() {
		return this.mSuggestion?.subverticals;
	}

	@computed
	public get schedule() {
		return this.mSuggestion?.schedule;
	}

	@computed
	public get targets() {
		return this.mSuggestion?.targets;
	}

	@computed
	public get isLoaded() {
		return !!this.mTemplate;
	}

	@computed
	public get isRejected() {
		return this.rejected;
	}

	@computed
	public get canEdit() {
		return (
			!this.impersonationContext?.account ||
			(this.impersonationContext?.account &&
				(this.userSession?.user?.role === 'admin' || this.userSession?.user?.role === 'superAdmin'))
		);
	}

	@computed
	public get filterCriteriaTags() {
		const sorted = VmUtils.sortContactFilterCriteria(this.mSuggestion?.filter.criteria);
		return sorted.searches
			.filter(x => x.property === Api.ContactFilterCriteriaProperty.Tag && x.value)
			.map(x => x.value);
	}

	@computed
	public get accountTagsMatchingFilterCriteriaTags() {
		return this.mMatchingAccountTags;
	}

	public toJs = () => {
		return this.mSuggestion;
	};

	@action
	public async load() {
		const templates = new TemplatesViewModel(this.userSession).impersonate(this.impersonationContext);
		await templates.getById(this.mSuggestion.templateReference.templateId).then(response => {
			this.mTemplate = response;
		});

		// load and match account tags
		if (this.filterCriteriaTags.length) {
			const tagsToSearchFor = this.filterCriteriaTags;
			const searchResults = await Promise.all(
				tagsToSearchFor.map(x => {
					const params: Api.IDictionary<string> = {
						query: x,
					};
					return this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IAccountTag[]>(
						this.composeApiUrl({ queryParams: params, urlPath: 'tag/search' }),
						'GET'
					);
				})
			);
			this.mMatchingAccountTags = searchResults
				.map((x, i) => {
					if (x.success) {
						const match = x.value.find(y => y.tag.toLocaleLowerCase() === tagsToSearchFor[i].toLocaleLowerCase());
						if (match) {
							return match;
						}
					}
				})
				.filter(x => x);
		} else {
			this.mMatchingAccountTags = [];
		}
	}

	@action
	public reject() {
		this.busy = true;
		const promise = new Promise<Api.IOperationResult<Api.IContentCalendarSuggestion>>((resolve, reject) => {
			const onFinish = action((opResult: Api.IOperationResult<Api.IContentCalendarSuggestion>) => {
				this.busy = false;
				if (opResult.success) {
					resolve(opResult);
					this.rejected = true;
				} else {
					reject(opResult);
					this.rejected = false;
				}
			});

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IContentCalendarSuggestion>(
				this.composeApiUrl({ urlPath: `ContentCalendarSuggestion/${this.mSuggestion.id}/reject` }),
				'POST',
				null,
				onFinish,
				onFinish
			);
		});
		return promise;
	}
}
