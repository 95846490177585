import { action, computed, observable } from 'mobx';
import moment from 'moment';
import * as Api from '../../sdk';
import {
	FilteredPageCollectionController,
	IBaseObservablePageCollectionControllerOptions,
	PageCollectionControllerViewModel,
} from '../Collections';
import { UserSessionContext, ViewModel } from '../index';

export const getApiActionPathForSurvey = (survey: Api.ISurvey) => {
	switch (survey._type) {
		case 'SatisfactionSurvey': {
			return 'satisfaction';
		}
		case 'EventRegistrationSurvey': {
			return 'eventRegistration';
		}
		default: {
			return '';
		}
	}
};

const toViewModel = (
	userSession: UserSessionContext,
	survey: Api.ISurvey,
	impersonationContext?: Api.IImpersonationContext
) => {
	switch (survey._type) {
		case 'SatisfactionSurvey': {
			return new SatisfactionSurveyViewModel(userSession, survey as Api.ISatisfactionSurvey).impersonate(
				impersonationContext
			);
		}
		case 'EventRegistrationSurvey': {
			return new EventSurveyViewModel(userSession, survey as Api.IEventRegistrationSurvey).impersonate(
				impersonationContext
			);
		}
		default: {
			return new SurveyViewModel(userSession, survey).impersonate(impersonationContext);
		}
	}
};

export class SurveysViewModel extends PageCollectionControllerViewModel<Api.ISurvey, SurveyViewModel> {
	constructor(userSession: UserSessionContext, includeArchived = false) {
		super(userSession, 'survey', {
			apiParams: { includeArchived },
			apiPath: 'survey',
			client: userSession.webServiceHelper,
			itemUniqueIdentifierPropertyPath: 'id',
			transformer: (s: Api.ISurvey) => {
				return toViewModel(userSession, s, this.impersonationContext);
			},
		});
	}

	@computed
	public get isBusy() {
		return this.mController.isFetching || this.busy;
	}
}

export class SurveyViewModel<TSurvey extends Api.ISurvey = Api.ISurvey> extends ViewModel {
	// @ts-ignore
	@observable.ref protected mSurvey: TSurvey;

	public static init<TSurvey extends Api.ISurvey = Api.ISurvey>(
		userSession: UserSessionContext,
		survey: TSurvey,
		impersonationContext?: Api.IImpersonationContext
	) {
		return toViewModel(userSession, survey, impersonationContext);
	}

	public static create = async <TSurveyViewModel extends SurveyViewModel>(
		userSession: UserSessionContext,
		survey: Api.ISurvey,
		impersonationContext?: Api.IImpersonationContext
	): Promise<TSurveyViewModel> => {
		const opResult = await userSession.webServiceHelper.callWebServiceAsync<Api.ISurvey>(
			Api.ImpersonationBroker.composeApiUrl({
				impersonationContext,
				// @ts-ignore
				queryParams: null,
				urlPath: `survey/${getApiActionPathForSurvey(survey)}`,
			}),
			'POST',
			survey
		);

		if (!opResult.success) {
			throw opResult;
		}

		// @ts-ignore
		return toViewModel(userSession, opResult.value, impersonationContext) as TSurveyViewModel;
	};

	public static load = async <TSurveyViewModel extends SurveyViewModel>(
		userSession: UserSessionContext,
		survey: Api.ISurvey,
		impersonationContext?: Api.IImpersonationContext
	) => {
		const opResult = await userSession.webServiceHelper.callWebServiceAsync<Api.ISurvey>(
			Api.ImpersonationBroker.composeApiUrl({ impersonationContext, urlPath: `survey/${survey?.id}` }),
			'GET'
		);

		if (!opResult.success) {
			throw opResult;
		}

		// @ts-ignore
		return toViewModel(userSession, opResult.value, impersonationContext) as TSurveyViewModel;
	};

	constructor(userSession: UserSessionContext, survey: TSurvey) {
		super(userSession);
		this.executeRequest = this.executeRequest.bind(this);
		this.mSetSurvey = this.mSetSurvey.bind(this);
		this.mSetSurvey(survey);
	}

	@computed
	public get type() {
		return this.mSurvey?._type;
	}

	@computed
	public get id() {
		return this.mSurvey?.id;
	}

	@computed
	public get creationMoment() {
		return this.mSurvey?.creationDate ? moment(this.mSurvey.creationDate) : null;
	}

	@computed
	public get archiveMoment() {
		return this.mSurvey?.archivedDate ? moment(this.mSurvey.archivedDate) : null;
	}

	@computed
	public get anonymousLink() {
		return this.mSurvey?.anonymousLink;
	}

	@computed
	public get creator() {
		return this.mSurvey?.creator;
	}

	@computed
	public get name() {
		return this.mSurvey?.name;
	}

	@computed
	public get expirationMoment() {
		return this.mSurvey?.expirationDate ? moment(this.mSurvey.expirationDate) : null;
	}

	@computed
	public get startMoment() {
		return this.mSurvey?.startDate ? moment(this.mSurvey.startDate) : null;
	}

	public load = async () => {
		if (!this.busy) {
			this.busy = true;
			const opResult = await this.executeRequest('GET');
			this.busy = false;
			if (!opResult.success) {
				throw opResult;
			}

			this.mSetSurvey(opResult.value);
			return opResult;
		}
	};

	public archive = async () => {
		if (!this.busy) {
			this.busy = true;
			const opResult = await this.executeRequest('DELETE');
			this.busy = false;

			if (!opResult.success) {
				throw opResult;
			}

			this.mSetSurvey(opResult.value);
			return opResult;
		}
	};

	public update = async (survey: TSurvey) => {
		if (survey && !this.busy) {
			this.busy = true;
			// @ts-ignore
			const opResult = await this.executeRequest('PUT', null, null, survey);
			this.busy = false;

			if (!opResult.success) {
				throw opResult;
			}

			this.mSetSurvey(opResult.value);
			return opResult;
		}
	};

	public getLink = async () => {
		if (!this.busy) {
			this.busy = true;
			const opResult = await this.executeRequest<string>('GET', 'link');
			this.busy = false;

			if (!opResult.success) {
				throw opResult;
			}
			return opResult;
		}
	};

	public toJs = () => {
		return this.mSurvey;
	};

	protected mSetSurvey(survey?: TSurvey) {
		// @ts-ignore
		this.mSurvey = survey;
	}

	protected executeRequest<TResult = TSurvey>(
		method: Api.HTTPMethod,
		navigationPath?: string,
		queryParams?: Api.IDictionary<any>,
		survey?: TSurvey
	) {
		return this.userSession.webServiceHelper.callWebServiceAsync<TResult>(
			this.composeApiUrl({
				queryParams,
				urlPath: `survey/${this.mSurvey.id}${navigationPath ? `/${navigationPath}` : ''}`,
			}),
			method,
			survey
		);
	}
}

export class SatisfactionSurveyViewModel extends SurveyViewModel<Api.ISatisfactionSurvey> {
	@computed
	public get reviewRequestSettings() {
		return this.mSurvey?.reviewRequestSettings;
	}

	@computed
	public get stats() {
		return this.mSurvey?.stats;
	}

	@computed
	public get companyDisplayName() {
		return this.mSurvey?.companyDisplayName;
	}

	@computed
	public get intro() {
		return this.mSurvey?.intro;
	}
}

export class EventSurveyViewModel extends SurveyViewModel<Api.IEventRegistrationSurvey> {
	@computed
	public get stats() {
		return this.mSurvey?.stats;
	}

	@computed
	public get startMoment() {
		return this.mSurvey?.eventInformation?.startTime ? moment(this.mSurvey.eventInformation.startTime) : null;
	}

	@computed
	public get eventInformation() {
		return this.mSurvey?.eventInformation;
	}

	@computed
	public get attendeeOptions() {
		return this.mSurvey?.attendeeOptions;
	}
}

export class SurveyReportViewModel<
	TSurvey extends Api.ISurvey = Api.ISurvey,
	TSurveyResponse extends Api.ISurveyResponse = Api.ISurveyResponse,
	TSurveyViewModel extends SurveyViewModel<TSurvey> = SurveyViewModel<TSurvey>,
	TSortDescriptor extends Api.ISortDescriptor = Api.ISortDescriptor,
	TResponseTypeDescriptor extends Api.IResponseTypeDescriptor = Api.IResponseTypeDescriptor,
> extends FilteredPageCollectionController<TSurveyResponse, TSurveyResponse, Api.ISurveyResponseFilterRequest> {
	protected mSurvey: TSurveyViewModel;
	@observable.ref public dateRange: { start: Date; end: Date };
	// @ts-ignore
	@observable.ref public sortDescriptor: TSortDescriptor;
	// @ts-ignore
	@observable.ref public responseTypeDescriptor: TResponseTypeDescriptor;

	public static instanceWithSurvey = <
		STSurvey extends Api.ISurvey = Api.ISurvey,
		STSurveyResponse extends Api.ISurveyResponse = Api.ISurveyResponse,
		STSurveyViewModel extends SurveyViewModel<STSurvey> = SurveyViewModel<STSurvey>,
	>(
		userSession: UserSessionContext,
		survey: STSurveyViewModel,
		options?: Partial<IBaseObservablePageCollectionControllerOptions<STSurveyResponse, STSurveyResponse>>
	) => {
		if (survey instanceof SatisfactionSurveyViewModel) {
			return new SatisfactionSurveyReportViewModel(userSession, survey, options as any).impersonate(
				survey.impersonationContext
			);
		}
		if (survey instanceof EventSurveyViewModel) {
			return new EventSurveyReportViewModel(userSession, survey, options as any).impersonate(
				survey.impersonationContext
			);
		}
		// @ts-ignore
		return new SurveyReportViewModel(userSession, survey, options).impersonate(survey.impersonationContext);
	};

	constructor(
		userSession: UserSessionContext,
		survey: TSurveyViewModel,
		options?: Partial<IBaseObservablePageCollectionControllerOptions<TSurveyResponse, TSurveyResponse>>
	) {
		super({
			apiPath: () => {
				return `survey/${this.mSurvey?.id}/response/filter`;
			},
			client: userSession.webServiceHelper,
			...(options || {}),
		});
		this.getNext = this.getNext.bind(this);
		this.export = this.export.bind(this);
		this.mSurvey = survey;
		this.dateRange = {
			end: moment().endOf('month').toDate(),
			start: moment().subtract(1, 'month').startOf('month').toDate(),
		};
	}

	@computed
	public get request(): Api.ISurveyResponseFilterRequest {
		const request: Api.ISurveyResponseFilterRequest = {
			endDate: this.dateRange?.end?.toISOString(),
			startDate: this.dateRange?.start?.toISOString(),
			// @ts-ignore
			surveyId: this.mSurvey?.id,
		};

		return request;
	}

	@action
	public getNext(request = this.request, pageSize?: number, params?: Api.IDictionary) {
		const computedParams = this.sortDescriptor ? { ...(params || {}), ...this.sortDescriptor } : null;
		// @ts-ignore
		return super.getNext(request, pageSize, computedParams);
	}

	@action
	public async export(request = this.request) {
		const requestBody: Api.ISurveyResponseExportRequest = {
			filter: request,
			// @ts-ignore
			surveyId: this.mSurvey.id,
		};

		const opResult = await this.mSurvey.userSession.webServiceHelper.callWebServiceAsync<Api.ISystemJob>(
			this.composeApiUrl({ urlPath: `survey/${getApiActionPathForSurvey(this.mSurvey.toJs())}/response/export` }),
			'POST',
			requestBody
		);

		if (!opResult.success) {
			throw opResult;
		}

		return opResult.value;
	}

	public get survey() {
		return this.mSurvey;
	}

	public load = () => {
		return this.mSurvey?.load();
	};

	public setDateRangeToShowAllResponses = () => {
		const nextDateRange = {
			end: moment(this.mSurvey.expirationMoment || moment())
				.endOf('month')
				.toDate(),
			start: moment(this.mSurvey.startMoment || this.mSurvey.creationMoment)
				.startOf('month')
				.toDate(),
		};
		this.dateRange = nextDateRange;
	};

	@action
	public getStatsForDateRange = async (dateRange?: { start?: Date; end?: Date }) => {
		const opResult = await this.mSurvey.userSession.webServiceHelper.callWebServiceAsync<Api.ISatisfactionSurveyStats>(
			this.composeApiUrl({
				urlPath: `survey/${getApiActionPathForSurvey(this.mSurvey.toJs())}/${this.mSurvey.id}/stats/filter`,
			}),
			'POST',
			dateRange
				? {
						...this.request,
						endDate: dateRange.end?.toISOString(),
						startDate: dateRange.start?.toISOString(),
					}
				: this.request
		);
		if (!opResult.success) {
			throw opResult;
		}

		return opResult;
	};
}

export class SatisfactionSurveyReportViewModel extends SurveyReportViewModel<
	Api.ISatisfactionSurvey,
	Api.ISatisfactionSurveyResponse,
	SatisfactionSurveyViewModel,
	Api.ISatisfactionSurveySortDecriptor
> {
	constructor(
		userSession: UserSessionContext,
		survey: SatisfactionSurveyViewModel,
		options?: Partial<
			IBaseObservablePageCollectionControllerOptions<Api.ISatisfactionSurveyResponse, Api.ISatisfactionSurveyResponse>
		>
	) {
		super(userSession, survey, {
			apiPath: () => {
				return `survey/satisfaction/response/filter`;
			},
			client: userSession.webServiceHelper,
			...(options || {}),
		});
	}

	@computed
	public get request(): Api.ISatisfactionSurveyResponseFilterRequest {
		const request: Api.ISatisfactionSurveyResponseFilterRequest = {
			endDate: this.dateRange?.end?.toISOString(),
			startDate: this.dateRange?.start?.toISOString(),
			// @ts-ignore
			surveyId: this.mSurvey?.id,
			_type: 'SatisfactionSurveyResponseFilterRequest',
		};

		return request;
	}
}

export class EventSurveyReportViewModel extends SurveyReportViewModel<
	Api.IEventRegistrationSurvey,
	Api.IEventRegistrationSurveyResponse,
	EventSurveyViewModel,
	Api.IEventSurveySortDescriptor,
	// @ts-ignore
	Api.IEventSurveyResponseTypeDescriptor
> {
	@observable public selectedStatus: Api.EventRegistrationResponseStatus =
		Api.EventRegistrationResponseStatus.Attending;
	@observable.ref public eventResponseStats: Api.IEventRegistrationSurveyResponseStats;

	@observable.ref public eventResponseSearchQuery = '';

	constructor(
		userSession: UserSessionContext,
		survey: EventSurveyViewModel,
		options?: Partial<
			IBaseObservablePageCollectionControllerOptions<
				Api.IEventRegistrationSurveyResponse,
				Api.IEventRegistrationSurveyResponse
			>
		>
	) {
		super(userSession, survey, {
			apiPath: () => {
				return `survey/eventRegistration/response/filter`;
			},
			client: userSession.webServiceHelper,
			...(options || {}),
		});
		// @ts-ignore
		this.eventResponseStats = survey.stats?.responseStats?.find(x => x.category === 'Total');
	}

	@computed
	public get searchQuery() {
		return this.eventResponseSearchQuery;
	}
	public set searchQuery(value: string) {
		this.eventResponseSearchQuery = value;
	}

	@computed
	public get request() {
		const request: Api.IEventRegistrationSurveyResponseFilterRequest = {
			status: [this.selectedStatus],
			search: this.eventResponseSearchQuery,
			surveyId: this.survey?.id,
			_type: 'EventRegistrationSurveyResponseFilterRequest',
		};

		return request;
	}

	@action
	public async export(request = this.request) {
		const exportRequest = { ...(request || {}) } as Api.IEventRegistrationSurveyResponseFilterRequest;
		// @ts-ignore
		delete exportRequest.status; // important, we want to export all
		return super.export(exportRequest);
	}
}
